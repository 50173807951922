<template>
  <div class="mt-5" id="LayaListCarousel" :class="{hideLeftArrow: !showLeftArrow, hideRightArrow: !showRightArrow}">
    <h3 class="mb-4">{{title}}</h3>
    <p class="description" v-if="description">{{description}}</p>
    <div class="position-relative">
      <div v-if="content" class="position-relative">
        <div class="slick-arrow-prev d-flex slick-arrow" :id="layaId(this.title)" @click="prev" :tabindex="showLeftArrow ? 0:-1" @keydown.enter="prev">
          <a class="bg-brand-highlight-1 slick-arrow-container" :id="layaId(this.title)">
            <i :id="layaId(this.title)" style="font-size: 16px" class="fa-regular fa-chevron-left laya-color-primary"></i>
          </a>
        </div>
        <slick
            @afterChange="handleSwipe"
            @beforeChange="handleBeforeChange"
            id="slick-id" ref="slick" :options="slickOptions" class="justify-content-start">
          <div v-for="(item, i) in content" :key="i" class="my-2">
            <LayaCarouselListCard :item="item" :swiping="swiping" :type="type" :matomo_class="matomo_class" :t_i="visibleSlides.includes(i) ? 0 : -1"/>
          </div>
        </slick>
        <div class="slick-arrow-next d-flex slick-arrow" :id="layaId(this.title)" @click="next" :tabindex="showRightArrow ? 0:-1" @keydown.enter="next">
          <a class="bg-brand-highlight-1 slick-arrow-container" :id="layaId(this.title)">
            <i :id="layaId(this.title)" style="font-size: 16px" class="fa-regular fa-chevron-right laya-color-primary"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import slick from "vue-slick"
import {mapGetters} from "vuex";
import LayaCarouselListCard from "@/components/NewWellbeing/LayaCarouselListCard.vue";

export default {
  name: "LayaListCarousel",
  props: {
    type: {
      type: String
    },
    title: {
      type: String
    },
    description: {
      type: String,
      default: false
    },
    content: {
      type: Array,
      default: []
    }
  },
  components: {LayaCarouselListCard, slick},
  data() {
    return {
      currentSlide: 0,
      loading_new_content: false,
      page: 1,
      totalPages: 1,
      totalSlides: null,
      swiping: false,
      slidesBeingDisplayed: 0,
      slickOptions: {
        initialSlide: 0,
        arrows: false,
        slidesToScroll: 1,
        nextArrow:
            `<div class="slick-arrow-next d-flex ${this.layaClass(this.title)}">` +
            `<a class="bg-brand-highlight-1 slick-arrow-container ${this.layaClass(this.title)} ${this.matomo_class}"><i style="font-size: 16px" class="fa-regular fa-chevron-right laya-color-primary  ${this.layaClass(this.title)}"></i></a>` +
            '</div>',
        prevArrow:
            `<div class="slick-arrow-prev d-flex ${this.layaClass(this.title)}">` +
            `<a class="bg-brand-highlight-1 slick-arrow-container ${this.layaClass(this.title)}"><i style="font-size: 16px" class="fa-regular fa-chevron-left laya-color-primary ${this.layaClass(this.title)}"></i></a>` +
            '</div>',
        adaptiveHeight: true,
        infinite: false,
        slidesToShow: 4,
        responsive: this.layaCarouselResponsiveOptions(),
      },
      visibleSlides: []
    }
  },
  mounted() {
    this.getContent(true);
  },
  computed: {
    ...mapGetters(["user"]),
    courseProgress() {
      return this.user.user.course_progress;
    },
    matomo_class() {
      return this.title.toLowerCase().replace(/\s/g, '_') + "_carousel_card";
    },
    studioEventProgressData() {
      if(!this.courseProgress) return null;
      return  this.courseProgress.filter(item => item.channel === 'dg' || item.channel === 'ds')
    },
    showLeftArrow() {
      return this.currentSlide !== 0 && this.contentLength > this.slidesBeingDisplayed;
    },
    showRightArrow() {
      return this.currentSlide + this.slidesBeingDisplayed < this.contentLength;
    },
    contentLength() {
      return this.content.length;
    }
  },
  methods: {
    next() {
      this.$refs.slick.next();
    },
    prev() {
      this.$refs.slick.prev();
    },
    seeMore() {
      switch (this.type.toLowerCase()) {
        case 'featured-event':
          this.$router.push("/wellbeing-studio");
          break;
        case 'studio':
          this.$router.push("/wellbeing-studio");
          break;
        case 'recipes':
          this.$router.push("/personal/nutrition");
          break;
        case 'gym':
          this.$router.push("/digital-gym");
          break;
        case 'classes':
          this.$router.push("/digital-gym");
          break;
        case 'exercise':
          this.$router.push("/digital-gym?selectedView='training_plans'");
          break;
        case 'courses':
          this.$router.push("/wellbeing");
          break;
        case 'surge':
          this.$router.push("/wellbeing");
          break;
        case 'be_calm': case 'be-calm':
          this.$router.push("/be-calm");
          break;
        case 'clinic':
          this.$router.push("/clinics/digital");
          break;
        case 'podcasts':
          this.$router.push("/sound-space/");
          break;
      }
    },
    handleBeforeChange() {
      //prevents reroute whilst tiles are in motion
      this.swiping = true;
      setTimeout(()=> {this.swiping = false},1000);
    },
    async handleSwipe(event, slick, currentSlide) {
      //if the total records - the current slide is 4x
      this.slidesBeingDisplayed = slick.options.slidesToShow;
      this.currentSlide = currentSlide;
      this.setVisibleSlides();
    },
    setVisibleSlides() {
      this.visibleSlides = [];
      for (let i = this.currentSlide; i < this.currentSlide + this.slidesBeingDisplayed; i++) {
        this.visibleSlides.push(i % this.content.length);
      }
    },
    async getContent(reInit = false) {
      this.reinitSlider(false);
    },
    reinitSlider(backToStart = false) {
      let self = this;
      if(backToStart) this.slickOptions.initialSlide = 0;
      if (self.$refs.slick) {
        let currentSlide = backToStart ? 0 : self.$refs.slick.currentSlide();
        this.$refs.slick.destroy();
        this.$nextTick(() => {
          let options = this.type === 'featured-event' ? self.eventSlickOptions : self.slickOptions;
          options.initialSlide = currentSlide;
          self.$refs.slick.create(options);
          this.slidesBeingDisplayed = self.$refs.slick.options.slidesToShow;
        });
      }
      this.loading_new_content = false;
      this.$nextTick(()=> {
        this.setVisibleSlides();
      });
    },
  },
  watch: {
    contentLength() {
      this.reinitSlider(true);
      this.$nextTick(()=> {
        this.setVisibleSlides();
      });
    }
  }
}
</script>

<style  lang="less">
#LayaListCarousel {
  width: calc(100% - 80px);
  margin: auto;
  .slick-track {
    margin: 0 !important;
  }
  &.hideLeftArrow {
    .slick-arrow-prev {
      display: none !important;
    }
  }
  &.hideRightArrow {
    .slick-arrow-next {
      display: none !important;
    }
  }
  .slick-slider {
    .slick-slide {
      transform: scale(1);
      margin: 0 8px;
    }
  }
  .description {
    font-family: Open Sans, sans-serif;
    margin-left: 10px;
  }
  .loadingCard {
    width: 100%;
    aspect-ratio: 274 / 363;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .hideLoader {
    width: 0 !important;
  }
  .slick-slide {
    margin: 0 !important;
  }
  .slick-slide:has(.hideLoader) {
    width: 0 !important;
    overflow: hidden !important;
  }
  .slick-arrow {
    cursor: pointer;
    border: 1px solid #d60570;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
    &:hover {
      background-color: #d60570;
      i {
        color: white;
      }
    }
  }
  .slick-arrow-next {
    right: -45px;
  }

  .slick-arrow-prev {
    left: -45px;
  }
  @media only screen and (max-width: 1066px) and (min-width: 992px) {
    .cardText {
      padding: 15px 15px 15px 15px !important;
    }
  }
  @media only screen and (max-width: 992px) {
    width: 100% !important;
    .cardText {
      padding: 15px 15px 15px 15px !important;
    }
    h2 {
      margin-left: 20px;
    }
    .layaBtn {
      margin-right: 20px;
    }
    .slick-arrow {
      display: none !important;
    }
  }
  @media only screen and (max-width: 576px) {
    width: 100% !important;
    .cardText {
      padding: 16px !important;
    }
    padding-right: 0 !important;
    h3, .description {
      margin-left: 0 !important;
    }
    h2 {
      margin-left: 20px;
    }
    .layaBtn {
      margin-right: 20px;
    }
    .slick-arrow {
      display: none !important;
    }
  }
  @media only screen and (min-width: 992px) {

    #LayaCarouselCard {
      margin-left: 2px !important;
      margin-right: 30px;
    }
  }
}
</style>