<template>
  <div id="TrainingPlan" class="d-flex justify-content-center">
    <div class="training-plan-container" v-if="!loading">
      <div class="row h-100">
        <div
          class="
            training-plan-selection-container
            bg-brand-primary
            col-md-4 col-sm-12
            text-white
            d-flex
            flex-column
            align-items-center
          "
        >
          <div
            class="
              training-plans-selection-title
              text-center text-white
              mb-5
              mt-5
            "
          >
            {{componentText.classes}}
          </div>
          <div class="training-plan-selection-items">
            <div
              class="
                training-plan-selection-item
                top-cap
                px-3
                d-flex
                flex-row
                align-items-center
                border-none
              "
            >
              <i class="fa fa-circle" aria-hidden="true"></i>
            </div>
            <div
              class="
                training-plan-selection-item
                px-3
                d-flex
                flex-row
                align-items-center
              "
              :class="[currentClassIndex > index ? 'complete' : 'in-progress']"
              v-for="(planClass, index) in trainingPlan.training_classes"
              :key="index"
            >
              <i
                class="fa fa-circle py-4"
                :class="{
                  'brand-highlight-1': currentClassIndex === index,
                  'training-plan-selection-item-first': index === 0,
                  'training-plan-selection-item-last':
                    index === trainingPlan.training_classes.length - 1,
                }"
                aria-hidden="true"
              ></i>
              <span
                class="training-plan-selection-item-title py-4"
                :class="{
                  'training-plan-selection-item-first': index === 0,
                  'training-plan-selection-item-last':
                    index === trainingPlan.training_classes.length - 1,
                }"
                @click="switchClass(index)"
                >{{ planClass.title }}</span
              >
            </div>
            <div
              class="
                training-plan-selection-item
                end-cap
                px-3
                d-flex
                flex-row
                align-items-center
                border-none
                pb-5
              "
            >
              <i class="fa fa-circle" aria-hidden="true"></i>
            </div>
          </div>
        </div>
        <div class="col-md-8 col-sm-12">
          <div class="training-plan-content-container">
            <div class="px-5 mb-5 pb-5">
              <div class="training-plan-content-back-container">
                <a
                  class="chapters__back-link pb-5 brand-primary"
                  @click="$router.push('/digital-gym?selectedView=%27training_plans%27')"
                >
                  <i class="fas fa-arrow-circle-left"></i> Back to Training
                  Plans
                </a>
              </div>
              <div class="training-plan-content-title-container">
                <h2 class="training-plan-content-title pb-5">
                  {{ currentClass.title }}
                </h2>
              </div>
              <div
                class="training-plan-content-video-container"
                v-if="currentClass.video_url"
              >
                <TrainingPlanVideo :classItem="currentClass" />
              </div>
              <div class="training-plan-content-description brand-text">
                {{ currentClass.description }}
              </div>
              <div
                class="training-plan-content-buttons-container d-flex flex-row"
                :class="[
                  currentClassIndex === 0
                    ? 'justify-content-end'
                    : 'justify-content-between',
                ]"
              >
                <b-button
                  class="cta-button shadow py-3 px-5 mx-4 my-3 my-md-0"
                  @click="switchClass(currentClassIndex - 1)"
                  v-if="currentClassIndex !== 0"
                  >{{componentText.buttonPrevious}}</b-button
                >
                <b-button
                  class="cta-button shadow py-3 px-5 mx-4 my-3 my-md-0"
                  @click="switchClass(currentClassIndex + 1)"
                  v-if="
                    currentClassIndex !==
                    trainingPlan.training_classes.length - 1
                  "
                  >{{componentText.buttonNext}}</b-button
                >
                <b-button
                  class="cta-button shadow py-3 px-5 mx-4 my-3 my-md-0"
                  @click="finishTrainingPlan()"
                  v-else
                  >{{componentText.finish}}</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="spinner-container d-flex flex-row align-items-center mt-5"
      v-else
    >
      <div class="mx-auto my-auto">
        <b-spinner class="brand-primary"></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import TrainingPlanVideo from "../components/DigitalGym/TrainingPlanVideo.vue";

export default {
  name: "TrainingPlan",
  components: {
    TrainingPlanVideo,
  },
  data() {
    return {
      loading: true,
      trainingPlan: {},
      currentClassIndex: 0,
      disclaimerVisible: false,
    };
  },
  mounted() {
    this.getTrainingPlan();
  },
  methods: {
    async getTrainingPlan() {
      let self = this;
      const payload = {
        user_id: this.$store.getters.user_id,
      };
      let res = await this.axios.post(
        process.env.VUE_APP_API_PATH +
          "/application/api/u/studio/get-training-plans",
        payload
      );
      if (res.data.success) {
        let planId = this.$route.params.id;
        let plans = res.data.training_plans;
        this.trainingPlan = plans.find(function (plan) {
          console.log("curious");
          return parseInt(plan.id) === parseInt(self.$route.params.id);
        });
        this.loading = false;
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      }
    },
    async markAsAttended(classItem) {
      let self = this;
      const payload = {
        user_id: this.$store.getters.user_id,
        training_class_id: classItem.id,
      };
      let res = await this.axios.post(
        process.env.VUE_APP_API_PATH +
          "/application/api/u/studio/training-class-attended",
        payload
      );
      if (res.data.success) {
        console.log(classItem.title + " marked as attended");
        classItem.attended = true;
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      }
    },
    switchClass(index) {
      if (this.currentClass.attended === false) {
        this.markAsAttended(this.currentClass);
      }
      this.currentClassIndex = index;
    },
    finishTrainingPlan() {
      if (this.currentClass.attended === false) {
        this.markAsAttended(this.currentClass);
      }
      this.$router.push("/digital-gym?selectedView=%27training_plans%27");
    },
  },
  computed: {
    componentText() {
      return this.getText.digitalGym;
    },
    currentClass() {
      return this.trainingPlan.training_classes[this.currentClassIndex];
    },
  },
};
</script>

<style lang="less">
#TrainingPlan {
  .training-plan-container {
    background-color: white;
    flex-grow: 1;
    .training-plan-selection-container {
      .training-plans-selection-title {
        font-size: 3rem;
        font-family: FilsonProBold, Arial, Helvetica, sans-serif;
        padding-top: 30px;
        @media only screen and (max-width: 576px) {
          padding-top: 10px;
        }
      }
      .training-plan-selection-items {
        .training-plan-selection-item {
          .training-plan-selection-item-title {
            cursor: pointer;
            border-left: 2px white solid;
            padding-left: 30px;
            font-size: 2.5rem;
            font-weight: bold;
            font-family: FilsonProBold, Arial, Helvetica, sans-serif;
          }
          .training-plan-selection-item-first {
            padding-top: 40px !important;
          }
          .training-plan-selection-item-last {
            padding-bottom: 40px !important;
          }
          .fa,
          .fas {
            font-size: 2rem;
            position: relative;
            left: 11px;
            top: 3px;
            -webkit-text-stroke: 1px #fff;
          }
        }
        .training-plan-selection-item.top-cap {
          .fa,
          .fas {
            font-size: 1.5rem;
            position: relative;
            left: 13px;
            -webkit-text-stroke: 1px #fff;
          }
        }
        .training-plan-selection-item.end-cap {
          .fa,
          .fas {
            font-size: 1.5rem;
            position: relative;
            left: 14px;
            top: -2px;
            -webkit-text-stroke: 1px #fff;
          }
        }
        .training-plan-selection-item.complete {
          .fa,
          .fas {
            color: grey;
          }
          .training-plan-selection-item-title {
            color: lightgrey;
          }
        }
      }
    }
    .training-plan-selection-container:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      border-top: 60px solid white;
      border-left: 60px solid transparent;
      width: 0;
    }
    .training-plan-content-container {
      background-color: white;
      max-width: 1100px;
      margin-left: auto;
      margin-right: auto;
      .training-plan-content-back-container {
        padding-top: 30px;
        padding-bottom: 50px;
        font-size: 2rem;
        .fa,
        .fas {
          font-size: 3rem;
          position: relative;
          top: 5px;
          padding-right: 10px;
        }
      }
      .training-plan-content-title-container {
        .training-plan-content-title {
          font-family: FilsonProBold, Arial, Helvetica, sans-serif;
          font-size: 3.5rem;
          font-weight: bold;
          text-align: left;
        }
      }
      .training-plan-content-description {
        font-size: 1.6rem;
        padding-bottom: 50px;
      }
    }
  }
  .text-link {
    font-size: 1.6rem;
    text-decoration: none;
    border-bottom: 0;
  }
}
</style>
