<template>
  <div id="home-events" class=" page-bg bg-white px-md-5" v-if="bookingCount > 0 || Object.keys(events).length > 0">
  <div class="content-container">
    <div class="section-header">
      <div class="section-header-divider"></div>
      <h3 class="section-header-title">{{componentText.title}}</h3>
    </div>
    <div class="row">
      <div class="col-lg-7 order-0">
        <h4>{{componentText.upcoming}}</h4>
        <div class="event-card-wrap row mx-0">
          <div class="event-card col-12 col-sm-6 pl-0" v-for="event in events">
            <div class="row mx-0 event-card-content bg-brand-primary" @click="bookNow(event)">
              <div class="col-7 event-title ">
                {{ event.title }}
              </div>
              <div class="col-5 event-date pt-3">
                <div class="event-day">
                  {{ getFormatedDate(event.start_date, "DD") }}
                </div>
                <div class="event-month">
                  {{ getFormatedDate(event.start_date, "MMM") }}
                </div>
              </div>
              <div class="col-5 book-now cta-button hover-darken py-3" >
                <span>{{componentText.book}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 order-lg-1 order-2">
        <h4>{{componentText.bookedEvents}}</h4>
        <div class="booked-events-wrap">
          <div
            v-if="bookingCount > 0"
            v-for="(booking, i) in bookings.items"
            class="booked-event border-brand-primary brand-secondary row mx-0"
            @click="$router.push({name: 'events', params:booking, query:{page:'1'}})"
          >
            <div class="col-2 start-date">
              <div class="start-day">
                {{ $date(new Date(booking.timeslot_start), "DD") }}
              </div>
              <div class="start-month">
                {{ $date(new Date(booking.timeslot_start), "MMM") }}
              </div>
            </div>
            <div class="col-8 booking-title">
              {{ booking.title }}
            </div>
            <div class="col-2">
              <div class="marker"></div>
            </div>
          </div>
          <div v-if="bookingCount > 0" class="booking-line"></div>
          <div class="no-bookings" v-else>
            {{componentText.noBookings}}
          </div>
      </div>
      </div>
      <div class="col-lg-7 order-lg-2 order-1 mt-lg-5 mb-lg-0 mb-3 py-5">
        <a class="home-link link-highlight" :class="{'border-brand-secondary': $store.getters.skin === 8 }" @click="$router.push({name: 'events'})">{{componentText.eventCalendar}}</a>
      </div>
      <div class="col-lg-5 order-3 mt-lg-5 py-5">
        <a class="home-link link-highlight" :class="{'border-brand-secondary': $store.getters.skin === 8 }" @click="$router.push({name: 'events', query:{page: 'bookings'}})">{{componentText.yourBookings}}</a>
      </div>
      </div>
  </div>
  </div>
</template>
<script>
// @ is an alias to /src
import axios from "axios";
import config from "../../config/constants";
import moment from 'moment'
export default {
  name: "HomeEvents",
  components: {},
  data() {
    return {
      events: {},
      bookings: {}
    };
  },
  mounted() {
    this.getEvents();
    this.getBookings();
  },
  methods: {
    async getEvents() {
      const payload = {
        user_id: this.$store.getters.user_id,
        category: "all",
        limit: 2
      };
      let res = await axios.post(
        config.api_env + "/application/api/u/events/get-home-events",
        payload,
          config.options
      );
      if(res.data.events_services) {
          this.events = res.data.events_services;
          this.parseEventDates();
      }
    },
    async getBookings() {
      const payload = {
        user_id: this.$store.getters.user_id,
        status: "upcoming",
        limit: 2
      };
      let res = await axios.post(
        config.api_env + "/application/api/u/events/get-bookings",
        payload,
          config.options
      );
      if(res.data.success) {
          this.bookings = res.data;
          this.parseBookingDates();
      }
    },
    parseEventDates() {
        //this is needed for IE
        if(this.events && this.events[0] && this.events[0].start_date) {
            let length = Object.keys(this.events).length;
            for (let i = 0; i < length; i++) {
                this.events[i].start_date = this.events[i].start_date.split(' ')[0]
            }
        }
    },
    parseBookingDates() {
        if(this.bookings && this.bookings.items) {
            let length = Object.keys(this.bookings.items).length;
            for (let i = 0; i < length; i++) {
                this.bookings.items[i].timeslot_start = this.bookings.items[i].timeslot_start.split(' ')[0]
            }
            this.bookings.items.sort((a,b) => (a.timeslot_start > b.timeslot_start)? 1 : -1)
        }
    },
    bookNow(event) {
      this.$router.push({
      name: "viewEvent",
      params: { id: event.event_date_id }
    });
    },
    exploreAll(){
        this.$router.push({name: 'events'}).catch(err => {})
    }
  },
    computed:{
        bookingCount(){
            if(this.bookings.items) {
                return Object.keys(this.bookings.items).length
            }
        },
      componentText(){
        return this.getText.loggedIn.home.homeEvents
      }
    }
};
</script>
<style lang="less">
.event-card-wrap {
  .event-card {
    &:first-child {
      padding-right: 25px;
    }
    &:last-child {
      padding-left: 25px;
    }
    .event-card-content {
      padding: 0 10px 35px 15px;
      border-radius: 10px 10px 0 10px;
      height: 100%;
      min-height: 170px;
      position: relative;
      cursor: pointer;
      &:hover{
        box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.3);
        transform: translate(-2px, -2px);
      }
      .event-title {
        color: #fff;
        font-size: 2rem;
        font-family: "FilsonProBook", sans-serif;
        font-weight: 400;
        line-height: 1.2;
        padding-top: 30px;
      }
      .event-date {
        color: #fff;
        font-family: "FilsonProLight", sans-serif;
        line-height: 1;
        text-align: right;
        .event-day {
          font-size: 6.4rem;
        }
        .event-month {
          font-size: 2.5rem;
        }
      }
      .book-now {
        position: absolute;
        right: 0;
        bottom: 0;
        text-align: center;
        font-size: 1.6rem;
        font-family: "filsonProBold", sans-serif;
        border-radius: 25px 0 0 0;
        cursor: pointer;

      }
    }
  }
}
.booked-events-wrap {
  position: relative;
  min-height: 170px;
  .booked-event {
    border-style: solid;
    border-width: 0 0 0 10px;
    border-radius:  0 10px 10px 0;
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.3);
    position: absolute;
    right: 0;
    width: 100%;
    min-height: 75px;
    font-family: "FilsonProBold", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover{
      transform: translateY(-2px);
    }
    &>div{
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        top: 13px;
      }
    }
    &:first-child {
      top: 0;
    }
    &:nth-child(2) {
      bottom: 0;
    }
    .start-date {
      text-align: center;
      .start-day {
        font-size: 2.6rem;
        line-height: 1;
      }
      .start-month {
        font-size: 2rem;
        line-height: 1;
        font-family: "filsonProBook", sans-serif;
      }
    }
    .booking-title {
      font-size: 1.8rem;
      margin: auto;
      &::first-letter{
        text-transform: uppercase;
      }
    }
    .marker {
      content: "";
      position: absolute;
      width: 12px;
      height: 12px;
      border: 1px solid #7e4578;
      border-radius: 50%;
      background-color: white;
      transform: translateY(-50%);
      z-index: 1;
      right: 30px;
    }
  }
  .booking-line {
    width: 1px;
    position: absolute;
    height: 100%;
    right: 35px;
    background-color: #929497;
  }
  .no-bookings {
    font-size: 2.4rem;
    font-family: "FilsonProBook";
  }
}
</style>
