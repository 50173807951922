<template>
  <div id="LayaServicesDisplay" style="padding-right: 40px; padding-left: 40px; margin-top: 32px; margin-bottom: 48px">
    <div v-for="item in servicesData" :class="layaClass(item.label)" >
      <div  class="outerServiceCard" @click="goToService(item)" :id="layaId(item.label)">
        <div class="innerServiceCard" :style="{ backgroundImage: 'url(' + item.img + ')' }" :class="layaClass(item.label)" :id="layaId(item.label)">
        </div>
        <div class="cardLabel" tabindex="0" @keydown.enter="goToService(item)">{{item.label}}</div>
      </div>
      <div class="subTitle mt-3" :class="layaClass(item.label)" >
        {{item.subTitle}}
      </div>
    </div>
  </div>
</template>

<script >
export default {
  name: 'LayaServicesDisplay',
  data() {
    return {
      servicesData: [
        {
          name: "layaDigitalGym",
          item_slug: "digitalGym",
          img: "/img/laya-services/dg.webp",
          subTitle: "Access live and on-demand classes like yoga, Pilates and HIIT.",
          label: "Digital gym",
          external_link: null,
          clickHere: "Access the gym"
        },
        {
          name: "layaWellbeingSeries",
          item_slug: "wellbeingSeries",
          img: "/img/laya-services/ds.webp",
          subTitle: "Watch expert speakers in a series of events and seminars.",
          label: "Wellbeing series",
          external_link: null,
          clickHere: "See the events"
        },
        {
          name: "layaEap",
          item_slug: "eap",
          img: "/img/laya-services/cahms.webp",
          subTitle: "Access immediate mental wellbeing support, 24 hours a day.",
          label: "Speak with a counsellor",
          external_link: null,
          clickHere: "Find out more"
        },
        {
          name: "layaBeCalm",
          item_slug: "beCalm",
          img: "/img/laya-services/meditation-m.webp",
          subTitle: "Access meditations to help with sleep, anxiety, stress and more.",
          label: "Guided meditation",
          external_link: null,
          clickHere: "Get Started"
        },
        {
          name: "layaSupport",
          item_slug: "expert",
          img: "/img/laya-services/eap.webp",
          subTitle: "Our team of experts are here to help with legal issues, mediation, career counselling and more.",
          label: "Expert support",
          external_link: null,
          clickHere: "Find out more"
        },
      ]
    }
  },
  methods: {
    goToService(item) {
      this.$router.push({name: item.item_slug});
      this.m('service-card-clicked',{card_label: item.label})
    }
  }
}
</script>

<style lang="less">
#LayaServicesDisplay {
  font-family: "Cocon Pro Regular", sans-serif;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 32px;
  row-gap: 32px;
  .subTitle {
    font-size: 16px;
    font-family: Open Sans, sans-serif;
  }
  .outerServiceCard {
    aspect-ratio: 376 / 200;
    position: relative;
    cursor: pointer;

    .innerServiceCard {
      box-shadow: 0px 4px 4px 0px #0000001F;
    }
    &:hover {
      .innerServiceCard {
        filter: brightness(60%);
      }
    }
    .cardLabel {
      color: white;
      font-size: 2.4rem;
      width: 100%;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .innerServiceCard {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 20px;
      filter: brightness(65%);
    }
  }

}
@media only screen and (max-width: 992px) {
  #LayaServicesDisplay {
    .cardLabel {
      font-size: 1.8rem !important;
    }
  }

}
@media only screen and (max-width: 884px) {
  #LayaServicesDisplay {
    width: 100%;
    margin: 0 auto !important;
    grid-template-columns: 1fr 1fr;
    .cardLabel {
      font-size: 16px !important;
    }
    .subTitle {
      font-size: 14px !important;
    }
  }
}
@media only screen and (max-width: 576px) {
  #LayaServicesDisplay {
    width: 90%;
    margin: 0 auto !important;
    grid-template-columns: 1fr;
    row-gap: 24px;
    .outerServiceCard {
      aspect-ratio: 288 / 101 !important;
    }
    .cardLabel {
      font-size: 16px !important;
    }
    .subTitle {
      margin-top: 4px !important;
    }
  }
}
</style>