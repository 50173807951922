<template>
    <div id="home-metrics" class="page-bg bg-brand-secondary px-md-5">
        <div class="content-container row">
            <div class="section-header">
                <div class="section-header-divider"></div>
                <h3 class="section-header-title text-white">{{componentText.title}}</h3>
            </div>
            <div
                    v-for="(metric ,i) in metrics"
                    :key="i"
                    class="col-6  col-lg-3 py-5 metric"
                    v-if="metrics.length > 0 && i < displayLength"
                    @click="seeAll"
            >
                <div class="metrics-wrap row  align-items-center">
                    <div class="metric-image col-12 col-sm-5">
                        <img :src="'/img/metrics/' + metric.title.replace(' ', '-').toLowerCase()+ '.svg'" v-if="$store.getters.skin === 0 || $store.getters.skin === 8">
                        <img :src="'/img/metrics/' + metric.title.replace(' ', '-').toLowerCase()+ '-generic.svg'" v-else>
                    </div>
                    <div class="metric-info col-12 col-sm-7">
                        <p class="metric-title text-white">{{metric.title}}
                            <img src="/img/metrics/edit.svg" v-if="$store.getters.skin === 0 || $store.getters.skin === 8">
                            <img src="/img/metrics/edit-generic.svg" v-else>
                        </p>
                        <p v-if="i === 5" class="metric-value">{{metric.value}}/{{metrics[6].value}}</p>
                        <p class="metric-value" v-else>{{metric.value}}{{metric.unit}}</p>
                    </div>
                </div>
            </div>
            <div class="col-12 pt-4 pl-0">
                <a class="home-link text-white border-white" @click="seeAll">{{componentText.seeAll}}</a>
            </div>
        </div>
    </div>
</template>
<script>
    // @ is an alias to /src
    import axios from "axios";
    import config from "../../config/constants";
    export default {
        name: "HomeMetrics",
        components: {},
        data() {
            return {
                screenWidth: 0,
                metrics: [],
                displayLength: 4,
            };
        },
        created() {
            window.addEventListener('resize', this.handleResize)
            this.handleResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize)
        },
        mounted() {
            this.getMetrics()
        },
        methods: {
            handleResize() {
                this.screenWidth = window.innerWidth;
            },
            seeAll(){
                this.$router.push({name: 'metrics'})
            },
            async getMetrics(){
                const payload = {
                    user_id: this.$store.getters.user_id
                }
                let res = await axios.post(config.api_env + "/application/api-hc/get-metric-range-all", payload, config.options)
                this.metrics = res.data.metrics
            },
        },
        computed:{
            componentText() {
                return this.getText.loggedIn.home.homeMetrics;
            },
        },
        watch:{
            screenWidth(newVal){
                if(newVal > 991){
                    this.displayLength = 4
                }else{
                    this.displayLength = 4
                }
            },
        }
    };
</script>
<style lang="less">
    .metric{
        cursor: pointer;
    }
    .metric-image{
        img{
            width: 108px;
            height: 108px;
        }
    }
    .metric-info{
        text-align: left;
        .metric-title{
            font-family: FilsonProBold;
            font-size: 2.2rem;
            text-transform: uppercase;
            color: #fff;
            line-height: 1;
            img{
                width: 14px;
                height: 14px;
                margin-left: 5px;
                cursor: pointer;
            }
        }
        .metric-value{
            color: #87d2dc;
            font-size: 1.9rem;
            font-family: 'FilsonProBook', sans-serif;
        }
    }
    @media only screen and (max-width: 576px){
        .metric-info{
            text-align: center;
        }
        .metric-image{
            text-align: center;
        }
    }
</style>
