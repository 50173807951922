<template>
    <div id="home-nutrition" class="page-bg bg-white px-md-5">
        <div class="content-container row">
            <div class="section-header">
                <div class="section-header-divider"></div>
                <h3 class="section-header-title">{{componentText.title}}</h3>
            </div>
            <div v-for="(recipe, i) in recipes" :key="i"
                 class="col-lg-4 col-md-6 col-sm-12 recipe-wrap pl-0"
                 v-if="i < displayLength"
            >
                <div @click="recipeNav(recipe.recipe_id)" class="recipe-card bg-brand-secondary">
                    <b-row class="mx-0 h-100">
                        <div class="col-6 px-0 recipe-image-wrap">
                            <img :src="recipe.featured_img">
                        </div>
                        <div class="col-6 px-0 recipe-card-content">
                            <div class="quarantine">
                                <img v-for="(allergen,i) in recipe.allergens"
                                     :key="i"
                                     :src="allergenImageThemed(allergen.allergen.split(' ')[0].toLowerCase())"
                                     @error="allergenImageDefault($event, allergen.allergen)"
                                >
                            </div>
                            <div class="recipe-title" v-html="recipe.recipe_name">
                            </div>
                            <div class="recipe-calories mb-4">
                                {{componentText.cals}}: {{recipe.calories}}
                            </div>
                        </div>
                    </b-row>
                </div>
                <a @click="favouriteRecipe(recipe.recipe_id, recipe.favourite, i)" class="recipe-heart">
                    <img v-if="!recipe.favourite" :src="'/img/nutrition/food/' + $store.getters.skin + '/heart-line.svg'" onerror="javascript:this.src='/img/nutrition/food/heart-line.svg'">
                    <img :src="'/img/nutrition/food/' + $store.getters.skin + '/heart.svg'" onerror="javascript:this.src='/img/nutrition/food/heart.svg'" v-else>
                </a>
            </div>
            <div class="col-12 pl-0">
                <a class="home-link link-highlight" :class="{'border-brand-secondary': $store.getters.skin === 8 }" @click="seeAll">{{componentText.seeAll}}</a>
            </div>
        </div>
    </div>
</template>
<script>
    // @ is an alias to /src
    import axios from "axios";
    import config from "../../config/constants";
    import { mapGetters } from "vuex"
    export default {
        name: "HomeNutrition",
        components: {},
        data() {
            return {
                recipes: [],
                screenWidth: 0,
                displayLength: 4
            };
        },
        created() {
            window.addEventListener('resize', this.handleResize)
            this.handleResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize)
        },
        mounted() {
            this.getHomeRecipes()
        },
        methods: {
            async getHomeRecipes(){
                const payload = {
                    user_id: this.$store.getters.user_id,
                    limit: 4
                }
                let res = await axios.post(config.api_env + '/application/api/recipes/get-home-recipe-list', payload, config.options)
                if(res.data.success){
                    this.recipes = res.data.recipes
                }
            },
            handleResize() {
                this.screenWidth = window.innerWidth;
            },
            recipeNav(id){
                this.$router.push('/recipe?rec='+id)
            },
            seeAll(){
                this.$router.push({name:'nutrition'})
            },
            async favouriteRecipe(id, favourite, i){
                const payload = {
                    user_id: this.$store.getters.user_id,
                    type: "recipe",
                    resource_id: id
                }
                let res
                if(favourite === 1){
                    this.recipes[i].favourite = 0
                    res = await axios.post(config.api_env + "/application/api-hc/unlike-item", payload, config.options)
                }else{
                    this.recipes[i].favourite = 1
                    res = await axios.post(config.api_env + "/application/api-hc/like-item", payload, config.options)
                }
            },
            allergenImageThemed(allergen){
                return '/img/nutrition/food/' + this.skin + '/' + allergen.toLowerCase() + '.svg'
            },
            allergenImageDefault(event, allergen){
                event.target.src = '/img/nutrition/food/' + allergen.split(" ")[0].toLowerCase() + '.svg'
            },
        },
        computed:{
            ...mapGetters(['hardcoded', 'skin']),
            heartLine(){
                if(this.hardcoded.heart_line){
                    return this.hardcoded.heart_line
                }else return "/img/heart-line.svg"
            },
            heart(){
                if(this.hardcoded.heart){
                    return this.hardcoded.heart
                }else return "/img/heart.svg"
            },
            componentText() {
                return this.getText.loggedIn.home.homeNutrition;
            },
        },
        watch:{
            screenWidth(newVal){
                if(newVal > 991){
                    this.displayLength = 3
                }else{
                    this.displayLength = 4
                }
            }
        }
    };
</script>
<style lang="less">
    .recipe-wrap {
        position: relative;
        cursor: pointer;
        &:hover{
            transform: translate(-2px, -2px);
        }
        .recipe-heart {
            top: 15px;
            right: 40px;
            width: 21px;
            height: 18px;
            z-index: 10;
            position: absolute;
            img {
                width: 21px;
                height: 18px;
            }
        }
    }
    .recipe-card{
        font-family: FilsonProBlack;
        color: #fff;
        position: relative;
        margin-right: 30px;
        margin-bottom: 25px;
        height: 210px;
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
        z-index: 0;
        &:hover{
            box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.3);
        }
        &:before{
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            height: 37px;
            width: 35px;
            display: inline-block;
            transform: skew(40deg) translateX(50%);
            z-index: 1;
            border-bottom-right-radius: 10px;
            border-top-right-radius: 5px;
        }
        &:after{
            content: "";
            position: absolute;
            right: 1px;
            bottom: 0;
            height: calc(100% - 30px);
            width: 29px;
            transform: translateX(100%);
            border-bottom-right-radius: 10px;
            border-top-right-radius: 5px;
        }
        .recipe-image-wrap {
            max-height: 100%;
            overflow: hidden;
            border-bottom-left-radius: 10px;
            border-top-left-radius: 10px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                border-bottom-left-radius: 10px;
                border-top-left-radius: 10px;
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                   width: auto;
                    transform: translateX(-25%);
                }
            }
        }
        .recipe-card-content{
            position:relative;
            .quarantine {
                position: absolute;
                top: 15px;
                right: 25px;
                min-width: 100%;
                display: flex;
                justify-content: flex-end;
                height: 15px;
                img{
                    display: block;
                    max-width: 22px;
                    max-height: 22px;
                    width: 100%;
                    height: 22px;
                    margin-left: 4px;
                    margin-top: -2px;
                }
            }
            .recipe-title{
                font-family: 'FilsonProBlack', sans-serif;
                font-size: 1.6rem;
                position: relative;
                padding-top: 10px;
                margin: 60px 0 15px 15px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                &:before{
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 15px;
                    height: 1px;
                    background: #fff;
                }
            }
            .recipe-calories{
                font-family: FilsonProBold, sans-serif;
                font-size: 1.5rem;
                letter-spacing: 1px;
                width: 100%;
                padding: 6px 15px;
                position: absolute;
                bottom: 0;
            }
        }
    }
</style>
