<template>
  <div class="view-bookings">
    <div class="content-container row" v-if="loading">
      <div class="col-12 d-flex justify-content-center">
        <b-spinner></b-spinner>
      </div>
    </div>
    <div
      class="content-container row px-0"
      v-else-if="upcomingBookings.length < 1"
    >
      <div class="col-12 col-md-6">
        <h3 class="brand-secondary">You have no upcoming bookings</h3>
      </div>
      <div class="d-none d-md-block col-md-6">
        <img src="/img/placeholder-bookings-generic.svg" />
      </div>
    </div>
    <div class="content-container row" v-else-if="bookings">
      <div class="col-md-5 bookings-wrap">
        <div
          v-for="(booking, i) in upcomingBookings"
          :key="i"
          class="booking pl-3 py-4 my-3"
          @click="selectBooking(booking)"
        >
          <a href="#booking-anchor">
            {{ formatDate(booking.ts_start_date_time) }} -
            {{ booking.e_c_label }}</a
          >
        </div>
      </div>
      <div class="col-md-7">
        <div id="booking-anchor" class="d-block d-md-none"></div>
        <div class="booking-card p-5 mx-md-5 mt-3 mb-5" v-if="selectedBooking">
          <h3 class="pt-3 pb-5">{{ selectedBooking.e_c_label }}</h3>
          <div
            class="booking-description"
            v-html="selectedBooking.e_c_description"
          ></div>
          <div class="booking-info py-5">
            <p class="mb-0">
              Appointment with: {{ selectedBooking.e_service_provider }}
            </p>
            <p class="mb-0">
              Date: {{ formatDate(selectedBooking.ts_start_date_time) }}
            </p>
            <p>Time: {{ parseTime(selectedBooking.ts_start_date_time) }}</p>
            <p>
              You will receive your unique video consultation link 1-2 days
              before your appointment. Please ensure to check your junk or spam
              inbox.
            </p>
            <p>
              5 minutes before your appointment, you should visit your link to
              make sure everything is working correctly.
            </p>
            <p>
              If you have any issues or questions, please use our live chat,
              call <a href="tel:015180273">01 518 0273</a> or email
              <a href="mailto:digitalclinics@layawellness.ie"
                >digitalclinics@layawellness.ie</a
              >
              for support.
            </p>
          </div>
          <div class="button-wrap py-5">
            <b-button
              class="cta-button px-5 py-3"
              @click="cancelTimeslotBooking(selectedBooking.b_id)"
              :disabled="!allowCancel(selectedBooking.ts_start_date_time)"
              >Cancel Booking</b-button
            >
          </div>
          <p
            v-if="!allowCancel(selectedBooking.ts_start_date_time)"
            class="cancel-text"
          >
            To change your appointment, please call
            <a href="tel:015180273">01 518 0273</a>.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config/constants";
import moment from "moment";
export default {
  name: "ViewBookings",
  props: ["selectedBookingID"],
  data() {
    return {
      loading: true,
      bookings: null,
      selectedBooking: null,
      selectedBookingIDCopy: null,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.selectedBookingIDCopy = this.selectedBookingID;
    this.getBookings();
  },
  methods: {
    async getBookings() {
      const payload = {
        user_id: this.$store.getters.user_id,
      };
      let res = await this.axios.post(
        config.api_env + "/application/api/g/global-events/get-bookings",
        payload,
        config.options
      );
      if (res.data.success) {
        this.bookings = res.data.bookings;
        if (this.selectedBookingIDCopy) {
          this.selectedBooking = this.upcomingBookings.find(
            (x) => x.ts_id === this.selectedBookingIDCopy
          );
        } else this.selectedBooking = this.upcomingBookings[0];
        this.loading = false;
      } else if (res.data.err && res.data.err.includes("3994")) {
        if (
          await this.showMsgModal(
            "You have been logged out due to inactivity, please login and try again"
          )
        ) {
          this.$store.dispatch("logout");
          this.$router.push("/login");
        }
      } else {
        this.showMsgModal("Something went wrong, please try again later");
      }
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YY");
    },
    parseTime(time) {
      return moment(time).format("hh:mm A");
    },
    selectBooking(booking) {
      this.selectedBooking = booking;
      this.selectedBookingIDCopy = null;
    },
    async cancelTimeslotBooking(timeslot) {
      const payload = {
        user_id: this.$store.getters.user_id,
        booking_id: timeslot,
      };
      let res = await this.axios.post(
        config.api_env + "/application/api/g/global-events/cancel-booking",
        payload,
        config.options
      );
      if (res.data.success) {
        await this.showMsgModal("Appointment cancelled successfully");
        this.loading = true;
        this.getBookings();
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      }
    },
    sortBookingsByDate(a, b) {
      let comparison = 0;
      if (a.ts_start_date_time > b.ts_start_date_time) {
        comparison = 1;
      } else if (a.ts_start_date_time < b.ts_start_date_time) {
        comparison = -1;
      }
      return comparison;
    },
    allowCancel(bookingTime) {
      const today = moment();
      const tomorrow = moment().add(1, "day");
      const currentTime = today.format("HH");
      const bookingDay = moment(bookingTime);
      //can't cancel same day bookings, or next day bookings if the current time is after 1pm
      return today.isSame(bookingDay, "day") ||
        (currentTime >= 13 && tomorrow.isSame(bookingDay, "day"))
        ? false
        : true;
    },
  },
  computed: {
    upcomingBookings() {
      return this.bookings
        .filter(
          (x) => x.ts_time_status === "upcoming" && x.b_status === "registered"
        )
        .sort(this.sortBookingsByDate);
    },
    cancelledBookings() {
      return this.bookings
        .filter(
          (x) => x.ts_time_status === "upcoming" && x.b_status === "cancelled"
        )
        .sort(this.sortBookingsByDate);
    },
    pastBookings() {
      return this.bookings
        .filter((x) => x.ts_time_status === "past")
        .sort(this.sortBookingsByDate);
    },
  },
};
</script>

<style scoped lang="less">
.bookings-wrap {
  width: 100%;
  .booking {
    width: 100%;
    border-left-style: solid;
    border-left-width: 10px;
    font-size: 2rem;
    color: #DA1984;
    cursor: pointer;
    font-family: ff-cocon-web-pro, sans-serif;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    &:hover {
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    }
    a {
      width: 100%;
      height: 100%;
      color: #00679A;
      &:hover {
        text-decoration: none;
      }
      &:focus {
        outline: none;
      }
    }
  }
}
.booking-card {
  font-family: Open Sans, sans-serif;
  h3 {
    text-align: center;
  }
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  p {
    font-size: 16px;
  }
  .button-wrap {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .cancel-text {
    text-align: center;
  }
}
</style>