<template>
  <div id="WellbeingStudio" class="version2">
    <div class="loading" v-if="loading">
      <b-spinner></b-spinner>
    </div>
    <div class="no-series" v-else-if="noSeries">
      <div class="content-container row py-5 mt-5">
        <div class="col-12 text-center">
          <h3 class="m-auto">{{ componentText.noSeries }}</h3>
        </div>
      </div>
    </div>
    <div class="page-wrap" v-else>
      <div class="laya-intro pt-5 bg-white" v-if="skin === 100">
        <div class="content-container row py-5 mt-5">
          <div class="col-12 col-md-4 page-title">
            <h2>Wellbeing <br />Series</h2>
          </div>
          <div class="col-12 col-md-7 offset-md-1">
            <h3>{{ series.title }}</h3>
            <p v-html="series.description"></p>
          </div>
          <div class="col-12 text-md-right text-center pt-5">
            <div class="btn-wrap">
              <b-button
                  v-if="series.other_series.prev"
                  class="btn-outline py-3 px-5 mx-2 my-2 my-md-0"
                  @click="getSeries(series.other_series.prev)"
              >See previous series</b-button
              >
              <b-button
                  v-if="series.other_series.next"
                  class="cta-button py-3 px-5 mx-2 my-2 my-md-0"
                  @click="getSeries(series.other_series.next)"
              >See next series</b-button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="page-header bg-white" v-else-if="!$store.getters.user.access_code_user">
        <div class="content-container bannerContainer row">
          <div class="bannerHeader">
            <div class="dg-logo">
              <div class="box">
                <div class="logo-text">
                <span class="digital">Wellbeing</span>
                <br/>
                <div class="poly"></div>Studio
              </div>
              </div>

            </div>
            <div class="series-title">
              {{series.title}}
            </div>
          </div>
        </div>
        <div class="content-container row py-3 bg-white mt-3">
          <div :class="skin === 10? 'col-12 py-3':'col-12 col-md-7'">
            <p v-html="series.description"></p>
          </div>
          <div class=" col-12 col-md-5 text-md-right text-center pt-2">
            <div class="btn-wrap">
              <b-button
                  v-if="series.other_series.prev"
                  class="btn-outline px-5 py-3 mx-2 my-2 my-md-0"
                  @click="getSeries(series.other_series.prev)"
              >{{ componentText.previous }}</b-button
              >
              <b-button
                  v-if="series.other_series.next"
                  class="cta-button px-5 py-3 mx-2 my-2 my-md-0"
                  @click="getSeries(series.other_series.next)"
              >{{ componentText.next }}</b-button
              >
            </div>
          </div>
        </div>
      </div>
      <section v-else>
        <div class="row bg-grey d-flex flex-row align-items-center">
          <div class="header-image-container col-7 px-0 position-relative d-none d-md-flex">
            <img class="img-fluid" src="/img/events-banner.jpg" />
            <div class="triangle-bottom-right"></div>
          </div>
          <div class="col-lg-5 col-12 px-3">
            <div class="px-5 py-5">
              <h1 class="banner-title">{{ series.title }}</h1>
              <div class="d-md-none">
                <img class="img-fluid rounded" src="/img/events-banner.jpg" />
              </div>
              <p class="banner-text pb-4" v-html="series.description">
              </p>
              <div class="btn-wrap px-0">
                <b-button
                    v-if="series.other_series.prev"
                    class="btn-outline px-5 py-3 "
                    @click="getSeries(series.other_series.prev)"
                >{{ componentText.previous }}</b-button
                >
                <b-button
                    v-if="series.other_series.next"
                    class="cta-button px-5 py-3 ml-3"
                    @click="getSeries(series.other_series.next)"
                >{{ componentText.next }}</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="laya-virtual-events bg-grey py-5" v-if="skin === 10">
        <div class="content-container row py-2">
          <div class="col-12 section-title">
            <h3>Virtual events</h3>
          </div>
          <div class="col-12 upcoming">
            <h4>This month's events: </h4>
          </div>
          <div class="col-12" v-for="(event, i) in series.events" :key="i">
            <div
                class="event-card my-2"
                @click="
                $router.push({ name: 'virtualEvent', params: { id: event.id } })
              "
            >
              <div class="event-date order-2 order-sm-1">
                <i class="far fa-calendar"></i>
                {{ getFormatedDate(event.start_date_time, "DD MMM") }}
                <i class="far fa-clock ml-3"></i>
                {{ getFormatedDate(event.start_date_time, "HH:mm") }} -
                {{ getFormatedDate(event.end_date_time, "HH:mm") }}
              </div>
              <div class="event-title pl-3 px-md-5 order-1 order-sm-2">
                {{ event.title }}
              </div>
              <div
                  class="live-now order-3 px-3 ml-3 mt-2 mt-sm-0 ml-md-0"
                  :class="{ 'dot-on': liveNowDot }"
                  v-if="eventIsLive(event)"
              >
                LIVE NOW
              </div>
              <div class="more-info order-4">
                <span class="d-none d-md-inline">More information </span>
                <i class="fas fa-chevron-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="virtual-events bg-white py-3" v-else>
        <div class="content-container row py-2">
          <div class="section-header col-12">
            <div class="section-header-divider"></div>
            <h3 class="section-header-title">{{ componentText.events }}</h3>
          </div>
          <div class="col-12 upcoming">
            <h4>{{ componentText.upcomingEvents }}</h4>
          </div>
          <div
              class="col-lg-4 col-md-6 col-12 event-card my-4"
              v-for="(event, i) in series.events"
              :key="i"
          >
            <div
                class="event-card-content my-2 mx-0 row"
                @click="
                $router.push({ name: 'virtualEvent', params: { id: event.id } })
              "
            > <div v-if="event.featured" class="featuredTab bg-brand-primary">Featured</div>
              <div class="col-7 event-title">
                {{ event.title }}
              </div>
              <div class="col-5 event-date pt-4">
                <div class="event-day">
                  {{ getFormatedDate(event.start_date_time, "DD") }}
                </div>
                <div class="event-month">
                  {{ getFormatedDate(event.start_date_time, "MMM") }}
                </div>
                <div class="event-time">
                  <i class="far fa-clock ml-3"></i>
                  {{ getFormatedDate(event.start_date_time, "HH:mm") }} -
                  {{ getFormatedDate(event.end_date_time, "HH:mm") }}
                </div>
              </div>
              <div class="book-now cta-button hover-darken py-3 px-5">
                <span>{{ componentText.moreInfo }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="series.courses.length !== 0">
        <div class="laya-e-learning py-5" v-if="skin === 10">
          <div class="content-container row pb-5">
            <div class="col-12 col-lg-5 pb-5 e-learning-content">
              <h2>E-learning</h2>
              <h3>{{ series.courses[0].title }}</h3>
              <p v-html="series.courses[0].description"></p>
              <div class="btn-wrap py-5">
                <b-button
                    class="px-5 py-3"
                    @click="$router.push('/wellbeing/' + series.courses[0].id)"
                >See wellbeing course</b-button
                >
              </div>
            </div>
            <div class="col-7">
              <div class="section-image d-none d-lg-block"></div>
            </div>
          </div>
        </div>
        <div class="learning pt-4" v-else>
          <div class="content-container row py-2">
            <div class="col-12 col-lg-5 pb-5 e-learning-content">
              <h3>{{ componentText.learning }}</h3>
              <h4>{{ series.courses[0].title }}</h4>
              <p class="mb-5" v-html="series.courses[0].description"></p>
              <a
                  class="text-link"
                  @click="
                  $router.push({
                    name: 'wellbeingCourse',
                    params: { id: series.courses[0].id },
                  })
                "
              >{{ componentText.seeCourse }}
              </a>
            </div>
            <div class="col-3 offset-4 d-none d-md-block course-image">
              <div class="image-wrap">
                <img :src="series.courses[0].featured_img" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
          class="laya-downloadable-guides py-2"
          v-if="skin === 10 && series.attachments.length > 0"
      >
        <div class="content-container row pt-4 pb-5 mb-5">
          <div class="col-12 text-center pb-4">
            <h3>Downloadable guides</h3>
          </div>
          <div
              class="col-md-6 mb-5"
              v-for="(guide, i) in series.attachments"
              :key="i"
          >
            <div
                class="download-card row mx-2"
                @click="downloadGuide(guide.url)"
            >
              <div
                  class="col-3 col-sm-4 card-image"
                  ref="image-box"
                  :style="'background-image: url(' + guide.img_url + ')'"
              >
                <div class="dots"></div>
              </div>
              <div class="col-9 col-sm-8 card-content pl-5 pt-4">
                <h3>{{ guide.title }}</h3>
                <p>{{ guide.description }}</p>
                <div class="download">Download guide</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
          class="downloadable-guides bg-white py-w"
          v-else-if="series.attachments.length > 0"
      >
        <div class="content-container row py-5">
          <div class="section-header col-12">
            <div class="section-header-divider"></div>
            <h3 class="section-header-title">{{ componentText.guides }}</h3>
          </div>
          <div
              class="col-lg-3 col-md-4 col-sm-6 col-12"
              v-for="(guide, i) in series.attachments"
              :key="i"
          >
            <div class="download-card mx-2" @click="downloadGuide(guide.url)">
              <div
                  class="card-image"
                  ref="image-box"
                  :style="'background-image: url(' + guide.img_url + ')'"
              ></div>
              <div class="card-content px-3 pt-4">
                <h4 class="my-3">{{ guide.title }}</h4>
                <div class="download pb-4">
                  <span>{{ componentText.download }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isLayaWellbeing()" id="laya_wellbeing_banner" class="content-container row mt-4">
        <div class="col-md-5">
          <div class="banner_text">
            <br/>
            <h2 class="mt-5 mb-5">Digital Gym</h2>
            <p>
              Our Digital Gym gives you access to live and on demand fitness classes, with a weekly schedule to choose from. All classes are delivered by handpicked experts who will demonstrate a safe and maintainable way to tackle fitness at home.
            </p>
            <br/>
            <a href="/digital-gym" class="btn btn-block cta-button py-3 px-5">Enter Digital Gym</a>
          </div>


        </div>
        <div class="col-md-7 img_container">
          <img class="img-fluid" src="https://healthcoach-media.com/res/images/Image-2-min.jpg" />
        </div>
      </div>



    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
//TODO:: Consider breaking this into laya / spectrum components
export default {
  name: "WellbeingStudioV2",
  data() {
    return {
      series: {},
      loading: true,
      noSeries: false,
      liveNowDot: true,
    };
  },
  mounted() {
    this.getSeries();
    window.setInterval(() => {
      this.liveNowDot = !this.liveNowDot;
    }, 500);
  },
  methods: {
    mindfulnessLink() {
      this.$router.push("/mindfulness");
    },
    async getSeries(seriesID = null) {
      const payload = {
        user_id: this.user_id,
        series_id: seriesID,
      };
      let res = await this.axios.post(
          process.env.VUE_APP_API_PATH +
          "/application/api/u/studio/get-studio-series",
          payload
      );
      if (res.data.success) {
        if(!res.data.series.id) this.noSeries = true;
        this.series = res.data.series;
        this.loading = false;
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        seriesID ? this.showError() : (this.noSeries = true);
        this.loading = false;
      }
    },
    downloadGuide(url) {
      window.open(url, "_blank");
    },
    showError() {
      //TODO:: show error
    },
    eventIsLive(event) {
      const eventStart = moment(event.start_date_time);
      const eventEnd = moment(event.end_date_time);
      console.log(moment().locale("fr"));
      const now = moment().locale("en");
      return now >= eventStart && now <= eventEnd;
    },
  },
  computed: {
    ...mapGetters(["skin", "user_id"]),
    componentText() {
      return this.getText.wellbeingStudio;
    },
  },
};
</script>

<style lang="less">
#WellbeingStudio {
  &.version2 {
    .btn-wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      * {
        width: 220px;
        margin-top: 10px !important;
        font-family: "FilsonProBold", sans-serif;
      }
    }
    .bannerContainer {
      margin-top: 0!important;
      max-width: 1500px !important;
      .bannerHeader {
        position: relative;
        width: 100%;
        height: 450px;
        background-image: url("https://spectrum-life-media.s3.eu-west-1.amazonaws.com/dfd_services/ds.webp");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        @media only screen and (min-width: 1500px) {
          &:before {
            position: absolute;
            content: '';
            width: 200px;
            height: 100%;
            margin-left: -100px;
            background: linear-gradient(90deg, white 60%, rgba(0, 0, 0, 0) 100%) !important ;
          }
          &:after {
            position: absolute;
            right: 0;
            content: '';
            width: 200px;
            height: 100%;
            margin-left: -100px;
            background: linear-gradient(270deg, white 60%, rgba(0, 0, 0, 0) 100%) !important ;
          }
        }
      }
      .series-title {
        position: absolute;
        font-size: 24px;
        color: white;
        top: 10%;
        right: 10%;
        font-family: FilsonProBold, Arial, Helvetica, sans-serif;
      }
      .dg-logo {
        .box {
          --b: 3px;   /* thickness of the border */
          --c: white;   /* color of the border */
          --w: 20px;  /* width of border */


          padding: var(--b); /* space for the border */

          position:relative;
          /*Irrelevant code*/
          width:100%;
          height:100%;
          box-sizing:border-box;
          margin:5px;
          display:inline-flex;
          font-size:30px;
          justify-content:center;
          align-items:center;
          text-align:center;
        }
        .box::before {
          content :"";
          position: absolute;
          inset: 0;
          background: var(--c,white);
          --_g: #0000 90deg,#000 0;
          --_p: var(--w) var(--w) no-repeat;
          --mask:
              conic-gradient(from 90deg  at top    var(--b) left  var(--b),var(--_g)) 0    0    / var(--_p),
              conic-gradient(from -90deg at bottom var(--b) right var(--b),var(--_g)) 100% 100% / var(--_p);
          -webkit-mask: var(--mask);
          mask: var(--mask);
        }
        .logo-text {
          font-size: 4rem;
          line-height: 3.6rem;
          color: white;
          padding: 13px;
          .digital {
            font-family: FilsonProBold, Arial, Helvetica, sans-serif;
          }
        }
        .poly {
          margin-left: 40px;
          margin-right: 3px;
          background: white;
          clip-path: circle(40%);
          width: 15px;
          height: 15px;
          display: inline-block;
        }
        position: absolute;
        width: auto;
        height: auto;
        top: 10%;
        left: 10%;
        max-width: 400px;
        display: flex;
        padding: 10px;
        align-items: center;
      }
      @media only screen and (max-width: 767px) {
        .bannerHeader {
          height: 200px;
        }
        .dg-logo {
          left: 10px;
          .logo-text {
            font-size: 3rem;
          }
          .poly {
            width: 10px;
            height: 10px;
            margin-left: 20px;
          }
        }
      }
    }
  }
  .header-image-container {
    height: 350px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    .img-fluid {
      position: relative;
      width: fit-contentl
    }
  }

  .triangle-bottom-right {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 100%;
    border-style: solid;
    border-width: 0 0 700px 350px;
    border-color: transparent transparent #f7f7f7 transparent;
  }

  .banner-title {
    font-size: 2rem;
    font-family: 'FilsonProBold', sans-serif;
    font-weight: bold;
    padding-bottom: 20px;
  }

  .banner-text {
    font-size: 1.4rem;
    max-width: 400px;

  }
  .rounded{
    border-radius: 16px;
    padding-bottom: 20px;
  }

  background-color: white;
  .mindfulnessContent {
    .mindfulnessTitle {
      margin-bottom: 40px;
      margin-top: 65px;
    }
    font-family: ff-sari-web-regular, sans-serif;
    width: 100% !important;
    .mindfulnessRow {
      margin: 0 auto 0px;
      width: 100%;
    }
    .mindfulnessCard {
      .mindfulnessHeader {
        position: relative;
        img {
          position: absolute;
          bottom: 0;
          right: 0;
          max-width: 150px;
        }
      }
      margin: 0 auto 50px;
      border: 1px solid #eaeaea;
      box-shadow: 0 .5rem 1.2rem rgba(0,0,0,.2);
      border-radius: 0 0 15px 0;
      max-width: 1000px;
      .iconRow {
        font-size: 1.6em;
      }
      .mindfulnessDescription {
        font-size: 1.6em;
      }
      .mindfulBtn {
        font-size: 1.6em;
      }
    }
  }
  .no-series {
    h3 {
      max-width: 600px;
    }
  }
  .text-link {
    background: transparent;
    font-family: FilsonProBold, sans-serif;
    border-bottom: 3px solid #ed3975;
    border-radius: 0;
    letter-spacing: 0;
    font-size: 1.7rem;
    &:hover {
      text-decoration: none !important;
    }
  }
  .section-header {
    h3 {
      text-transform: capitalize;
      padding: 0 20px 20px 0;
    }
    .section-header-divider {
      transform: translateY(-15px);
    }
  }
  .page-header {
    background-color: #f4f4f4;
    h3 {
      letter-spacing: 0;
      padding: 20px 0 10px 0;
    }
    p {
      font-size: 1.6rem;
    }
  }
  .virtual-events {
    h4 {
      color: #919191;
    }
    .event-card {
      .event-card-content {
        padding: 0 10px 35px 15px;
        border-radius: 15px 15px 0 15px;
        height: 100%;
        min-height: 170px;
        position: relative;
        cursor: pointer;
        .featuredTab {
          position: absolute;
          top: 0;
          left: 0;
          padding: 2px;
          color: #fff;
          font-size: 1.4rem;
          font-family: "FilsonProBook", sans-serif;
          width: 35%;
          text-align: center;
          border-radius: 0 0 10px 0
        }
        &:hover {
          box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.3);
          transform: translate(-2px, -2px);
        }
        .event-title {
          color: #fff;
          font-size: 2rem;
          font-family: "FilsonProBook", sans-serif;
          font-weight: 400;
          line-height: 1.2;
          padding-top: 30px;
        }
        .event-date {
          color: #fff;
          font-family: "FilsonProLight", sans-serif;
          line-height: 1;
          text-align: right;
          position: relative;
          .event-day {
            font-size: 6.4rem;
          }
          .event-month {
            font-size: 2rem;
            line-height: 0.6;
          }
          .event-time {
            font-size: 1.5rem;
            position: absolute;
            padding-top: 10px;
            right: 15px;
            white-space: nowrap;
          }
        }
        .book-now {
          position: absolute;
          right: 0;
          bottom: 0;
          text-align: center;
          font-size: 1.5rem;
          font-family: "filsonProBook", sans-serif;
          border-radius: 30px 0 0 0;
          cursor: pointer;
        }
      }
    }
  }
  .live-now {
    color: #fff;
    background-color: red;
    border-radius: 8px;
    font-size: 1.6rem;
    font-weight: 400;
    &:before {
      content: "•";
    }
    &.dot-on {
      &:before {
        color: red;
      }
    }
  }
  .learning {
    background-color: #f4f4f4;
    h3 {
      text-transform: capitalize;
      padding-top: 0;
    }
    h4 {
      font-size: 2rem;
      font-family: FilsonProBold, sans-serif;
    }
    p {
      font-size: 1.6rem;
    }
    .image-wrap {
      display: flex;
      align-items: center;
      height: 100%;
      img {
        width: 100%;
      }
    }
  }
  .downloadable-guides {
    .download-card {
      text-align: center;
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      cursor: pointer;
      &:hover {
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
      }
      .card-image {
        width: 100%;
        height: 150px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }
      .card-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        h4 {
          font-size: 2rem;
          font-family: FilsonProLight;
        }

        .download {
          margin-top: auto;
          font-size: 1.8rem;
          font-family: FilsonProBold, sans-serif;
        }
      }
    }
  }
  #laya_wellbeing_banner {
    overflow: hidden;
    background-color: #fafafa;
    margin-bottom: 100px;
    border-radius : 25px;
    box-shadow: 0px 10px 10px rgba(0,0,0,0.2);
    .img_container {
      padding: 0px;
      max-height: 400px;
      img {

      }
    }

    .banner_text {
      padding: 15px 15px;
      position: relative;
      //height : 100%;
      p {
        margin-top: 16px;
        font-family: ff-sari-web-regular,sans-serif;
        font-size: 15px;
        margin-bottom: 30px;
      }
      h2 {
        text-align: left;
        font-family: ff-sari-web-regular,sans-serif;
      }
      a {
        //position: absolute;
        //left : 0px;
        //bottom: 30px;
      }
    }
  }
}
</style>
