<template>
  <div :tabindex="t_i" id="LayaCarouselCard" :class="matomo_class" @keydown.enter="goToVideo()">
    <div class="carouselCard" @click="goToVideo()" :class="matomo_class" :id="title">
      <div :class="matomo_class" :id="title" class="topCard d-flex justify-content-center align-items-center" v-if="type === 'be-calm' || type === 'beCalm'" @click="goToVideo()">
        <img :src="thumbnail" style="display: block; width: 100%" :alt="item.title" :class="matomo_class" :id="title"/>
      </div>
      <div :class="matomo_class" :id="title" class="topCard" :style="{ backgroundImage: 'url(' + thumbnail + ')' }" v-else>
        <b-badge v-if="isNew" class="newTag" :class="matomo_class" :id="title">New</b-badge>
      </div>
      <div class="cardText position-relative" :class="matomo_class" :id="title">
        <b-progress v-if="progress" :id="title" class="position-absolute w-100" max="100" :value="progress" style="top: -7px; left: 0" @click="goToVideo()"></b-progress>
        <div class="cardTextTop" :class="matomo_class" @click="goToVideo()" :id="title">
          <div :class="matomo_class" :id="title">{{authorName}}</div>
          <div :class="matomo_class" :id="title" class="text-right" v-if="type !== 'exercise' && type !== 'beCalm' && type !== 'be-calm'">{{mins_left}}</div>
          <div :class="matomo_class" :id="title" class="text-right" v-else-if="type === 'be-calm'">{{item.details.total_modules}} {{item.details.total_modules > 1 ? 'meditations':'meditation'}}</div>
          <div :class="matomo_class" :id="title" class="text-right" v-else>{{item.details.class_number}}
          {{ type === 'beCalm' ? item.details.class_number > 1 ? 'meditations' : 'meditation' : 'classes' }}
          </div>
        </div>
        <div :id="title" class="cardTitle" @click="goToVideo()" :class="type === 'event-progress' ? matomo_class + ' in-progress' : matomo_class" :style="{maxHeight: titleLineHeight + 'px'}">
          <TextTruncator :matomo-class="matomo_class" :text="title" :font-name="'Cocon Pro Regular'" :font-size="titleFontSize" :line-height="titleLineHeight" :id="title" :enable-tool-tip="true" :maxLines="1"/>
        </div>
        <div class="cardDescription" @click="goToVideo" :class="matomo_class">
          <TextTruncator :matomo-class="matomo_class" :text="description" :font-name="'Open Sans'" :font-size="16" :line-height="19" :id="title" :max-lines="2"/>
        </div>
        <div class="cardTextBottom d-flex" :id="title" :class="matomo_class" v-if="includeActionButtons">
            <div class="d-flex flex-wrap" style="width: 75%" :class="matomo_class" :id="title">
            </div>
            <div :class="matomo_class" :id="title" class="float-end d-flex align-items-center justify-content-between w-25" v-if="item.type !== 'fitness_plans' && item.type !== 'beCalm'">
              <div :class="matomo_class" :id="title" style="cursor: pointer">
                <div :class="matomo_class" :id="'favs-'+unique_id" class="video-card-favourite d-flex align-items-center justify-content-center" v-if="!item.favourited">
                  <i :id="title" @click.stop="toggleDGFavourite" class="fa-regular fa-heart fa-lg" :class="matomo_class"></i>
                  <b-tooltip :target="'favs-'+unique_id" triggers="hover" placement="top" custom-class="mylist-tooltip">
                    {{item.favourited ? 'Remove from favourites' : 'Add to favourites'}}
                  </b-tooltip>
                </div>
                <div :class="matomo_class" :id="'favs-'+unique_id" class="video-card-favourite d-flex align-items-center justify-content-center" v-else>
                  <i @click.stop="toggleDGFavourite" class="fa-solid fa-heart fa-lg" :id="title" ></i>
                  <b-tooltip :target="'favs-'+unique_id" triggers="hover" placement="top" custom-class="mylist-tooltip">
                    {{item.favourited ? 'Remove from favourites' : 'Add to favourites'}}
                  </b-tooltip>
                </div>
              </div>
              <div :id="title" :class="matomo_class" class="d-flex align-items-center justify-content-center" style="cursor: pointer; z-index: 100">
                <i :id="'myList-'+unique_id" class="fa-regular fa-lg myListBtn" :class="isItemInMyList ? 'fa-circle-check plus':'fa-circle-plus plus'" @click.stop="watchLater">
              </i>
              <b-tooltip :id="title" :target="'myList-'+unique_id" triggers="hover" placement="top" custom-class="mylist-tooltip">
                {{isItemInMyList ? 'Remove from Watch Later' : 'Add to Watch Later'}}
              </b-tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment/moment";
import TextTruncator from "@/components/TextTruncator.vue";

export default {
  name: 'LayaCarouselStudioCard',
  components: {TextTruncator},
  props: {
    item: {
      type: Object,
      default: {}
    },
    swiping: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    },
    matomo_class: {
      type: String,
      default: ''
    },
    t_i: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      vimeoId: 0,
      videoIsPlaying: false,
      unique_id: null,
      descriptionWidth: 0,
      descriptionHeight: 0,
      titleFontSize: 20,
      titleLineHeight: 30,
      titleResponsiveness: [{titleFontSize: 20, titleLineHeight: 30, screenWidth: '*'},{titleFontSize: 16, titleLineHeight: 18, screenWidth: 567}],
    }
  },
  mounted() {
    this.unique_id = this.genRandonString(10);
    this.setTitleHeight();
    window.addEventListener('resize', this.setTitleHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.setTitleHeight)
  },
  methods: {
    setTitleHeight() {
      let fontSize = this.titleFontSize;
      let lineHeight = this.titleLineHeight;
      let width = window.innerWidth;
      this.titleResponsiveness.forEach(item=> {
        if(item.screenWidth === '*') {
          fontSize = item.titleFontSize;
          lineHeight = item.titleLineHeight;
        } else if (width <= item.screenWidth) {
          fontSize = item.titleFontSize;
          lineHeight = item.titleLineHeight;
        }
      });
      this.titleLineHeight = lineHeight;
      this.titleFontSize = fontSize;
    },
    getBeCalmImage(title) {
      let url = 'https://spectrum-life-media.s3.eu-west-1.amazonaws.com/CONTENT/BeCalm/dfd_be_calm_images/'
      switch (title) {
        case 'Sleep Aid':
          url += 'Sleep aid_hero.png'
          break;
        case 'Reducing Stress':
          url += 'Reducing stress_hero.png'
          break;
        case 'Self-Compassion': case 'Self-compassion':
          url += 'Self compassion_hero.png'
          break;
        case 'Improving Performance':
          url += 'Improving performance_hero.png'
          break;
        case 'Increase Happiness':
          url += 'Increase Happiness_hero.png'
          break;
        case 'Reducing Anxiety':
          url += 'Reducing Anxiety_hero.png'
          break;
        case 'Birdsong in the Woods':
          url += 'Birdsong in the Woods_hero.png'
          break;
        case 'Babbling Brook':
          url += 'Babbling Brook_hero.png'
          break;
        case 'Living in the Moment':
          url += 'Living in the Moment_hero.png'
          break;
        case 'Inspiring Hope and Fulfilment':
          url += 'Inspiring Hope and Fulfilment_hero.png'
          break;
        default:
          return null;
      }
      return url;
    },
    async watchLater(evt) {
      evt.stopPropagation();
      if (this.type === 'TrainingPlan') return;
      let action = 'add';
      if(!this.isItemInMyList) {
        await this.$store.dispatch('addMyListItem',({media_type: 'video', product_id: this.product_id, content_id: this.item.id}));
      } else {
        action = 'remove';
        const myListRecord = this.$store.getters.myList.filter(item => item.product_id === this.product_id && item.content_id === this.item.id);
        await this.$store.dispatch('deleteMyListItem',({id: myListRecord[0].id}));
      }
      this.m('watch_later_toggle', {title: this.title, area: this.matomo_class, action: action});
    },
    goToVideo() {
      if(this.swiping) return;
      if(this.$route.name === "home") {
        if (this.type === 'be-calm' || this.type === 'beCalm') this.$router.push('/be-calm/'+this.item.id)
        if (this.type === 'exercise') this.$router.push('/digital-gym/training-plans/'+this.item.id)
        if (this.item.schedule_type === 'gym') this.$router.push('/digital-gym/' + this.item.id)
        if (this.item.schedule_type === 'studio') this.$router.push('/wellbeing-studio/' + this.item.id)
      } else {
        if (this.type === 'be-calm' || this.type === 'beCalm') this.$router.push('/be-calm/'+this.item.id)
        if (this.type === 'exercise') this.$router.push('/digital-gym/training-plans/'+this.item.id)
        if (this.item.schedule_type === 'gym') this.$router.push('/digital-gym/' + this.item.id)
        if (this.item.schedule_type === 'studio') this.$router.push('/wellbeing-studio/' + this.item.id)
      }
    },
    async toggleDGFavourite(evt) {
      evt.stopPropagation();
      if(this.type === 'be-calm'|| this.type === 'beCalm') {
        await this.toggleBeCalmFavourite();
        return;
      }
      const payload = {
        user_id: this.$store.getters.user_id,
        event_id: this.item.id,
      };
      let res = await this.axios.post(
          process.env.VUE_APP_API_PATH +
          "/application/api/u/studio/toggle-favourite-gym-event",
          payload
      );
      if (res.data.success) {
        this.item.favourited = res.data.is_favourite;
        this.m('favourite_toggle', {title: this.title, area: this.matomo_class, action: res.data.is_favourite ? 'add' : 'remove'});
      }
    },
    async toggleBeCalmFavourite() {
      const payload = {
        user_id: this.$store.getters.user_id,
        course_id: this.item.id
      }
      let res = await this.axios.post(
          process.env.VUE_APP_API_PATH +
          "/application/api/u/resources/toggle-be-calm-favourite",
          payload
      );
      if(res.data.success) {
        this.item.favourited = res.data.is_favourite;
        this.m('favourite_toggle', {title: this.title, area: this.matomo_class, action: res.data.is_favourite ? 'add' : 'remove'});
      }
    },
  },
  computed: {
    includeActionButtons() {
      return this.type !== 'exercise';
    },
    thumbnail() {
      if(this.type === 'be-calm') {
        const image = this.getBeCalmImage(this.item.title);
        return image ? image : this.item.thumbnail;
      }
      if(this.item.thumbnail) return this.item.thumbnail;
      if(this.item.schedule_type === 'gym') return '/img/wellbeing-studio/dg_ph.webp';
      if(this.item.schedule_type === 'studio') return '/img/wellbeing-studio/wellbeing-studio.svg';
    },
    product_id() {
      if(this.type === 'event-progress') {
        if(this.item.schedule_type === 'gym') {
          return 22;
        } else {
          return 21;
        }
      }
      switch (this.type) {
        case 'studio':
          return 21;
        case 'gym':
          return 22;
        case 'beCalm': case 'be-calm':
          return 17;
        default:
          return 0;
      }
    },
    isItemInMyList() {
      if (this.type === 'exercise') return null;
      const productId = this.type === 'be-calm' ? 17 : this.product_id;
      const isInList = this.$store.getters.myList.filter(item =>
          Number.parseInt(item.product_id) === productId
          && Number.parseInt(item.content_id) === Number.parseInt(this.item.id));
      return isInList.length > 0;
    },
    isNew() {
      if(this.type === 'exercise') return false;
      const now = moment();
      const date = moment(this.item.start, "YYYY-MM-DD HH:mm:ss"); // Parse the timestamp
      const daysDifference = now.diff(date, 'days');
      return daysDifference < 5;
    },
    mins_left() {
      if(this.type === 'be-calm') return null;
      if(this.progress) {
        const course_progress_data = this.course_progress;
        const channel = this.item.schedule_type === 'gym' ? 'dg' : 'ds';
        const i = course_progress_data.findIndex(item => item.channel === channel && item.id === this.item.id);
        return Math.round((course_progress_data[i].duration - course_progress_data[i].seconds)/60,0) + ' mins left';
      } else {
        return this.runningTime;
      }
    },
    runningTime() {
      const duration = moment.duration(moment(this.item.end).diff(moment(this.item.start)));
      const hours = parseInt(duration.asHours());
      const minutes = parseInt(duration.asMinutes()) % 60;
      let string = '';
      if(hours) {
        string += hours === 1 ? hours + ' hour ' : hours + ' hours';
      }
      if(minutes) {
        string += minutes + ' mins';
      }
      return  string;
    },
    course_progress() {
      return this.$store.getters.user.user.course_progress;
    },
    progress() {
      if(this.type === 'exercise' || this.type === 'beCalm') return false;
      const course_progress_data = this.course_progress;
      const channel = this.item.schedule_type === 'gym' ? 'dg' : 'ds';
      const i = course_progress_data.findIndex(item => item.channel === channel && item.id === this.item.id);
      if(i === -1) return false
      return course_progress_data[i].percent * 100;
    },
    authorName() {
      if(!this.item.author) return null;
      let author = '';
      if(this.item.author.firstname) author = this.item.author.firstname + ' ';
      if(this.item.author.lastname) author += this.item.author.lastname;
      return author;
    },
    title() {
      return this.item.title;
    },
    description() {
      if(this.item.source === 'TrainingPlan' || this.item.source === 'ResCourses') {
        return this.item.details.description
      } else if(this.item.source === 'beCalm') {
        return  this.item.details.description;
      }
      return this.item.description;
    },
    tags() {
      return this.item.tags
    }
  }
}
</script>

<style lang="less">
#LayaCarouselCard {
  cursor: pointer;
  width: 274px;
  .carouselCard {
    width: 100%;
    aspect-ratio: 274 / 363;
    border-radius: 20px;
    border: 1px solid #EAEAEB;
    background: var(--gray-white, #FFF);
    box-shadow: 0 4px 4px rgba(79, 108, 129, 0.12);
    &:hover {
      box-shadow: 0 4px 16px rgba(79, 108, 129, 0.12)
    }
    overflow: hidden;
    .topCard {
      width: 105%;
      margin-left: -3%;
      aspect-ratio: 16/9;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      overflow: hidden;
      position: relative;
      .newTag {
        position: absolute;
        top: 15px;
        right: 12px;
        border-radius: 20px;
        background-color: #0072CE;
        font-family: Open Sans, sans-serif;
        font-weight: 400!important;
      }
      .hideVideo {
        opacity: 0;
      }
      .vimeo-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        * {
          border: none !important;
        }
        iframe {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          bottom: 0;
          pointer-events: none;
          border: none !important;
        }
      }
    }
    .cardText {
      color: #53565A;
      font-family: Open Sans,sans-serif;
      position: relative;
      padding: 24px;
      height: 56%;
      .progress {
        display: none;
        border-radius: 0 !important;
        height: 7px;
        background: #53565A;
        cursor: pointer;
        .progress-bar {
          background: #CCE8FF;
        }
      }
      .cardTextTop {
        font-size: 14px;
        cursor: pointer;
        font-style: normal;
        font-weight: 400;
        display: flex;
        justify-content: space-between;
        padding-bottom: 8px;
      }
      .cardTitle {
        width: 100%;
        font-family: "Cocon Pro Regular", sans-serif;
        cursor: pointer;
        margin: 8px 0;
      }
      .video-card-favourite {
        color: #DA1984;
      }
      .cardDescription {
        overflow: hidden;
        height: 40px;
        width: 100%;
        &.beCalmDesc {
          line-height: 24px;
          height: 72px;
        }
      }
      .cardTextBottom {
        bottom: 24px;
        right: 24px;
        height: 28px;
        width: calc(100% - 32px);
        position: absolute;
        .badge {
          border-radius: 20px;
          background: #eaeaeb;
          margin-right: 10px;
        }
      }
    }
  }
  @media only screen and (max-width: 576px) {

    .carouselCard {
      width: 235px;
      margin-left: 0;
      .cardText {
        padding: 16px;
      }
      .cardTextBottom {
        height: 20px;
        bottom: 16px !important;
      }
    }
  }
}
</style>