<template>
  <div id="MobileNavigation">
    <b-navbar class="py-0 pl-0 row bg-white d-flex justify-content-lg-center">
      <div class="col-3 col-sm-4 nav-button">
        <b-button @click="togglePageNav">
          <font-awesome-icon
            class="brand-primary"
            icon="times"
            size="3x"
            v-if="pageNav"
          ></font-awesome-icon>
          <font-awesome-icon
            class="brand-primary"
            icon="bars"
            size="3x"
            v-else
          ></font-awesome-icon>
        </b-button>
      </div>
      <b-navbar-brand
        class="col-6 col-sm-4 px-0"
        @click="hardCodedNav($store.getters.homeRoute)"
      >
        <img
          :src="getLogo"
          onerror="javascript:this.src='/img/logo-spectrum-life.svg'"
        />
      </b-navbar-brand>
      <div class="col-3 col-sm-4 nav-button">
        <b-button @click="toggleSettingsNav"
          ><font-awesome-icon
            class="brand-primary"
            icon="ellipsis-h"
            size="3x"
          ></font-awesome-icon
        ></b-button>
      </div>
    </b-navbar>
    <b-collapse :visible="pageNav" class="col-12 page-nav bg-white">
      <b-navbar-nav class="px-3">
        <div v-for="(item, index) in nav" :key="index">
          <b-nav-item
            @click="navigate(item)"
            v-if="!item.hasOwnProperty('sub_items')"
          >
            {{ item.label }}
          </b-nav-item>
          <b-nav-item v-b-toggle="'mobNavCollapse-' + index" v-else>
            {{ item.label }}<i class="ml-2 fa fa-caret-down"></i
          ></b-nav-item>
          <b-collapse :id="'mobNavCollapse-' + index">
            <div v-for="(subItem, index) in item.sub_items" :key="index">
              <b-nav-item class="pl-5" @click="navigate(subItem)">{{
                subItem.label
              }}</b-nav-item>
            </div>
          </b-collapse>
        </div>
      </b-navbar-nav>
    </b-collapse>
    <div>
      <b-col
        v-if="settingsNav"
        class="col-12 settings-nav bg-brand-secondary-dark"
      >
        <b-link
          v-if="showSideBar && $store.getters.skin !== 20"
          to="/settings"
          class="col-4 border-right"
          ><img src="/img/settings-generic.svg"
        /></b-link>
        <div
          @click="navigate('/faq')"
          v-if="showSideBar && $store.getters.skin !== 20"
          class="col-4 border-right"
        >
          <img src="/img/faq-generic.svg" />
        </div>
        <div class="col-4">
          <img src="/img/logout-generic.svg" @click="logOut" />
        </div>
      </b-col>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "MobileNavigation",
  components: {},
  data() {
    return {
      pageNav: false,
      settingsNav: false,
    };
  },
  mounted() {},
  methods: {
    hardCodedNav(url) {
      this.$router.push(url).catch((err) => {});
      this.pageNav = false;
      this.settingsNav = false;
    },
    navigate(item) {
      //Need to use falsy value as empty external link is returned inconsistently
      if (!item.external_link) {
        this.$router.push({ name: item.item_slug });
        this.pageNav = false;
        this.settingsNav = false;
      } else {
        window.open("https://" + item.external_link, "_blank");
      }
    },
    togglePageNav() {
      this.pageNav = !this.pageNav;
      this.settingsNav = false;
    },
    toggleSettingsNav() {
      this.settingsNav = !this.settingsNav;
      this.pageNav = false;
    },
    logOut() {
      this.$store.dispatch("logout");
      this.$router.push("/login").catch((err) => {});
    },
  },
  computed: {
    ...mapGetters(["hardcoded", "skin", "client"]),
    nav() {
      return this.$store.state.nav;
    },
    currentPage() {
      return this.$route.name;
    },
    topLevel() {
      return Object.keys(this.nav);
    },
    showSideBar() {
      if (
        this.$store.state.accessCode ||
        this.isLayaWellbeing()
      ) {
        return false;
      } else return true;
    },
    getLogo() {
      if (this.hardcoded && this.hardcoded.client_logo) {
        return this.hardcoded.client_logo;
      } else if (this.skin === 8) {
        return "/img/boi/boi-logo-alt.png";
      } else return this.client.client_logo;
    },
  },
};
</script>
<style lang="less">
#MobileNavigation {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10000;
  .navbar {
    height: 80px;
    .nav-button {
      text-align: center;
      .btn {
        background: transparent;
      }
    }
    .navbar-brand {
      width: 40%;
      background-color: #fff;
      text-align: center;
      cursor: pointer;
      height: 80px;
      max-width: 247px;
      align-items: center;
      display: flex;
      img {
        max-width: 100%;
        margin: auto;
        max-height: 80%;
        //this section to be edited when BOI assets are ready
      }
    }
  }
  .page-nav {
    .nav-item {
      position: relative;
      border-bottom: solid 1px;
      a {
        font-size: 1.7rem;
        font-family: "FilsonProBook", sans-serif;
      }
      &.active {
        a {
          font-family: "FilsonProBold", sans-serif;
        }
        &:before {
        }
      }
    }
    .dropdown-menu {
      position: relative;
    }
    .plans {
      font-size: 1.7rem;
    }
  }
  .settings-nav {
    height: 70px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      padding: 0;
      width: 31px;
      margin: auto;
    }
    .border-right {
      border-right: 1px solid #baa7bc !important;
    }
  }
}
</style>