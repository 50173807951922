<template>
  <div id="home-fitness" class="page-bg bg-grey px-md-5">
    <div class="content-container row">
      <div class="section-header">
        <div class="section-header-divider"></div>
        <h3 class="section-header-title">{{ componentText.title }}</h3>
      </div>
      <div class="col-12 all-plans pt-5">
        <b-row>
          <div
            v-for="(plan, i) in fitnessPlans"
            :key="i"
            @click="selectPlan(plan.plan_id)"
            class="col-md-6 col-lg-3 col-6 py-4 fitness-plan-card"
          >
            <div class="image-wrap">
              <img
                class="title-image bg-brand-secondary"
                :src="plan.title_img"
              />
              <img
                class="level-image"
                :src="'/img/fitness/level-' + plan.fitness_level + '.svg'"
              />
            </div>
            <div class="card-content brand-secondary" v-html="plan.title"></div>
          </div>
        </b-row>
      </div>
      <div class="col-12 pl-0 pb-5">
        <a
          class="home-link link-highlight"
          :class="{ 'border-brand-secondary': $store.getters.skin === 8 }"
          @click="seeAllPlans"
          >{{ componentText.seeAll }}</a
        >
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import axios from "axios";
import config from "../../config/constants";
export default {
  name: "HomeFitness",
  components: {},
  data() {
    return {
      fitnessPlans: []
    };
  },
  mounted() {
    this.getFitnessPlans();
  },
  methods: {
    async getFitnessPlans() {
      const payload = {
        user_id: this.$store.getters.user_id,
        limit: 4,
        channel: "surge"
      };
      let res = await axios.post(
        config.api_env + "/application/api-hc/get-fitness-plans",
        payload,
        config.options
      );
      if (res.data.success) {
        this.fitnessPlans = res.data.plans;
      }
    },
    selectPlan(id) {
      this.$router.push({
        name: "fitness",
        params: { setPage: 4, setSelectedPlan: id }
      });
    },
    seeAllPlans() {
      this.$router.push({ name: "fitness" });
    }
  },
  computed: {
    componentText() {
      return this.getText.loggedIn.home.homeFitness;
    }
  }
};
</script>
<style lang="less">
.all-plans {
  text-align: center;
  .fitness-plan-card {
    .image-wrap {
      position: relative;
      cursor: pointer;

      .title-image {
        height: 175px;
        width: 175px;
        border-radius: 50%;
      }

      .level-image {
        width: 37px;
        position: absolute;
        right: 45px;
        bottom: 2px;
        border-radius: 50%;
      }
    }

    .card-content {
      font-size: 1.8rem;
      text-align: center;
      cursor: pointer;
      max-width: 175px;
      margin: auto;
    }
    &:hover {
      transform: translate(-2px, -2px);
      .image-wrap {
        img {
          box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
}
@media only screen and (max-width: 576px) {
  .all-plans {
    .image-wrap {
      .title-image {
        height: 135px;
        width: 135px;
        border-radius: 50%;
      }
    }
  }
}
</style>
