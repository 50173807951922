<template>
  <div class="container verify-email">
    <div class="row page-content">
      <div class="col-md-7 img-part px-0">
        <div
          class="login-bg"
          :class="{
            'bg-brand-secondary': skin > 0,
            'bg-grad-image-purple': skin === 0,
          }"
        >
          <div class="heart-placeholder">
            <img
              v-if="hardcoded.spectrum_heart"
              class="heart-img"
              :src="hardcoded.spectrum_heart"
            />
            <img class="heart-img" src="/img/default-asset.svg" v-else />
          </div>
          <div class="verify-image"></div>
        </div>
      </div>
      <div class="col-md-5 form min-height">
        <div v-if="hardcoded.login_logos" class="logos d-flex pt-5">
          <div
            v-for="(logo, i) in hardcoded.login_logos"
            :key="i"
            class="d-flex logo px-1"
          >
            <img :src="logo" />
          </div>
        </div>
        <div class="form-content" v-if="!loading">
          <div
            :class="[
             ($store.getters.client.client_logo.toLowerCase().includes('spectrum-life-logo') ||  $store.getters.client.client_logo.toLowerCase().includes('lloydwhyte')) && skin === 15
                ? 'lw-form-logo'
                : 'form-logo',
            ]"
          >
            <img
              :src="getLogo"
              onerror="javascript:this.src='/img/logo-spectrum-life.svg'"
            />
          </div>
          <h2 class="form-title">{{ componentText.title }}</h2>
          <p>{{ componentText.message }}</p>
          <div class="col-12 text-center py-5" v-if="!resend">
            <a @click="showInput" class="show-input">{{
              componentText.noEmail
            }}</a>
          </div>
          <b-form class="px-5 pt-3" v-if="resend">
            <b-form-group
              id="input-group"
              :label="componentText.email"
              label-for="verifyEmail"
            >
              <b-form-input
                type="email"
                id="verifyEmail"
                v-model="$v.user.email.$model"
                :state="$v.user.email.$dirty ? !$v.user.email.$error : null"
                aria-describedby="user-name-error"
                @keyup.enter.native="verify"
              ></b-form-input>
              <b-form-invalid-feedback id="user-name-error">
                {{ componentText.emailError }}
              </b-form-invalid-feedback>
            </b-form-group>
            <div
              col-sm-12
              class="submit-form d-flex align-items-center justify-content-center"
            >
              <b-button type="button" @click="verify" class="cta-button py-3">{{
                componentText.resend
              }}</b-button>
            </div>
          </b-form>
        </div>
        <div class="form-content verifying" v-else>
          <div>
            <h2 class="form-title">{{ componentText.verifying }}</h2>
          </div>
          <div id="loading" :class="{ finished: userVerified }"></div>
        </div>
      </div>
    </div>
    <popup
      v-if="userVerified"
      :content="popupContent"
      v-on:close="loginNav"
    ></popup>
    <popup
      v-if="userVerified === false"
      :content="popupContent"
      v-on:close="hidePopup"
    ></popup>
  </div>
</template>

<script>
import axios from "axios";
import config from "../../config/constants";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import Popup from "../Popup.vue";
import { mapGetters } from "vuex";
export default {
  components: { Popup },
  name: "VerifyEmail",
  mixins: [validationMixin],
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
      newUser: {},
      isSignUp: false,
      organisation: null,
      loading: false,
      userVerified: "",
      popupContent: {},
      resend: false,
    };
  },
  validations: {
    user: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  mounted() {
    if (this.$route.query.key) {
      this.loading = true;
      this.verifyKey(this.$route.query.key);
    }
  },
  methods: {
    switchForm() {
      this.isSignUp = !this.isSignUp;
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    showInput() {
      this.resend = true;
    },
    submitForm() {
      this.$v.user.$touch();
      if (this.$v.user.$anyError) {
        return;
      }
      this.verify();
    },
    async verify() {
      let payload = {
        email: this.user.email,
        platform: "web",
        lang: this.$store.state.lang,
      };
      let res = await axios.post(
        config.api_env + "/application/api-hc/send-validation-email",
        payload,
        config.options
      );
      if (res.data.success) {
        this.$emit("email-sent");
      }
    },
    async getOrgLocations() {
      let payload = {
        org_code: this.newUser.org_code,
        lang: this.$store.state.lang,
      };
      try {
        let res = await axios.post(
          config.api_env + "/application/api-hc/get-client-signup-options",
          payload,
          config.options
        );
        this.organisation = res.data;
      } catch (e) {
        console.log(e);
      }
    },
    async verifyKey(key) {
      const payload = {
        key: key,
        lang: this.$store.state.lang,
      };
      try {
        let res = await axios.post(
          config.api_env + "/application/api-hc/verify-email",
          payload,
          config.options
        );
        if (res.data.success) {
          this.userVerified = true;
          this.popupContent.header = this.componentText.success;
          this.popupContent.message = this.componentText.successMessage;
        } else {
          this.userVerified = false;
          this.popupContent.header = this.componentText.error;
          this.popupContent.message = res.data.err;
        }
      } catch (e) {
        this.userVerified = false;
        this.popupContent.header = this.componentText.error;
        this.popupContent.message = e;
      }
    },
    hidePopup() {
      this.userVerified = "";
      this.loading = false;
    },
    loginNav() {
      this.$router.push("/login").catch((err) => {});
    },
  },
  computed: {
    ...mapGetters(["hardcoded", "skin"]),
    getLogo() {
      if (this.hardcoded && this.hardcoded.logo) {
        return this.hardcoded.logo;
      } else return this.$store.getters.client.client_logo;
    },
    componentText() {
      return this.getText.notLoggedIn.verifyPassword;
    },
  },
};
</script>
<style lang="less">
.verifying {
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#loading {
  width: 50px;
  height: 50px;
  border: 3px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  &.finished {
    display: none;
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.show-input {
  width: 100%;
  text-align: center;
  margin: auto;
  color: #8d8e92 !important;
  font-size: 1.5rem;
  text-decoration: underline !important;
}
</style>
