<template>
  <div
    id="sso-landing"
    :class="{
      'ornua-background': $store.getters.subdomain === 'ornua',
      'pwc-background': $store.getters.subdomain === 'pwcuk',
    }"
  >
    <div class="container">
      <div class="row page-content">
        <div class="col-12 card min-height py-5">
          <div class="logo-wrap pb-5">
            <img
              src="/img/ornua/ornua-spring-login.png"
              v-if="$store.getters.subdomain === 'ornua'"
            />
            <img
              src="/img/pwc/pwcuk-login.png"
              v-if="$store.getters.subdomain === 'pwcuk'"
            />
            <img
              src="/img/pwc/pwcuk-login.png"
              v-if="$store.getters.subdomain === 'pwcire'"
            />
            <img
              src="/img/greenergy/greenergy-login.png"
              v-if="$store.getters.subdomain === 'greenergy'"
            />
            <img
              src="/img/irishdistillers/irishdistillers-login.png"
              v-if="$store.getters.subdomain === 'irish-distillers' || $store.getters.subdomain === 'irishdistillers'"
            />
          </div>
          <h2 class="card-title brand-highlight-1 mb-2">
            Your wellbeing journey starts here!
          </h2>
          <h3 class="laya-info px-md-5 pb-5">Sign in to get started</h3>
          <div class="button-wrap submit-form pb-5">
            <b-button :href="redirectLink" class="cta-button py-3 px-5"
              ><span v-if="$store.getters.subdomain === 'greenergy'"
                >Greenergy SSO</span
              ><span v-else>SIGN IN</span></b-button
            >
          </div>
          <div class="spectrun-login pb-5" v-if="showSignInLink">
            <a @click="$emit('spectrum-login')">Sign in with Spectrum.Life</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SSOLanding",
  computed: {
    redirectLink() {
      if (this.$store.getters.subdomain === "pwcuk") {
        return process.env.VUE_APP_PWC_SSO;
      } else if (this.$store.getters.subdomain === "pwcire") {
        return process.env.VUE_APP_PWC_IRE_SSO;
      } else if (this.$store.getters.subdomain === "convergint") {
        return process.env.VUE_APP_CONVERGINTTECHNOLOGIES_SSO;
      } else if (this.$store.getters.subdomain === "greenergy") {
        return process.env.VUE_APP_GREENERGY_SSO;
      } else  if (this.$store.getters.subdomain === "prnua"){
        return process.env.VUE_APP_ORNUA_SSO;
      } else if (this.$store.getters.subdomain === "irish-distillers" || this.$store.getters.subdomain === "irishdistillers"){
        return process.env.VUE_APP_IRISHDISTILLERS_SSO;
      } else if(this.$store.getters.subdomain === "nchwellbeing") {
        return process.env.VUE_APP_NCHWELLBEING_SSO;
      } else if(this.$store.getters.subdomain === "nulondon") {
        return process.env.VUE_APP_NULONDON_SSO;
      } else if(this.$store.getters.subdomain === "uhi") {
        return process.env.VUE_APP_UHI_SSO;
      } else if(this.$store.getters.subdomain === "rcsi-eap" || this.$store.getters.subdomain === "rcsieap") {
        return process.env.VUE_APP_RCSI_EAP_SSO
      } else if(this.$store.getters.subdomain === "rcsi-sap" || this.$store.getters.subdomain === "rcsisap") {
        return process.env.VUE_APP_RCSI_SAP_SSO
      } else if(this.$store.getters.subdomain === "hrduo"){
        return process.env.VUE_APP_HRDUO_SSO
      } else if(this.$store.getters.subdomain === "test"){
        return process.env.VUE_APP_TEST_SSO
      }
    },
    showSignInLink(){
      return !(this.$store.getters.subdomain === 'rcsi-sap' || this.$store.getters.subdomain === 'rcsisap' || this.$store.getters.subdomain === 'rcsi-eap' || this.$store.getters.subdomain === 'rcsieap')
    }
  },
};
</script>

<style lang="less">
#sso-landing {
  display: flex;
  min-height: 100vh;
  flex-grow: 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  align-items: center;
  .page-content {
    border-radius: 20px !important;
    max-width: 800px;
    margin: auto;
    .card {
      align-items: center;
      .logo-wrap {
        max-width: 400px;
        img {
          width: 100%;
        }
      }
      h2 {
        padding: 0;
        color: #6a6a6a;
      }
      h3 {
        font-family: FilsonProLight, sans-serif;
        font-size: 3rem;
        color: #6a6a6a;
      }
      .cta-button {
        box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.3);
      }
      .spectrun-login {
        font-size: 1.5rem;
      }
      @media (max-width: 768px) {
        h2 {
          font-size: 2.5rem;
        }
        h3 {
          font-size: 2rem;
        }
      }
    }
  }
}
.ornua-background {
  background: url("/img/ornua/ornua-sso-background.png");
}
.pwc-background {
  background-color: #67a0d5;
}
</style>