var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"CustomHomeSlider",attrs:{"id":"CustomHomeSlider"},on:{"mouseleave":_vm.clearSelection}},[_c('div',{ref:"viewer",style:({marginLeft: _vm.viewerLeftMargin}),attrs:{"id":"viewer"}},[_c('div',{ref:"track",attrs:{"id":"track"}},[_c('div',{staticClass:"tile invisibleCard",style:({
            width:  _vm.invisibleCardWidth + 'px',
            height: _vm.tileHeight + 'px',
          })}),_vm._l((_vm.items),function(item,i){return _c('div',{key:'slider-card-' + i,staticClass:"tile",class:_vm.tileSelected === i && _vm.tilesToShow > 2 ? 'selected': null,style:({
          width:  _vm.getTileWidth(i),
          height: _vm.tileHeight + 'px',
          paddingLeft: _vm.tilePaddingLeft + 'px',
          paddingRight: _vm.tilePaddingRight + 'px'
        }),on:{"mouseover":function($event){return _vm.selectTile(i)}}},[_c('div',{staticClass:"innerTile",class:{hiddenCard: _vm.hideCards}},[(_vm.tileType === 'services')?_c('ServicesCard',{attrs:{"item":item,"stop-expand":_vm.stopExpand,"expanded":_vm.tileSelected === i && _vm.tilesToShow,"tile-height":_vm.tileHeight,"increase-factor":_vm.expandedTileStretch,"standard-tile-width":_vm.tileWidth}}):_vm._e()],1)])})],2)]),_c('div',{staticClass:"arrowLeft arrow",class:{fadeOut: _vm.page <= 1},style:({top: (_vm.tileHeight / 2 - 20) + 'px'})},[(_vm.page > 1)?_c('i',{staticClass:"fa-solid fa-chevron-left",on:{"click":_vm.goBack}}):_vm._e()]),_c('div',{staticClass:"arrowRight arrow",class:{fadeOut: _vm.totalPages <= _vm.page},style:({top: (_vm.tileHeight / 2 - 20) + 'px'})},[(_vm.totalPages > _vm.page)?_c('i',{staticClass:"fa-solid fa-chevron-right",on:{"click":_vm.goForward}}):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }