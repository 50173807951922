<template>
    <div class="FeaturesCarousel" v-if="!loaded || content.length > 0" @mouseover="showArrows = true" @mouseleave="showArrows = false" :class="showArrows ? null : 'hide-arrows'">
        <div v-if="!loaded" class="mb-3 container d-flex flex-row justify-content-between align-items-center pt-3">
            <HomeLoading/>
        </div>
        <div v-else>
            <div class="position-relative">
                <div class="fc-container w-100" v-if="type === 'featured-event' && loaded === true">
                    <slick id='slick-id' ref="slick" :options="eventSlickOptions"
                           class="justify-content-center">
                          <FeaturesCarouselCard v-for="event in content" :event="event" :reminders="reminders"/>
                    </slick>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import slick from "vue-slick"
import VideoCard from "@/components/Home/New/VideoCard.vue"
import BookingCard from "@/components/Home/New/BookingCard.vue"
import PodcastCard from "@/components/Home/New/PodcastCard.vue"
import HomeBannerEventCard from "@/components/Home/New/HomeBannerEventCard.vue";
import EventBus from "@/helpers/eventbus";
import HomeRecipeCard from "@/components/Home/New/HomeRecipeCard.vue";
import Loader from "@/components/Loader.vue";
import VimeoCard from "@/components/Home/New/VimeoCard.vue";
import CourseCard from "@/components/Home/New/CourseCard.vue";
import HomeLoading from "@/components/Home/New/HomeLoading.vue";
import HomeClinicCard from "@/components/Home/New/HomeClinicCard.vue";
import moment from "moment";
import FeaturesCarouselCard from "@/components/Home/New/FeaturesCarouselCard.vue";

export default {
    name: "FeaturesCarousel",
    props: {
        title: String,
        type: String,
        permaTag: String,
        cardWith: Number
    },
    components: {
        FeaturesCarouselCard,
        HomeLoading,
        HomeClinicCard,
        HomeRecipeCard,
        VideoCard,
        BookingCard,
        PodcastCard,
        HomeBannerEventCard,
        slick,
        Loader,
        VimeoCard,
        CourseCard
    },
    data() {
        return {
            reminders: [],
            swiping: false,
            loaded: false,
            loading_new_content: false,
            content: [],
            page: 1,
            totalRecords: 0,
            totalPages: 0,
            optionalTag: [],
            showArrows: false,
            clinicSettings: null,
            eventSlickOptions: {
                initialSlide: 0,
                arrows: true,
                slidesToScroll: 1,
                slidesToShow: 1,
                nextArrow:
                    '<div class="slick-arrow-next">' +
                    '<a class="slick-arrow-container"><i class="fas fa-chevron-right text-white fa-4xs"></i></a>' +
                    '</div>',
                prevArrow:
                    '<div class="slick-arrow-prev">' +
                    '<a class=" slick-arrow-container"><i class="fas fa-chevron-left text-white fa-4xs"></i></a>' +
                    '</div>',
                adaptiveHeight: true,
                infinite: false,
                responsive: [],
            },
        };
    },
    methods: {

        status(startDateTime,endDateTime) {
          const start = moment(startDateTime);
          const end = moment(endDateTime);
          const now = moment();
          if(now.isBefore(start) || !startDateTime || !endDateTime) return 'not_started';
          if(now.isAfter(end)) return 'ended';
          return 'live';
        },
        async getContent(reInit = false, restart = false) {
            this.loading_new_content = true;
            let contentPath = '';
            switch(this.type) {
                case 'gym':
                    contentPath = "/application/api/u/studio/events";
                    break;
                case 'studio':
                    contentPath = "/application/api/u/studio/events";
                    break;
                case 'featured-event':
                    contentPath = "/application/api/u/studio/events/trending";
                    break;
                case 'recipes':
                    contentPath = "/application/api/u/content/nutrition";
                    break;
                case 'clinic':
                    contentPath = "/application/api/u/home/get-dc-banner";
                    break;
                default:
                    contentPath = "/application/api/u/content";
            }
            if(restart) this.page = 1;
            const payload = {
                user_id: this.$store.getters.user_id,
                tags: this.optionalTag.concat([this.type === 'featured-event' ? 'studio' : this.type]),
                page: this.page,
                pageSize: 10,
            };
            if(this.permaTag) {
                payload.tags.push(this.permaTag)
            }
            let res = await this.axios.post(
                process.env.VUE_APP_API_PATH +
                contentPath,
                payload
            );
            if (res.data.success) {
                if (this.page === 1) {
                    this.content = res.data.data;
                    this.totalRecords = res.data.meta.pagination.total;
                    this.totalPages = res.data.meta.pagination.total_pages;
                    this.page++;
                    if (reInit) this.reinitSlider(true);
                    this.loaded = true;
                } else {
                    this.loading_new_content = false;
                    this.content = this.content.concat(res.data.data);
                    this.page++;
                    this.reinitSlider();
                    this.loaded = true;
                }
            } else if (res.data.err && res.data.err.includes("3994")) {
                this.$store.dispatch("logout");
                this.$router.push("/login");
            } else {
                console.log('no data')
                this.loading_new_content = false;
                this.loaded = true;
            }

        },
        reinitSlider(backToStart = false) {
            let self = this;
            if(backToStart) this.eventSlickOptions.initialSlide = 0;
            if (self.$refs.slick) {
                let currentSlide = backToStart ? 0 : self.$refs.slick.currentSlide();
                this.$refs.slick.destroy();
                this.$nextTick(() => {
                    console.log()
                    let options = self.eventSlickOptions;
                    options.initialSlide = currentSlide;
                    self.$refs.slick.create(options);
                });
            }
            this.loading_new_content = false;
        },

    },
    mounted() {
        let self = this;
        EventBus.$on("setTag", function (tag) {
            if (tag) {
                self.optionalTag = [tag.tag];
            } else {
                self.optionalTag = [];
            }
        });
        this.getContent(true);
        EventBus.$on('setBookingReminders', (data) => {
            this.reminders = data;
        });
        EventBus.$on("sideNavExpanded",(expanded) => {
          setTimeout(()=> {
            this.reinitSlider();
          }, 500)
        })
    },
    computed: {
    }
};
</script>

<style lang="less">
.FeaturesCarousel {
  .fc-container {
    margin: auto;
  }
  .slick-disabled {
    display: none !important;
  }
  .slick-track {
    transition: width 0.2s linear;
  }
  &.hide-arrows {
    .std-arrow {
      display: none !important;
    }
  }
  .home-title-line {
    height: 1px;
    width: 200px;
    max-width: 50%;
  }
  .hideLoader {
    width: 0px !important;
  }
  .slick-slide:has(.hideLoader) {
    width: 0px !important;
  }
  .see-more-link {
    font-family: "FilsonPro", sans-serif;
    font-size: 1.5em;
    &:hover {
      cursor: pointer;
    }
  }
  .slick-slider {
    transition: width 0.2s linear;
    .slick-slide {
      transition: width 0.2s linear;
      transform: scale(1);
    }
  }

  .slick-list {
    margin: auto;
    transition: width 0.2s linear;
    overflow: visible;
  }

  .slick-arrow-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    padding: 25px;
    height: 100%;
    display: none;
  }
  @media only screen and (max-width: 767px) {
    .home-title-line {
      display: none !important;
    }
  }
  @media only screen and (min-width: 980px) and (max-width: 992px) {
    .slick-arrow-container {
      display: flex;
    }
  }

  @media only screen and (min-width: 1130px) and (max-width: 1200px) {
    .slick-arrow-container {
      display: flex;
    }
  }

  @media only screen and (min-width: 1320px) {
    .slick-arrow-container {
      display: flex;
    }
  }
  .displayLoader {

  }
  .slick-arrow-next {
    right: 20px;
  }

  .slick-arrow-prev {
    left: 0px;
  }
  .std-arrow {
    top: 70px !important;
    &.slick-arrow-next {
      right: 0px;
    }
    &.slick-disabled {
      display: none !important;
    }
    &.slick-arrow-prev {
      left: -25px;
    }
    .std-arrow-container {
      background-color: rgba(245, 241, 241, 0.7);
    }
  }
  .slick-track{
    margin-left: 0;
  }
.slick-slide {
    opacity: 0;
}
  .slick-slide.slick-active {
    pointer-events: auto;
    opacity: 1;
  }

  .slick-slide {
    pointer-events: none;
  }

  .slick-disabled {
    .bg-brand-highlight-1 {
      background-color: #eee;
      cursor: default;
    }
  }
  .video-blur-container {
    pointer-events: none;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;

    .container {
      flex-grow: 1;

    }

    .left-blur {
      flex-grow: 1;
      background: rgb(247, 247, 247);
      background: linear-gradient(270deg, rgba(255, 255, 255, 0.0) 5%, rgba(255, 255, 255, 0.7) 35%, rgba(255, 255, 255, 1) 70%);
    }

    .right-blur {
      flex-grow: 1;
      background: rgb(247, 247, 247);
      background: linear-gradient(90deg, rgba(255, 255, 255, 0.0) 5%, rgba(255, 255, 255, 0.7) 35%, rgba(255, 255, 255, 1) 70%);
    }
  }
}
</style>
