import Vue from "vue";
import moment from "moment";
import store from '@/store'
import ProfileQuestionnaireModal from '../components/modals/ProfileQuestionnaireModal.vue'


import axios from "axios";
import config from "../config/constants";

/**
 * https://bootstrap-vue.js.org/docs/components/modal/#modal-message-boxes
 * accepted props : https://bootstrap-vue.js.org/docs/components/modal/#comp-ref-b-modal
 * but in camelCase and not kebab-case
 */
const defaultModalOptions = {
  title: "",
  hideHeader: true,
  size: "md",
  buttonSize: "lg",
  okTitle: "OK",
  cancelTitle: "NO",
  footerClass: "p-2",
  hideHeaderClose: true,
  centered: true,
  contentClass: "pop-up-content"
};
/**
 *  language imports for translation files
 */
import * as en from '../assets/lang/_en'
import * as de from '../assets/lang/_de'
import { options } from "less";
const languages = {
  en: en,
  de: de
}
const languageNames = {
  en: 'English',
  de: 'Deutsch'
}
Vue.mixin({
  methods: {
    m(eventName,payload) {
        window._mtm.push({"event": eventName, path: this.$route.path ,...payload});
    },
    async logAction(action, successFunction) {
      let res = await this.api({
        path: "api/u/settings/log-action",
        action,
      });
      if(successFunction){
        successFunction;
      }
    },
    scrollToRef(ref) {
      if(ref){
        const el = this.$refs[ref];
        el.scrollIntoView({ behavior: "smooth" });
      }else{
        const el = document.getElementById('LoggedIn');
        el.scrollIntoView({behavior: "smooth"});
      }
    },
    friendlyTimeString(str) {
      return moment(str).fromNow();
    },
    getDateHours(str) {
      //return this.$date(new Date(str.replace(" ", "T")), "ddd DD MMM HH.mm");
      return moment(str.replace(" ", "T")).locale(this.$store.state.lang).format("ddd DD MMM HH:mm");
    },
    getMonth(str) {
      return moment(str.replace(" ", "T")).locale(this.$store.state.lang).format("MMM");
    },
    getDay(str) {
      return moment(str.replace(" ", "T")).locale(this.$store.state.lang).format("DD");
    },
    getDayString(str) {
      return moment(str.replace(" ", "T")).locale(this.$store.state.lang).format("ddd");
    },
    getDayStringLong(str) {
      return moment(str.replace(" ", "T")).locale(this.$store.state.lang).format("dddd");
    },
    getDate(str) {
      return moment(str).locale(this.$store.state.lang).format("ddd DD MMM Y");
    },
    getDateWithoutYear(str) {
      return moment(str).locale(this.$store.state.lang).format("ddd DD MMM");
    },
    getShortDate(str) {
      return moment(str).locale(this.$store.state.lang).format("DD MMM YY");
    },
    getHoursMinutes(str, isTwelveHour = false) {
      if (isTwelveHour) return moment(str.replace(" ", "T")).format("h:mm A");
      return moment(str.replace(" ", "T")).format("HH:mm");
    },
    getFormatedDate(date, format) {
      return moment(date).locale(this.$store.state.lang).format(format)
    },
    getChatDate(date) {
      return moment(date).locale(this.$store.state.lang).format('DD/MM HH:mm');
    },
    getDateLineText(appointmentStr) {
      let appointmentDate = new Date(appointmentStr).setHours(0, 0, 0, 0);
      if (navigator.userAgent.toLowerCase().indexOf("safari/")) {
        const safariDate = new Date(appointmentStr.replace(/-/g, "/"));
        appointmentDate = new Date(safariDate).setHours(0, 0, 0, 0);
      }
      const today = new Date().setHours(0, 0, 0, 0);
      const dayString = moment(appointmentDate)
        .locale(this.$store.state.lang)
        .format("dddd");
      if (appointmentDate === today) return "Today";
      if (appointmentDate === today + 86400000) return "Tomorrow";
      if (moment(appointmentDate).isoWeek() === moment().isoWeek())
        return dayString + " - This Week";
      if (moment(appointmentDate).isoWeek() === moment().isoWeek() + 1)
        return dayString + " - Next Week";
      if (moment(appointmentDate).isoWeek() === 1 && moment().isoWeek() === 52)
        return dayString + " - Next Week";
      return moment(appointmentDate).fromNow();
    },
    getFriendlyDateText(appointmentStr) {
      let appointmentDate = new Date(appointmentStr).setHours(0, 0, 0, 0);
      if (navigator.userAgent.toLowerCase().indexOf("safari/")) {
        const safariDate = new Date(appointmentStr.replace(/-/g, "/"));
        appointmentDate = new Date(safariDate).setHours(0, 0, 0, 0);
      }
      const today = new Date().setHours(0, 0, 0, 0);
      const dayString = moment(appointmentDate)
        .locale(this.$store.state.lang)
        .format("Do MMMM");
      if (appointmentDate === today) return "Today";
      if (appointmentDate === today + 86400000) return "Tomorrow";
      if (moment(appointmentDate).isoWeek() === moment().isoWeek())
        return dayString;
      if (moment(appointmentDate).isoWeek() === moment().isoWeek() + 1)
        return dayString;
      if (moment(appointmentDate).isoWeek() === 1 && moment().isoWeek() === 52)
        return dayString; 
      return moment(appointmentDate).fromNow();
    },
    getTheme() {
      return "theme-" + this.$store.getters.skin
    },
    isPast(str) {
      return new Date(str.replace(" ", "T")) < new Date();
    },
    showMsgModal(msg = "something", options = defaultModalOptions) {
      const skin = this.$store.getters.skin;
      options.modalClass = "theme-" + skin;
      return this.$bvModal.msgBoxOk(msg, options);
    },
    showConfirmModal(msg = "something", options = defaultModalOptions) {
      const skin = this.$store.getters.skin;
      options.modalClass = "theme-" + skin;
      return this.$bvModal.msgBoxConfirm(msg, options);
    },
    showConversationWrapper() {
      const content = this.$createElement(ConversationWrapper)
      const skin = this.$store.getters.skin;
      options.modalClass = "conversation-modal theme-" + skin;
      options.okTitle = "Close";
      let opts = {
        title: "",
        hideHeader: true,
        size: "xl",
        buttonSize: "lg",
        okTitle: "OK",
        footerClass: "p-2",
        hideHeaderClose: true,
        centered: true,
        contentClass: "pop-up-content"
      }
      return this.$bvModal.msgBoxOk(content, opts);
    },
    coachModal(c_id) {
      const content = this.$createElement(EvolveSingleCoach, { props: { coach_id: c_id } })
      const skin = this.$store.getters.skin;
      let opts = {
        title: "Choose your coach",
        hideHeader: false,
        hideFooter: true,
        modalClass: "coach_modal theme-" + skin,
        headerClass: "coach_modal_header bg-brand-highlight-1",
        size: "xl",
        buttonSize: "sm",
        centered: true,
        contentClass: "coach_modal",
        id: 'single_coach_modal'
      }
      return this.$bvModal.msgBoxOk(content, opts);
    },
    cardModal(amount, currency) {
      const content = this.$createElement(CardPayment, { props: { amount: amount, currency: currency } })
      const skin = this.$store.getters.skin;
      let opts = {
        title: "Payment",
        hideHeader: false,
        hideFooter: true,
        modalClass: "card_modal theme-" + skin,
        headerClass: "card_modal_header bg-brand-highlight-1",
        size: "xl",
        buttonSize: "sm",
        centered: true,
        contentClass: "card_modal",
        id: 'card_modal'
      }
      return this.$bvModal.msgBoxOk(content, opts);
    },
    voucherModal() {
      const content = this.$createElement(RedeemVoucher, { props: { close_function: () => { this.$bvModal.hide("redeem_voucher_modal") } } })
      const skin = this.$store.getters.skin;
      let opts = {
        title: "Payment",
        hideHeader: false,
        hideFooter: true,
        modalClass: "redeem_voucher_modal wallet_modal theme-" + skin,
        headerClass: "redeem_voucher_modal_header d-none",
        footerClass: "d-none",
        size: "md",
        buttonSize: "sm",
        centered: true,
        contentClass: "card_modal",
        id: 'redeem_voucher_modal'
      }
      return this.$bvModal.msgBoxOk(content, opts);
    },
    showProfileQuestionnaireModal(user, email, client_name) {
      const content = this.$createElement(ProfileQuestionnaireModal, { props: { user: user, email: email, client_name: client_name } })
      const skin = this.$store.getters.skin;
      let opts = {
        title: "Complete your profile to start your journey!",
        hideHeader: true,
        hideFooter: true,
        hideOk: true,
        modalClass: "questionnaire-modal theme-" + skin,
        headerClass: "questionnaire-modal-header",
        size: "lg",
        buttonSize: "sm",
        centered: true,
        contentClass: "questionnaire-modal-content",
        id: 'profile-questionnaire-modal'
      }
      return this.$bvModal.msgBoxOk(content, opts);
    },
    currencyString(cents = 0, curr = "eur") {
      return (cents /= 100).toLocaleString("en-GB", {
        style: "currency",
        currency: curr.toUpperCase()
      });
    },
    async api(params) {
      params.user_id = this.$store.getters.user_id
      const path = params.path
      let payload = params
      delete payload.path
      let res = await axios.post(
        config.api_env + "/application/" + path,
        payload,
        config.options
      );
      if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login").catch((err) => { });
        return false;
      } else {
        return res.data;
      }
    },
    isLayaWellbeing() {
      return true;
    },
    genRandomStringLowerCase(length) {
       var chars = 'abcdefghijklmnopqrstuvwxyz';
       var charLength = chars.length;
       var result = '';
       for ( var i = 0; i < length; i++ ) {
          result += chars.charAt(Math.floor(Math.random() * charLength));
       }
       return result;
    },
    genRandonString(length) {
       var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()';
       var charLength = chars.length;
       var result = '';
       for ( var i = 0; i < length; i++ ) {
          result += chars.charAt(Math.floor(Math.random() * charLength));
       }
       return result;
    },
    getCurrencyPriceString(currency, amount, full = false) {
      let am = amount / 100
      let opts = {
        style: "currency",
        currency: currency.toUpperCase(),
        maximumFractionDigits: full ? 2 : 0
      }
      let value = am.toLocaleString("en-IE", opts);
      if (value.slice(-3) === '.00'){
        value = value.slice(0, value.length-3);
      }
      return value;
    },
    addCommasToInteger(int) {
        let number = Number.parseInt(int);
        if(isNaN(number)) return false;
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    getCharacterWidth(char) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        context.font = '16px Open Sans';
        return context.measureText(char).width;
    },
    convertMilesToMeters(miles) {
        return Math.floor(miles * 1609.344);
    },
    convertMetersToMiles(meters) {
        return Math.round(meters * 0.000621371 * 100) / 100;
    },
    layaClass(inputString) {
        return inputString.replace(/ /g, '_');
    },
    layaId(inputString) {
        return inputString;
    },
    layaCarouselResponsiveOptions() {
        const a = {"breakpoint": 351, "settings": {"slidesToShow": 1.2}};
        let i = 350;
        const array = [];
        do {
            const paddingLeft = i < 768 ? 16 : 40;
            const carouselWidth = i - paddingLeft;
            const gap = i < 767 ? 16 : 32;
            const cardWidth = i < 577 ? 233 : 272;
            const total = cardWidth + gap;
            const bp = (carouselWidth - gap) / (total);
            array.push({"breakpoint": i, "settings": {"slidesToShow": Number(bp.toFixed(2))}})
            i++;
        } while (i <= 991);
        const setBps = [
            {i: 1240, bp: 3}
            ];
        setBps.forEach(item => {
            array.push({"breakpoint": item.i, "settings": {"slidesToShow": item.bp}})
        });
        return array;
    }
  },
  computed: {
    allLanguages() {
      return languageNames
    },
    getText() {
      let lang = store.state.lang;
      if(lang !== 'en' || lang !== 'de') lang = 'en';
      return languages[lang].default
    }
  }
});
