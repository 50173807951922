<template>
  <div>
  <div id="HomeTMH">
    <div class="content-container">
      <div class="row">
        <div class="col-md-6 col-sm-12 d-flex align-items-center">
          <div class="home-card shadow mb-4">
            <h3>Total Mental Health</h3>
            <p>
              Our team of Mental Health Coaches are on hand to help you make the
              changes you want or need to make in your life. Whether it be
              emotional wellbeing, motivation, goal setting or a feeling that
              things are just a little off, our qualified and experienced
              coaches are here to help. You can also speak to our team of Mental
              Health Professionals 24/7 via Phone, SMS or WhatsApp.
            </p>
<!--            <div class="evolve-home-eap-numbers">-->
<!--              <div class="eap-number-box">IE: <a href="tel:+1800903542" class="telephone-link"><b>1800 903 542</b></a></div>-->
<!--              <div class="eap-number-box">UK: <a href="tel:+08081962016" class="telephone-link"><b>0808 196 2016</b></a></div>-->
<!--              <div class="eap-number-box">International: <a href="tel:+0035315180277" class="telephone-link"><b>+353 1 518 0277</b></a></div>-->
<!--            </div>-->
            <b-button
              class="cta-button mt-4"
              @click="$router.push('total-mental-health')"
              >View Total Mental Health</b-button
            >
          </div>
        </div>
        <div class="col-md-6 col-sm-12 z-index">
          <img class="img-fluid" src="/img/home/hometmh.png" />
        </div>
      </div>
    </div>
    <div class="circle-1"></div>
    <div class="circle-2"></div>

    <div class="bottom-arch"></div>
  </div >
    <div class="w-100" style="background-color: white">
      <div class="content-container py-5" id="EAPNumbers-Homepage">
        <p>
          If you would prefer to talk with a member of the 24/7 Mental Health Support team instead you can call or text us:
        </p>
        <div class="evolve-home-eap-numbers pb-5 row mt-5">
          <div class="col-4 eap-number-box">IE: <a href="tel:+1800903542" class="telephone-link"><b>1800 903 542</b></a></div>
          <div class="col-4 eap-number-box">UK: <a href="tel:+08081962016" class="telephone-link"><b>0808 196 2016</b></a></div>
          <div class="col-4 eap-number-box">International: <a href="tel:+0035315180277" class="telephone-link"><b>+353 1 518 0277</b></a></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HomeTMH",
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="less">
#HomeTMH {
  z-index: 1;
  display: flex;
  justify-content: center;
  padding-top: 45px;
  padding-bottom: 50px;
  background-color: #9d9964;
  position: relative;
  overflow: hidden;
  .home-card {
    z-index: 3;
    background-color: white;
    padding: 30px;
    font-size: 1.6rem;
    border-radius: 16px;
    h3 {
      letter-spacing: 1px;
      padding-top: 15px;
      font-family: "FilsonProBook", Arial, Helvetica, sans-serif;
    }
    .newbuttoncolours {
      background-color: #651242;
      color: white;
    }
  }
  .z-index {
    z-index: 3;
  }
  .circle-1 {
    z-index: 2;
    width: 500px;
    height: 500px;
    border-radius: 500px;
    position: absolute;
    background-color: #758d8c;
    left: -140px;
    top: -170px;
  }
  .circle-2 {
    z-index: 2;
    width: 500px;
    height: 500px;
    border-radius: 500px;
    position: absolute;
    background-color: #7a474d;
    right: -150px;
    top: 25px;
  }
  .bottom-arch {
    z-index: 2;
    position: absolute;
    bottom: 0;
    width: calc(100% + 50px);
    height: 200px;
    background-color: #edece5;
    border-top-left-radius: 90% 100%;
    border-top-right-radius: 90% 100%;
  }
  .telephone-link {
    color: black;
  }
}
#EAPNumbers-Homepage {
  background-color: white;
  p {
    font-size: 16px;
    text-align: center;
    font-family: FilsonProBold;

  }
  .eap-number-box {
    color: black;
    font-size: 16px;
    text-align: center;
    a {
      color: black;
    }
  }
}
</style>
