<template>
  <div id="LayaStudioEvent">
    <div class="content-container" v-if="!loading">
      <div class="pageContainer mt-5">
        <div class="authorRow" v-if="event.author">
          <div class="authorImg ml-3">
            <img alt="author picture" :src="event.author.image">
          </div>
          <div class="authorName ml-4" >
            <div style="font-weight: bold">{{event.author.firstname}} {{event.author.lastname}}</div>
            <div>{{event.author.title}}</div>
          </div>
        </div>
        <div class="eventSection">
          <h3 class="my-5">{{title}}</h3>
          <div class="infoRow d-flex justify-content-between">
            <div>
              <span style="font-weight: bold" class="mr-3">{{formattedDate}}</span>
              {{eventLengthMinutes}}
            </div>
            <div style="width: 60px" class="d-flex justify-content-between align-items-center" v-if="!isTrainingPlan">
                <div style="cursor: pointer" tabindex="0" @keydown.enter="toggleDGFavourite">
                  <div  :id="'favs'" class="video-card-favourite" v-if="!event.is_favourited"><i @click="toggleDGFavourite" style="color: #DA1984;" class="fa-regular fa-heart fa-lg"></i></div>
                  <div  :id="'favs'" class="video-card-favourite" v-else><i @click="toggleDGFavourite" style="color: #DA1984;" class="fa-solid fa-heart fa-lg"></i></div>
                  <b-tooltip :target="'favs'" triggers="hover" placement="top" custom-class="mylist-tooltip">
                    {{event.is_favourited ? 'Remove from favourites' : 'Add to favourites'}}
                  </b-tooltip>
                </div>
                <div style="cursor: pointer" class="d-flex align-items-center justify-content-center h-100" @click="watchLater" tabindex="0" @keydown.enter="watchLater">
                  <i :id="'myList'" class="fa-regular fa-lg myListBtn" :class="isItemInMyList ? 'fa-circle-check plus':'fa-circle-plus plus'"></i>
                  <b-tooltip :target="'myList'" triggers="hover" placement="top" custom-class="mylist-tooltip">
                    {{isItemInMyList ? 'Remove from Watch Later' : 'Add to Watch Later'}}
                  </b-tooltip>
                </div>

            </div>
          </div>
          <VisualAudioDisplay
            style="max-width: 680px"
            v-if="isTrainingPlan && currentModule"
            :type="'embedded_code'"
            :id="currentModule.event_id"
            :url="null"
            :is-first-item="isFirstItem"
            :is-last-item="isLastItem"
            :content="null"
            :title="currentModule.title"
            :narrow-device-mode="false"
            :laya-mode="true"
            :exlcude-mark-as-complete="isTrainingPlan"
            :embedded-code="currentModule.video_url"
            @previous="()=>{}"
            @next="completedModule"
            @ended="completedModule"
          ></VisualAudioDisplay>
          <VisualAudioDisplay
              style="max-width: 680px"
              v-else-if="event.embed_code"
              :type="'embedded_code'"
              :id="event.id"
              :url="null"
              :is-first-item="true"
              :is-last-item="true"
              :content="null"
              :title="event.title"
              :narrow-device-mode="false"
              :embedded-code="event.embed_code"
              :laya-mode="true"
              :exlcude-mark-as-complete="isTrainingPlan"
              @previous="()=>{}"
              @next="()=>{}"
              @ended="()=>{}"
          ></VisualAudioDisplay>
          <div v-if="!isTrainingPlan && !event.embed_code" class="my-4">
            <p>This event is not available to view yet, click below to receive a reminder email before it goes live!</p>
            <div class="d-flex justify-content-center align-items-center">
              <i class="fa-calendar-check navIcon fa-xl fa-regular mr-4"></i><span style="font-weight: bold">{{eventStartText}}</span>
            </div>
            <div class="d-flex justify-content-center align-items-center mt-4">
              <b-button class="layaBtn m-auto mark-as-complete" :id="this.title" @click="remindMe">{{event.reminder_registered ? 'Cancel reminder' : 'Remind me'}}</b-button>
            </div>

          </div>
          <div class="text-center disclaimerContainer" v-if="event.schedule_type === 'gym' || isTrainingPlan">
            <p class="disclaimer-heading">
              By taking part in this information session, you accept this health and wellbeing waiver.
            </p>
            <a id="disclaimerToggleButton" v-b-toggle.disclaimer class="text-link" v-if="!disclaimerVisible">{{
                componentText.showMore
              }}</a>
            <b-collapse
                id="disclaimer"
                v-model="disclaimerVisible"
            >
              <h4>Advice & Disclaimer For Health And Wellbeing Session:</h4>
              <p>It is the responsibility of the participant to ensure:</p>
              <ul>
                <li>There is sufficient space to perform the exercises safely and move freely without obstacles or obstructions, free from furniture, equipment and other hazards – a minimum of four square metres per person</li>
                <li>Any equipment is safe and in a suitable condition for the activity being performed</li><li>The surrounding floor space is entirely clear to remove any hazards that may increase the risk of slips, trips or falls</li>
                <li>There are no distractions throughout the duration of the session</li>
                <li>There is sufficient heating, lighting, ventilation and hydration</li>
                <li>They have contacted their GP or Health Professional prior to engaging with this programme to confirm it is safe for them to do so, especially if they have a pre diagnosed health condition</li>
              </ul>
              <h4>Disclaimer</h4>
              <p>As with all fitness and health programmes, you need to use your common sense. To reduce and avoid injury, you will have to consult with your GP or Health Professional before engaging in any physical exercise. By performing any fitness exercises without supervision, you are performing them at your own risk. This fitness programme is conducted by Spectrum.Life. Spectrum.Life is not responsible or liable for any injury or harm you sustain as a result of this fitness programme/fitness session or information shared on our website(s)</p>
            </b-collapse>
            <a v-b-toggle.disclaimer class="text-link" v-if="disclaimerVisible">{{
                componentText.showLess
              }}</a>
          </div>
          <div class="descriptionRow" :class="{bottomBorder: !isTrainingPlan}">
            {{description}}
          </div>
          <div class="d-flex tpBtn" style="max-width: 680px" v-if="isTrainingPlan">
            <b-button class="layaBtn mark-as-complete training-plan" :id="this.matomoTitle" @click="completedModule">Mark as complete</b-button>
          </div>
          <div class="mt-5">
            <div class="authorBioBox d-flex" v-if="event.author">
              <div class="authorImg mr-4">
                <img alt="author picture" :src="event.author.image">
              </div>
              <div class="authorBioText">
                <div style="font-weight: bold" class="name mb-2">{{event.author.firstname}} {{event.author.lastname}}</div>
                <div class="bio">{{event.author.bio}}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isTrainingPlan" class="similarCoursesSection trainingPlan pl-5" style="padding-top: 20px" >
          <h4 class="my-5">Modules in this plan</h4>
          <div class="mt-4 similarCoursesHolder">
            <div v-for="(mod,i) in modules" class="similarCourseBox my-4 d-flex modules-in-plan" :id="layaId(mod.title)" @click="changeSelectedModule(mod.id)" @keydown.enter="changeSelectedModule(mod.id)" tabindex="0">
              <div style="width: 100px; height: 100%;" :id="layaId(mod.title)" class="imageHolder d-flex align-items-center justify-content-center modules-in-plan" :style="{ backgroundImage: 'url(' + course.featured_img + ')' }">
              </div>
              <div :id="layaId(mod.title)" class="textHolder position-relative modules-in-plan">
                <b-badge :id="layaId(mod.title)" v-if="selectedModule === mod.id" variant="success" class="position-absolute modules-in-plan" style="top: 10px; right: 10px">Playing</b-badge>
                <div :id="layaId(mod.title)" class="topRow modules-in-plan">
                  <span :id="layaId(mod.title)" class="float-end modules-in-plan">
                    {{ mod.duration_minutes + ' mins' }}
                  </span>
                </div>
                <h6 :id="layaId(mod.title)" class="mt-3 title modules-in-plan">{{mod.title}}</h6>
                <div :id="layaId(mod.title)" class="modComplete modules-in-plan" v-if="mod.attended"><i class="fa-regular fa-check"></i>Completed</div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="similarCoursesSection pl-5" style="padding-top: 20px">
          <h4 class="my-5">Similar Videos</h4>
          <div class="mt-4 similarCoursesHolder">
            <div v-for="(c,i) in similarCourses" class="similarCourseBox my-4 d-flex" :id="layaId(c.title)" :class="layaClass(c.title)+ ' similar-videos'" @click="goToVideo(c)" @keydown.enter="goToVideo(c)" tabindex="0" >
              <div :id="layaId(c.title)" v-if="c.thumbnail !== null" style="width: 100px; height: 100%;" class="imageHolder" :class="layaClass(c.title) + ' similar-videos'" :style="{ backgroundImage: 'url(' + c.thumbnail + ')' }"></div>
              <div :id="layaId(c.title)" v-else style="width: 100px; height: 100%; background-image: url('/img/wellbeing-studio/wellbeing-studio.svg');background-size: contain;" class="imageHolder" :class="layaClass(c.title) + ' similar-videos'" :style="{ backgroundImage: 'url(/img/wellbeing-studio/wellbeing-studio.svg)', }"></div>
              <div class="textHolder" :id="layaId(c.title)" :class="layaClass(c.title)+ ' similar-videos'">
                <div class="topRow" :id="layaId(c.title)" :class="layaClass(c.title)+ ' similar-videos'">
                  <span v-if="c.author" class="float-start similar-videos" :id="layaId(c.title)" :class="layaClass(c.title)+ ' similar-videos'">
                    {{c.author.firstname}} {{c.author.lastname}}
                  </span>
                  <span class="float-end" :id="layaId(c.title)" :class="layaClass(c.title)+ ' similar-videos'">
                    {{ similarCourseDuration(c.start, c.end) }}
                  </span>
                </div>
                <h6 class="mt-3 title similar-videos" :id="layaId(c.title)">{{c.title}}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import moment from "moment";
import EventVideo from "@/components/LiveEvent/EventVideo.vue";
import Vimeo from "@vimeo/player";
import VisualAudioDisplay from "@/components/NewStudio&CourseLayout/Content/VisualAudioDisplay.vue";
import ShareModal from "@/components/NewStudio&CourseLayout/Modals/ShareModal.vue";
import EventBus from "@/helpers/eventbus";

export default {
  name: "LayaStudioEvent",
  components: {VisualAudioDisplay, EventVideo},
  data() {
    return {
      disclaimerVisible: false,
      author: {},
      event: {},
      loading: true,
      similarCourses: [],
      course: {},
      modules: [],
      progress: 0,
      selectedModule: null,
    }
  },
  mounted() {
    if(this.isTrainingPlan) {
      this.getCourse();
    } else {
      this.getEvent();
    }
  },
  destroyed() {
    clearTimeout(this.timeout);
  },
  methods: {
    async remindMe() {
      this.changingReminderStatus = true;
      const payload = {
        user_id: this.$store.getters.user_id,
        event_id: this.$route.params.id,
      };
      if (!this.event.reminder_registered) {
        let res = await this.axios.post(
            process.env.VUE_APP_API_PATH +
            "/application/api/u/studio/register-reminder",
            payload
        );
        if (res.data.success) {
          this.event.reminder_registered = true;
          EventBus.$emit('updateDFDReminders');
        }
        this.changingReminderStatus = false;
        if(!this.isItemInMyList) this.watchLater();
      } else {
        let res = await this.axios.post(
            process.env.VUE_APP_API_PATH +
            "/application/api/u/studio/cancel-reminder",
            payload
        );
        if (res.data.success) {
          this.event.reminder_registered = false;
          EventBus.$emit('updateDFDReminders');
        }
        this.changingReminderStatus = false;
      }
    },
    back() {
      this.$router.push(this.backRoute);
    },
    previous() {
      if(this.isFirstItem) return;
      const i = this.modules.findIndex(mod => mod.module_id === this.selectedModule);
      this.selectedModule = this.modules[i-1].module_id;
    },
    async completedModule() {
      if(!this.currentModule.attended) {
        const payload = {
          user_id: this.$store.getters.user_id,
          training_class_id: this.currentModule.id
        };
        await this.axios.post(
            process.env.VUE_APP_API_PATH +
            "/application/api/u/studio/training-class-attended",
            payload
        );
        await this.setModuleAsCompleted(this.currentModule.id);
      }
      if(!this.isLastItem) {
        this.moveToNextModule();
      }
    },
    async setModuleAsCompleted(id) {
      this.modules.map(mod => {
        if(mod.id === id) {
          mod.attended = 1;
        }
      })
      await this.api({
        path: "api/u/studio/log-event-as-activity",
        event_id: this.currentModule.event_id
      });
    },
    moveToNextModule() {
      let i = false;
      let currentModuleFound = false
      this.modules.map(mod => {
        if(mod.id === this.currentModule.id) currentModuleFound = true;
        if(currentModuleFound && !i && !mod.attended) {
          i = true;
          this.selectedModule = mod.id;
        }
      })
      if(!i) this.selectedModule = this.modules[0].id;
    },
    showShareModal() {
      const skin = this.$store.getters.skin;
      const content = this.$createElement(ShareModal, {
        props: {
          title: 'Share this course',
          instruction: 'Share this course with other by copying the link below',
          link: this.link
        }
      });
      let modalClass = "share-modal theme-"+skin;
      if(this.darkMode) modalClass += ' dark-mode-modal';
      let opts = {
        hideHeader: false,
        hideFooter: true,
        modalClass: modalClass,
        headerClass: "share-modal d-none",
        footerClass: "d-none",
        size: "lg",
        buttonSize: "sm",
        centered: true,
        contentClass: "share-modal theme-" + skin,
        id : 'share-modal',
      }
      return this.$bvModal.msgBoxOk(content, opts);
    },
    changeSelectedModule(id) {
      this.selectedModule = id
    },
    async getCourse() {
      const payload = {
        user_id: this.$store.getters.user_id,
      };
      let res = await this.axios.post(
          process.env.VUE_APP_API_PATH +
          "/application/api/u/studio/get-training-plans",
          payload
      );
      if (res.data.success) {
        let planId = this.$route.params.id;
        let course = res.data.training_plans.filter(item => Number.parseInt(item.id) === Number.parseInt(planId));
        this.course = course[0];
        this.modules = this.course.training_classes;
        let completed = 0;
        this.modules.map(item => {
          if (item.attended)  completed++
        });
        this.progress = completed;
        this.selectedModule = null;
        this.modules.forEach(mod => {
          if(!mod.attended && !this.selectedModule) {
            this.selectedModule = mod.id
          }
        });
        if(!this.selectedModule) this.selectedModule = this.modules[0].id
        this.loading = false;
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      }
    },
    async watchLater() {
      this.m('watch_later_toggle', {title: this.title , area: 'course_page', action: !this.isItemInMyList ? 'add' : 'remove'});
      if(!this.isItemInMyList) {
        await this.$store.dispatch('addMyListItem',({media_type: 'video', product_id: this.product_id, content_id: this.event.id}));
      } else {
        const myListRecord = this.$store.getters.myList.filter(item => item.product_id === this.product_id && item.content_id === this.event.id);
        await this.$store.dispatch('deleteMyListItem',({id: myListRecord[0].id}));
      }
    },
    async toggleDGFavourite() {
      const payload = {
        user_id: this.$store.getters.user_id,
        event_id: this.event.id,
      };
      let res = await this.axios.post(
          process.env.VUE_APP_API_PATH +
          "/application/api/u/studio/toggle-favourite-gym-event",
          payload
      );
      if (res.data.success) {
        this.event.is_favourited = res.data.is_favourite;
        this.m('favourite_toggle', {title: this.title, area: 'course_page', action: res.data.is_favourite ? 'add' : 'remove'});

      }
    },
    goToVideo(event) {
      switch (event.schedule_type) {
        case "studio":
          this.$router.push('/wellbeing-studio/' + event.id);
          this.getEvent();
          break;
        case "gym":
          this.$router.push('/digital-gym/' + event.id);
          this.getEvent();
          break;
      }
    },
    similarCourseDuration(start,end) {
      const startTime = moment(start, 'YYYY-MM-DD HH:mm:ss');
      const endTime = moment(end, 'YYYY-MM-DD HH:mm:ss');
      const duration = moment.duration(endTime.diff(startTime));
      return duration.asMinutes() + ' mins';
    },
    async getSimilarCourses() {
      let contentPath = "/application/api/u/studio/events";
      const payload = {
        user_id: this.$store.getters.user_id,
        tags: [this.event.schedule_type],
        page: 1,
        pageSize: 5,
      };
      let res = await this.axios.post(
          process.env.VUE_APP_API_PATH +
          contentPath,
          payload
      );
      if (res.data.success) {
        this.similarCourses = res.data.data;
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      }
    },
    async getEvent() {
      const payload = {
        user_id: this.$store.getters.user_id,
        event_id: this.$route.params.id,
      };
      let res = await this.axios.post(
          process.env.VUE_APP_API_PATH +
          "/application/api/u/studio/get-virtual-event",
          payload
      );
      if (res.data.success) {
        this.event = res.data.event;
        this.getSimilarCourses();
        if(!this.event.embed_code) {
          this.timeout = setTimeout(async ()=> {
            if(!this.event.embed_code) {
              //await this.getEvent();
            }
          }, 1000 * 60);
        }
        this.loading = false;
        //this.setRefreshTimer();
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        this.showMsgModal(
            "Woops, we couldn't find that event. Please try another!"
        ).then(() => {
          this.$route.path.includes("gym")
              ? this.$router.push("/digital-gym")
              : this.$router.push("/wellbeing-studio");
        });
      }
    },
    handleTimeUpdate(data) {
      const currentTime = data.seconds; // Current time in seconds
    },
  },
  computed: {
    componentText() {
      return this.getText.liveEvent;
    },
    eventStartText() {
      return moment(this.event.start_date_time).format("h:mm a on dddd, Do MMMM")
    },
    title() {
      if(this.isTrainingPlan) {
        return this.course.title;
      } else {
        return this.event.title
      }
    },
    matomoTitle() {
      if(this.isTrainingPlan) {
        return this.currentModule.title;
      } else {
        return this.event.title
      }
    },
    description() {
      if(this.isTrainingPlan) {
        return this.course.description;
      } else {
        return this.event.title
      }
    },
    isFirstItem() {
      const i = this.modules.findIndex(mod => mod.id === this.selectedModule);
      return i === 0
    },
    isLastItem() {
      const i = this.modules.findIndex(mod => mod.id === this.selectedModule);
      return i === this.modules.length - 1
    },
    link() {
      return window.location.href;
    },
    currentModule() {
      const m = this.modules.filter(mod => mod.id === this.selectedModule);
      return m[0];
    },
    isTrainingPlan() {
      return this.$route.name === "trainingPlan";
    },
    isItemInMyList() {
      const isInList = this.$store.getters.myList.filter(item =>
          Number.parseInt(item.product_id) === this.product_id
          && Number.parseInt(item.content_id) === Number.parseInt(this.event.id));
      return isInList.length > 0;
    },
    product_id() {
      switch (this.event.schedule_type) {
        case 'studio':
          return 21;
        case 'gym':
          return 22;
        default:
          return 0;
      }
    },
    formattedDate() {
      if(this.isTrainingPlan) return '';
      const inputDate = this.event.start_date_time;
      const date = moment(inputDate, 'YYYY-MM-DD HH:mm:ss');
      const day = date.format('Do');
      const month = date.format('MMMM');
      return `${day} ${month}`;
    },
    eventLengthMinutes() {
      if(this.isTrainingPlan) {
        if(this.currentModule) return this.currentModule.duration_minutes + ' mins video';
      }
      const startTime = moment(this.event.start_date_time, 'YYYY-MM-DD HH:mm:ss');
      const endTime = moment(this.event.end_date_time, 'YYYY-MM-DD HH:mm:ss');
      const duration = moment.duration(endTime.diff(startTime));
      return duration.asMinutes() + ' minutes video';
    },
  }
}
</script>

<style lang="less">
#LayaStudioEvent {
  width: 100vw;
  font-family: Open Sans, sans-serif;
  i {
    font-size: 20px;
  }
  .pageContainer {
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 1fr;
    .authorRow {
      grid-column: 1 / span 2;
      font-size: 1.5rem;
      grid-template-columns: fit-content(100%) 4fr 2fr;
      display: grid;
      .authorImg {
        img {
          width: 50px;
          border-radius: 50%;
        }
      }
    }
    .eventSection {
      padding: 20px;

      .infoRow {
        font-size: 1.4rem;
      }
      .videoContainer {
        iframe {
          border-radius: 15px;
        }
      }
      .descriptionRow {
        max-width: 650px;
        font-size: 16px;
        line-height: 1.85rem;
        text-align: justify;
        margin-top: 24px;
      }
      .infoRow {
        max-width: 650px;;
      }
      .authorBioBox {
        max-width: 650px;
        background: #FAFAFA;
        padding: 40px 40px;
        border-radius: 20px;
        .authorImg {
          img {
            width: 100px;
            border-radius: 50%;
          }
        }
        .authorBioText {
          .name {
            font-family: "Cocon Pro Regular", sans-serif;
            font-size: 2rem;
          }
          .bio {
            text-align: justify;
            font-size: 16px;
            line-height: 1.65rem;
          }
        }
      }
    }
    .similarCoursesSection {
      &.trainingPlan {
        .textHolder {
          padding: 10px 20px !important;
        }
      }
      .similarCoursesHolder {
        .similarCourseBox {
          height: 105px;
          aspect-ratio: 376 / 120;
          box-shadow: 0 4px 40px 0 #4F6C811F;
          border-radius: 10px;
          cursor: pointer;
          overflow: hidden;
          &:hover {
            transform: scale(1.05);
          }
          .imageHolder {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            filter: brightness(75%);
          }
          .textHolder {
            .title {
              display: -webkit-box;
              -webkit-line-clamp: 1;
              line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
            width: 75%;
            padding: 20px;
            .modComplete {
              color: #219ba0;
              i {
                font-size: 1.1rem;
                margin-right: 10px;
              }
              font-size: 1.1rem;
            }
            .topRow {
              width: 100%;
              justify-content: space-between;
              display: flex;
              span {
                font-size: 1.1rem;
              }
            }
          }
        }
      }
    }
  }
  .tpBtn {
    margin-top: 24px;
    justify-content: center;
  }
  .disclaimerContainer {
    max-width: 680px;
    text-align: left;
    #disclaimer {
      margin: 16px 0;
      color: #36383A;
    }
    ul {
      text-align: left;
    }
    p {
      font-size: 16px;
      line-height: unset;
      margin-bottom: 8px;
      text-align: left !important;
      color: #36383A;
    }
    .disclaimer-heading {
      margin-top: 16px;
      text-align: left !important;
      margin-bottom: 0;
      color: #36383A;
    }
    h4 {
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      color: #36383A;
      text-align: left;
      font-weight: 600;
    }
  }
  @media only screen and (max-width: 992px) {
    .pageContainer {
      .eventSection {
        h3 {
          margin-top: 24px !important;
          margin-left: 16px !important;
        }
        #VisualAudioDisplay, .infoRow {
          margin-left: auto;
          margin-right: auto;
        }
      }
      .authorRow {
        grid-column: 1;
      }
      grid-template-columns: 1fr;
      h4 {
        font-size: 2.25rem;
        margin: 15px 0 !important;
      }
      h6 {
        font-size: 1.8rem;
      }
      .textHolder {
        width: calc(100% - 100px) !important;
      }
      .similarCoursesSection {
        width: 100%;
        .similarCourseBox {
          width: 90%;
          margin: auto;
        }
      }
      .eventSection {
        width: 100%;
        padding: 5px 10px;
        h3 {
          font-size: 2.75rem;
        }
        .descriptionRow {
          width: 100%;
          max-width: 100%;
          padding: 0 15px 0 15px;
          &.bottomBorder {
            border-bottom: #e1e1e1 1px solid;
          }
        }
        .tpBtn {
          padding-bottom: 10px;
          width: 100% !important;
          max-width: 100% !important;
          border-bottom: #e1e1e1 1px solid;
        }
        #VisualAudioDisplay {
          max-width: 100%;
          width: 100% !important;
        }
      }
    }
  }
}
</style>