<template>
  <div id="app" :class="appClass">
    <router-view/>
  </div>
</template>
<script>
import axios from "axios";
import config from "./config/constants";
import { SocketService } from "@/socket/SocketService";
export default {
  name: "app",
  data() {
    return {
      showBanner: false,
      socketService: null,
    };
  },
  created() {
    var element = document.createElement("link");
    element.setAttribute("rel", "stylesheet");
    element.setAttribute("type", "text/css");
    element.setAttribute("href", "/css/laya.css");
    document.getElementsByTagName("head")[0].appendChild(element);

    let cookieCode = process.env.NODE_ENV === 'production' ? '0190495a-578a-7321-b0a9-f39023e2eed7':'8ef8a0e2-a935-44bc-a8bb-3a1edb453c8d-test';
    var scriptTag = document.createElement('script');
    scriptTag.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
    scriptTag.type = "text/javascript";
    scriptTag.charset = "UTF-8";
    scriptTag.setAttribute("data-domain-script", cookieCode);

    var wrapperFunction = document.createElement('script');
    wrapperFunction.type = "text/javascript";
    wrapperFunction.text = "function OptanonWrapper() { }";

    document.head.appendChild(scriptTag);
    document.head.appendChild(wrapperFunction);

    const tag_id = process.env.NODE_ENV === 'production' ? 'https://www.layahealthcare.ie/api/mtm/js/container_WBLRifoi.js' : 'https://preprod.layahealthcare.ie/api/mtm/js/container_DjyhB9p1.js';
    const matomoTag = document.createElement('script');
    matomoTag.type = "text/javascript";
    matomoTag.text = `var _mtm = window._mtm = window._mtm || [];\n` +
        `_mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});\n` +
        `var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];\n` +
        `g.async=true; g.src='${tag_id}'; s.parentNode.insertBefore(g,s);`
    const head = document.getElementsByTagName("head")[0];
    head.insertBefore(matomoTag, head.firstChild);

    this.setHotjar();
    //reset empty nav object in store to resolve caching issue
    if (!Array.isArray(this.$store.state.nav)) {
      this.$store.dispatch("clearNav");
    }
    //check if user is using IE
    if (window.document.documentMode) {
      this.$store.commit("setBrowserIE", window.document.documentMode);
    } else {
      this.$store.commit("setBrowserIE", false);
    }
    
    this.getBranding();    

    var APP_ID = process.env.VUE_APP_INTERCOM_APP_ID;
    (function () {
      var w = window;
      var ic = w.Intercom;
      if (typeof ic === "function") {
        ic("reattach_activator");
        ic("update", w.intercomSettings);
      } else {
        var d = document;
        var i = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        w.Intercom = i;
        var l = function () {
          var s = d.createElement("script");
          s.type = "text/javascript";
          s.async = true;
          s.src = "https://widget.intercom.io/widget/" + APP_ID;
          var x = d.getElementsByTagName("script")[0];
          x.parentNode.insertBefore(s, x);
        };
        if (document.readyState === "complete") {
          l();
        } else if (w.attachEvent) {
          w.attachEvent("onload", l);
        } else {
          w.addEventListener("load", l, false);
        }
      }
    })();
  },
  mounted() {
    if (process.env.VUE_APP_WEBSOCKET_ENABLE == 1) {
      this.socketService = new SocketService(this);
    }

    if (this.$store.getters.subdomain === 'uclemployeesupport' || this.$store.getters.subdomain === 'ucl247studentsupport' || this.$store.getters.subdomain === 'lancasteruni') {
      this.loadAccessibeScript();
    }
    this.shouldDisplayCookieBanner();
  },
  methods: {
    loadAccessibeScript() {
      let primary = '#3A4A61'
      if(this.$store.getters.subdomain === 'uclemployeesupport') primary = '#381c51';
      var s = document.createElement('script'); var h = document.querySelector('head') || document.body; s.src = 'https://acsbapp.com/apps/app/dist/js/app.js'; s.async = true; s.onload = function () { acsbJS.init({ statementLink: '', footerHtml: '', hideMobile: false, hideTrigger: false, disableBgProcess: false, language: 'en', position: 'left', leadColor: primary, triggerColor: '#e83b75', triggerRadius: '50%', triggerPositionX: 'left', triggerPositionY: 'bottom', triggerIcon: 'people', triggerSize: 'medium', triggerOffsetX: 20, triggerOffsetY: 20, mobile: { triggerSize: 'small', triggerPositionX: 'right', triggerPositionY: 'center', triggerOffsetX: 10, triggerOffsetY: 0, triggerRadius: '50%' } }); }; h.appendChild(s);
    },

    async getBranding() {
      const domain = window.location.host
        .split(".")
        .filter((x) => x !== "www")[0];
      if (domain.includes("laya")) {
        this.setMeta(domain, "/img/laya-favicon.png");
      }
      if (domain.includes("focusonyou")) {
        this.setMeta("Focus on You", "/img/favicon.ico");
      }
      if (domain.includes("bupa")) {
        this.setMeta("Digital GP services | Bupa UK", "/img/bupa-favicon.ico");
      }
      const payload = {
        portal_domain: domain,
      };
      this.$store.commit("setDomain", domain);
      let res = await axios.post(
        config.api_env + "/application/api/customisations/get-client-branding",
        payload,
        config.options
      );
      if (res.data.success) {
        this.$store.commit("setSkin", res.data.skin);
        this.$store.commit("setAccessCode", res.data.access_code);
        this.$store.commit("setClientBranding", res.data);
        this.$store.commit("setNavType",res.data.home_page);
        if (this.$route.query.lang) {
          this.$store.commit("setLanguage", this.$route.query.lang);
        } else if (!this.$store.state.user.authenticated) {
          this.$store.commit("setLanguage", res.data.lang);
        }
        if (res.data.skin === 13) {
          this.setMeta("SanusX", "/img/sanusx/favicon.png");
        }
      } else {
        if (this.$store.getters.hardcoded && this.$store.getters.hardcoded) {
          this.$store.commit("setSkin", this.$store.getters.hardcoded.skin);
        }
        this.$store.commit("setAccessCode", 0);
      }
    },
    setMeta(title, faviconURL) {
      document.title = title;
      const link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = faviconURL;
      document.getElementsByTagName("head")[0].appendChild(link);
    },
    setHotjar() {
      (function (h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: 2202008, hjsv: 6 };
        a = o.getElementsByTagName("head")[0];
        r = o.createElement("script");
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
    },
  },
  computed: {
    skin() {
      return this.$store.getters.skin;
    },
    darkModeAvailable() {
      const darkModeSkins = this.$store.getters.skinsWithDarkMode;
      return darkModeSkins.includes(this.skin);
    },
    darkMode() {
      return this.$store.getters.darkMode;
    },
    appClass() {
      if(this.isLayaWellbeing()) return 'theme-31';
      let className = 'theme-' + this.skin;
      if(this.darkMode && this.darkModeAvailable) className += ' theme_dark_mode';
      return className
    }
  },
  watch: {},
};
</script>
<style lang="less">
.toggleTooltip {
  .arrow{
    display: none !important;
  }
  .tooltip-inner {
    background-color: grey;
    border-radius: 15px;
    color: white;
    font-size: 1.2rem;
    opacity: 1;
    line-height: 1.2;
    h4 {
      font-size: 1.8rem;
    }
    p{
      text-align: center;
    }
  }
}
.mylist-tooltip{
  top: -7px !important;
  .arrow{
    display: none !important;
  }
  .tooltip-inner {
    background-color: grey;
    border-radius: 15px;
    padding: 5px 10px;
    color: white;
    font-size: 1.2rem;
    opacity: 1;
    line-height: 1.2;
    h4 {
      font-size: 1.8rem;
    }
    p{
      text-align: center;
    }
  }
}
@import (once) "./assets/themes.less";

html {
  font-size: 10px;

  @media only screen and (max-width: 576px) {
    font-size: 9px;
  }
}

@font-face {
  font-family: "FilsonProBlack";
  src: url("assets/fonts/FilsonProBlack.ttf");
  src: url("assets/fonts/FilsonProBlack.woff");
  src: url("assets/fonts/FilsonProBlack.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "FilsonProBold";
  src: url("assets/fonts/FilsonProBold.ttf");
  src: url("assets/fonts/FilsonProBold.woff");
  src: url("assets/fonts/FilsonProBold.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "FilsonProBook";
  src: url("assets/fonts/FilsonProBook.ttf");
  src: url("assets/fonts/FilsonProBook.woff");
  src: url("assets/fonts/FilsonProBook.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "FilsonProLight";
  src: url("assets/fonts/FilsonProLight.ttf");
  src: url("assets/fonts/FilsonProLight.woff");
  src: url("assets/fonts/FilsonProLight.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lyon';
  src: url("assets/fonts/LyonDisplay-Bold-Trial.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "ff-cocon-web-pro";
  src: url(assets/fonts/CoconPro-Regular.woff);
}

@font-face {
  font-family: "ff-sari-web-bold";
  src: url(assets/fonts/ufonts.com_sari-bold.woff);
}

@font-face {
  font-family: "ff-sari-web-medium";
  src: url(assets/fonts/ufonts.com_sari-medium.woff);
}

@font-face {
  font-family: "ff-sari-web-regular";
  src: url(assets/fonts/ufonts.com_sari-regular.woff);
}

#app:not(.theme-31) {

  height: 100vh;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  //position: relative; // why it doesnt work like usual ?
  overflow-x: hidden;
  background: #fff;

  .page-title h2 {
    text-transform: capitalize;
  }

  a {
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  h2 {
    font-family: FilsonProLight;
    font-size: 40px;
    color: #3a4961;
    text-align: center;
    margin-bottom: 20px;
  }

  p {
    color: #394961;
  }

  .btn {
    border-radius: 60px;
    font-size: 1.2rem;
    line-height: 1;
    letter-spacing: 0.2rem;
    border-width: 0;
    padding: 10px 15px;
    &.border-white {
      border-width: 1px;
      border-color: #fff;

      &.active {
        border-width: 0;
      }
    }

    &:focus {
      box-shadow: none;
    }
  }

  .clickable {
    cursor: pointer;
  }

  //TODO move to skins
  .btn-purple {
    background-color: #e83b75;
    color: #fff;
  }

  input[type="email"]::-ms-clear,
  input[type="text"]::-ms-clear {
    display: none;
  }

  input {
    // set as important as Filson Pro looks broken in input fields
    //TODO:: Discuss font options with Niamh
    font-family: "Open Sans", sans-serif !important;
  }

  .col-xs-5ths,
  .col-sm-5ths,
  .col-md-5ths,
  .col-lg-5ths {
    position: relative;
    min-height: 1px;
  }

  .col-xs-5ths {
    width: 20%;
    float: left;
  }

  @media (min-width: 768px) {
    .col-sm-5ths {
      width: 20%;
      float: left;
    }
  }

  @media (min-width: 992px) {
    .col-md-5ths {
      width: 20%;
      float: left;
    }
  }

  @media (min-width: 1200px) {
    .col-lg-5ths {
      width: 20%;
      float: left;
    }
  }
}

.questionnaire-modal {
  .modal-content {
    border-radius: 24px;
    overflow: hidden;

    .modal-footer {
      display: none !important;
    }
  }

  .modal-body {
    padding: 0;
  }
}

//TODO move it to somewhere where it makes sense
.modal-content {

  //TODO move to skins
  .btn {
    border-radius: 60px;
    font-size: 1.3rem;
    //font-size: 14px;
    line-height: 1;
    font-family: "FilsonProBold", arial;
    /*letter-spacing: 0.2rem;*/
    //border-width: 2;
    padding: 10px 15px;
    &.border-white {
      border-width: 1px;
      border-color: #fff;

      &.active {
        border-width: 0;
      }
    }

    &:focus {
      box-shadow: none;
    }
  }

  .btn-purple {
    background-color: #e83b75;
    color: #fff;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);

    &:hover {
      //TODO something
      color: #fff;
    }

    &:active {
      color: #fff;

      //TODO something
    }
  }

  .btn-outline-purple {
    background-color: white;
    color: #e83b75;
    border: 2px solid #e83b75;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: whitesmoke;
      color: #e83b75;
    }

    &:active {
      color: #e83b75;

      //TODO something
    }
  }

  .btn-outline-grey {
    background-color: white;
    color: #808080;
    border: 1px solid #808080;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: whitesmoke;

      //TODO something
    }

    &:active {
      //TODO something
    }
  }

  &.coach_modal {
    overflow: hidden;
    border: none;

    //box-shadow: 0px 0px 100px rgba(0,0,0,0.5) inset;
    .coach_modal_header {
      color: white;
      padding: 10px 30px;

      h5 {
        font-size: 17px;
      }
    }

    .modal-body {
      padding: 0px 15px;
    }

    .modal-footer {
      display: none;
    }
  }
}

#clinician_modal {
  .modal-dialog {
    transition: all 0.2s ease-in-out !important;
  }
}

.filson-light {
  font-family: 'FilsonProLight', sans-serif !important;
  letter-spacing: normal !important;
}

.filson-book {
  font-family: 'FilsonProBook', sans-serif !important;
  letter-spacing: normal !important;
}

.filson-bold {
  font-family: 'FilsonProBold', sans-serif !important;
  letter-spacing: normal !important;
}
#my-org-edit-page-modal {
  .modal-title {
    font-size: 1.9rem;
  }
  font-size: 1.8rem;
  input, select, textarea {
    font-size: 1.4rem;
  }
}

</style>
