<template>
    <div class="audio-player" id="#audio-player"> 
    <audio ref="audio" class="audio" style="display:block;" @timeupdate="onTimeupdate"> 
                <source :src="src"> 
        </audio> 
     <div class="audio-player__container"> 
        <button class="btn btn--clear" type="button" v-on:click="onPlayButtonClick"> 
                     <img class="audio-player__play-img" :src="'/img/play-' +  user_skin_id + '.svg'" @error="genericImage($event, '/img/play')" alt="play icon" v-show="!isPlay">
                     <img class="audio-player__play-img" :src="'/img/pause-' + user_skin_id + '.svg'" @error="genericImage($event, '/img/pause')" alt="play icon" v-show="isPlay">
            </button> 
        <div class="audio-progress__content"> 
                  <div class="audio__img" ref="audioProgressBar"  @mousedown="onProgressBarClick"> 
                            <img src="/img/audio.svg" alt="play icon"> 
                               <div class="audio__progress-content" ref="audioProgress"> 
                                        <img class="img-active" src="/img/audio-active.svg" alt="play icon"> 
                                    </div> 
                            </div> 
                    </div> 
        </div> 
    </div>
</template>

<script>
    // @ is an alias to /src
    export default {
        name: "AudioPlayer",
        props: ['src'],

        data() {
            return {
                progressInterval: null,
                isPlay: false,
                user_skin_id: this.$store.getters.skin
            };
        },
        watch:{
          src(){
              this.$refs.audio.load()
              this.isPlay = false;
          }
        },
        methods: {

            onProgressBarClick(event) {
                this.serCurrentAudioPosition(event);

                document.addEventListener("mousemove", this.setCurrentProgressOnMouseMove, true);
                document.addEventListener("mouseup", () => {
                    document.removeEventListener('mousemove', this.setCurrentProgressOnMouseMove, true);
                });
            },

            onTimeupdate(event) {
                this.setCurrentProgressWidth();
            },

            onPlayButtonClick() {
                const audio = this.$refs.audio;

                if (audio.paused) {
                    audio.play();
                    this.isPlay = true;
                } else {
                    audio.pause();
                    this.isPlay = false;
                }
            },

            setCurrentProgressWidth() {
                const audio = this.$refs.audio;

                this.$refs.audioProgress.style.width = audio.currentTime / audio.duration * 100 + '%';
            },

            serCurrentAudioPosition(event) {
                const progressBarWidth = this.$refs.audioProgressBar.getBoundingClientRect().width;
                const audio = this.$refs.audio;
                const { offsetX } = event;

                audio.currentTime = audio.duration * (offsetX / progressBarWidth);
                this.setCurrentProgressWidth();
            },

            setCurrentProgressOnMouseMove(eventMuseMove) {
                const clientRect = this.$refs.audioProgressBar.getBoundingClientRect();
                const audioProgressBarWidth = clientRect.width;
                const audioProgressBarLeft = clientRect.left;
                const currentAudioProgressBarPosition = eventMuseMove.clientX - audioProgressBarLeft;

                if (((currentAudioProgressBarPosition) >= 0) && ((currentAudioProgressBarPosition) <= audioProgressBarWidth)) {
                    const audio = this.$refs.audio;

                    audio.currentTime = audio.duration * (currentAudioProgressBarPosition / audioProgressBarWidth);
                    this.setCurrentProgressWidth();
                }
            },
            genericImage(event, image){
                event.target.src = image + '-generic.svg'
            }
        }
    };
</script>
<style lang="less">
    .audio-player audio {
        display: block;
    }

    .audio-player{
        width:60%;
        margin:auto;
    }

    .audio-player__container {
        display: flex;
    }

    .audio-progress__content {
        position: relative;
        margin-left: 15px;
        cursor: pointer;
        width: 100%;
    }

    .audio-player__play-img {
        max-width: 43px;
        width: 100%;
    }

    .audio__progress-content {
        position: absolute;
        overflow: hidden;
        width: 0;
        height: 100%;
        left: 0;
        top: 0;
        transition-duration: 0.1s;
    }

    .audio__img img {
        height: 100%;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-user-drag: none;
        user-drag: none;
        -webkit-touch-callout: none;
        max-width: 100%;
    }

    .img-active {
        color: red;
    }

    @media all and (max-width: 375px) {
        .audio-progress__content {
            margin-left: 0;
        }

        .audio-player__container .btn {
            padding-left: 0;
        }
    }
</style>
