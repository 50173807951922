export default {
  boi: {
    org_code: "4wSZAoYA",
    logo: "/img/boi/boi-wellbeing.png",
    client_logo: "/img/boi/boi-logo-alt.png",
    promotions: [
      {
        title: "Bank of Ireland Wellbeing App",
        sub_title:
          "Click on the links below to download the Bank of Ireland Colleague App to your smartphone.",
        link_ios: "https://apps.apple.com/ie/app/spectrum-life/id1454812579",
        link_android:
          "https://play.google.com/store/apps/details?id=com.sw.aluna",
        image_url:
          "https://healthcoach-media.com/res/surge/promotion/Download%20the%20app.svg",
        link_text: null,
        link_url: null,
        bannerText: "Bank of Ireland Wellbeing App",
        bannerSubText:
          "Bank of Ireland Wellbeing content is best accessed using mobile app or on web using non-Bank of Ireland computers (on Microsoft Edge, Chrome or Firefox browsers). A scaled-back version of Bank of Ireland Wellbeing content is available on Bank of Ireland computers due to bandwidth restrictions."
      }
    ],
    downloadBanner: {
      bannerText: "Bank of Ireland Wellbeing App",
      bannerSubText:
        "This site is best accessed using mobile app or on web using non-BOI computers (Microsoft Edge, Chrome or Firefox browsers). A scaled-back version of this site is available on BOI computers due to bandwidth restrictions.",
      link_ios: { name: "download" },
      link_android:
        "https://play.google.com/store/apps/details?id=com.sw.aluna.bankofireland"
    },
    my_org: {
      title: "What's Happening"
    }
  },
  talknow: {
    org_code: "talknow",
    my_org: {
      title: "Bath Services"
    },
    permittedRoutes:[
      "NotLoggedIn",
      "verifyEmail",
      "resetPassword",
      "eap",
      "article",
      "myOrg",
      "faq",
      "settings",
      "not-found",
      "home"
    ]
  },
  vodafone: {
    org_code: "Vodafone",
    logo: "/img/vodafone/evolve-logo.png",
    footer_logo: "/img/logo-vodafone.svg"
  },
  daa: {
    logo: "/img/daa/focusonyou_logo.svg",
    client_logo: "/img/daa/focusonyou_logo.svg",
    illustration_events: "/img/daa/events.svg",
    illustration_insights: "/img/daa/watermelon.svg",
    illustration_myorg: "/img/daa/my_organisation.svg",
    login_logos: [
      "/img/daa/daa_logo.png"
    ],
    spectrum_heart: "/img/default-asset-generic.svg",
    heart_line: "/img/heart-line_white.svg",
    heart: "/img/heart_white.svg",
    rewardsChartColor: "#4d4d4f"
  },
  focusonyou: {
    skin: 9,
    logo: "/img/daa/focusonyou_logo.svg",
    client_logo: "/img/daa/focusonyou_logo.svg",
    illustration_events: "/img/daa/events.svg",
    illustration_insights: "/img/daa/watermelon.svg",
    illustration_myorg: "/img/daa/my_organisation.svg",
    login_logos: [
      "/img/daa/daa_logo.png",
    ],
    spectrum_heart: "/img/default-asset-generic.svg",
    heart_line: "/img/heart-line_white.svg",
    heart: "/img/heart_white.svg",
    rewardsChartColor: "#4d4d4f"
  },
  eap:{
    client:{
      client_logo: "/img/logo-spectrum-life.svg",
      client_name: "EAP Spectrum.life",
      currency: "eur",
      eap_active: true,
      logo:
          "/img/logo-spectrum-life.svg",
      skin: 0,
      success: true
    },
    permittedRoutes:[
      "NotLoggedIn",
      "eap",
      "article",
      "allArticles",
      "allCourses",
      "myOrg",
      "wellbeing",
      "course",
      "not-found"
    ]
  },
  layaeap:{
    user:{
      authenticated: true,
      success: true,
      up_domain: "up",
      insights_completed: true,
      user_client_id: Number,
      user_id: 'LayaEAP',
      user_role: "cl",
      user_token: 'LayaEAP',
      username: 'LayaEAP',
      v3_ready: true
    },
    client:{
      client_logo: "/img/laya/laya-logo-main.svg",
      client_name: "EAP Spectrum.life",
      currency: "eur",
      eap_active: true,
      logo:
          "/img/logo-spectrum-life.svg",
      skin: 10,
      success: true
    },
    permittedRoutes:[
      "eap",
      "article",
      "allArticles",
      "allCourses",
      "myOrg",
      "wellbeing",
      "course",
      "not-found"
    ]
  },
  ibwellbeing:{
    permittedRoutes:[
      "NotLoggedIn",
      "eap",
      "article",
      "allArticles",
      "allCourses",
      "myOrg",
      "wellbeing",
      "course",
      "not-found",
    ]
  },
  'laya-wellbeing':{
    skin: 10,
    // permittedRoutes:[
    //   "home",
    //   "NotLoggedIn",
    //   "digitalClinics",
    //   "loginAs",
    //   "article",
    //   "allArticles",
    //   "allCourses",
    //   "myOrg",
    //   "eap",
    //   "wellbeing",
    //   "layaCourse",
    //   "wellbeingStudio",
    //   "wellbeingSeries",
    //   "virtualEvent",
    //   "virtualEventTest",
    //   "digitalGym",
    //   "digitalGymArticle",
    //   "trainingPlan",
    //   "course",
    //   "privacy",
    //   "TCs",
    //   "not-found",
    //   "studioOverview",
    //   "mindfulness"
    // ],
    logo: '/img/laya/laya-logo-main.svg',
    client_logo: '/img/laya/laya-logo-main.svg',
  },
  ihf:{
    permittedRoutes:[
      "NotLoggedIn",
      "digitalClinics",
      "loginAs",
      "article",
      "allArticles",
      "allCourses",
      "myOrg",
      "eap",
      "wellbeing",
      "layaCourse",
      "wellbeingStudio",
      "wellbeingSeries",
      "virtualEvent",
      "virtualEventTest",
      "digitalGym",
      "digitalGymArticle",
      "trainingPlan",
      "course",
      "privacy",
      "TCs",
      "not-found",
    ],
  },
  runinthedark:{
    spectrum_heart: "/img/default-asset-generic.svg",
    footer_logo: "/img/run-in-the-dark/ritd-logo.png",
    logo: "/img/run-in-the-dark/ritd-login-logo-right.png",
    client:{
      client_logo: "/img/logo-spectrum-life.svg",
      client_name: "EAP Spectrum.life",
      currency: "eur",
      eap_active: true,
      logo: "/img/logo-spectrum-life.svg",
      skin: 12,
      success: true
    },
  },
  microsoft:{
    logo:"/img/microsoft/logo-joined.png"
  }
};
