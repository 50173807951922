<template>
    <div id="CustomAudioPlayer" class="mt-4">
        <audio ref="audioPlayer" class="player-object" @timeupdate="updateProgress" @ended="stop" @canplay="play">
            <source  :src="audioSrc"/>
        </audio>
        <div class="audio-player">
            <div class="audio-top mb-2">
                <div class="audio-title">Audio Content</div>
                <div class="image-holder">
                    <img src="/img/audio-pulse.png" alt="audio wave"/>
                </div>
            </div>
            <div class="audio-track py-1 px-5">
                <div class="custom-progress" ref="progressBar" @mousedown="startDrag" @mousemove="drag" @mouseup="stopDrag">
                    <div class="custom-progress-bar">
                        <div class="custom-progressed" :style="{ width: progressBarWidth }"></div>
                        <div class="custom-progress-ball" ref="progressBall" :style="{ left: progressBarWidth }"></div>
                        <div class="custom-progress-time-elapsed">{{ formatTime(timeElapsed) }}</div>
                        <div class="custom-progress-time-remaining">{{ '-' + formatTime(timeRemaining) }}</div>
                    </div>
                </div>
            </div>
            <div class="audio-buttons mt-5">
                <i class="fa-solid fa-backward-fast" @click="rewind" :class="disablePrevious ? 'disabled-custom-btn':null"></i>
                <i class="fa-solid fa-circle-play" @click="play" v-if="!isPlaying"></i>
                <i class="fa-solid fa-pause" @click="pause" v-else></i>
                <i class="fa-solid fa-forward-fast" @click="fastForward" :class="disableNext ? 'disabled-custom-btn':null"></i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        audioSrc: {
            type: String,
            required: true
        },
        disableNext: {
            type: Boolean,
            default: false
        },
        disablePrevious: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            duration: 0,
            currentTime: 0,
            isDragging: false,
            progressBarWidth: "0%",
            isPlaying: false
        };
    },
    watch: {
      audioSrc() {
        this.$refs.audioPlayer.load();
      },
    },
    computed: {
        timeElapsed() {
            return Math.floor(this.currentTime);
        },
        timeRemaining() {
            return Math.floor(this.duration - this.currentTime);
        }
    },
    mounted() {
        this.$refs.audioPlayer.oncanplay(()=> {
          this.play();
        })
    },
    methods: {
        rewind() {
            this.$refs.audioPlayer.currentTime = Math.max(0, this.$refs.audioPlayer.currentTime - 10);
        },
        fastForward() {
            this.$refs.audioPlayer.currentTime = Math.min(this.$refs.audioPlayer.duration, this.$refs.audioPlayer.currentTime + 10);
        },
        ended() {
            this.$emit('ended')
        },
        next() {
            if(this.disableNext) return;
            this.$emit('next');
        },
        previous() {
            if(this.disablePrevious) return;
            this.$emit('previous');
        },
        updateProgress() {
            this.currentTime = this.$refs.audioPlayer.currentTime;
            this.duration = this.$refs.audioPlayer.duration;
            this.progressBarWidth = (this.currentTime / this.duration) * 100 + "%";
        },
        formatTime(time) {
            let minutes = Math.floor(time / 60);
            let seconds = Math.floor(time - minutes * 60);
            if(seconds < 10) seconds = String('0' + seconds);
            return `${minutes}:${seconds}`;
        },
        play() {
            this.$refs.audioPlayer.play();
            if(!this.$refs.audioPlayer.paused) {
              this.isPlaying = true;
            }
        },
        pause() {
            this.isPlaying = false;
            this.$refs.audioPlayer.pause();
        },
        startDrag(event) {
            this.isDragging = true;
            this.drag(event);
        },
        drag(event) {
            if (this.isDragging) {
                const progressBarRect = this.$refs.progressBar.getBoundingClientRect();
                const x = event.clientX - progressBarRect.left;
                const progress = x / progressBarRect.width;
                this.$refs.audioPlayer.currentTime = this.duration * progress;
            }
        },
        stopDrag() {
            this.isDragging = false;
        },
        stop() {
            this.isPlaying = false;
            this.$emit('next');
        }
    }
};
</script>

<style lang="less">
    #CustomAudioPlayer {

        font-family: Open Sans, sans-serif;
        color: white;
        position: relative;
        .disabled-custom-btn {
            color: grey !important;
            cursor: default !important;
        }
        .player-object {
            position: absolute;
            width: 1px;
            height: 1px;
            bottom: 0;
            right: 0;
            visibility: hidden;
        }
        .audio-player {
            border: 1px solid #FFFFFF80;
            background: linear-gradient(180deg, #242424 0%, rgba(36, 36, 36, 0) 100%);
            padding: 20px;
            border-radius: 20px;
            width: 400px;
            max-width: 100%;
            .audio-top {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                padding: 0 25px;
                .audio-title {
                    font-family: FilsonProBold, Arial, Helvetica, sans-serif;
                    font-size: 2.5rem;
                }
            }
            .audio-buttons{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px 35px;
                i {
                    font-size: 5rem;
                    cursor: pointer;
                }
            }
            .custom-progress {
                height: 5px;
                background-color: #eee;
                margin-top: 10px;
                position: relative;
                cursor: pointer;
            }
            .custom-progressed {
                height: 100%;
                background-color: white;
                position: relative;
            }
            .custom-progress-bar {
                height: 100%;
                background-color: grey;
                position: relative;
            }

            .custom-progress-ball {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: white;
                position: absolute;
                top: -3px;
                left: 0;
                cursor: pointer;
                transform: translateX(-50%);
            }

            .custom-progress-time-elapsed {
                font-size: 12px;
                color: #999;
                position: absolute;
                top: 20px;
                left: 0;
            }

            .custom-progress-time-remaining {
                font-size: 12px;
                color: #999;
                position: absolute;
                top: 20px;
                right: 0;
            }

        }
    }
</style>
