<template>
  <div id="TopNav" class="">
    <div class="content-container" v-if="$store.getters.skin !== 20">
      <b-navbar class="py-0 ml-0 desktop row">
        <b-navbar-brand
          @click="navigateHome($store.getters.homeRoute)"
          class="col-3 d-flex flex-row justify-content-start align-items-center position-relative"
        >
        <div class="line-hider"></div>
          <img src="/img/boi/boi-logo-alt.png" v-if="skin === 8" />
          <img
            :src="getLogo"
            onerror="javascript:this.src='/img/logo-spectrum-life.svg'"
            v-else
          />
        </b-navbar-brand>
        <b-navbar-nav
          v-if="$store.getters.isIE && skin === 8"
          class="ml-auto brand-secondary col-9"
          align="center"
        >
          <b-nav-item
            class="mx-5 brand-primary"
            :class="{ active: currentPage === 'home' }"
            ><b-link id="nav-home" to="/">home</b-link></b-nav-item
          >
          <b-nav-item
            class="mx-5 brand-primary"
            :class="{ active: currentPage === 'eap' }"
            ><b-link id="nav-eap" to="/eap">EAP</b-link></b-nav-item
          >
          <b-nav-item
            class="mx-5 brand-primary"
            :class="{ active: currentPage === 'events' }"
            ><b-link id="nav-events" to="/marketplace/events"
              >events</b-link
            ></b-nav-item
          >
          <b-nav-item
            class="mx-2 brand-primary"
            :class="{ active: currentPage === 'whats-happening' }"
            ><b-link id="nav-whats-happening" to="/personal/my-company"
              >what's happening?</b-link
            ></b-nav-item
          >
        </b-navbar-nav>
        <b-navbar-nav
          class="ml-auto brand-secondary col-9 pl-5"
          align="right"
          v-else
        >
          <div v-for="(navItem, index) in nav" :key="index">
            <b-nav-item
              v-if="showNavItem(navItem, index)"
              :id="
                'id-' + navItem.items[0].name.toLowerCase().split(' ').join('-')
              "
              class="mx-4 brand-primary"
              :class="{ active: currentPage === navItem }"
              @click="navigate(navItem.items[0])"
            >
              <div
                v-if="skin === 8 && navItem.items[0].name === 'my organisation'"
                :id="
                  'id-' +
                  navItem.items[0].name.toLowerCase().split(' ').join('-')
                "
              >
                what's happening
              </div>
              <div
                :id="
                  'id-' +
                  navItem.items[0].name.toLowerCase().split(' ').join('-')
                "
                v-else
              >
                {{
                  navItem.items[0].alias
                    ? navItem.items[0].alias
                    : navItem.items[0].name
                }}
              </div>
            </b-nav-item>
            <b-nav-item-dropdown
              v-else-if="navItem.items.length > 1"
              :text="
                navItem.items[0].alias
                  ? navItem.items[0].alias
                  : navItem.items[0].name
              "
              toggle-class="nav-link-custom"
              class="mx-4"
              id="id-plans"
              :class="[
                'length-' + navItem.items.length,
                {
                  virtual:
                    navItem.items[0].name === 'virtual' ||
                    navItem.items[0].name.toLowerCase() === 'wellbeing studio',
                },
              ]"
            >
              <b-row class="dropdown-card">
                <b-dropdown-item
                  v-for="(dropdownItem, i) in navItem.items"
                  :key="i"
                  @click="navigate(dropdownItem)"
                  class="dropdown-wrap col"
                  :class="{ 'col-4': navItem.items.length >= 6 }"
                  :id="'id-' + navItem.items[i].name"
                  v-if="i !== 0 && navItem.items[i].name.toLowerCase() !== 'community'"
                >
                  <div class="image-wrap">
                    <img
                      :src="
                        '/img/navigation/navigation-' +
                        dropdownItem.name.toLowerCase().split(' ').join('-') +
                        '.svg'
                      "
                      v-if="skin === 0 || skin === 8"
                    />
                    <img
                      :src="
                        '/img/navigation/navigation-' +
                        dropdownItem.name.toLowerCase().split(' ').join('-') +
                        '-generic.svg'
                      "
                      v-else
                    />
                  </div>
                  <div class="dropdown-label">
                    {{
                      dropdownItem.alias
                        ? dropdownItem.alias
                        : dropdownItem.name
                    }}
                    <img src="/img/navigation/arrow.svg" />
                  </div>
                </b-dropdown-item>
              </b-row>
            </b-nav-item-dropdown>
          </div>
        </b-navbar-nav>
      </b-navbar>
    </div>
    <div v-else>
      <b-navbar class="py-0 ml-0 desktop row">
        <b-navbar-brand
          @click="navigateHome($store.getters.homeRoute)"
          class="col-5 d-flex flex-row justify-content-start align-items-center position-relative"
        >
        <div class="line-hider"></div>
          <img
           class="py-3"
            :src="getLogo"
            onerror="javascript:this.src='/img/logo-spectrum-life.svg'"
          />
        </b-navbar-brand>
        <b-navbar-nav
          class="ml-auto brand-secondary col-7 pl-5"
          align="center"
        >
          <div class="row" v-for="(navItem, index) in nav" :key="index">
            <b-nav-item
              v-if="showNavItem(navItem, index)"
              :id="
                'id-' + navItem.items[0].name.toLowerCase().split(' ').join('-')
              "
              class="mx-4 brand-primary"
              :class="{ active: currentPage === navItem }"
              @click="navigate(navItem.items[0])"
            >
              <div
                v-if="skin === 8 && navItem.items[0].name === 'my organisation'"
                :id="
                  'id-' +
                  navItem.items[0].name.toLowerCase().split(' ').join('-')
                "
              >
                what's happening
              </div>
              <div
                :id="
                  'id-' +
                  navItem.items[0].name.toLowerCase().split(' ').join('-')
                "
                v-else
              >
                {{
                  navItem.items[0].alias
                    ? navItem.items[0].alias
                    : navItem.items[0].name
                }}
              </div>
            </b-nav-item>
            <b-nav-item-dropdown
              v-else-if="navItem.items.length > 1"
              :text="
                navItem.items[0].alias
                  ? navItem.items[0].alias
                  : navItem.items[0].name
              "
              toggle-class="nav-link-custom"
              class="mx-4"
              id="id-plans"
              :class="[
                'length-' + navItem.items.length,
                {
                  virtual:
                    navItem.items[0].name === 'virtual' ||
                    navItem.items[0].name.toLowerCase() === 'wellbeing studio',
                },
              ]"
            >
              <b-row class="dropdown-card">
                <b-dropdown-item
                  v-for="(dropdownItem, i) in navItem.items"
                  :key="i"
                  @click="navigate(dropdownItem)"
                  class="dropdown-wrap col"
                  :class="{ 'col-4': navItem.items.length >= 6 }"
                  :id="'id-' + navItem.items[i].name"
                  v-if="i !== 0 && navItem.items[i].name.toLowerCase() !== 'community'"
                >
                  <div class="image-wrap">
                    <img
                      :src="
                        '/img/navigation/navigation-' +
                        dropdownItem.name.toLowerCase().split(' ').join('-') +
                        '.svg'
                      "
                      v-if="skin === 0 || skin === 8"
                    />
                    <img
                      :src="
                        '/img/navigation/navigation-' +
                        dropdownItem.name.toLowerCase().split(' ').join('-') +
                        '-generic.svg'
                      "
                      v-else
                    />
                  </div>
                  <div class="dropdown-label">
                    {{
                      dropdownItem.alias
                        ? dropdownItem.alias
                        : dropdownItem.name
                    }}
                    <img src="/img/navigation/arrow.svg" />
                  </div>
                </b-dropdown-item>
              </b-row>
            </b-nav-item-dropdown>
          </div>
        </b-navbar-nav>
      </b-navbar>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "TopNav",
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    navigate(route) {
      //open wellbeing live link in new tab
      if (route.link.toLowerCase().includes("https")) {
        window.open(route.link, "_blank");
      } else if (this.$route.path !== route.link) {
        this.$router.push({ path: route.link });
        this.$store.commit("setPageTitle", route.alias);
      }
    },
    navigateHome(url) {
      this.$router.push(url);
    },
    showNavItem(navItem, index) {
      //dont show the item as a link if the array has more than one
      //show the 'home' link if it is not the first in the array
      if (navItem.items.length === 1) {
        if (navItem.items[0].name.toLowerCase() === "home" && index > 0) {
          return true;
        } else if (navItem.items[0].name.toLowerCase() === "home") {
          return false;
        } else return true;
      } else return false;
    },
  },
  computed: {
    ...mapGetters(["hardcoded", "skin", "client"]),
    nav() {
      return this.$store.state.nav;
    },
    currentPage() {
      return this.$route.name;
    },
    topLevel() {
      return Object.keys(this.nav);
    },
    getLogo() {
      if (this.hardcoded && this.hardcoded.client_logo) {
        return this.hardcoded.client_logo;
      } else return this.client.client_logo;
    },
  },
};
</script>
<style lang="less">
#TopNav {
  .line-hider{
    position: absolute;
    bottom: -1px;
    width: 80% !important;
    height: 1px !important;
    z-index: 100;
    background-color: white;
  }
  .line-hider{
    position: absolute;
    bottom: -1px;
    right: 1px;
    width: 80%;
    height: 1px;
    z-index: 100;
    background-color: white;
  }
  .navbar {
    .nav-item {
      position: relative;
      a {
        font-size: 1.6rem;
        font-family: "FilsonProBook", sans-serif;
      }
      &.active {
        a {
        }
        &:before {
          content: "";
          width: calc(100% + 20px);
          height: 18px;
          display: block;
          background-color: #fff;
          position: absolute;
          top: -30px;
          transform: translateX(-10px);
        }
      }
    }
    .navbar-brand {
      width: 180px;
      background-color: #fff;
      text-align: left;
      cursor: pointer;
      z-index: 5;
      height: 80px;
      padding: 5px 0;
      img {
        object-fit: contain;
        height: auto;
        width: auto;
        max-width: 100%;
        max-height: 100%;
        z-index: 5;
      }
      &::after {
        z-index: -1;
      }
    }
    //length-11 should get more height, because we're hiding community we have to add this rule here though
    .length-11 .dropdown-menu,
    .length-10 .dropdown-menu,
    .length-9 .dropdown-menu,
    .length-8 .dropdown-menu {
      width: 400px;
      height: 375px;
      .dropdown-menu .dropdown-item .dropdown-label {
        margin-bottom: 19px;
      }
    }
    .length-7 .dropdown-menu {
      width: 400px;
      height: 255px;
      .dropdown-menu .dropdown-item .dropdown-label {
        margin-bottom: 19px;
      }
    }
    .length-6 {
      .dropdown-card {
        justify-content: center;
      }
      .dropdown-menu {
        width: 400px;
        height: 255px;
        justify-content: center;
      }
    }
    .length-5 .dropdown-menu {
      width: 560px;
    }
    .length-4 .dropdown-menu {
      width: 440px;
    }
    .length-3 .dropdown-menu {
      width: 310px;
    }
    .length-3 .dropdown-menu {
      width: 180px;
    }
    .virtual .dropdown-menu {
      height: unset;
      transform: translate(-70%, 10px);
      padding: 5px 15px;
      width: 195px;
      &:after {
        top: -8px;
        border-width: 0 5px 8px 5px;
        right: 50px;
        left: unset;
      }
      .dropdown-card {
        margin: 0;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          flex-direction: column;
          height: 100%;
        }
      }
      .dropdown-wrap {
        padding: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        &:last-child {
          border-bottom: none;
        }
      }
      .dropdown-item {
        padding: 0;
        &:hover {
          display: block;
          .dropdown-label {
            width: 100%;
          }
        }
        .dropdown-label {
          font-size: 1.6rem;
          text-align: right;
          text-transform: capitalize;
          margin: 15px 0;
        }
        .image-wrap {
          display: none;
        }
      }
    }
    //IE styles
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .dropdown-card {
        width: 100%;
      }
    }
    .dropdown-menu {
      height: 140px;
      margin-left: 1px;
      padding: 20px 40px;
      background: #ffffff;
      border-radius: 12px;
      box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.3);
      transform: translate(-50%, 10px);
      position: absolute;
      left: 50%;
      align-items: flex-start;
      justify-content: center;
      &.show {
        display: flex;
        flex-direction: row;
      }
      &:after {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        top: -18px;
        left: 50%;
        transform: translateX(-50%);
        border-left: 16px solid transparent;
        border-right: 16px solid transparent;
        border-bottom: 18px solid #ffffff;
      }
      .dropdown-item {
        text-align: center;
        .image-wrap {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 65px;
          height: 65px;
          margin: auto auto 15px auto;
          border-radius: 50%;
          background: #ffffff;
          box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.3);
          img {
            height: 30px;
          }
        }
        .dropdown-label {
          color: #3a4961;
          font-size: 1.4rem;
          line-height: 1;
          margin-bottom: 19px;
          text-align: center;
          img {
            display: none;
          }
        }
        &:hover {
          background-color: transparent;
          .dropdown-label {
            font-family: FilsonProBold;
            background-color: transparent;
            position: relative;
            width: 65px;
            img {
              display: inline;
              width: 12px;
            }
          }
        }
        &:active {
          background-color: transparent !important;
        }
      }
    }
  }
}
</style>
