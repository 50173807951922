<template>
  <div id="LayaBeCalmCourse">
    <div class="content-container">
      <div class="pageContainer mt-5">
        <div class="authorRow" v-if="author && author.name !== 'Spectrum Life'">
          <div class="authorImg">
            <img alt="author picture" :src="author.profile_img">
          </div>
          <div class="authorName ml-4" >
            <div style="font-weight: bold">{{author.name}}</div>
            <div>{{author.title}}</div>
          </div>
        </div>
        <div class="eventSection">
          <h3 class="my-5">{{course.course_title}}</h3>
          <div class="infoRow d-flex justify-content-between">
            <div>
              {{eventLengthMinutes}}
            </div>
            <div style="width: 60px" class="d-flex justify-content-between align-items-center">
                <div style="cursor: pointer" tabindex="0" @keydown.enter="toggleBeCalmFavourite">
                  <i :id="'favs'" :style="course.favourited? 'color: #DA1984;':''" class="fa-lg myListBtn" :class="course.favourited ? 'fa-heart fa-solid plus':'fa-heart fa-regular plus'" @click="toggleBeCalmFavourite"></i>
                  <b-tooltip :target="'favs'" triggers="hover" placement="top" custom-class="mylist-tooltip">
                    {{course.favourited ? 'Remove from favourites' : 'Add to favourites'}}
                  </b-tooltip>
                </div>
                <div style="cursor: pointer" class="d-flex align-items-center justify-content-center h-100" @click="watchLater" tabindex="0" @keydown.enter="watchLater">
                  <i :id="'myList'" class="fa-regular fa-lg myListBtn" :class="isItemInMyList ? 'fa-circle-check plus':'fa-circle-plus plus'" ></i>
                  <b-tooltip :target="'myList'" triggers="hover" placement="top" custom-class="mylist-tooltip">
                    {{isItemInMyList ? 'Remove from Watch Later' : 'Add to Watch Later'}}
                  </b-tooltip>
                </div>

            </div>
          </div>
          <VisualAudioDisplay
              style="max-width: 680px"
              class="mt-3"
              v-if="currentModule.module_type === 'video' ||
                  currentModule.module_type === 'audio'"
              :type="currentModule.module_type"
              :id="currentModule.module_id"
              :url="currentModule.asset_url"
              :is-first-item="isFirstItem"
              :is-last-item="isLastItem"
              :content="currentModule.content"
              :title="currentModule.title"
              :exlcude-mark-as-complete="true"
              @previous="previous"
              @next="completedModule"
              @ended="completedModule"
          ></VisualAudioDisplay>

          <div class="descriptionRow">
            {{course.course_summary}}
          </div>
          <div class="d-flex justify-content-start bcBtn" style="max-width: 680px">
            <b-button class="layaBtn guided_meditation" @click="completedModule" :id="layaId(currentModule.title)">Mark as complete</b-button>
          </div>
          <div class="mt-5">
            <div class="authorBioBox d-flex" v-if="author && author.name !== 'Spectrum Life'">
              <div class="authorImg mr-4">
                <img alt="author picture" :src="author.profile_img">
              </div>
              <div class="authorBioText">
                <div style="font-weight: bold" class="name mb-2">{{ author.name }}</div>
                <div class="bio">{{author.bio}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="similarCoursesSection trainingPlan pl-5" style="padding-top: 20px" >
          <h4 class="my-5">Modules in this course</h4>
          <div class="mt-4 similarCoursesHolder modules-in-course">
            <div tabindex="0" v-for="(mod,i) in modules" :id="layaId(mod.title)" class="similarCourseBox my-4 d-flex modules-in-course" @click="changeSelectedModule(mod.module_id)" @keydown.enter="changeSelectedModule(mod.module_id)">
              <div style="width: 100px; height: 100%;" :id="layaId(mod.title)" class="imageHolder d-flex align-items-center justify-content-center modules-in-course" :style="{ backgroundImage: 'url(' + course.featured_img + ')' }">
              </div>
              <div :id="layaId(mod.title)" class="textHolder position-relative modules-in-course">
                <b-badge :id="layaId(mod.title)" v-if="selectedModule === mod.module_id" variant="success" class="position-absolute modules-in-course" style="top: 10px; right: 10px">Playing</b-badge>
                <div :id="layaId(mod.title)" class="topRow modules-in-course">
                  <span class="float-end modules-in-course" :id="layaId(mod.title)">
                    {{ modMins(mod.duration)}}
                  </span>
                </div>
                <h6 :id="layaId(mod.title)" class="mt-3 title modules-in-course">{{mod.title}}</h6>
                <div :id="layaId(mod.title)" class="modComplete modules-in-course" v-if="mod.complete"><i class="fa-regular fa-check"></i>Completed</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import moment from "moment";
import EventVideo from "@/components/LiveEvent/EventVideo.vue";
import Vimeo from "@vimeo/player";
import VisualAudioDisplay from "@/components/NewStudio&CourseLayout/Content/VisualAudioDisplay.vue";
import ShareModal from "@/components/NewStudio&CourseLayout/Modals/ShareModal.vue";
import axios from "axios";
import config from "@/config/constants";
import {auth} from "google-auth-library";
export default {
  name: "LayaBeCalmCourse",
  components: {VisualAudioDisplay, EventVideo},
  data() {
    return {
      author: {},
      course: {},
      modules: [],
      progress: "",
      sidebarExtended: false,
      selectedModule: '',
      detailsNavSelection: 0,
    }
  },
  mounted() {
    this.getCourse();
  },
  watch: {
    '$route'(to,from) {
      if(to.params.id !== from.params.id) {
        this.getCourse();
      }
    },
    selectedModule() {
      window.scrollTo(0,0);
    }
  },
  computed: {
    eventLengthMinutes() {
      return Math.floor(this.currentModule.duration / 60) + ' mins';
    },
    product_id () {
      return this.course.course_channel === 'surge' ? 4 : 17;
    },
    isItemInMyList() {
      const isInList = this.$store.getters.myList.filter(item => item.product_id === this.product_id && Number.parseInt(item.content_id) === Number.parseInt(this.course.course_id));
      return isInList.length > 0;
    },
    darkMode() {
      return this.$store.getters.darkMode;
    },
    isNarrowDevice() {
      return this.windowWidth <= 992;
    },
    backRoute() {
      if(this.$route.query.back === 'home') return '/';
      if(this.$route.query.back) return '/' + this.$route.query.back;
      return this.$route.name === 'beCalmCourse' ? '/be-calm' : '/wellbeing'
    },
    isFirstItem() {
      const i = this.modules.findIndex(mod => mod.module_id === this.selectedModule);
      return i === 0
    },
    isLastItem() {
      const i = this.modules.findIndex(mod => mod.module_id === this.selectedModule);
      return i === this.modules.length - 1
    },
    link() {
      return window.location.href;
    },
    currentModule() {
      const m = this.modules.filter(mod => mod.module_id === this.selectedModule);
      return m[0];
    },
    navItems() {
      return this.modules.map(module => {
        return {
          id: module.module_id,
          title: module.title,
          description: null,
          complete: module.complete,
          img: module.featured_img,
          selected: Boolean(module.module_id === this.selectedModule)
        }
      })
    }
  },
  methods: {
    async toggleBeCalmFavourite() {
      const payload = {
        user_id: this.$store.getters.user_id,
        course_id: this.course.course_id
      }
      let res = await this.axios.post(
          process.env.VUE_APP_API_PATH +
          "/application/api/u/resources/toggle-be-calm-favourite",
          payload
      );
      if(res.data.success) {
        this.course.favourited = res.data.is_favourite;
        this.m('favourite_toggle', {title: this.course.course_title, area: 'course_page', action: res.data.is_favourite ? 'add' : 'remove'});
      }
    },
    modMins(mins) {
      return Math.floor(mins / 60) + ' mins';
    },
    async watchLater() {
      this.m('watch_later_toggle', {title: this.course.course_title, area: 'course_page', action: !this.isItemInMyList ? 'add' : 'remove'});
      if(!this.isItemInMyList) {
        await this.$store.dispatch('addMyListItem',({media_type: 'course', product_id: this.product_id, content_id: this.course.course_id}));
      } else {
        const myListRecord = this.$store.getters.myList.filter(item => item.product_id === this.product_id && item.content_id === Number.parseInt(this.course.course_id));
        await this.$store.dispatch('deleteMyListItem',({id: myListRecord[0].id}));
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth;
      this.detailsNavSelection = 0;
    },
    back() {
      this.$router.push(this.backRoute);
    },
    previous() {
      if(this.isFirstItem) return;
      const i = this.modules.findIndex(mod => mod.module_id === this.selectedModule);
      this.selectedModule = this.modules[i-1].module_id;
    },
    async completedModule() {
      if(!this.currentModule.complete) {
        const payload = {
          user_id: this.$store.getters.user_id,
          module_id: this.currentModule.module_id
        };
        await this.axios.post(
            process.env.VUE_APP_API_PATH +
            "/application/api-hc/complete-course-module",
            payload
        );
        this.setModuleAsCompleted(this.currentModule.module_id);
      }
      if(!this.isLastItem) {
        this.moveToNextModule();
      }
    },
    setModuleAsCompleted(id) {
      this.modules.map(mod => {
        if(mod.module_id === id) {
          mod.complete = 1;
        }
      })
    },
    moveToNextModule() {
      let i = false;
      let currentModuleFound = false
      this.modules.map(mod => {
        if(mod.module_id === this.currentModule.module_id) currentModuleFound = true;
        if(currentModuleFound && !i && Number.parseInt(mod.complete) !== 1) {
          i = true;
          this.selectedModule = mod.module_id;
        }
      })
      if(!i) this.selectedModule = this.modules[0].module_id;
    },
    showShareModal() {
      const skin = this.$store.getters.skin;
      const content = this.$createElement(ShareModal, {
        props: {
          title: 'Share this course',
          instruction: 'Share this course with other by copying the link below',
          link: this.link
        }
      });
      let modalClass = "share-modal theme-"+skin;
      if(this.darkMode) modalClass += ' dark-mode-modal';
      let opts = {
        hideHeader: false,
        hideFooter: true,
        modalClass: modalClass,
        headerClass: "share-modal d-none",
        footerClass: "d-none",
        size: "lg",
        buttonSize: "sm",
        centered: true,
        contentClass: "share-modal theme-" + skin,
        id : 'share-modal',
      }
      return this.$bvModal.msgBoxOk(content, opts);
    },
    changeSelectedModule(id) {
      this.selectedModule = id
    },
    async getCourse() {
      const payload = {
        user_id: this.$store.getters.user_id,
        course_id: this.$route.params.id,
      };
      let res = await axios.post(
          config.api_env + "/application/api-hc/get-course",
          payload,
          config.options
      );
      if (res.data.success) {
        this.author = res.data.author;
        this.course = res.data.course;
        this.modules = res.data.modules;
        this.progress = res.data.progress;
        this.selectedModule = null;
        this.modules.forEach(mod => {
          if(!mod.complete && !this.selectedModule) {
            this.selectedModule = mod.module_id
          }
        });
        if(!this.selectedModule) this.selectedModule = this.modules[0].module_id
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login").catch((err) => {});
      }
    },
  }
}
</script>

<style lang="less">
#LayaBeCalmCourse {
  width: 100vw;
  font-family: Open Sans, sans-serif;
  i {
    font-size: 20px;
  }
  .pageContainer {
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 1fr;
    .authorRow {
      grid-column: 1 / span 2;
      font-size: 1.5rem;
      grid-template-columns: fit-content(100%) 4fr 2fr;
      display: grid;
      .authorImg {
        img {
          width: 50px;
          border-radius: 50%;
        }
      }
    }
    .eventSection {
      padding: 20px;

      .infoRow {
        font-size: 1.4rem;
      }
      #VisualAudioDisplay {
        background-color: transparent !important;
        border-radius: 15px;
        overflow: hidden;
      }
      .videoContainer {
        iframe, video {
          border-radius: 15px;
        }
      }
      .descriptionRow {
        max-width: 680px;
        font-size: 16px;
        line-height: 1.85rem;
        text-align: justify;
        margin-top: 24px;
      }
      .bcBtn {
        margin-top: 24px;
      }
      .infoRow {
        max-width: 680px;;
      }
      .authorBioBox {
        max-width: 680px;
        background: #FAFAFA;
        padding: 40px 40px;
        border-radius: 20px;
        .authorImg {
          img {
            width: 100px;
            border-radius: 50%;
          }
        }
        .authorBioText {
          .name {
            font-family: "Cocon Pro Regular", sans-serif;
            font-size: 2rem;
          }
          .bio {
            text-align: justify;
            font-size: 1.4rem;
            line-height: 1.65rem;
          }
        }
      }
    }
    .similarCoursesSection {
      &.trainingPlan {
        .textHolder {
          padding: 10px 20px !important;
        }
      }
      .similarCoursesHolder {
        .similarCourseBox {
          height: 105px;
          aspect-ratio: 376 / 120;
          max-width: 95%;
          margin: auto;
          box-shadow: 0 4px 40px 0 #4F6C811F;
          border-radius: 10px;
          cursor: pointer;
          overflow: hidden;
          &:hover {
            transform: scale(1.05);
          }
          .imageHolder {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            filter: brightness(75%);
          }
          .textHolder {
            width: 75%;
            padding: 20px;
            .title {
              display: -webkit-box;
              -webkit-line-clamp: 1;
              line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
            .modComplete {
              color: #219ba0;
              i {
                font-size: 1.1rem;
                margin-right: 10px;
              }
              font-size: 1.1rem;
            }
            .topRow {
              width: 100%;
              justify-content: space-between;
              display: flex;
              span {
                font-size: 1.1rem;
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .pageContainer {
      .authorRow {
        grid-column: 1;
      }
      grid-template-columns: 1fr;
      h4 {
        font-size: 2.25rem;
        margin: 15px 0 !important;
      }
      h6 {
        font-size: 1.8rem;
      }
      .similarCoursesSection {
        width: 100%;
        .similarCourseBox {
          width: 90%;
          margin: auto;
        }
      }
      .eventSection {
        width: 100%;
        padding: 5px 10px;
        h3 {
          font-size: 2.75rem;
          margin: 15px 0 !important;
        }
        .descriptionRow {
          padding: 0 15px 30px 15px;
          border-bottom: #e1e1e1 1px solid;
        }
        #VisualAudioDisplay {
          max-width: 100%;
          width: 100% !important;
        }
      }
    }
  }
}
</style>