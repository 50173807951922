<template>
  <div id="LayaWellbeingCourse">
    <section class="content-container row pt-5 mt-5 mb-4 px-0">
      <div class="banner-image">
        <img src="/img/laya/laya-sample-banner.png" />
        <svg
          class="image-curve"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1252 88"
        >
          <g>
            <g>
              <path
                fill="#f5f5f5"
                d="M1.628 87.698h1249.59V.003C834.553 39.475 418.889 39.475 4.226.003.255-.375-.61 28.857 1.628 87.698z"
              />
            </g>
          </g>
        </svg>
      </div>
    </section>
    <section class="row course-wrap" v-if="modules.length > 0">
      <b-button
        class="return ml-3"
        @click="$router.back()"
        alt="return to all events"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          viewBox="0 0 50 50"
        >
          <g>
            <g>
              <g>
                <path
                  class="circle"
                  fill="#d60570"
                  d="M24.805 0c13.698 0 24.804 11.105 24.804 24.804 0 13.699-11.106 24.804-24.804 24.804C11.105 49.608 0 38.503 0 24.804 0 11.105 11.106 0 24.805 0z"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke="#f1f1f1"
                  stroke-linecap="round"
                  stroke-miterlimit="20"
                  stroke-width="2.83"
                  d="M23.137 13.934v0l-8.908 10.028v0l7.793 10.033v0"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke="#f1f1f1"
                  stroke-linecap="round"
                  stroke-miterlimit="20"
                  stroke-width="2.83"
                  d="M14.711 23.906h26.893"
                />
              </g>
            </g>
          </g>
        </svg>
      </b-button>
      <div class="col-12">
        <div class="course-card px-5 py-5">
          <h2>{{ course.course_title }}</h2>
          <div
            class="course-description"
            v-html="course.course_description"
          ></div>
          <p>
            <span class="pr-5"
              ><i class="far fa-clock"></i> {{ totalDuration }}
            </span>
            <i class="fas fa-volume-up"></i> {{ modules.length }} chapters
          </p>
          <div class="btn-wrap pt-5">
            <b-button @click="selectModule(0)" class="cta-button"
              >Start course</b-button
            >
          </div>
        </div>
      </div>
      <div class="col-12 col-md-5">
        <div
          class="course-card module-card px-5 py-4 my-4"
          v-for="(module, i) in modules"
          :key="i"
          @click="selectModule(i)"
          :class="{ active: activeIndex === i }"
        >
          <div class="course-icon">
            <img
              :src="'/img/wellbeing/course-icons/10/' + module.type + '.svg'"
            />
          </div>
          <div class="pl-4">
            <p class="mb-2">
              <span class="chapter-index">Chapter {{ i + 1 }}</span>
            </p>
            <h3>{{ module.title }}</h3>
            <p class="mb-0">
              <i class="far fa-clock"></i> {{ module.duration }}
            </p>
          </div>
        </div>
      </div>
      <div id="module-content" class="col-12 col-md-7">
        <div class="active-module py-5">
          <div class="video-module" v-if="activeModule.type === 'video'">
            <video
              v-if="activeModule.type === 'video' && !embedCode"
              :src="activeModule.asset_url"
              controls
              controlsList="nodownload"
              @ended="nextModule()"
              ref="media"
            ></video>
            <div v-else v-html="embedCode"></div>
          </div>
          <div class="audio-module" v-if="activeModule.type === 'audio'">
            <h3>{{ activeModule.title }}</h3>
            <audio
              controls
              class="audio-player"
              :key="activeModule.id"
              ref="media"
              @ended="nextModule()"
              autoplay
            >
              <source :src="activeModule.asset_url" type="audio/mpeg" />
            </audio>
          </div>
          <div class="text-module" v-if="activeModule.type === 'text'">
            <h3>{{ activeModule.title }}</h3>
            <div v-html="activeModule.content"></div>
            <div class="buttons">
              <b-button
                v-if="activeIndex < modules.length - 1"
                @click="nextModule()"
                class="px-5 py-3 my-5"
                >Next chapter</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "LayaWellbeingCourse",
  data() {
    return {
      course: {},
      author: {},
      modules: [],
      progress: 0,
      totalDuration: 0,
      activeModule: {},
      activeIndex: 0,
    };
  },
  mounted() {
    this.getCourse();
  },
  methods: {
    async getCourse() {
      const payload = {
        user_id: this.$store.getters.user_id,
        course_id: this.$route.params.id,
      };
      let res = await this.axios.post(
        process.env.VUE_APP_API_PATH +
          "/application/api/u/resources/get-course",
        payload
      );
      if (res.data.success) {
        this.course = res.data.course.course;
        this.author = res.data.course.author;
        this.modules = res.data.course.modules;
        this.progress = res.data.course.progress;
        this.totalDuration = res.data.course.duration;
        this.setInitialActiveModule(res.data.course.modules);
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      }
    },
    setInitialActiveModule(modules) {
      if (this.$store.state.accessCode) {
        this.activeModule = modules[0];
        this.modules[0].is_active = true;
        this.activeIndex = 0;
      } else {
        for (let module in modules) {
          if (modules[module].complete === 0) {
            this.activeModule = modules[module];
            this.modules[module].is_active = true;
            this.activeIndex = module;
            return;
          }
        }
        this.activeModule = modules[0];
        this.modules[0].is_active = true;
        this.activeIndex = 0;
      }
    },
    selectModule(index) {
      this.activeModule = this.modules[index];
      this.activeIndex = index;
      const scrollTo = document.getElementById("module-content");
      scrollTo.scrollIntoView({ behavior: "smooth" });
      if (this.activeModule.type !== "text") {
        this.$nextTick().then(() => {
          this.$refs.media.play();
        });
      }
    },
    previousModule() {
      this.selectModule(this.activeIndex - 1);
    },
    nextModule() {
      this.completeCourseModule(this.activeModule.id);
      this.selectModule(this.activeIndex + 1);
    },
    async completeCourseModule(id) {
      const payload = {
        user_id: this.$store.getters.user_id,
        module_id: id,
      };
      await this.axios.post(
        process.env.VUE_APP_API_PATH +
          "/application/api-hc/complete-course-module",
        payload
      );
    },
  },
  computed: {
    embedCode() {
      if(Object.hasOwn(this.activeModule, 'embed_code')) {
        return this.activeModule.embed_code ? atob(this.activeModule.embed_code) : null;
      } else {
        return null;
      }
    },
  }
};
</script>

<style scoped lang="less">
#LayaWellbeingCourse {
  background: #f5f5f5;
  h2 {
    color: #069 !important;
  }
  .return {
    width: 50px;
    height: 50px;
  }
  .banner-image {
    border-radius: 30px 30px 0 0;
    overflow: hidden;
    position: relative;
    img {
      width: 100%;
    }
    .image-curve {
      position: absolute;
      width: calc(100% + 10px);
      bottom: -5px;
      left: -5px;
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        max-height: 88px;
      }
    }
  }
  .course-wrap {
    max-width: 1100px;
    margin: auto;
    position: relative;
    .return {
      padding: 0;
      position: absolute;
      top: -70px;
      left: 0;
      .circle {
        transition: fill 0.15s ease-in-out, background-color 0.15s ease-in-out;
      }
      &:hover {
        .circle {
          fill: darken(#d60570, 10%);
        }
      }
    }
    .course-card {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      border-radius: 8px;
      background-color: #ffffff;
      h2 {
        font-family: ff-cocon-web-pro;
        font-size: 3rem;
        text-align: left;
      }
      p {
        font-size: 1.6rem;
        max-width: 565px;
        color: #666666 !important;
      }
      h3 {
        padding: 0;
      }
      .course-description {
        font-size: 16px;
        font-family: Open Sans, sans-serif;
        max-width: 565px;
        color: #666666 !important;
        p {
          color: #666666 !important;
        }
      }
      .cta-button {
        font-size: 2.4rem;
      }
      &.module-card {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        &:hover {
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
        }
        &.active {
          background: #e7f5fb;
        }
        .course-icon {
          width: 24px;
          img {
            width: 24px;
          }
        }
        h3 {
          color: #2686b1;
          font-family: "Open Sans", sans-serif;
          font-size: 1.8rem;
          font-weight: 700;
        }
        p {
          .chapter-index {
            color: #2686b1;
            font-size: 1.3rem;
          }
        }
      }
    }
    .active-module {
      h3 {
        color: #2686b1;
        font-family: "Open Sans", sans-serif;
        font-size: 1.8rem;
        font-weight: 700;
        padding-top: 0;
      }
      video {
        width: 100%;
        border-radius: 36px;
        border: 1px solid #afb0b2;
        &:focus {
          outline: none;
        }
      }
      .audio-player {
        width: 100%;
        border: 1px solid #2686b1;
        border-radius: 30px;
        color: #2686b1;
        &:focus {
          outline: none;
        }
      }
      .text-module {
        font-size: 1.6rem;
        .buttons {
          width: 100%;
          text-align: center;
          .btn {
            font-size: 1.7rem;
          }
        }
      }
    }
  }
}
</style>
