<template>
<div id="NewMobileNav">
  <div id="navApp" data-v-app="">
    <header id="navAppHeader" class="shadow-1 mobile">
      <div id="navHolder" class="mobile"><!----><!---->
        <nav class="laya-nav-mobile shadow-1">
          <button id="navbarButton" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <svg class="hamburger-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" v-if="closed" @click="closed = false">
              <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"></path>
            </svg>
            <svg class="close-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" v-else @click="closed = true">
              <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"></path>
            </svg>
          </button>
          <div style="cursor: pointer; background-image: url('/img/laya-logo-axa-long.png')" aria-label="laya logo" id="logoDivMobile" @click="goHome"></div>
          <div style="height: 100%;" class="d-flex mr-2">
            <div class="d-flex align-items-center ml-4 settingBtn position-relative">
              <div class="nameTag settingToggle mr-3" style="" @click="settingsOpen = !settingsOpen">
                {{initals}}
              </div>
              <div class="settingDropdown" :class="{open: settingsOpen}">
                <div class="settingDropdownItem">
                  <div class="topItem">
                    <div class="nameTag">
                      {{initals}}
                    </div>
                    <div>
                      <div class="fullName">
                        {{fullName}}
                      </div>
                      <div class="email">
                        {{ $store.getters.user.user.email }}
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="false" class="settingDropdownItem otherItem" id="nav_dropdown" @click="navigateFromFixedButton('/my-progress')">
                  <i class="fa-solid fa-chart-simple fa-lg mr-4 ml-2" id="dropdown-my-progress-icon"></i>
                  My Progress
                </div>
                <div class="settingDropdownItem otherItem" id="nav_dropdown" @click="navigateFromFixedButton('/my-list')">
                  <i class="fa-solid fa-list fa-lg mr-4 ml-2"></i>
                  Watch Later
                </div>
                <div class="settingDropdownItem otherItem" id="nav_dropdown" @click="navigateFromFixedButton('/favourites')">
                  <i class="fa-regular fa-heart fa-lg mr-4 ml-2"></i>
                  Favourites
                </div>
<!--                <div class="settingDropdownItem otherItem"  @click="navigateFromFixedButton('/settings')">-->
<!--                  <i class="fa-solid fa-circle-user fa-lg mr-4 ml-2"></i>-->
<!--                  Settings-->
<!--                </div>-->
                <div class="settingDropdownItem otherItem logOut" id="nav_dropdown" @click="logOut">
                  <svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-4 ml-2">
                    <path d="M6.40833 10.9917L7.58333 12.1667L11.75 8L7.58333 3.83333L6.40833 5.00833L8.55833 7.16667H0.5V8.83333H8.55833L6.40833 10.9917ZM13.8333 0.5H2.16667C1.24167 0.5 0.5 1.25 0.5 2.16667V5.5H2.16667V2.16667H13.8333V13.8333H2.16667V10.5H0.5V13.8333C0.5 14.75 1.24167 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V2.16667C15.5 1.25 14.75 0.5 13.8333 0.5Z" :fill="'#DA1984'"/>
                  </svg>
                  Logout
                </div>
              </div>
            </div>
          </div>
          <div class="laya-dropdown mobile" :class="{closed: closed}">
            <div class="mt-5"></div>
            <template v-for="item in nav">
              <div class="menu-item-plus-dropdown" v-if="item.hasOwnProperty('sub_items')">
                <p class="menuItem" @click="toggleMenu(item.id)">
                  <a>{{item.label}}<i class="fa-solid  ml-3" :class="open_menu === item.id? 'fa-chevron-up':'fa-chevron-down'"></i>
                  </a>
                </p>
                <div class="navSubItems" :class="{open: open_menu === item.id}">
                  <p class="ml-4 menuItem" v-for="subItem in item.sub_items" @click="navigate(subItem)">
                    <a>{{subItem.label}}</a>
                  </p>
                </div>
                <hr>
                <div class="menu-item-plus-dropdown-holder"></div>
              </div>
              <div class="menu-item-plus-dropdown" v-else>
                <p class="menuItem"><a href="#" @click="navigate(item)">{{item.label}}</a></p>
                <hr>
                <div class="menu-item-plus-dropdown-holder"></div>
              </div>
            </template>
          </div>
        </nav>
      </div>
    </header>

  </div>
</div>
</template>

<script>
import LayaSearchBar from "@/components/LayaSearchBar.vue";
import ReminderHolder from "@/components/ReminderHolder.vue";
import NavAppointmentHolder from "@/components/NavAppointmentHolder.vue";
import EventBus from "@/helpers/eventbus";
import moment from "moment/moment";
import {mapGetters} from "vuex";

export default {
  name: "NewLayaMobileNav",
  components: {NavAppointmentHolder, ReminderHolder, LayaSearchBar},
  data() {
    return {
      closed: true,
      open_menu: -1,
      remindersExtended: false,
      iconHoveredOver: -1,
      unseenNotifications: false,
      notifications: [],
      bookings: [],
      bookingsLoading: false,
      bookingsExtended: false,
      notificationChecker: null,
      settingsOpen: false
    }
  },
  mounted() {
    this.getBookings();
    this.checkForNotifications();
    const _t = this;
    EventBus.$on('updateDFDReminders',function() {
      setTimeout(()=> {_t.getBookings();},5000);
    });
    this.$store.dispatch('setMyList');
    if(this.$route.name === 'challenges') {
      this.showChallenges = true
    }
    this.notificationChecker = setInterval(()=> {
      if(!this.unseenNotifications) this.checkForNotifications();
    },1000 * 60);
    const content = document.getElementById('content');
    content.addEventListener('click',()=> {
      this.dropdownOpen = false;
      this.settingsOpen = false;
    })
  },
  destroyed() {
    clearInterval(this.notificationChecker);
  },
  methods: {
    goHome() {
      this.settingsOpen = false;
      this.$router.push('/')
    },
    toggleBookingsExtended() {
      if(this.showReminderAlert) this.$store.dispatch("setBookingReminderLastSeen");
      this.bookingsExtended = !this.bookingsExtended;
    },
    async checkForNotifications() {
      return;
      const res = await this.api({
        path: '/api/u/notifications/unread'
      })
      if(res.success) {
        this.unseenNotifications = res.unread_count && res.unread_count > 0;
      } else {
        this.unseenNotifications = false;
      }
    },
    async getBookings(){
      try {
        let res = await this.api({
          path: "api/u/home-page/get-booking-widget",
        });
        if (res.success) {
          this.bookings = res.bookings;
          EventBus.$emit("setBookingReminders", this.bookings);

        }
        this.bookingsLoading = false;
      } catch (e) {
        this.bookingsLoading = false;
      }

    },
    toggleRemindersExtended() {
      this.remindersExtended = !this.remindersExtended;
      if(this.remindersExtended) {
        EventBus.$emit('getReminders');
        this.unseenNotifications = false;
      }
    },
    toggleMenu(id) {
      if(this.open_menu === id) {
        this.open_menu = -1;
      } else {
        this.open_menu = id;
      }
    },
    navigate(item) {
      if (!item.external_link) {
        if(item.asset_id){
          this.$router.push({ name: item.item_slug, params: { id: item.asset_id }  });
        }else{
          this.$router.push({ name: item.item_slug });
        }
      } else {
        window.open("https://" + item.external_link, "_blank");
      }
      this.closed = true;
    },
    logOut() {
      this.$store.dispatch("logout");
      this.$router.push("/login").catch(err => {});
    },
    navigateFromFixedButton(url) {
      this.$router.push(url);
      this.closed = true;
      this.settingsOpen = false;
    },
    toggleNav() {
      this.closed = !this.closed;
    }
  },
  watch: {
    closed() {
      this.settingsOpen = false;
    }
  },
  computed: {
    ...mapGetters(['bookingReminderLastSeen']),
    fullName() {
      return this.$store.getters.user.user.first_name +" "+ this.$store.getters.user.user.last_name;
    },
    initals() {
      return this.$store.getters.user.user.first_name.charAt(0).toUpperCase() + this.$store.getters.user.user.last_name.charAt(0).toUpperCase()
    },
    showReminderAlert() {
      const today = moment().format("YYYY-MM-DD");
      if(today === this.bookingReminderLastSeen) return false;
      let show = 0;
      this.bookings.map(item => {
        if(moment(item.start).format("YYYY-MM-DD") === today) show++;
      });
      return show;
    },
    nav() {
      return this.$store.getters.nav;
    },
  }
}
</script>

<style lang="less">
#NewMobileNav {
  #LayaSearchBar {
    width: 100% !important;
    .form-search {
      width: 100% !important;
    }
    #searchButton {
      margin-left: -4rem !important
    }
    .resultsBackground {
      width: calc(100% - 16px) !important;
      top: 65px !important;
      left: 16px !important;
    }
    #SearchBarResult {
      grid-template-columns: 1fr !important;
      grid-template-rows: 1fr 1fr 1fr !important;
      .resultHeader {
        grid-column: 1;
      }
      .resultSubTitle {
        grid-column: 1;
      }
      .resultMeta {
        grid-column: 1;
      }
      .imgHolder {
        display: none;
      }
    }
  }
  #navAppHeader.mobile {
    margin-bottom: 0 !important;
    .navSubItems {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.25s ease-in;
      p {
        margin-top: 10px !important;
        margin-bottom: 10px !important;
      }
      &.open {
        max-height: 600px;
        transition: max-height 0.5s ease-in;
      }
    }
  }
  .apptNavIcon, .reminderNavIcon {
    .bookingAlert {
      position: absolute;
      bottom: -2px;
      right: 5px;
      width: 10px;
      height: 10px;
      background: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%);
      border-radius: 50%;
      z-index: 201;
    }
    .bookingAlertBox {
      display: none;
      overflow: visible;
      position: absolute;
      z-index: 201;
      left: -150px;
      bottom: -70px;
      width: 300px;
      .bookingAlertBoxOuter {
        position: relative;
        .bookingAlertBoxTriangle {
          position: absolute;
          background-image: linear-gradient(90deg, #a73870 0%, #9c386e 100%);
          clip-path: polygon(50% 0, 100% 100%, 0 100%);
          width: 25px;
          height: 20px;
          top: -19px;
          left: 160px;
        }
      }
      .bookingAlertBoxInner {
        color: white;
        background: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%);
        overflow: visible;
        display: flex;
        align-items: center;
        font-size: 13px;
        border-radius: 5px;
        padding: 5px;
        i {
          color: white;
          margin-right: 10px;
          font-size: 2.3rem;
        }
      }
    }
    &:hover {
      .bookingAlertBox {
        display: block;
      }
    }
  }
  .navIcon {
    cursor: pointer;
    position: relative;
    font-size: 2.75rem;
    margin: 0 5px;
    color: #9F9E93;
    &:hover, &.challengesOpen {
      color: #d63384;
    }

    .walletHolder {
      width: 400px;
      height: 200px;
      background-color: transparent;
      top: 20px;
      right: 0;
    }
    .apptHolder {
      width: 300px;
      background-color: white;
      top: 25px;
      right: -80px;
    }
    .reminderDropDown {
      background-color: white;
      top: 25px;
      right: -80px;
    }
    .iconDropDown {
      display: none;
      position: absolute;
      overflow: visible;
      z-index: 9999;
      right: 0;
      top: 90%;
    }
    &.hoverable:hover, &.extended {
      .iconDropDown{
        display: block;
      }
    }
  }
  .navItems {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 14px;
    cursor: pointer;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    .topNavItem {
      width: 100%;
      max-width: 200px;
      position: relative;
      height: 100%;
      padding: 2px 3px;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      .navDropDown {
        display: none;
        width: 100%;
        position: absolute;
        overflow: visible;
        z-index: 9999;
        top: 50px;
        .navDropDownItem {
          padding: 2px 3px;
          width: 100%;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px 0;
          &:hover {
            color: white;
            background: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%);
          }
        }
      }
      &:hover {
        background: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%);
        color: white;
        .navDropDown {
          display: flex;
          flex-direction: column;
        }
      }
      .navDropDown:hover {
        display: flex;
        flex-direction: column;
      }
    }
  }
  .settingToggle {
    cursor: pointer;
  }
  #Notification {
    .iconHolder {
      i {
        font-size: 3rem;
      }
    }
    .new {
      font-size: 1.2rem !important;
    }
    .date {
      font-size: 1.15rem !important;
    }
    .body {
      font-size: 1.25rem !important;
    }
  }
  .nameTag {

    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    background-color: #da1984;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .settingDropdown {
    cursor: pointer;
    .settingsDropdownItem {
      width: 100%;
      padding: 8px 15px;
      i {
        margin-right: 20px;
        color: #0d77a1;
      }
      &:hover {
        background-color: #f5f5f5;
      }
    }
    min-width: 250px;
    position: absolute;
    right: 0;
    top: 42px;
    height: 0;
    min-height: 0;
    z-index: 981;
    background-color: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    transition: all 0.3s linear;
    overflow: hidden;
    .settingDropdownItem {
      width: 100%;
      padding: 10px 12px 10px 12px;
      border-bottom: 1px rgba(54, 56, 58, 0.25) solid;
      min-height: 55px;
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 14px;
      font-family: "Open Sans",sans-serif;
      &.logOut {
        color: #d60570;
      }
      &:last-of-type {
        border-bottom: none;
      }
      &.otherItem {
        &:hover {
          color: #d60570;
          i {
            color: #d60570;
          }
        }
      }
    }
    .topItem {
      font-family: "Cocon Pro Regular",sans-serif;
      display: grid;
      grid-template-columns: 2fr 8fr;
      .nameTag {
        grid-column: 1;
        grid-row: 1 / span 2;
      }
      .fullName {
        text-align: left;
        margin-left: 5px;
      }
      .email {
        font-size: 12px;
        font-family: "Open Sans",sans-serif;
        margin-left: 5px;
      }
    }

    &.open {
      transition: all 0.3s linear;
      min-height: 100px;
      height: fit-content;

    }
  }
  .menuItem {
    a:hover {
      color: #da1984;
    }
  }
}
</style>