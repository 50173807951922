import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import NotLoggedIn from "../views/NotLoggedIn.vue";
import LoggedIn from "../views/LoggedIn.vue";
import NotFound from "../views/NotFound.vue";
import Feedback from "../views/Feedback.vue";
import LoginAs from "../views/LoginAs.vue";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TCs from "../pages/TCs";
import Redirect from "../pages/Redirect";
import Info from "../pages/Info";
import Datenschutzrichtlinie from "../pages/Datenschutzrichtlinie";
import Nutzungsbedingungen from "../pages/Nutzungsbedingungen";
import OAuthGoogle from "../pages/oauth/google/Authenticate";

Vue.use(VueRouter);


const beforeEnterHandler = function (to, from, next) {
  if (store.getters.user.authenticated) {
    if(store.getters.isSaml){
      if (!store.getters.user.user.tc_accepted) {
        next('/terms-and-conditions').catch(() => { });
      };
      if (!store.getters.user.user.pp_accepted) {
        next('/privacy-policy').catch(() => { });
      };
    }
    /**
     * hardcoded homepages per client
     */
    if(to.name === 'home'){
      if (store.getters.skin === 20) {
        next('/digital-gym').catch(() => { })
      }
      switch(parseInt(store.getters.user.user_client_id)) {
        case 3519:
          next('/personal/my-company').catch(() => { })
          break;
        case 5191:
          /**
           * Workhuman
           */
          next('/clinics/digital').catch(() => { })
          break;
        default:
          next()
      }
    }
  }
  next();
}
const routes = [
  {
    path: "/login",
    name: "NotLoggedIn",
    component: NotLoggedIn,
    beforeEnter: (to, from, next) => {
      if (store.getters.subdomain === 'layaeap') {
        store.dispatch('accessCodeLogin', { router: router, accessCode: 'LayaEAP' })
      }
      next();
    }
  },
  {
    path: "/site/loginas",
    name: "loginAs",
    component: LoginAs
  },
  {
    path: "/sso/",
    name: "sso",
    component: LoginAs
  },
  {
    path: "/loginas",
    name: "loginAs",
    component: LoginAs
  },
  {
    path: "/verify",
    name: "verifyEmail",
    component: NotLoggedIn
  },
  {
    path: "/reset",
    name: "resetPassword",
    component: NotLoggedIn
  },
  {
    path: "/forgot",
    name: "forgotPassword",
    component: NotLoggedIn
  },
  {
    path: "/submit-feedback/:token/:type/:lang?",
    name: "Feedback",
    component: Feedback
  },
  {
    path: "/",
    name: "home",
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
  },
  {
    //needed for vodafone
    path: "/site/enterprise-app-validate",
    name: "iosAppDownload",
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
  },
  {
    path: "/wellbeing",
    name: "wellbeing",
    // meta: { title: navItems['wellbeing'] ? navItems['wellbeing'].label : "" },
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
  },
  {
    path: "/wellbeing/articles",
    name: "allArticles",
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
    // meta: { title: navItems['wellbeing'] ? navItems['wellbeing'].label : "" },
  },
  {
    path: "/wellbeing/article/:id",
    name: "article",
    // meta: { title: navItems['wellbeing'] ? navItems['wellbeing'].label : "" },
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
  },
  {
    path: "/wellbeing/courses",
    name: "allCourses",
    // meta: { title: navItems['wellbeing'] ? navItems['wellbeing'].label : "" },
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
  },
  {
    path: "/wellbeing/:id",
    name: store.getters.skin === 10 ? "layaCourse" : "course",
    // meta: { title: navItems['wellbeing'] ? navItems['wellbeing'].label : "" },
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
  },
  {
    path: "/be-calm",
    name: "beCalm",
    // meta: { title: navItems['be calm'] ? navItems['be calm'].label : "" },
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
  },
  {
    path: "/be-calm/:id",
    name: "beCalmCourse",
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
  },
  {
    path: "/studio-overview",
    name: "studioOverview",
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
  },
  {
    path: "/wellbeing-studio",
    name: "wellbeingSeries",
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
  },
  {
    path: "/wellbeing-studio/:id",
    name: "virtualEvent",
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
  },
  {
    path: "/wellbeing-studio-test/:id",
    name: "virtualEventTest",
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
  },
  {
    path: "/wellbeing-studio/course/:id",
    name: store.getters.skin === 10 ? "layaCourse" : "wellbeingCourse",
    // meta: { title: navItems['wellbeing'] ? navItems['wellbeing'].label : "" },
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
  },
  {
    path: "/digital-gym",
    name: "digitalGym",
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
  },
  {
    path: "/digital-gym/training-plans/:id",
    name: "trainingPlan",
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
  },
  {
    path: "/digital-gym/:id",
    name: "digitalGymArticle",
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
  },
  {
    path: "/eap",
    name: "eap",
    // meta: { title: navItems['eap'] ? navItems['eap'].label : "" },
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
  },
  {
      path: "/eap?expert=true",
      name: "expert",
      // meta: { title: navItems['eap'] ? navItems['eap'].label : "" },
      component: LoggedIn,
      beforeEnter: beforeEnterHandler,
    },
  {
    path: "/marketplace/events/view-event/:id",
    name: "viewEvent",
    // meta: { title: navItems['events'] ? navItems['events'].label : "" },
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
  },
  {
    path: "/marketplace/events/:page?/:webinar?",
    name: "events",
    // meta: { title: navItems['events'] ? navItems['events'].label : "" },
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
  },
  {
    path: "/digital-clinics",
    name: "digitalClinics",
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
  },
  {
      path: "/mindfulness",
      name: "mindfulness",
      component: LoggedIn,
      beforeEnter: beforeEnterHandler,
    },
  // {
  //   path: "/digital-clinics/bookings",
  //   name: "digitalClinicsBookings",
  //   component: LoggedIn,
  //   beforeEnter: beforeEnterHandler,
  // },
  {
    path: "/whats-happening",
    name: "whatsHappening",
    // meta: { title: navItems['my organisation'] ? navItems['my organisation'].label : "" },
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
  },
  {
    path: "/personal/my-company",
    name: "myOrganisation",
    // meta: { title: navItems['my organisation'] ? navItems['my organisation'].label : "" },
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
  },
  {
    path: "/personal/fitness",
    name: "fitness",
    // meta: { title: navItems['fitness'] ? navItems['fitness'].label : "" },
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
    props: true
  },
  {
    path: "/personal/nutrition",
    name: "nutrition",
    // meta: { title: navItems['nutrition'] ? navItems['nutrition'].label : "" },
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
  },
  {
    path: "/recipe",
    name: "recipe",
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
  },
  {
    path: "/personal/metrics",
    name: "metrics",
    // meta: { title: navItems['metrics'] ? navItems['metrics'].label : "" },
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
  },
  {
    path: "/personal/rewards",
    name: "rewards",
    // meta: { title: navItems['rewards'] ? navItems['rewards'].label : "" },
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
  },
  {
    path: "/personal/insights",
    name: "insights",
    // meta: { title: navItems['insights'] ? navItems['insights'].label : "" },
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
  },
  {
    path: "/wellness-benefits",
    name: "wellnessBenefits",
    // meta: { title: navItems['wellbeing benefits'] ? navItems['wellbeing benefits'].label : "" },
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
  },
  {
    path: "/marketplace/wellness-benefits",
    name: "wellnessBenefitsOld",
    // meta: { title: navItems['wellbeing benefits'] ? navItems['wellbeing benefits'].label : "" },
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
    props: { specsavers: true },
  },
  {
    path: "/marketplace/wellbeing-benefits",
    name: "wellbeingBenefits",
    // meta: { title: navItems['wellbeing benefits'] ? navItems['wellbeing benefits'].label : "" },
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
  },
  {
    path: "/marketplace/wellbeing-benefits/specsavers",
    name: "specsavers",
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
  },
  {
    path: "/marketplace/store",
    name: "fitbit",
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
  },
  {
    path: "/marketplace/confirmation",
    name: "orderConfirmation",
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
  },
  {
    path: "/get-ios-app",
    name: "download",
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
  },
  {
    path: "/faq",
    name: "faq",
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
  },
  {
    path: "/settings",
    name: "settings",
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
  },
  {
    path: "/my-future-wellbeing",
    name: "myFutureWellbeing",
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
  },
  {
    path: "*",
    name: "not-found",
    component: NotFound
  },
  {
    path: "/privacy-policy",
    name: "privacy",
    component: PrivacyPolicy
  },
  {
    path: "/oauth/google/authenticate",
    name: "OAuthGoogle",
    component: OAuthGoogle
  },
  {
    path: "/terms-and-conditions",
    name: "TCs",
    component: TCs
  },
  {
    path: "/redirect/:client",
    name: "Redirect",
    component: Redirect
  },
  {
    path: "/datenschutzrichtlinie",
    name: "Datenschutzrichtlinie",
    component: Datenschutzrichtlinie
  },
  {
    path: "/nutzungsbedingungen",
    name: "Nutzungsbedingungen",
    component: Nutzungsbedingungen
  },
  {
    path: "/sound-space",
    name: "soundSpace",
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
  },
  {
    path: "/sound-space/:id",
    name: "podcast",
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
  },
  {
    path: "/total-mental-health",
    name: "totalMentalHealth",
    component: LoggedIn
  },
  {
    path: "/total-mental-health/start",
    name: "totalMentalHealthStart",
    component: LoggedIn
  },
  {
    path: "/total-mental-health/select-coach",
    name: "totalMentalHealthSelectCoach",
    component: LoggedIn,
  },
  {
    path: "/total-mental-health/your-impact",
    name: "totalMentalHealthYourImpact",
    component: LoggedIn,
  },
  {
    path: "/booking-video-room/:booking_id/:tests?",
    name: "bookingVideoRoom",
    component: LoggedIn
  },
  {
    path: "/info/:code",
    name: "info",
    component: Info
  },
  {
    path: "/survey",
    name: "survey",
    component: NotLoggedIn
  },
  {
    path: "/clinics/digital/select-clinician",
    name: "digitalClinicsSelectClinician",
    component: LoggedIn,
  },
  {
    path: "/faqs",
    name: "faqs",
    component: LoggedIn
  },
  {
    path: "/wallet",
    name: "wallet",
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
  },
  {
      path: "/coaching-survey",
      name: "coaching-survey",
      component: NotLoggedIn,
  },
  {
    path: "/company-board",
    name: "companyBoard",
    component: LoggedIn,
  },
  {
    path: "/company-board/category/:id",
    name: "companyBoardCategory",
    component: LoggedIn,
  },
  {
    path: "/company-board/:id",
    name: "companyBoardPost",
    component: LoggedIn,
  },
  {
      path: "/womens-day-series",
      name: "womensDaySeries",
      component: LoggedIn,
  },
  {
      path: "/womens-day-series/:id",
      name: "womensDayEvent",
      component: LoggedIn,
      beforeEnter: beforeEnterHandler,
  },
  {
    path: "/gp",
    name: "gp",
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
  },
  {
    path: "/gp/select/:appt_type",
    name: "gpSelect",
    component: LoggedIn,
    beforeEnter: beforeEnterHandler,
  },
   {
       path: "/my-progress",
       name: "myProgress",
       component: LoggedIn
   },
   {
        path: "/my-list",
        name: "myList",
        component: LoggedIn
   },
   {
       path: "/favourites",
       name: "favourites",
       component: LoggedIn
   },
   {
      path: "/oauth/google/redirect",
      name: "OAuthRedirectGoogle",
      component: LoggedIn
   },
   {
      path: "/customise",
      name: "customise",
      component: LoggedIn
    }
];

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

if (store.getters.hardcoded && store.getters.hardcoded.permittedRoutes) {
  router.beforeEach((to, from, next) => {
    if (
      !store.getters.hardcoded.permittedRoutes.includes(to.name)
    ) {
      try {
        next(store.getters.homeRoute);
      } catch (e) {
        console.log(e)
        next('/login')
      }
    }
    next();
  });
}
export default router;
