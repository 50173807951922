<template>
  <div id="DigitalGym">
    <div class="loading" v-if="loading">
      <b-spinner></b-spinner>
    </div>
    <div class="no-series" v-else-if="noSeries">
      <div class="content-container row py-5 mt-5">
        <div class="col-12 text-center">
          <h3 class="m-auto">{{ componentText.noSchedules }}</h3>
        </div>
      </div>
    </div>
    <div class="page-wrap" v-else>
      <section class="spectrum-intro pt-5 bg-grey" v-if="!$store.getters.user.access_code_user && $store.getters.skin === 20">
        <div class="content-container row mt-5">
          <div class="page-title col-md-6">
            <div class="logo-wrap mb-5">
              <img src="/img/wellbeing-studio/digital-gym.svg" v-if="$store.getters.skin !== 20" />
              <h1 class="page-title brand-secondary" v-else><b>Wellbeing Classes</b></h1>
            </div>
            <p v-if="$store.getters.skin !== 20">{{ series.description }}</p>
            <p class="mb-5" v-else>Welcome to your Wellbeing classes where you can find a range of instructor led
              classes to suit you. From meditation, desk yoga to combat fitness, there's something for everyone. You can
              watch these classes live or on catch up at your leisure.</p>
          </div>
          <div class="col-5 offset-1 d-none d-md-block pb-5">
            <img src="/img/wellbeing-studio/dg-image.svg" v-if="$store.getters.skin !== 20" />
            <img class="banner-image img-fluid " src="/img/benenden-health/bh-dg-banner.jpg" v-else />
          </div>
        </div>
      </section>
      <section v-else-if="$store.getters.user.access_code_user || this.isLayaWellbeing()">
        <div class="row bg-grey d-flex flex-row align-items-center">
          <div class="header-image-container col-md-7 col-12 px-0 position-relative d-none d-md-flex">
            <img class="img-fluid" src="/img/digital-gym-banner.jpg" />
            <div class="triangle-bottom-right"></div>
          </div>
          <div class="col-md-5 col-12 px-3">
            <div class="px-5 py-5">
              <h1 class="banner-title">Digital Gym</h1>
              <div class="d-md-none">
                <img class="img-fluid rounded" src="/img/digital-gym-banner.jpg" />
              </div>
              <p class="banner-text">
                Our Digital Gym gives you access to live and on demand fitness classes, with weekly schedule to choose
                from. All classes are delivered by expererts who will demonstrate a safe and maintainable way to tackle
                fitness at home. Our team of welllbeing experts are passionate about health &amp fitness. All of our
                coaches are handpicked and all come from a wide spectrum of expertise, reflecting the diversity of
                different sports and exercises around.
              </p>
            </div>

          </div>
        </div>
      </section>
      <section v-else class="spectrum-intro">
        <div class="content-container bannerContainer row mt-5">
          <div class="bannerHeader">
            <!--          <img src="/img/wellbeing-studio/digital-gym.svg" class="dg-logo" />-->
            <div class="dg-logo">
              <div class="logo-text"><span class="digital">Digital</span> Gym</div>
              <div class="poly"></div>
            </div>
          </div>
        </div>

      </section>
      <section class="bg-white view-buttons-container pt-5">
        <div class="
            col-12
            content-container
            d-flex
            flex-row flex-wrap
            justify-content-center
            pt-5
            text-right
          ">
          <b-button :class="[
            selectedView === 'weekly_timetable'
              ? 'cta-button'
              : 'btn-outline',
          ]" class="view-button shadow px-5 mx-4 my-3 my-md-0" size="lg" @click="selectView('weekly_timetable')">{{
  componentText.weeklyTimetable
}}</b-button>
          <b-button :class="[selectedView === 'classes' ? 'cta-button' : 'btn-outline']"
            class="view-button shadow px-5 mx-4 my-3 my-md-0" size="lg" @click="selectView('classes')">{{
              componentText.classes
            }}</b-button>
          <b-button :class="[
            selectedView === 'training_plans' ? 'cta-button' : 'btn-outline',
          ]" v-if="$store.getters.skin !== 20" class="view-button shadow px-5 mx-4 my-3 my-md-0" size="lg"
            @click="selectView('training_plans')">{{ componentText.trainingPlans }}</b-button>
        </div>
      </section>
      <section class="gym-calendar py-5 px-3" v-if="selectedView === 'weekly_timetable'">
        <div class="content-container row py-5" v-if="series.events.length > 0">
          <EventsCalendar :events="series.events" @date-select="eventNav" class="col-12 px-0" />
          <div class="col-12 btn-wrap pt-5 text-right">
            <b-button v-if="series.other_series.prev" class="btn-outline py-3 px-5 mx-2 my-2 my-md-0"
              @click="getSchedule(series.other_series.prev)">{{ componentText.previous }}</b-button>
            <b-button v-if="series.other_series.next" class="cta-button py-3 px-5 mx-2 my-2 my-md-0"
              @click="getSchedule(series.other_series.next)">{{ componentText.next }}</b-button>
          </div>
        </div>
      </section>
      <section id="classes-view" class="classes-view-container pt-5" v-if="selectedView === 'classes'">
        <ClassesSearch />
      </section>
      <section id="training-plans-view" class="training-plans-view-container" v-if="selectedView === 'training_plans'">
        <TrainingPlans />
      </section>

      <section class="downloadable-guides bg-white pb-5" v-if="
        series.attachments.length > 0 && selectedView !== 'training_plans'
      ">
        <div class="content-container row pb-5" v-if="$store.getters.skin !== 20">
          <div class="section-header col-12">
            <div class="section-header-divider"></div>
            <h3 class="section-header-title">{{ componentText.guides }}</h3>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 col-12" v-for="(guide, i) in series.attachments" :key="i">
            <div class="download-card mx-2" @click="downloadGuide(guide.url)">
              <div class="card-image" ref="image-box" :style="'background-image: url(' + guide.img_url + ')'"></div>
              <div class="card-content px-3 pt-4">
                <h4 class="my-3">{{ guide.title }}</h4>
                <div class="download pb-4">
                  <span>{{ componentText.download }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div v-if="isLayaWellbeing()" id="laya_wellbeing_banner" class="content-container row">
        <div class="col-md-5">
          <div class="banner_text">
            <br />
            <h2 class="mt-5 mb-5">Wellbeing Series</h2>
            <p>
              In the Wellbeing Studio, this year is more exciting and relevant than ever before. Instead of following a
              monthly theme, the series this year will focus on key days in the national and international health and
              wellbeing calendars and social calendars.
            </p>
            <br />
            <a href="/wellbeing-studio" class="btn btn-block cta-button py-3 px-5">Enter Wellbeing Series</a>
          </div>


        </div>
        <div class="col-md-7 img_container">
          <img class="img-fluid" src="https://healthcoach-media.com/res/images/Image-1-min.jpg" />
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import EventsCalendar from "../components/Events/EventsCalendar";
import ClassesSearch from "../components/DigitalGym/ClassesSearch";
import TrainingPlans from "../components/DigitalGym/TrainingPlans";

import { mapGetters } from "vuex";
//TODO:: Consider breaking this into laya / spectrum components
export default {
  name: "DigitalGym",
  components: {
    EventsCalendar,
    ClassesSearch,
    TrainingPlans,
  },
  data() {
    return {
      series: {
        events: [],
        attachments: [],
        other_series: [],
      },
      loading: true,
      noSeries: false,
      selectedView: "weekly_timetable",
    };
  },
  mounted() {
    if (this.$store.getters.skin === 20 && !this.$store.getters.user.user.tc_accepted) {
      this.$router.push("/terms-and-conditions");
    }
    this.setView();
    this.getSchedule();
  },
  methods: {
    setView() {
      if (this.$route.query.selectedView === "'training_plans'") {
        this.selectedView = "training_plans";
      }
      if (this.$route.query.selectedView === "'classes'") {
        this.selectedView = "classes";
      }
    },
    async getSchedule(series = null) {
      const payload = {
        user_id: this.user_id,
        series_id: series,
      };
      let res = await this.axios.post(
        process.env.VUE_APP_API_PATH +
        "/application/api/u/studio/get-gym-series",
        payload
      );
      if (res.data.success) {
        this.series = res.data.series;
        this.loading = false;
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        series ? this.showError() : (this.noSeries = true);
        this.loading = false;
      }
    },
    showError() {
      //TODO:: show error
    },
    eventNav(event) {
      this.$router.push({
        name: "digitalGymArticle",
        params: { id: event.id },
      });
    },
    selectView(view) {
      this.selectedView = view;
    },
    downloadGuide(url) {
      window.open(url, "_blank");
    },
  },
  computed: {
    ...mapGetters(["skin", "user_id"]),
    componentText() {
      return this.getText.digitalGym;
    },
  },
};
</script>

<style lang="less">
#DigitalGym {
  background-color: white;
  .bannerContainer {
    margin-top: 0!important;
    max-width: 1900px !important;
    justify-content: center;
    display: flex;
    .bannerHeader {
      position: relative;
      width: 100%;
      height: 450px;
      background-image: url("https://spectrum-life-media.s3.eu-west-1.amazonaws.com/dfd_services/dg.webp");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      @media only screen and (min-width: 1900px) {
        &:before {
          position: absolute;
          content: '';
          width: 200px;
          height: 100%;
          margin-left: -100px;
          background: linear-gradient(90deg, white 60%, rgba(0, 0, 0, 0) 100%) ;
        }
        &:after {
          position: absolute;
          right: 0;
          content: '';
          width: 200px;
          height: 100%;
          margin-left: -100px;
          background: linear-gradient(270deg, white 60%, rgba(0, 0, 0, 0) 100%) ;
        }
      }
    }
    .dg-logo {
      .logo-text {
        font-size: 4rem;
        color: white;
        .digital {
          font-family: FilsonProBold, Arial, Helvetica, sans-serif;
        }
      }
      .poly {
        margin-left: 20px;
        background: white;
        clip-path: polygon(0 0, 0 99%, 99% 52%);
        width: 30px;
        height: 30px;
      }
      position: absolute;
      width: auto;
      top: 10%;
      left: 10%;
      max-width: 400px;
      display: flex;
      align-items: center;
    }
    @media only screen and (max-width: 767px) {
      .bannerHeader {
        height: 200px;
      }
      .dg-logo {
        left: 10px;
        .logo-text {
          font-size: 3rem;
        }
        .poly {
          width: 20px;
          height: 20px;
          margin-left: 10px;
        }
      }
    }
  }


  .header-image-container {
    height: 350px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    .img-fluid {
      position: relative;
      width: fit-content;
    }
  }

  .triangle-bottom-right {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 100%;
    border-style: solid;
    border-width: 0 0 700px 350px;
    border-color: transparent transparent #f7f7f7 transparent;
  }

  .banner-title {
    font-size: 2rem;
    font-family: 'FilsonProBold', sans-serif;
    font-weight: bold;
    padding-bottom: 20px;
  }

  .banner-text {
    font-size: 1.4rem;
    max-width: 400px;

  }

  .page-title {
    font-size: 4rem;
  }

  .rounded{
    border-radius: 16px;
    padding-bottom: 20px;
  }

  .no-series {
    h3 {
      max-width: 600px;
    }
  }

  .intro-text {
    align-items: center;
    display: flex;

    p {
      font-size: 1.6rem;
    }
  }

  .banner-image {
    border-radius: 16px;
  }

  .gym-calendar {
    background: #fff;

    .events-calendar {
      .section-heading .btn i {
        font-size: 1.6rem;
      }

      .previous-button {
        transform: translate(-40px, 30px);
      }

      .next-button {
        transform: translate(40px, 30px);
        font-size: 1.6rem;
      }
    }
  }

  .spectrum-intro {
    .logo-wrap {
      max-width: 350px;
    }

    p {
      font-size: 1.6rem;
    }
  }

  .view-buttons-container {
    background-color: white;

    .view-button {
      letter-spacing: 1px;
      border-width: 2px !important;
      min-width: 300px;
    }
  }

  .events-calendar {
    background: #e9e9eb;
    border-radius: 15px;
    box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.1);

    .list-event-date {
      font-weight: 300;

      .list-event-day {
        font-size: 1.6rem;
      }

      .list-event-weekday {
        font-size: 5.8rem;
        font-weight: 300;
        font-style: normal;
      }
    }

    .upcoming-events {
      display: flex;
      flex-grow: 1;
      border: 1px solid #c1c1c2;
      border-radius: 15px;

      @media screen and (max-width: 992px) {
        display: block;

        .day-column {
          padding-right: 0;
          border: none;

          &:first-child {
            .list-event-date {
              border-radius: 15px 15px 0 0;
            }

            .event-day-tabs {
              &:last-child {
                border-radius: 0;
              }
            }
          }

          &:last-child {
            .list-event-date {
              border-radius: 0;
            }

            .event-day-tabs {
              &:last-child {
                border-radius: 0 0 15px 15px;
              }
            }
          }
        }
      }
    }

    .day-column {
      padding-right: 1px;
      display: flex;
      border-left: 1px solid #c1c1c2;
      flex-direction: column;
      align-self: stretch;

      &:first-child,
      &:only-child {
        border-left: none;

        .list-event-date {
          border-radius: 15px 0 0 0;
        }

        .event-day-tabs:last-child {
          border-radius: 0 0 0 15px;
        }
      }

      &:last-child {
        padding-right: 0;

        .list-event-date {
          border-radius: 0 15px 0 0;
        }

        .event-day-tabs {
          border-right: none;

          &:last-child {
            border-radius: 0 0 15px 0;
          }
        }

        &:only-child {
          .list-event-date {
            border-radius: 15px 0 0 0;
          }

          .event-day-tabs:last-child {
            border-radius: 0 0 0 15px;
          }
        }
      }

      .event-link a {
        font-weight: 400;
        font-size: 1.6rem;
      }
    }
  }

  .classes-view-container {
    background-color: white;
  }

  .training-plans-view-container {
    background-color: white;
    padding-top: 30px;
  }

  .downloadable-guides {
    .download-card {
      text-align: center;
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      cursor: pointer;

      &:hover {
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
      }

      .card-image {
        width: 100%;
        height: 150px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }

      .card-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        h4 {
          font-size: 2.3rem;
          font-family: FilsonProLight;
        }

        .download {
          margin-top: auto;
          font-size: 1.8rem;
          font-family: FilsonProBold, sans-serif;
        }
      }
    }
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ddd;
    opacity: 1;
    /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #ddd;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #ddd;
  }

  #laya_wellbeing_banner {
    overflow: hidden;
    background-color: #fafafa;
    margin-bottom: 100px;
    border-radius: 25px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);

    .img_container {
      padding: 0px;
      max-height: 400px;

      img {}
    }

    .banner_text {
      padding: 15px 15px;

      p {
        margin-top: 16px;
        font-family: ff-sari-web-regular, sans-serif;
        font-size: 15px;
        margin-bottom: 30px;
      }

      h2 {
        text-align: left;
        font-family: ff-sari-web-regular, sans-serif;
      }
    }
  }


}
</style>
