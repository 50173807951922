<template>
  <div class="events-calendar">
    <div class="section-heading">
      <b-button
        @click="availableEventsPage--"
        v-if="availableEventsPage > 0"
        class="previous-button"
      >
        <i class="fas fa-chevron-left brand-secondary pb-3"></i>
      </b-button>
      <b-button
        @click="availableEventsPage++"
        v-if="availableEventsPage < pageCount - 1"
        class="next-button"
      >
        <i class="fas fa-chevron-right brand-secondary pb-3"></i>
      </b-button>
    </div>
    <div class="upcoming-events">
      <div
        class="day-column col-md-5ths"
        v-for="(day, index) in displayEvents[availableEventsPage]"
        :key="index"
        :data-length="day.events.length"
      >
        <div
          class="list-event-date bg-brand-primary d-flex align-content-center justify-content-center"
          :class="'calendar-day-' + index"
        >
          <div class="d-block calendarHeader">
            <span class="list-event-day">{{
              getFormatedDate(day.date, "ddd")
            }}</span>
            <span class="list-event-weekday">
            {{ getFormatedDate(day.date, "DD") }}
            </span>
            <span class="list-event-month">{{
              getFormatedDate(day.date, "MMM")
            }}</span>
          </div>
        </div>
        <div
          tabindex="0"
          class="event-day-tabs"
          :id="layaId(event.title)"
          v-for="(event, i) in day.events"
          :key="i"
          :class="event.fully_booked ? 'fully-booked': null +' '+ layaClass(event.title) +' dg_calendar'"
          @click="eventNav(event)"
          @keydown.enter="eventNav(event)"
          style="position: relative"
        >
          <i :id="layaId(event.title)" :class="' dg_calendar'" class="fa-solid fa-euro-sign currency-symbol" v-if="(event.e_employee_series_cost || event.e_employee_slot_cost) && event.e_employee_pay_currency === 'eur'"></i>
          <i :id="layaId(event.title)" :class="' dg_calendar'" class="fa-solid fa-pound-sign currency-symbol" v-if="(event.e_employee_series_cost || event.e_employee_slot_cost) && event.e_employee_pay_currency !== 'eur'"></i>
          <div class="event-link" :id="layaId(event.title)" :class="'dg_calendar'">
            <a :id="layaId(event.title)" :class="skin === 22 && event.category ? 'event-text-' + event.category.toLowerCase() : null" class="dg_calendar">{{ event.title }}</a>
            <p :id="layaId(event.title)" :class="'dg_calendar'" class="event_time" v-if="$route.name === 'digitalGym'"><i class="far fa-clock" :id="layaId(event.title)" :class="layaClass(event.title) +' dg_calendar'"></i> {{getFormatedDate(event.start_date_time, "HH:mm")}} - {{getFormatedDate(event.end_date_time, "HH:mm")}}</p>
          </div>
          <div v-if="event.fully_booked" class="full mt-2" :id="layaId(event.title)" :class="'dg_calendar'">
            {{ componentText.full }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "EventsCalendar",
  props: {
    events: Array,
    displayLength: {
      type: Number,
      default: 5
    }
  },
  data() {
    return {
      availableEventsPage: 0
    };
  },
  methods: {
    eventNav(eventDate) {
      this.$emit("date-select", eventDate)
    }
  },
  computed: {
    ...mapGetters(['hardcoded', 'skin']),
    displayEvents() {
      let allEvents = [...this.events];
      let splicedEvents = [];
      for (let i = 0; i < this.pageCount; i++) {
        //split events into display pages
        splicedEvents[i] = allEvents.splice(0, this.displayLength);
      }
      return splicedEvents;
    },
    pageCount() {
      return Math.ceil(this.events.length / this.displayLength);
    },
    componentText() {
      return this.getText.loggedIn.events.availableEvents;
    }
  }
};
</script>

<style scoped lang="less">
.btn {
  display: none;
  background: transparent;
  border-width: 0;
  position: absolute;
  font-size: 2rem;
  &.next-button {
    right: 0;
  }
  &.previous-button {
    left: 0;
  }
  &:hover,
  &:focus,
  &:active {
    background: transparent !important;
  }
  i {
    font-size: 2.2rem;
  }
}
.currency-symbol {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 2rem;
  color: rgb(145, 138, 138);
}
.upcoming-events {
  background-color: rgba(211, 211, 211, 0.19);
  border-radius: 15px;
  .day-column {
    padding-right: 1px;
    &:first-child {
      .list-event-date {
        border-radius: 25px 0 0 0;
      }
    }
    &:nth-child(5) {
      padding-right: 0;
      .list-event-date {
        border-radius: 0 25px 0 0;
      }
    }
    .list-event-date {
      min-height: 80px;
      font-size: 5.6rem;
      text-align: center;
      letter-spacing: 0.5px;
      font-family: "FilsonProLight";
      color: #fff;
      .list-event-day {
        text-transform: uppercase;
        position: relative;
        bottom: 12px;
        left: -5px;
        font-size: 1.6rem;
      }
      .list-event-month {
        display: inline;
        font-size: 1.6rem;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        position: relative;
        right: -3px;
      }
    }
    .event-day-tabs {
      border: 1px solid #d3d3d330;
      min-height: 100px;
      background: white;
      text-align: center;
      margin-bottom: 1px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &.fully-booked {
        background-color: #cecece;
        .full {
          width: 100%;
          font-size: 1.6rem;
          font-family: FilsonProLight, sans-serif;
          color: #444444;
        }
      }
      & > div {
        @media screen and (-ms-high-contrast: active),
          (-ms-high-contrast: none) {
          padding-top: 35px;
        }
      }
      .event-link {
        //IE styles
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          max-width: 100%;
        }
      }
      a {
        font-size: 1.6rem;
        font-family: "FilsonProBook";
        font-weight: 600;
        &:hover {
          text-decoration: none;
        }
      }
      .event-time{
        font-size: 1.5rem;
      }
    }
  }
}
@media only screen and (max-width: 992px) {
  .upcoming-events .day-column:first-child .list-event-date {
    border-radius: 25px 25px 0 0;
  }
}
</style>
