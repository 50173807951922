<template>
  <div id="LayaListPage">
    <div class="content-container" style="margin-top: 32px;">
      <h2>{{pageTitle}}</h2>
      <div class="emptyMessage text-center mt-4" v-if="showEmptyMessage">{{showEmptyMessage}}</div>
      <LayaListCarousel type="be-calm" title="Guided Meditation" :content="beCalm" :description="null" v-if="beCalm.length"/>
      <LayaListCarousel type="gym" title="Digital Gym" :content="digitalGym" :description="null" v-if="digitalGym.length"/>
      <LayaListCarousel type="studio" title="Wellbeing Series" :content="wellbeingSeries" :description="null" v-if="wellbeingSeries.length"/>
    </div>
  </div>
</template>

<script>
import LayaListCarousel from "@/components/NewWellbeing/LayaListCarousel.vue";
import axios from "axios";
import config from "@/config/constants";
import {mapGetters} from "vuex";
import EventBus from "@/helpers/eventbus";

export default {
  name: "LayaListPage",
  components: {LayaListCarousel},
  data() {
    return {
      digitalGym: [],
      wellbeingSeries: [],
      beCalm: [],
      loadedBeCalm: false,
      loadedDs: false
    }
  },
  mounted() {
    this.init();
    EventBus.$on('removeFromMyList',(data)=> {
      if(this.pageType !== 'myList') return;
      this.remove_item(data.product_id,data.content_id);
    })
    EventBus.$on('removeFromMyFavs',(data)=> {
      if(this.pageType !== 'favourites') return;
      this.remove_item(data.product_id,data.content_id);
    })
  },
  computed: {
    ...mapGetters(["user"]),
    showEmptyMessage() {
      if (this.loadedBeCalm && this.loadedDs &&
          (this.beCalm.length === 0 && this.digitalGym.length === 0 && this.wellbeingSeries.length === 0)) {
        switch (this.pageType) {
          case "favourites":
            return "You currently have no items in this list";
          case "myProgress":
            return "You currently have no items in progress";
          case "myList":
            return "You currently have no items in this list";
        }
      }
      return null;
    },
    courseProgress() {
      return this.user.user.course_progress;
    },
    studioEventProgressData() {
      if(!this.courseProgress) return null;
      return  this.courseProgress.filter(item => item.channel === 'dg' || item.channel === 'ds')
    },
    pageType() {
      return this.$route.name
    },
    pageTitle() {
      switch (this.pageType) {
        case "favourites":
          return "Favourites";
        case "myProgress":
          return "My Progress";
        case "myList":
          return "Watch Later"
      }
    }
  },
  methods: {
    init() {
      this.loadedBeCalm = false;
      this.loadedDs = false;
      switch (this.pageType) {
        case "favourites":
          this.getFavourites();
          break;
        case "myProgress":
          this.getMyProgressData();
          break;
        case "myList":
          this.getMyListItems();
          break;
      }
    },
    clearList() {
      this.loadedDs = false;
      this.loadedBeCalm = false;
      this.digitalGym = [];
      this.wellbeingSeries = [];
      this.beCalm = [];
    },
    async getFavourites() {
      const res = await this.api({
        path: 'api/u/favourites'
      });
      if(res.success) {
        this.clearList();
        this.beCalm = res.courses;
        res.studio_series.forEach(series => {
          if(series.product_id === 21) {
            this.wellbeingSeries.push(series)
          } else {
            this.digitalGym.push(series)
          }
        })
        this.loadedBeCalm = true;
        this.loadedDs = true;
      }
    },
    async getMyListItems() {
      let params = { path: "api/u/my-list/items", pageSize: 999 }
      let res = await this.api(params);
      if(res.success) {
        this.clearList();
        res.data.forEach(item => {
          switch (item.product_id) {
            case 17:
              this.beCalm.push(item);
              break;
            case 22:
              this.digitalGym.push(item);
              break;
            case 21:
              this.wellbeingSeries.push(item)
          }
        })
      }
      this.loadedBeCalm = true;
      this.loadedDs = true;
    },
    async getMyProgressData() {
      this.clearList();
      const payload = {
        user_id: this.$store.getters.user_id,
        channel: "be_calm",
        limit: 0,
        override_enrollment_requirement: true
      }
      let res = await axios.post(config.api_env + '/application/api/u/resources/get-be-calm-courses', payload, config.options)
      if(res.data.success){
        res.data.courses.forEach(course => {
          if(course.course_progress > 0 && course.course_progress < 100) {
            this.beCalm.push({
              content_id: course.course_id,
              description: course.course_summary,
              embed_code: null,
              favourited: course.favourited,
              media_type: "video",
              thumbnail: course.course_image_url,
              product_id: 17,
              progress: course.course_progress,
              subtitle: null,
              title: course.course_title
            })
          }
        });
      }
      const ids = this.studioEventProgressData.map(i => i.id)
      const studioRes = await this.api({
        path: "api/u/studio/get-studio-events-by-ids",
        event_ids: ids
      });
      studioRes.events.forEach(evt => {
        let eventData = {
          content_id: evt.id,
          description: evt.description,
          embed_code: null,
          favourited: evt.favourited,
          media_type: "video",
          schedule_type: evt.schedule_type,
          product_id: evt.schedule_type === 'gym' ? 22 : 21,
          subtitle: evt.subtitle,
          title: evt.title,
          thumbnail: evt.thumbnail
        }
        if(evt.schedule_type === 'gym') {
          this.digitalGym.push(eventData)
        } else {
          this.wellbeingSeries.push(eventData)
        }
      })
      this.loadedDs = true;
      this.loadedBeCalm = true;
    },
    remove_item(product_id,content_id) {
      switch (product_id) {
        case 17:
          const bcItems = this.beCalm.filter(item => item.content_id !== content_id);
          this.beCalm = bcItems;
          return;
        case 21:
          const dsItems = this.wellbeingSeries.filter(item => item.content_id !== content_id);
          this.wellbeingSeries = dsItems;
          return;
        case 22:
          const dgItems = this.digitalGym.filter(item => item.content_id !== content_id);
          this.digitalGym = dgItems;
          return;
      }
    },
  },
  watch: {
    '$route.name': {
      handler() {
        this.clearList();
        this.init();
      }
    }
  },
}
</script>

<style lang="less">
#LayaListPage {
  padding-bottom: 34px;
  h2 {
    margin-left: 40px;
  }
  @media screen and (max-width: 992px) {
    h2 {
      margin-left: 16px;
      margin-bottom: 32px !important;
    }
    #LayaListCarousel {
      h3 {
        margin-bottom: 24px !important;
      }
      margin-left: 16px;
    }
  }
}
</style>