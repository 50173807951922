<template>
  <div class="bg-white" id="LayaDFD" ref="top">
   <div class="content-container">
     <div class="dfdHeader" style="padding-left: 40px; padding-right: 40px; margin-top: 48px; margin-bottom: 32px">
       <h2>Welcome, {{firstname}}</h2>
       <p style="padding-bottom: 0 !important;">You can access a range of health and wellbeing benefits. Things like Pilates, counselling, guided meditation, expert speakers - and more.</p>
     </div>

    <LayaServicesDisplay/>
<!--    <LayaCarousel :type="'event-progress'" :title="'In progress'" :description="'Pick up where you left off...'" v-if="videoProgress"/>-->
    <LayaCarousel :type="'be-calm'" :title="'Guided meditation'" :description="beCalmDescription"/>
    <LayaCarousel :type="'gym'" :title="'Digital gym'" :description="digitalGymDescription"/>
    <LayaCarousel :type="'studio'" :title="'Wellbeing series'" :description="studioDescription"/>

    <LayaCarousel :type="'exercise'" :title="'Training plans'" :description="tpDescription"/>

   </div>
  </div>
</template>

<script>

import LayaServicesDisplay from "../../components/NewWellbeing/LayaServicesDisplay.vue";
import LayaCarousel from "../../components/NewWellbeing/LayaCarousel.vue";
import HomeCardCarousel from "../../components/Home/New/HomeCardCarousel.vue";

export default {
  name: "LayaDFD",
  components: {HomeCardCarousel, LayaCarousel, LayaServicesDisplay},
  data() {
    return {
      digitalGymDescription: null,
      studioDescription: null,
      beCalmDescription: null,
      tpDescription: 'Access on-demand training plans to suit a range of fitness levels'
    }
  },
  mounted() {
    this.getDescriptions();
  },
  methods: {
    getDescriptions() {
      this.$store.getters.nav.forEach(item => {
        if(item.item_slug === 'digitalGym') this.digitalGymDescription = item.description;
        if(item.item_slug === 'wellbeingSeries') this.studioDescription = item.description;
        if(item.item_slug === 'beCalm') this.beCalmDescription = item.description;
        if(item.hasOwnProperty('sub_items')) {
          item.sub_items.forEach(sub => {
            if(sub.item_slug === 'digitalGym') this.digitalGymDescription = sub.description;
            if(sub.item_slug === 'wellbeingSeries') this.studioDescription = sub.description;
            if(sub.item_slug === 'beCalm') this.beCalmDescription = sub.description;
          })
        }
      })
    },
  },
  computed: {
    firstname() {
      return this.$store.getters.user.user.first_name;
    },
    videoProgress() {
      const data = this.$store.getters.user.user.course_progress.filter(item => (item.channel === 'dg' || item.channel === 'ds'));
      return !(data.length === 0);
    },
    showDcs() {
      return false;
    },
    componentText() {
      return this.getText.dfd;
    }
  }
}
</script>

<style lang="less">
#LayaDFD {
  height: fit-content;
  margin-bottom: 20px;
  @media only screen and (max-width: 768px) {
    .content-container {
      width: 100% !important;
      max-width: 100% !important;
      margin: 0 !important;
    }
  }
}
</style>