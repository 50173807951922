<template>
  <div class="container reset-password">
    <div class="row page-content">
      <div
        class="col-md-7 img-part px-0"
        :class="{
          'bg-brand-secondary': skin > 0,
          'bg-grad-image-purple': skin === 0,
        }"
      >
        <div class="login-bg d-flex justify-content-center align-items-center">
          <div class="heart-placeholder">
            <img
              v-if="hardcoded.spectrum_heart"
              class="heart-img"
              :src="hardcoded.spectrum_heart"
            />
            <img class="heart-img" src="/img/default-asset.svg" v-else />
          </div>
          <div class="login-image"></div>
        </div>
      </div>
      <div class="col-md-5 form min-height">
        <div v-if="hardcoded.login_logos" class="logos d-flex pt-5">
          <div
            v-for="(logo, i) in hardcoded.login_logos"
            :key="i"
            class="d-flex logo px-1"
          >
            <img :src="logo" />
          </div>
        </div>
        <div class="form-content">
          <div
            :class="[
             ( $store.getters.client.client_logo.toLowerCase().includes('spectrum-life-logo') ||  $store.getters.client.client_logo.toLowerCase().includes('lloydwhyte') ) && skin === 15
                ? 'lw-form-logo'
                : 'form-logo',
            ]"
          >
            <img
              :src="getLogo"
              onerror="javascript:this.src='/img/logo-spectrum-life.svg'"
            />
          </div>
          <h2 class="form-title">
            {{ componentText.title }}
          </h2>
          <b-form-group
            id="input-group"
            :label="componentText.password"
            label-for="newPassword"
          >
            <div class="password-wrap">
              <b-form-input
                :type="passwordFieldType"
                v-model="$v.newPassword.$model"
                :state="$v.newPassword.$dirty ? !$v.newPassword.$error : null"
                id="newPassword"
                @focus="pw_flag = true"
                @blur="pw_flag = false"
                required
              ></b-form-input>
              <div class="eye" @click="switchVisibility">
                <font-awesome-icon
                  v-if="passwordFieldType === 'password'"
                  icon="eye"
                ></font-awesome-icon>
                <font-awesome-icon v-else icon="eye-slash"></font-awesome-icon>
              </div>
            </div>
            <b-form-invalid-feedback id="password-error">
              {{ componentText.passInvalid }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group class="col-md-12 validationBox" v-if="pw_flag">
            <span :class="[tenChars?'passed':'failed']"><i class="fa-light fa-xmark" v-if="!tenChars"></i><i class="fa-light fa-check" v-else></i> At least 10 characters</span><br>
            <span :class="[upperCase?'passed':'failed']"><i class="fa-light fa-xmark" v-if="!upperCase"></i><i class="fa-light fa-check" v-else></i> At least 1 uppercase letter</span><br>
            <span :class="[lowerCase?'passed':'failed']"><i class="fa-light fa-xmark" v-if="!lowerCase"></i><i class="fa-light fa-check" v-else></i> At least 1 lower letter</span><br>
            <span :class="[contNumber?'passed':'failed']"><i class="fa-light fa-xmark" v-if="!contNumber"></i><i class="fa-light fa-check" v-else></i> At least 1 number</span><br>
            <span :class="[specChar?'passed':'failed']"><i class="fa-light fa-xmark" v-if="!specChar"></i><i class="fa-light fa-check" v-else></i> At least 1 special character</span>
          </b-form-group>
          <b-form-group
            id="input-group-repeat"
            :label="componentText.repeat"
            label-for="repeatPassword"
          >
            <div class="password-wrap">
              <b-form-input
                :type="passwordFieldType"
                v-model="$v.repeatPassword.$model"
                :state="
                  $v.repeatPassword.$dirty ? !$v.repeatPassword.$error : null
                "
                id="repeatPassword"
                required
                @keyup.enter.native="submitForm"
              ></b-form-input>
              <div class="eye" @click="switchVisibility">
                <font-awesome-icon
                  v-if="passwordFieldType === 'password'"
                  icon="eye"
                ></font-awesome-icon>
                <font-awesome-icon v-else icon="eye-slash"></font-awesome-icon>
              </div>
            </div>
            <b-form-invalid-feedback id="password-error">
              {{ componentText.passMatch }}
            </b-form-invalid-feedback>
          </b-form-group>
          <div
            col-sm-12
            class="submit-form d-flex align-items-center justify-content-center"
          >
            <b-button
                :disabled="buttonDisabled"
                class="cta-button py-3"
                type="button"
                @click.stop.prevent="submitForm"
                block
              >{{ componentText.buttonText }}</b-button
            >
          </div>
        </div>
      </div>
    </div>
    <popup
      v-if="showPopup"
      :content="popupContent"
      v-on:close="hidePopup"
    ></popup>
  </div>
</template>

<script>
import axios from "axios";
import config from "../../config/constants";
import popup from "../Popup.vue";
import { validationMixin } from "vuelidate";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
export default {
  components: {
    popup,
  },
  name: "resetPassword",
  mixins: [validationMixin],
  data() {
    return {
      newPassword: "",
      repeatPassword: "",
      showPopup: false,
      popupContent: {},
      passwordFieldType: "password",
      tenChars: false,
      upperCase: false,
      lowerCase: false,
      contNumber: false,
      specChar: false,
      pw_flag: false
    };
  },
  validations: {
    newPassword: {
      required,
      minLength: function(value) {
        this.tenChars = value.length > 9;
        return value.length > 9;
      },
      containsUppercase: function(value) {
        this.upperCase = /[A-Z]/.test(value);
        return /[A-Z]/.test(value);
      },
      containsLowercase: function(value) {
        this.lowerCase = /[a-z]/.test(value);
        return /[a-z]/.test(value);
      },
      containsNumber: function(value) {
        this.contNumber = /[0-9]/.test(value);
        return /[0-9]/.test(value);
      },
      containsSpecial: function(value) {
        this.specChar = /[^a-zA-Z0-9\-\/]/.test(value);
        return /[^a-zA-Z0-9\-\/]/.test(value);
      }
    },
    repeatPassword: {
      sameAsPassword: sameAs("newPassword"),
    },
  },
  methods: {
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    submitForm() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      } else {
        this.sendReset();
      }
    },
    async sendReset() {
      let params = this.$route.query;

      const payload = {
        email: params.id,
        auth_key: params.auth_key,
        password: this.newPassword,
        password_repeat: this.repeatPassword,
        lang: this.$store.state.lang,
      };
      const options = {
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=utf-8",
        },
      };
      let res = await axios.post(
        config.api_env + "/application/api-hc/reset-password",
        payload,
        options
      );
      if (res.data.success) {
        this.popupContent = {
          header: this.componentText.successTitle,
          message: this.componentText.success,
        };
        setTimeout(() =>{
          this.$emit("reset-pass");
        }, 3000)

      } else {
        this.popupContent = {
          header: this.componentText.error,
          message: this.passwordErrorText + '<br><br>Current Error:<br>' + res.data.err,
        };
      }
      this.showPopup = true;
    },
    hidePopup(){
      this.showPopup = false;
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
  },
  computed: {
    ...mapGetters(["hardcoded", "skin"]),
    buttonDisabled() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return true;
      }
      return false;
    },
    getLogo() {
      if (this.hardcoded && this.hardcoded.logo) {
        return this.hardcoded.logo;
      } else return this.$store.getters.client.client_logo;
    },
    componentText() {
      return this.getText.notLoggedIn.resetPassword;
    },
    passwordErrorText(){
      return this.getText.notLoggedIn.signUp.errorMessage
    }
  },
};
</script>
<style lang="less">
.reset-password {
  .img-part {
    background-image: none !important;
  }
}
</style>
