<template>
  <div id="LayaSettings">
    <div class="page-header bg-brand-secondary">
      <div class="content-container row">
        <div class="page-title col-12">
          <h2>{{componentText.title}}</h2>
        </div>
      </div>
    </div>
    <div class="bg-white mb-5">
      <div class="content-container row py-5">
        
        <div class="col-12 locations" v-if="$store.getters.user.user_client_id !== 5191">
          <p>{{componentText.location}}</p>
          <b-form-select v-model="selectedLocation" class="select-location">
            <option v-for="location in locations.locations" :value="location.location_id" :class="{selected: locations.current_loc == location.location_id}">{{location.location_label}}</option>
          </b-form-select>
          <b-button  @click="setLocation" class="layaBtn my-4">{{componentText.save}}</b-button>
        </div>

        <ClientMemberLevelDropdown v-if="clientMemberLevel !== null && locations.client_id"
          :client-id="locations.client_id"          
          :client-member-level="clientMemberLevel"
        ></ClientMemberLevelDropdown>

        <div class="col-12 py-5 my-5">
          <p>{{componentText.password}}</p>
          <b-button  @click="openPopup" class="layaBtn my-4">{{componentText.reset}}</b-button>
        </div>
        <div class="col-12 mb-5" v-if="env == 'development' || env == 'staging'">
            <p>Click on the button below to reload top navigation</p>
            <b-button @click="reloadNavigation" class="layaBtn my-4">Reload Navigation</b-button>
        </div>
        <div class="col-12 locations" v-if="env !== 'production'">
          <p>Dev Only - Choose Theme</p>
          <b-form-select v-model="skin" class="select-location">
            <option  :value="0" >0 / base</option>
            <option  :value="1" >1 / blue</option>
            <option  :value="2" >2 / green</option>
            <option  :value="3" >3 / gold</option>
            <option  :value="4" >4 / red</option>
            <option  :value="5" >5 / grey</option>
            <option  :value="6" >6 / purple</option>
            <option  :value="7" >7 / Vodafone</option>
            <option  :value="8" >8 / BOI</option>
            <option  :value="9" >9 / DAA</option>
            <option  :value="10" >10 / Laya EAP</option>
            <option  :value="11" >11 / Incorporate Benefits</option>
            <option  :value="12" >12 / Run in the dark</option>
            <option  :value="13" >13 / SanusX</option>
            <option  :value="14" >14 / Microsoft</option>
            <option  :value="15" >15 / L&W</option>
            <option  :value="16" >16 / BOI no logos</option>
            <option  :value="17" >17 / Ornua</option>
            <option :value="18">18 / Simply Health</option>
            <option :value="19">19 / PWC</option>
            <option :value="20">20 / Benedend Health</option>
            <option :value="21">21 / Irish Distillers</option>
            <option :value="22">22 / Pfizer</option>
            <option :value="23">23 / Radox</option>
            <option :value="24">24 / EY</option>
            <option :value="25">25 / L&G</option>
            <option :value="26">26 / WPA</option>
            <option :value="27">27 / HCML</option>
            <option :value="28">28 / EY</option>
            <option :value="29">29 / Bupa</option>
            <option :value="30">30 / Hospitality Action</option>
          </b-form-select>
        </div>
      </div>
    </div>
    <div class="pop-up" v-if="showPopup">
      <div class="pop-up-background" @click="hidePopup"></div>
      <div class="pop-up-content bg-brand-secondary row">
        <div class="col-12">
          <h4>{{componentText.reset}}</h4>
        </div>
        <div class="col-12 current-form" v-if="!showResetForm">
          <b-form-group
                  id="input-group"
                  :label="componentText.currentPass"
                  label-for="currentPassword"
          >
            <b-form-input
                    type="password"
                    v-model="currentPassword"
                    id="currentPassword"
                    required
                    @keyup.enter.native ="checkCurrentPassword(currentPassword)"
            ></b-form-input>
            <p v-if="currentFailed">{{componentText.passFail}}</p>
          </b-form-group>
            <b-button @click="checkCurrentPassword(currentPassword)" class="layaBtn mt-5">{{componentText.next}}</b-button>
        </div>
          <div class="col-12 reset-form" v-if="showResetForm && !passwordChanged">
              <b-form-group
                  id="input-group-1"
                  :label="componentText.newPass"
                  label-for="newPassword"
              >
                  <b-form-input
                          type="password"
                          v-model="$v.newPassword.$model"
                          :state="$v.newPassword.$dirty ? !$v.newPassword.$error : null"
                          id="newPassword"
                          required
                  ></b-form-input>
                <b-form-invalid-feedback id="password-error" v-html="componentText.passLength">

                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                      id="input-group-2"
                      :label="componentText.repeat"
                      label-for="repeatPassword"
              >
                  <b-form-input
                          type="password"
                          v-model="$v.repeatPassword.$model"
                          :state="$v.repeatPassword.$dirty ? !$v.repeatPassword.$error : null"
                          id="repeatPassword"
                          required
                          @keyup.enter.native ="submitForm()"
                  ></b-form-input>
                <b-form-invalid-feedback id="password-error">
                  {{componentText.noMatch}}
                </b-form-invalid-feedback>
              </b-form-group>
              <p v-if="passwordMatch">{{componentText.passMatch}}</p>
              <p v-if="errorMessage">Something went wrong: {{errorMessageText}}</p>
              <b-button @click="submitForm()" class="layaBtn mt-5">{{componentText.save}}</b-button>
          </div>
          <div class="col-12" v-if="passwordChanged">
              <p>{{componentText.success}}</p>
          </div>
        <b-button class="close-popup" @click="hidePopup"><font-awesome-icon icon="times-circle"></font-awesome-icon></b-button>
      </div>
    </div>
    <Popup v-if="showLocationPopup" :content="locationPopupContent" v-on:close="hideLocationPopup"></Popup>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import config from "@/config/constants";
import {validationMixin} from "vuelidate";
import { required, minLength, sameAs } from "vuelidate/lib/validators";

export default {
  name: "LayaSettings",
  components: {
    UserBasicInformation: () =>
      import("@/components/Settings/UserBasicInformation.vue"),
    UserLocation: () => import("@/components/Settings/UserLocation.vue"),
    UserSecuritySettings: () =>
      import("@/components/Settings/UserSecuritySettings.vue"),
    UserControlPreferences: () =>
      import("@/components/Settings/UserControlPreferences.vue"),
    ClientMemberLevelDropdown: () => import("@/components/Settings/ClientMemberLevelDropdown.vue")
  },
  mixins: [validationMixin],
  data() {
    return {
      selectedLocation: '',
      locations:{},
      locationPopupContent : {
          header : '',
          message : ''
      },
      showLocationPopup: false,
      showPopup: false,
      currentPassword: '',
      newPassword: '',
      repeatPassword: '',
      currentFailed: false,
      showResetForm: false,
      passwordMatch: false,
      errorMessage: false,
      errorMessageText: '',
      passwordChanged: false,
      authKey: '',
      env: process.env.NODE_ENV,
      skin: this.$store.getters.skin,
      clientMemberLevel: null,
      achievements: [
        { name: "perfect week" },
        { name: "step it up" },
        { name: "starting out" },
      ],
      page_component_id: 0,
      page_components: [
        {
          id: 0,
          menu_item_name: "My Information",
          component_name: "UserBasicInformation",
          active: true,
        },
        {
          id: 1,
          menu_item_name: "My Location",
          component_name: "UserLocation",
          active: false,
        },
        {
          id: 2,
          menu_item_name: "My Control Preferences",
          component_name: "UserControlPreferences",
          active: false,
        },
        {
          id: 3,
          menu_item_name: "My Security Settings",
          component_name: "UserSecuritySettings",
          active: false,
        }
      ],
      autocomplete: "",
      address1Field: "",
      address2Field: "",
      postalField: "",
    };
  },
  mounted() {
    this.getUserDetails();
    this.getLocations();
  },
  methods: {
    async reloadNavigation() {
        await this.$store.dispatch("getNav", this.$store.getters.user_id);
    },
    selectSideMenuItem(id) {
      this.page_components[this.page_component_id].active = false;
      this.page_component_id = id;
      this.page_components[this.page_component_id].active = true;
    },
    async getUserDetails() {
      const payload = {
        user_id: this.$store.getters.user_id,
      };
      let res = await axios
        .post(
          config.api_env + "/application/api/u/user/get-user-profile",
          payload,
          config.options
        )
        .then(
          (response) => {
            this.$store.dispatch("setUserProfile", response.data.user_profile);
            this.$store.dispatch("setUserSettings",response.data.user_settings);
            this.clientMemberLevel = response.data.user_profile.client_member_level;
          },
          (error) => {
            console.log(error);
          }
        );
    },
    async getLocations(){
      const payload = {
        user_id: this.$store.getters.user_id
      }
      let res = await axios.post(config.api_env + '/application/api-hc/get-locations', payload, config.options)
      if(res.data.success) {
          this.locations = res.data
      }else if(res.data.err && res.data.err.includes('3994')){
          this.$store.dispatch('logout')
          this.$router.push("/login");
      }
      this.selectedLocation = this.locations.current_loc
    },
    async setLocation(){
        const payload = {
          user_id: this.$store.getters.user_id,
          location_id: this.selectedLocation
        }
        let res = await axios.post(config.api_env + '/application/api-hc/set-location', payload, config.options)
        if(res.data.success){
            this.locationPopupContent.header = this.componentText.locationSuccess
            this.locationPopupContent.message = this.componentText.locationSuccessMessage
            this.showLocationPopup = true
        }
    },
    openPopup(){
        return this.showPopup = true
    },
    hidePopup(){
      this.newPassword = '';
      this.repeatPassword = '';

      this.passwordChanged = false;
      return this.showPopup = false
    },
    validateState(ref) {
        if (
            this.veeFields[ref] &&
            (this.veeFields[ref].dirty || this.veeFields[ref].validated)
        ) {
            return !this.veeErrors.has(ref);
        }
        return null;
    },
    submitForm() {
        this.$v.$touch();
        if (this.$v.$anyError) {
            return;
        }else {
            this.resetPassword();
        }
    },
    async checkCurrentPassword(current){
        const payload = {
            user_id: this.$store.getters.user_id,
            password: current
        }
        let res = await axios.post(config.api_env + '/application/api-hc/check-password', payload, config.options)
        if(res.data.success && res.data.valid){
            this.showResetForm = true
            this.authKey = res.data.auth_key
        }else if (!res.data.valid){
            this.currentFailed = true
        }
    },
    async resetPassword(){
        const payload = {
            email: this.$store.getters.user.username,
            auth_key: this.authKey,
            password: this.newPassword,
            password_repeat: this.repeatPassword
        }
        let res = await axios.post(config.api_env + '/application/api-hc/reset-password', payload, config.options)
        if(res.data.success && res.data.password_changed){
            this.passwordChanged = true
        }else{
            if(res.data.hasOwnProperty('err')) {
              this.errorMessageText = res.data.err
            } else {
              this.errorMessageText = 'Something went wrong!  Please try again.'
            }
            this.errorMessage = true
        }
    },
    hideLocationPopup(){
        this.showLocationPopup = false
    }
  },
  computed: {
    ...mapState(["user", "client"]),
    privacy(){
        if(this.$store.getters.skin === 8){
            return "/img/boi/BOI-Wellbeing-Privacy-Policy.pdf"
        }else{
            return "/privacy-policy"
        }
    },
    terms(){
        if(this.$store.getters.skin === 8){
            return "/img/boi/BOI-Wellbeing-TCs.pdf"
        }else{
            return "/terms-and-conditions"
        }
    },
    componentText() {
      return this.getText.loggedIn.settings;
    },
  },
  watch: {
    skin(newVal) {
      this.$store.commit("setSkin", newVal);
    },
  },
  validations: {
    newPassword: {
      required,
      minLength: minLength(10),
      containsUppercase: function(value) {
        return /[A-Z]/.test(value)
      },
      containsLowercase: function(value) {
        return /[a-z]/.test(value)
      },
      containsNumber: function(value) {
        return /[0-9]/.test(value)
      },
      containsSpecial: function(value) {
        return /[^a-zA-Z0-9\-\/]/.test(value)
      }
    },
    repeatPassword: {
      sameAsPassword: sameAs("newPassword"),
    },
  },
};
</script>
<style lang="less">
#LayaSettings {
  font-family: Open Sans, sans-serif;
  select {
    border-radius: 10px !important;
  }
  font-size: 1.6rem;
  .page-header {
    padding: 30px 0 15px 0;
  }
  .page-title {
    text-align: left;
    h2 {
      text-align: left;
      font-size: 3.8rem;
      font-family: "Cocon Pro Regular", sans-serif;
    }
  }
  .locations{
    font-size: 1.6rem;
    .select-location{
      font-size: 1.6rem;
      height: 40px;
    }
  }
  .pop-up{
    display: flex;
    align-items: center;
    z-index: 500;
    position:fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;
    .pop-up-content{
      max-width: 636px;
      width: 100%;
      margin: auto;
      border-radius: 25px;
      padding: 50px 20px;
      font-size: 1.6rem;
      text-align: center;
      z-index: 10;
      position: relative;
      p{

      }
      h4{
        font-size: 3.8rem;

      }
      .form-control{
        height: 30px;
        border-radius: 5px;
        font-size: 1.6rem;
      }
      .service-description{
        text-align: left;
      }
      .close-popup{
        position: absolute;
        top: 3px;
        right: 3px;
        padding: 0 !important;
        background-color: transparent;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
          color: rgb(218, 25, 132) !important;
          &:hover {
            color: #b1045c !important;
          }
          width: 30px;
          height: 30px;
        }
      }
    }
    .pop-up-background{
      width: 100vw;
      height: 100vh;
      position: absolute;
      left: 0;
      right: 0;
      z-index: 5;
      background: transparent;
    }
  }
}
#NewSettings {
  font-size: 1.6rem;
  .page-header {
    padding: 60px 0;
    background-color: #e6e6e6;
  }
  ul {
    list-style-type: none;
    font-size: 18px;
    margin: 5px;
    padding: 0;
  }
  .card {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #e83b75;
    border-color: #ffffff;
  }
  .list-group {
    border-color: unset;
  }
  .list-group-item {
    cursor: pointer;
    height: 64px;
    border-color: #e6e6e6;
    padding: 2rem;
  }
  .list-group-item:nth-child(1) {
    height: 135px;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 25px;
    text-align: center;
    color: #36264d;
  }
  .list-group-item.active {
    background-color: #ffffff;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    margin-left: 11px;
    color: #36264d;
  }
  .list-group-item.active.slide-right {
    animation: 1s slide-right;
  }
  @keyframes slide-right {
    from {
      margin-left: 0;
    }
    to {
      margin-left: 11px;
    }
  }

  .page-title {
    text-align: left;
    h2 {
      text-align: left;
      font-size: 3.8rem;
      letter-spacing: 0.7rem;
      color: #fff;
    }
  }
  .locations {
    .select-location {
      font-size: 1.6rem;
      height: 40px;
    }
  }
  .pop-up {
    display: flex;
    align-items: center;
    z-index: 500;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;
    .pop-up-content {
      max-width: 636px;
      width: 100%;
      margin: auto;
      border-radius: 25px;
      padding: 50px 20px;
      font-size: 1.6rem;
      text-align: center;
      z-index: 10;
      color: #fff;
      position: relative;
      p {
        color: #fff;
      }
      h4 {
        font-size: 3.8rem;
        color: #fff;
      }
      .form-control {
        height: 30px;
        border-radius: 5px;
        font-size: 1.6rem;
      }
      .service-description {
        text-align: left;
      }
      .close-popup {
        position: absolute;
        top: 0;
        right: 0;
        background-color: transparent;
        width: 50px;
        height: 50px;
        svg {
          width: 30px;
          height: 30px;
        }
      }
    }
    .pop-up-background {
      width: 100vw;
      height: 100vh;
      position: absolute;
      left: 0;
      right: 0;
      z-index: 5;
      background: transparent;
    }
  }
  .header-inner-banner {
    position: relative;
    min-height: 250px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;

    h3 {
      padding: 0;
      color: #394961;
      letter-spacing: 0;
    }
  }
  .outer-achievements {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    overflow: hidden;
    color: #36264d;
    font-weight: bold;
    text-transform: capitalize;
  }
  .setting-header {
    min-height: 300px;
    position: relative;
    background: url("../../../public/img/settings/profile_banner.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }
  @media only screen and (max-width: 980px) {
    .setting-header {
      margin-top: -10px;
      min-height: 200px;
      width: 100%;
      position: relative;
      background: url("../../../public/img/settings/profile_banner.jpg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    .list-group-item.active {
      background-color: #ffffff;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      margin-left: 11px;
      color: #36264d;
    }
  }

  /*.achievements {
        margin: auto;
        position: absolute;
        top: -100px;
        bottom: 0;
        right: 0;
        left: 0;
        width: 90%;
        height: 100px;
        background-color: #e6e6e6;
        transform: skewY(10deg);
        transform-origin: center;
    }*/
  .user-name {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 36px;
    color: #36264d;
  }
  .personal-info {
    font-style: normal;
    text-transform: lowercase;
  }
  .personal-info-title {
    font-weight: bold;
    font-style: normal;
  }

}
</style>