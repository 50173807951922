<template>
  <div id="home-happening" class=" page-bg bg-white px-md-5" v-if="orgPages.length > 0">
  <div class="content-container row">
    <div class="section-header">
      <div class="section-header-divider"></div>
      <h3 class="section-header-title">{{componentText.title}}</h3>
    </div>
    <h4 class="col-12 pl-md-0">{{componentText.message}}</h4>
    <div
            v-for="(page, i) in orgPages"
            v-if="i<4"
            :key="i"
            class=" col-md-6 mx-0 row  mb-3 pl-md-0"
            @click="viewPage( i)"
    >
      <div class="page-event brand-secondary">
        <div class="col-3 icon">
          <img :src="getIcon(page.category_id)">
        </div>
        <div class="col-1">
          <div class="marker"></div>
          <div class="page-line"></div>
        </div>
        <div class="col-8 page-title">
          {{ page.title }}
        </div>
      </div>
    </div>
    <div class="col-12 mt-5 pl-0 pb-5">
      <a class="home-link link-highlight" :class="{'border-brand-secondary': $store.getters.skin === 8 }" @click="exploreAll">{{componentText.seeAll}}</a>
    </div>
  </div>
  </div>
</template>
<script>
// @ is an alias to /src
import axios from "axios";
import config from "../../config/constants";
export default {
  name: "HomeHappening",
  components: {},
  data() {
    return {
        orgPages:[],
        icons:[
          { id: 9, path:'icon_general-grey.svg'},
          { id: 1, path:'icon_fitness-grey.svg'},
          { id: 2, path:'icon_nutrition-grey.svg'},
          { id: 8, path:'icon_wellbeing-grey.svg'},
      ],
    };
  },
  mounted() {
      this.getOrgPages()
  },
  methods: {
      async getOrgPages() {
          const payload = {
              user_id: this.$store.getters.user_id,
              draft: 0
          };
          let res = await axios.post(
              config.api_env + "/application/api-hc/get-company-pages",
              payload,
              config.options
          );
          if (res.data.success) {
              this.orgPages = res.data.pages;
          }
      },
      getIcon(icon){
          var matched = null;
          var output = '';

          this.icons.map(function (i) {
              if (i.id === icon ) {
                  output =  i.path;
                  matched = true;
              }
          });

          if (!matched){
              output = this.icons[0].path;
          }
          return '/img/my-company/' + output;
      },
      viewPage(i){
          this.$router.push('/whats-happening?page=' + i).catch(err => {})
      },
      exploreAll(){
          this.$router.push('/whats-happening').catch(err => {})
      }
  },
  computed:{
    componentText() {
      return this.getText.loggedIn.home.homeHappening;
    },
  }
};
</script>
<style lang="less">
  .page-results-title {
    margin: 0 0 24px 4px;
    font-family: "FilsonProLight", sans-serif;
    font-size: 19px;
    line-height: 1;
    color: #394961;
    opacity: 0.6;
  }
  .page-results-wrap {
    position: relative;
  }
  .see-all-button {
    background-color: transparent;
    border-color: #e83b75;
    color: #e83b75;
  }
  .page-event {
    border-style: solid;
    border-width: 0 0 0 10px;
    border-radius:  0 10px 10px 0;
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.3);
    width: 100%;
    min-height: 75px;
    font-family: "FilsonProBold", sans-serif;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover{
      box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.3);
      transform-style: flat;
      transform: translateY(-2px);
    }
    &.active {
      box-shadow: none;
      background-color: #f6f6f6;
    }
    &:last-child{
      margin-bottom: 0;
    }
    .start-date {
      text-align: center;
      .start-day {
        font-size: 2.6rem;
        line-height: 1;
      }
      .start-month {
        font-size: 2rem;
        line-height: 1;
        font-family: "filsonProBook", sans-serif;
      }
    }
    .page-title {
      font-size: 1.8rem;
      &::first-letter{
        text-transform: uppercase;
      }
    }
    .marker {
      content: "";
      position: absolute;
      width: 12px;
      height: 12px;
      border: 1px solid #7e4578;
      border-radius: 50%;
      background-color: white;
      transform: translateY(-50%);
      z-index: 1;
      right: 30px;
    }
  }
  .page-line {
    width: 1px;
    position: absolute;
    height: 60px;
    top: -30px;
    right: 36px;
    background-color: #929497;
  }
  .icon{
    text-align: center;
    img{
      max-width: 60px;
      max-height: 60px;
    }
  }
</style>
