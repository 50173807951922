<template>
  <div id="home-wellbeing" class="page-bg px-md-5 " :class="{'bg-transparent': $store.getters.skin === 0, }" >
    <div class="content-container pt-5">
      <link rel="stylesheet"
            href="https://use.fontawesome.com/releases/v5.2.0/css/all.css"
            integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ"
            crossorigin="anonymous">
      <slick ref="slick" :options="slickOptions" class="pt-5">
        <a v-for="course in courses" @click="selectCourse(course.course_id)">
          <div class="slide-inner bg-brand-secondary">
            <div
              v-if="course.course_recommend === 1"
              class="recommended-course"
            ></div>
            <div class="course-image">
              <img :src="course.course_image_url" />
            </div>
            <div class="slide-content">
              <div v-if="course.course_progress > 0" class="course-progress">
                <div class="progress-bar">
                  <div
                    class="progress"
                    :style="'width:' + course.course_progress + '%;'"
                  ></div>
                  <div
                    class="progress-info"
                    :style="'left:' + course.course_progress + '%;'"
                  >
                    {{ course.course_progress }}%
                  </div>
                </div>
              </div>
              <div class="explore">
                {{componentText.exploreTitle}}
                <h3 class="course-title">{{ course.course_title }}</h3>
              </div>
            </div>
            <div class="recommended-badge" v-if="course.course_recommend === '1'"></div>
          </div>
        </a>
      </slick>
      <a class="home-link link-white" :class="{'border-brand-secondary': $store.getters.skin === 8 }" @click="exploreAll">{{componentText.explore}}</a>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import axios from "axios";
import config from "../../config/constants";
import Slick from "vue-slick";
export default {
  name: "HomeWellbeing",
  components: {
    Slick
  },
  data() {
    return {
      courses: {},
      slickOptions: {
        infinite: true,
        autoplay: false,
        dots: false,
          nextArrow:
              '<button class="slick-next  py-1" type="button" style=""><i class="fas fa-chevron-right"></i></button>',
          prevArrow:
              '<button class="slick-prev  py-1" type="button" style=""><i class="fas fa-chevron-left"></button>',
        speed: 300,
          initialSlide: 1,
        slidesToScroll: 0,
        slidesToShow: 3,
        centerMode: true,
        centerPadding: "0",
        variableWidth: false,
        adaptiveHeight: true,
          responsive:[
              {
                  breakpoint: 768,
                  settings:{
                      slidesToShow: 1,
                      slidesToScroll: 0,
                  }
              }
          ]
      }
    };
  },
  mounted() {
    this.getWellbeingCourses();
  },
  methods: {
    async getWellbeingCourses() {
      const payload = {
        user_id: this.$store.getters.user_id,
        limit: 10,
        channel: "surge"
      };
      let res = await axios.post(
        config.api_env + "/application/api-hc/list-surge-courses",
        payload,
          config.options
      );
      this.courses = res.data.courses;
      //destroy and recreate slick carousel
      if (this.$refs.slick) {
        this.$refs.slick.destroy();
      }
      this.$nextTick(function() {
        if (this.$refs.slick) {
          this.$refs.slick.create(this.slickOptions);
        }
      });
    },
    selectCourse(course_id) {
      this.$router.push("/wellbeing/" + course_id).catch(err => {});
    },
    exploreAll() {
      this.$router.push("/wellbeing").catch(err => {});
    }
  },
  computed: {
    componentText() {
      return this.getText.loggedIn.home.homeWellbeing;
    },
  }
};
</script>
<style lang="less">

#home-wellbeing {
  padding-bottom: 50px;
}
.slick-slide {
  transform: scale(0.8);
  a:hover {
    text-decoration: none;
  }
  .slide-inner {
    position: relative;
    height: 420px;
    margin: 0 10px;
    padding: 30px 20px;
    display: block;
    &:hover {
      text-decoration: none;
    }
    position: relative;
    .course-image {
      .slide-overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(28, 14, 42, 0.71);
      }
      margin: auto;
      padding-bottom: 20px;
      img {
        width: 85%;
        height: 85%;
        margin: auto;
      }
    }
    .slide-content {
      position: absolute;
      width: 100%;
      padding: 0 36px 18px 36px;
      bottom: 20%;
      left: 0;
      transform: translateY(50%);
      transition: 300ms;
      .course-progress {
        display: none;
      }
      .explore {
        font-size: 1.6rem;
        color: #fff;
        font-family: "FilsonProLight", sans-serif;
        text-transform: uppercase;
        h3 {
          padding: 0;
          font-size: 2.2rem;
          letter-spacing: 0;
          color: #fff;
          text-transform: unset;
          font-family: "FilsonProBold", sans-serif;
        }
      }
    }
    .recommended-badge{
      position: absolute;
      top: 3px;
      left: 20px;
      width: 53px;
      height: 91px;
      background-position: center;
      background-size: cover;
      z-index: 1;
    }
  }
  &.slick-center {
    transform: scale(1);
    .slide-inner {
      .course-image {
        .slide-overlay {
          background-color: rgba(28, 14, 42, 0.4);
        }
      }
      .slide-content {
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        align-items: center;
        text-align: center;
        .explore {
          font-size: 1.8rem;
          text-align: center;
          h3 {
            font-family: "FilsonProBook", sans-serif;
          }
        }
        .course-progress {
          display: block;

          .progress-bar {
            height: 5px;
            background-color: #fff;
            width: 100%;
            border-radius: 10px;
            position: relative;
            .progress {
              position: absolute;
              height: 10px;
              top: 50%;
              transform: translateY(-50%);
              border-radius: 3px;
              white-space: nowrap;
            }
            .progress-info {
              position: absolute;
              min-width: 50px;
              height: 30px;
              padding-left: 6px;
              padding-right: 6px;
              top: -45px;
              transform: translateX(-50%);
              font-size: 16px;
              line-height: 34px;
              text-align: center;
              border-radius: 5px;
              font-family: "FilsonProBold", sans-serif;
              display: flex;
              align-items: center;
              justify-content: center;
              &:before {
                position: absolute;
                content: "";
                left: 50%;
                bottom: -5px;
                transform: translateX(-50%);
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
              }
            }
          }
        }
      }
    }
  }
}
.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background-color: transparent;
  border: none;
  font-size: 3rem;
  font-family: "FilsonProBold", sans-serif;
  &:focus {
    outline: none;
  }
  &.slick-next {
    right: 0;
  }
}
</style>
