<template>
    <div class="HomeVideoCard">
        <div class="video-card-video-container shadow position-relative" @click="goToVideo()">
            <div class="w-100 h-100 clickable d-flex justify-content-center" @mouseover="playVideo()"
                @mouseleave="resetVideo()" @click="">
                <img class="img-fluid image-class h-100" :src="video.thumbnail" v-if="video.thumbnail" />
                <img class="img-fluid image-class-2 h-100 px-5"
                    :src="type === 'Digital Gym' ? '/img/wellbeing-studio/digital-gym.svg' : '/img/wellbeing-studio/wellbeing-studio.svg'"
                    v-else />
                <video class="img-fluid video-class" :src="video.details.video_gif" ref="video" loop nocontrols ></video>
            </div>
            <div class="video-card-featured bg-brand-highlight-1 animate__animated" v-if="false">Featured</div>
            <div class="video-card-live animate__animated d-flex justify-content-between align-items-center" v-if="status === 'live'">
              <svg height="12px" width="12px" class="blinking mr-2 ml-2">
                <circle cx="5" cy="5" r="5" fill="white" />
              </svg>
              <div>LIVE</div>
            </div>
            <div class="video-card-duration" v-if="runningTime && !videoIsPlaying">
              {{runningTime}}
            </div>
            <div v-if="video.type === 'digital_gym'">
              <div  class="video-card-favourite" v-if="!video.favourited"><i @click="toggleDGFavourite" class="fa-regular fa-heart fa-2x"></i></div>
              <div  class="video-card-favourite" v-else><i @click="toggleDGFavourite" class="fa-solid fa-heart fa-2x"></i></div>
            </div>
            <div class="video-card-play-button w-100 text-center animate__animated"><i
                    class="fas fa-play-circle fa-6x text-white"></i>
            </div>
        </div>
        <div class="video-card-meta-container">
            <div class="video-card-series"></div>
            <div class="video-card-sub-title" v-if="video.subtitle">{{video.subtitle}}</div>
            <div class="d-flex flex-row flex-nowrap justify-content-between align-items-center">

                <div class="video-card-title brand-text clickable">{{ video.title }}</div>
                <div class="video-card-tags">
                    <div class="video-card-tag" v-for="(tag, i) in video.tags" :key="i" v-if="tag !== type">#{{ tag }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";

export default {
    name: "VideoCard",
    props: ['video', 'type','swiping'],
    data() {
        return {
          loading: false,
          runningTime: null,
          videoIsPlaying: false,
        };
    },
    mounted() {
      if(this.video.details.start_date_time && this.video.details.start_date_time) this.setRunningTime();
    },
    computed: {
      status() {
        const start = moment(this.video.start);
        const end = moment(this.video.end);
        const now = moment();
        if(now.isBefore(start) || !this.video.start || !this.video.end) return 'not_started';
        if(now.isAfter(end)) return 'ended';
        return 'live';
      },
    },
    methods: {
      setRunningTime() {
        const duration = moment.duration(moment(this.video.details.end_date_time).diff(moment(this.video.details.start_date_time)));
        const hours = parseInt(duration.asHours());
        const minutes = parseInt(duration.asMinutes()) % 60;
        const seconds = parseInt(duration.asSeconds()) % 60;
        let string = '';
        if(hours) {
          string += this.parseUnitOfTime(hours) + ':';
        }
        string += this.parseUnitOfTime(minutes) + ':';
        string += this.parseUnitOfTime(seconds);
        this.runningTime = string;
      },
      parseUnitOfTime(unit) {
        if(unit < 10) {
          return '0' + unit;
        } else {
          return unit;
        }
      },
      async toggleDGFavourite() {
        this.loading = true;
        const payload = {
          user_id: this.$store.getters.user_id,
          event_id: this.video.id,
        };
        let res = await this.axios.post(
            process.env.VUE_APP_API_PATH +
            "/application/api/u/studio/toggle-favourite-gym-event",
            payload
        );
        if (res.data.success) {
          this.video.favourited = res.data.is_favourite;
        }
        this.loading = false;
      },
      goToVideo() {
            if(this.loading || this.swiping) return;
            if (this.video.type === 'digital_gym') this.$router.push('/digital-gym/' + this.video.id)
            if (this.video.type === 'wellbeingstudio') this.$router.push('/wellbeing-studio/' + this.video.id)
        },
      playVideo() {
            this.$refs.video.play();
            this.videoIsPlaying = true;
        },
      resetVideo() {
            this.$refs.video.pause();
            this.$refs.iframe.currentTime = 0;
            this.videoIsPlaying = false;
      }
    }
};
</script>

<style lang="less">
@keyframes fadeIn {
    from {
        display: none;
    }

    to {
        display: block;
    }
}

@keyframes fadeOut {
    from {
        display: block;
    }

    to {
        display: none;
    }
}

.HomeVideoCard {
    .video-card-video-container {
        aspect-ratio: 16/9 !important;
        font-family: "FilsonProBold", sans-serif;
        overflow: hidden;
        margin-bottom: 10px;
        background-color: #555;

        .video-class {
            display: none;
        }

        .image-class-2 {
            background-color: #eee !important;
        }

        &:hover {
            .image-class {
                display: none;
            }

            .image-class-2 {
                display: none;
            }

            .video-card-play-button {
                pointer-events: none;
                animation-name: fadeOut;
                animation-duration: 0.3s;
            }

            .video-card-featured {
                pointer-events: none;
                animation-name: fadeOut;
                animation-duration: 0.3s;
            }

            .video-card-duration {
                pointer-events: none;
                animation-name: fadeOut;
                animation-duration: 0.3s;
            }

            .video-class {
                display: block;
            }
        }
    }

    .video-card-play-button {
        position: absolute;
        top: 35%;

    }

    .video-card-featured {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: "FilsonProBold", sans-serif;
        position: absolute;
        left: 0;
        top: 0;
        margin-left: 10px;
        margin-top: 10px;
        padding: 5px;
        color: white;
        border-radius: 3px;
    }
    .video-card-live {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-family: "FilsonProBold", sans-serif;
      position: absolute;
      right: 0;
      bottom: 0;
      margin-right: 10px;
      margin-bottom: 10px;
      padding: 5px;
      color: white;
      border-radius: 3px;
      background-color: red;
    }

    .video-card-duration {
        font-family: 'FilsonProBold', sans-serif;
        position: absolute;
        bottom: 0;
        right: 0;
        margin-right: 10px;
        margin-bottom: 10px;
        padding: 5px;
        background-color: white;
        color: #555;
        font-size: 1.25em;
        min-width: 55px;
        text-align: center;
    }

    .video-card-meta-container {
        .video-card-series {
            font-size: 1.4rem;
        }

        .video-card-title {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-family: "FilsonProBold", sans-serif;
            font-size: 2.2rem;
            max-height: 70px;
        }
        .video-card-sub-title {
          font-family: "FilsonPro", sans-serif;
          font-size: 1.2rem;
        }
        .video-card-tags {
            .video-card-tag {
                margin-bottom: 2px;
                text-transform: capitalize;
                font-size: 1.2rem;
                padding: 3px 8px;
                border: 1px solid #555;
                border-radius: 100px;
                max-width: 100px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }
  .video-card-favourite {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    &:hover {
      cursor: pointer;
    }
  }

  .blinking {
    -webkit-animation: 1s blink ease infinite;
    -moz-animation: 1s blink ease infinite;
    -ms-animation: 1s blink ease infinite;
    -o-animation: 1s blink ease infinite;
    animation: 1s blink ease infinite;

  }

  @keyframes "blink" {
    from, to {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

  @-moz-keyframes blink {
    from, to {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

  @-webkit-keyframes "blink" {
    from, to {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

  @-ms-keyframes "blink" {
    from, to {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

  @-o-keyframes "blink" {
    from, to {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
}
</style>
