<template>
  <div id="LayaSearchBar" @mouseleave="hideResults" @mouseover="handleMouseOverInput">
    <form  action="/searchresults/" method="get" class="form-search search-field-desktop position-relative">
      <input
          v-on:keyup="handleSearchInput" v-on:keyup.enter="handleSearchInput(false)" v-model="searchTerm"
          id="siteSearch" name="q" type="search" class="form-control" aria-label="Search site" placeholder="Search">
      <button id="searchButton" class="search-button search-svg" aria-label="search" @click="handleSearchClick" v-if="!searching">
        <svg class="search-icon search-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path class="search-svg" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"></path>
        </svg>
      </button>
      <div class="spinner-border spinner-border-sm" role="status" style="position: absolute; right: 16px; top: 15px" v-else>
        <span class="sr-only">Loading...</span>
      </div>
    </form>
    <div class="resultsBackground" >
      <div class="resultsContainer" @scroll="scroll" ref="resultsContainer" v-bind:style="{maxHeight: resultContainerMaxHeight + 'px'}" v-if="searchResults.length >= 1 && searchResultsVisible">
        <SearchBarResult v-for="result in searchResults" :result="result" @newSearch="newSearch"/>
      </div>
      <div v-else-if="noResults && searchResultsVisible" class="resultsContainer noResults">
        Sorry, no results matching your search were found.
      </div>
      <div v-else-if="error && searchResultsVisible" class="resultsContainer noResults">
        Sorry but something went wrong.  Please try again later
      </div>
    </div>
  </div>
</template>

<script>
import SearchBarResult from "@/components/SearchBarResult.vue";

export default {
  name: "LayaSearchBar",
  components: {SearchBarResult},
  props: {
    placeholder: {
      type: String,
      default: "What are you looking for..."
    },
    stopResultsReappear: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchTerm: null,
      searchResults: [],
      searchResultsVisible: false,
      searching: false,
      total_pages: 1,
      current_page: 1,
      resultContainerMaxHeight: 600,
      noResults: false,
      error: false
    }
  },
  watch: {
    searchTerm() {
      this.noResults = false;
      this.error = false;
      this.searchResults = []
    }
  },
  methods: {
    handleMouseOverInput() {
      if(this.stopResultsReappear) return;
      this.searchResultsVisible = true;
    },
    hideResults () {
      this.searchResultsVisible = false;
      this.noResults = false;
    },
    scroll() {
      const percentageScrolled = (this.$refs.resultsContainer.scrollTop + this.$refs.resultsContainer.clientHeight) / this.$refs.resultsContainer.scrollHeight;
      if(this.current_page < this.total_pages && percentageScrolled > 0.85) this.search(true);
    },
    handleSearchInput(setDelay = true) {
      this.current_page = 1;
      this.total_pages = 1;
      if(this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      if(setDelay) {
        if(this.searchTerm.length > 2) {
          this.searchTimeout = setTimeout(this.search, 2000);
        }
      } else {
        this.search();
      }
    },
    newSearch(term) {
      this.searchTerm = term;
      this.handleSearchInput(false);
    },
    async handleSearchClick(evt) {
      evt.preventDefault();
      await this.search();
    },
    async search(incrementPage = false) {
      try {
        if(this.current_page === 1) {
          window._mtm.push({"event": "nav_search", "searchQuery": this.searchTerm});
        }

        if(this.searching) return;
        this.searching = true;
        if(incrementPage) this.current_page ++;
        let res = await this.api({
          path: "api/u/content/search",
          query: this.searchTerm,
          page: this.current_page,
          page_size: 10
        });
        if (res.success) {
          if(incrementPage) {
            this.searchResults = this.searchResults.concat(res.data);
          } else {
            this.searchResults = res.data;
            if(!res.data.length) {
              this.noResults = true;
            }
          }
          this.searchResultsVisible = true;
          this.current_page = res.meta.pagination.current_page;
          this.total_pages = res.meta.pagination.total_pages
        }
        this.searching = false
      } catch (e) {
        this.searching = false;
        this.searchResultsVisible = true;
        this.error = true;
      }

    }
  },
}
</script>

<style lang="less">
#LayaSearchBar {
  margin-left: auto;
  .resultsBackground {
    width: calc(50% + 25px);
    padding-right: 25px;
    padding-bottom: 25px;
    right: 0;
    height: fit-content;
    z-index: 980;
    position: absolute;
  }
  .resultsContainer {
    width: 100%;
    height: fit-content;
    top: calc(100% - 17px);
    background-color: white;
    overflow-y: auto;
    border: grey 1px solid;
    box-shadow: 1px 3px 3px #2f2f2f;
    &.noResults {
      width: 100%;
      height: 50px;
      font-size: 1.6rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.5);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #da1984;
      -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.5);
      border-radius: 10px;
    }
  }
}
</style>