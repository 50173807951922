<template>
    <div id="HomeFilters">
        <div v-if="loading" class="pb-3 container d-flex flex-row justify-space-between align-items-center">
          <HomeLoading/>
        </div>
        <div v-else>
          <div class="pb-3 container d-flex flex-row justify-space-between align-items-center">
              <div>
                  <h2 class="home-title">Try one of these...</h2>
                  <div class="home-title-line bg-brand-secondary"></div>
              </div>
          </div>
          <div class="container mb-5">
              <div class="row">
                  <div class="filters">
                      <b-button class="mx-2 my-2 btn" :variant="selectedFilter.id === null ? 'cta' : 'cta-outline'" @click="clearFilter()">All</b-button>
                      <b-button :key="'tag'+i" class="mx-2 my-2 btn" :variant="selectedFilter.id === tag.id ? 'cta' : 'cta-outline'" @click="selectFilter(tag)" v-for="(tag,i) in tags">{{tag.tag}}</b-button>
                  </div>
              </div>
          </div>
        </div>
    </div>
</template>

<script>
import EventBus from "@/helpers/eventbus";
import HomeLoading from "@/components/Home/New/HomeLoading.vue";

export default {
    name: "HomeFilters",
    components: {
      HomeLoading
    },
    data() {
        return {
            selectedFilter: {id: null, tag: 'All'},
            tags: [],
            excludedTags: ['Training Plans','Courses','Surge','Draft','Ignite','Healthcoach'],
            loading: true
        };
    },
    mounted() {
        this.getTags();
        this.getContent();
    },
    methods: {
        async getTags(){
            let res = await this.api({
                path: "api/u/content/tags",
            });
            if (res.success) {
                this.tags = [];
                res.data.forEach((tag,i) => {
                    if(!this.excludedTags.includes(tag)) {
                        this.tags.push({id: i+1 , tag:tag})
                    }
                })
            }
            this.loading = false;
        },
        clearFilter(){
            this.selectedFilter = {id: null, tag: 'All'}
            EventBus.$emit('setTag', null)
        },
        selectFilter(tag){
            if(tag === this.selectedFilter) {
              this.clearFilter();
              return;
            }
            this.selectedFilter = tag;
            EventBus.$emit('setTag', tag);
        },
    }
};
</script>

<style lang="less">
#HomeFilters{
    .btn, button {
        min-width: 150px !important;
        font-size: 1.5em;
    }
  @media only screen and (max-width: 768px) {
    .filters {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}
</style>
