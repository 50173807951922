<template>
  <div id="HomeDigitalGym">
    <div class="content-container">
      <div class="row">
        <div class="col-12">
          <div class="home-card shadow">
            <div class="col-md-6 col-sm-12 p-5 d-flex align-items-center">
              <div class="card-content">
                <div class="col-6 px-0 mb-4">
                  <img
                    class="img-fluid"
                    src="/img/wellbeing-studio/digital-gym.svg"
                  />
                </div>

                <p>
                  Our team of wellbeing experts are passionate about health &amp; fitness. All of our coaches are handpicked and all come from a wide spectrum of expertise, reflecting the diversity of different sports and exercises around.
                </p>
                <b-button
                  class="cta-button mt-4"
                  @click="$router.push('/digital-gym')"
                  >View Digital Gym</b-button
                >
              </div>
            </div>
            <div class="half-circle bg-brand-tertiary d-none d-md-flex"></div>
            <div class="col-md-6 col-sm-12 p-5 bg-brand-tertiary">
              <img class="img-fluid my-5" src="/img/home/homedg.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HomeTMH",
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="less">
#HomeDigitalGym {
  padding: 45px 0;
  background-color: #edece5;

  .home-card {
    display: flex;
    position: relative;
    background-color: white;
    font-size: 1.6rem;
    border-radius: 16px;
    overflow: hidden;
    .card-content {
      margin-right: 50px;
    }
    h3 {
      padding-top: 15px;
      font-family: "FilsonProBook", Arial, Helvetica, sans-serif;
    }
    .newbuttoncolours {
      background-color: #651242;
      color: white;
    }
  }
  .bg-brand-tertiary {
    background-color: #658fb6;
  }
  .half-circle {
    position: absolute;
    top: -50%;
    left: calc(50% - 60px);
    width: 450px;
    height: 200%;
    border-top-left-radius: 100% 50%;
    border-bottom-left-radius: 100% 50%;
  }
}
</style>
