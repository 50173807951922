<script>
export default {
  name: "TextTruncator",
  props: ['text','fontSize','fontName','lineHeight','id','matomoClass','enableToolTip','maxLines'],
  data() {
    return {
      itemHeight: 0,
      itemWidth: 0,
      textIsTruncated: true,
      hovering: false,
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.setDims();
    });
    window.addEventListener('resize',this.setDims)
  },
  destroyed() {
    window.removeEventListener('resize',this.setDims)
  },
  computed: {
    tooltipClass() {
      let classText = 'tt-tooltip ' + this.matomoClass;
      if(this.textIsTruncated && this.enableToolTip && this.hovering) {
        classText += ' showToolTip'
      }
      return classText;
    },
    truncatedText() {
      const desc = this.stripHtmlTags(this.text);
      const words = desc.split(' ');
      const maxLines = Math.floor(this.itemHeight / this.lineHeight);
      return maxLines > 1 ? this.truncateMultipleLines(words,maxLines) : this.truncateSingleLine(words)
    },

  },
  updated() {
    this.$nextTick(() => {
      this.setDims();
    });
  },
  methods: {
    truncateSingleLine(words) {
      let text = '';
      const ellipsisLength = this.getCharacterWidth('...');
      const spaceLength = this.getCharacterWidth(' ');
      let remainingWidth = this.itemWidth;
      if(this.getCharacterWidth(this.text) < this.itemWidth) {
        this.textIsTruncated = false;
        return this.text;
      }
      let addMoreLetters = true;
      words.forEach((word) => {
        const letters = word.split('');
        letters.forEach((letter) => {
          if(addMoreLetters) {
            const charWidth = this.getCharacterWidth(letter);
            if(charWidth + ellipsisLength + spaceLength < remainingWidth) {
              text += letter;
              remainingWidth -= charWidth;
            } else {
              addMoreLetters = false;
              text += '...'
            }
          }
        });
        if(addMoreLetters) {
          text += ' ';
          remainingWidth -= spaceLength;
        }
      });
      return text.trim();
    },
    truncateMultipleLines(words,maxLines) {
      let line = 1;
      let i = 0;
      let text = '';
      const ellipsisLength = this.getCharacterWidth('...');
      const spaceLength = this.getCharacterWidth(' ');
      do {
        let widthOfThisLine = 0;
        const width = line === maxLines ? (this.itemWidth - ellipsisLength) : this.itemWidth;
        let spaceLeft = true;
        do {
          const letters = words[i].split('');
          letters.forEach(letter => {
            widthOfThisLine += this.getCharacterWidth(letter);
          });
          widthOfThisLine += spaceLength;
          if(widthOfThisLine < width) {
            text += words[i] + ' ';
            i++;
          } else {
            spaceLeft = false
          }
        } while(spaceLeft && words.length > i)
        line++;
      } while (line <= maxLines && words.length > i);
      if(i !== words.length) {
        if(text.slice(-2) === '. ' || text.slice(-2) === ', ') {
          text = text.slice(0,-2) + '...'
        } else {
          text = text.slice(0,-1) + '...'
        }
      }
      return text;
    },
    setDims() {
      if (this.$refs.truncatorItem) {
        this.itemWidth = Math.floor(this.$refs.truncatorItem.clientWidth);
        if(this.maxLines) {
          this.itemHeight = Math.floor(this.lineHeight * this.maxLines);
        } else {
          this.itemHeight = Math.floor(this.$refs.truncatorItem.clientHeight);
        }
      }
    },
    stripHtmlTags(input) {
      return input.replace(/<\/?[^>]+(>|$)/g, "");
    },
    getCharacterWidth(char) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      context.font = `${this.fontSize}px ${this.fontName}`;
      return context.measureText(char).width;
    },
  },
  watch: {
    fontSize() {
      this.setDims();
    },
    lineHeight() {
      this.setDims();
    }
  }
}
</script>

<template>
  <div @mouseenter="hovering = true" @mouseleave="hovering = false" ref="truncatorItem" :id="id" style="width: 100%; height: 100%;" :style="`font-family: ${fontName}, sans-serif; font-size: ${fontSize}px; line-height: ${lineHeight}px`" class="TextTruncator" :class="matomoClass">
    {{truncatedText}}
    <span :id="id" :class="tooltipClass" v-if="textIsTruncated && enableToolTip" :style="{top: -(lineHeight)+'px'}">{{text}}</span>
  </div>
</template>

<style lang="less">
.TextTruncator {
  position: relative;
  .tt-tooltip {
    position: absolute;
    font-family: Open Sans, sans-serif;
    left: 0;
    font-size: 13px;
    background-color: grey;
    color: white;
    padding: 3px 6px;
    border-radius: 5px;
    pointer-events: none;
    opacity: 0;
    transition: opacity .25s ease-in-out;
    z-index: 100;
    line-height: 18px;
    &.showToolTip {
      opacity: 1;
    }
  }
}
</style>