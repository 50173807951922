<template>
    <div class="HomeBookingCard">
        <div class="booking-card bg-brand-secondary text-white">
            <div class="booking-card-content-container">
                <div class="booking-card-heading d-flex flex-row justify-content-between mx-4 py-3">
                    <div class="booking-card-event-date">
                        <span class="booking-card-event-day">{{ getDay(booking.start) }}</span>
                        <div class="booking-card-event-month">{{ getMonth(booking.start) }}</div>
                    </div>
                    <div class="booking-card-icon-container">
                        <div v-bind:style="{ backgroundImage: 'url(' + booking.featured_image + ')' }" class="img-fluid" v-if="booking.featured_image" />
                    </div>
                </div>
                <div class="booking-card-details mx-4 mb-3 py-3">
                    <div class="booking-card-title">
                      <b>{{ booking.title }}</b><br>
                      <span v-if="booking.position" class="booking-card-position">{{booking.position}}</span>
                    </div>
                    <div class="booking-card-date-details">
                        <div class="booking-card-day">{{ getDateLineText(booking.start) }}</div>
                        <div class="booking-card-hours">
                            <i class="fas fa-clock" aria-hidden="true"></i>
                            {{ getHoursMinutes(booking.start) }} - {{ getHoursMinutes(booking.end) }}
                        </div>
                    </div>
                </div>
                <div class="booking-card-button-container">
                    <b-button variant="cta" size="md" class="booking-card-button w-100" @click="goToBooking()" v-if="eventStarted">
                      {{ componentText.cards.join }} <i class="fa-solid fa-video"></i>
                    </b-button>
                    <b-button v-else variant="cta" size="md" class="booking-card-button w-100 py-4" @click="goToBooking()">
                      {{ componentText.cards.moreInformation }}
                    </b-button>
                </div>
            </div>

            <div class="booking-card-blob-container">
                <img class="img-fluid" src="/img/home/booking-blob.png" />
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";

export default {
    name: "BookingCard",
    props: {
        isEarliest: Boolean,
        booking: Object,
    },
    data() {
    },
    mounted() {

    },
    computed: {
      eventStarted() {
        return moment() > moment(this.booking.start);
      },
      componentText() {
        return this.getText.dfd;
      },
    },
    methods: {
        goToBooking(){
            switch(this.booking.type){
                case 'dg':
                    this.$router.push('/digital-gym/' + this.booking.id)
                    break;
                case 'dc':
                    this.$router.push('/clinics/digital');
                    break;
                case 'tmh':
                    this.$router.push('/total-mental-health');
                    break;
              case 'corporate':
                    this.$router.push('marketplace/events/view-event/'+ this.booking.event_date_id);
                  break;
                case 'ws':
                    this.$router.push('/wellbeing-studio/' + this.booking.id);
                    break;
            }
        },
    }
};
</script>

<style lang="less">
.HomeBookingCard {

    max-width: 240px;
    .booking-card {
        position: relative;
        border-radius: 24px 24px 30px 30px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        z-index: 1;
        .booking-card-content-container{
            position: relative;
            z-index: 3;
        }
        .booking-card-date-details {
            min-height: 60px;
            .booking-card-day,.booking-card-hours {
                font-size: 1.35rem;
            }
        }
        .booking-card-heading {
            border-bottom: 1px solid white;

            .booking-card-event-date {
                margin-top: 8px;
                .booking-card-event-day {
                    line-height: 1;
                    font-size: 3.2rem;
                    padding-bottom: 10px;
                }

                .booking-card-event-month {
                    line-height: 1;
                    font-size: 2.0rem;
                    margin-bottom: 10px;
                }
            }

            .booking-card-icon-container {
                margin-top: 10px;
                height: 60px;
                width: 60px;
                border-radius: 50% !important;
                .img-fluid {
                    border-radius: 50% !important;
                    height: 100%;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                }
            }
        }

        .booking-card-details {
            font-size: 1.1rem;

            .booking-card-title {
                font-size: 1.4rem;
                font-family: "FilsonProBold", sans-serif;
                margin-bottom: 15px;
                min-height: 50px;
                .booking-card-position {
                  font-family: Open Sans, sans-serif !important;
                }
            }
        }

        .booking-card-button {
            font-size: 1.3rem;
            letter-spacing: normal;
            border-radius: 0 0 24px 24px !important;
        }

        .booking-card-blob-container {
            position: absolute;
            top: 0;
            z-index: 2;
            img{
                z-index: 2;
            }
        }

    }
}
</style>
