<template>
  <div id="NavigationV2">
    <div id="newNavTop" class="newNavTop bg-brand-primary" :class="{sideNavExpanded: sideNavExpanded}" ref="newTopNav">
      <div class="topNavLeftSide" :class="{sideNavExpanded: sideNavExpanded}">
        <div v-if="sideNavExpanded" class="imgHolder">
          <img
              @click="$store.getters.user.user_client_id !== 3519 ? $router.push('/') : $router.push('/personal/my-company')"
              class="nav-logo clickable"
              :src="getLogo"
              alt="company logo"
              onerror="javascript:this.src='/img/Spectrum-Life-Logo.png'"
          />
        </div>
        <div class="topButton" @click="toggleSideNav">
          <div class="bar1"></div>
          <div class="bar2"></div>
          <div class="bar3"></div>
        </div>
      </div>
      <div class="topNavMain bg-brand-primary text-white" :class="{sideNavExpanded: sideNavExpanded}">
        <SearchBar class="searchBar" :placeholder="nav_elements.what_are_you_looking_for"/>
        <div class="navItems" ref="navItem">
          <div class="topNavItem" v-for="(item,i) in nav" :key="'nav-item-1-'+i" @click="navigate(item)">
            {{item.label}}
            <div class="navDropDown bg-brand-primary" v-if="item.sub_items">
              <div class="navDropDownItem" v-for="sub in item.sub_items" @click="navigate(sub)">{{sub.label}}</div>
            </div>
          </div>
        </div>
        <div class="iconRow">
          <i @click="home" :class="iconHoveredOver === 4? 'fa-solid' : 'fa-light'" class="fa-home navIcon" @mouseover="iconHoveredOver = 4" @mouseleave="iconHoveredOver = -1"></i>
          <div class="navIcon hoverable" v-if="walletUser">
            <i class="fa-wallet" :class="iconHoveredOver === 1? 'fa-solid' : 'fa-light'"   @mouseover="iconHoveredOver = 1" @mouseleave="iconHoveredOver = -1"></i>
            <div class="iconDropDown walletHolder" @click="$router.push('/wallet')">
              <WalletCard />
            </div>
          </div>

           <i class="fa-solid fa-trophy navIcon" @click="toggleChallenges" :class="{challengesOpen: showChallenges}" @mouseover="iconHoveredOver = 5" @mouseleave="iconHoveredOver = -1"></i>

<!--          <i class="fa-solid fa-trophy navIcon" -->
<!--            @click="syncActivities" -->
<!--          ></i>-->
          
          <div class="navIcon apptNavIcon" :class="bookingsExtended ? 'extended': null">
            <i @click="toggleBookingsExtended" :class="iconHoveredOver === 2? 'fa-solid' : 'fa-light'" class="fa-calendar-check navIcon"
               @mouseover="iconHoveredOver = 2" @mouseleave="iconHoveredOver = -1">
            </i>
            <div class="bookingAlert" v-if="showReminderAlert > 0"></div>
            <div v-if="showReminderAlert > 0" class="bookingAlertBox">
              <div class="bookingAlertBoxOuter" @mouseover="iconHoveredOver = 6" @mouseleave="iconHoveredOver = -1">
                <div class="bookingAlertBoxTriangle"></div>
                <div class="bookingAlertBoxInner">
                  <i class="fa-solid fa-circle-exclamation"></i>
                  {{ showReminderAlert > 1 ? 'You have bookings today, click here to view details' : 'You have a booking today, click here to view details' }}
                </div>
              </div>
            </div>
            <div class="iconDropDown apptHolder" >
              <NavAppointmentHolder :bookings="bookings" @reload="getBookings" @close="bookingsExtended = false"/>
            </div>
          </div>
          <div class="navIcon reminderNavIcon" :class="remindersExtended ? 'extended': null">
            <i @click="toggleRemindersExtended" :class="iconHoveredOver === 3? 'fa-solid' : 'fa-light'" class="fa-bell navIcon" @mouseover="iconHoveredOver = 3" @mouseleave="iconHoveredOver = -1"></i>
            <div class="bookingAlert" v-if="unseenNotifications"></div>
            <div v-if="unseenNotifications" class="bookingAlertBox">
              <div class="bookingAlertBoxOuter" @mouseover="iconHoveredOver = 3" @mouseleave="iconHoveredOver = -1" v-if="!remindersExtended">
                <div class="bookingAlertBoxTriangle"></div>
                <div class="bookingAlertBoxInner">
                  <i class="fa-solid fa-circle-exclamation"></i>
                  Click the bell icon to see notifications
                </div>
              </div>
            </div>
            <div class="iconDropDown reminderDropDown">
              <ReminderHolder :notifications="notifications" @close="remindersExtended = false"/>
            </div>
          </div>
        </div>
        <DarkModeToggler :nav_elements="nav_elements" v-if="darkModeAvailable"/>
      </div>
    </div>
    <div class="newNavSide" :class="{sideNavExpanded: sideNavExpanded}">
      <div class="sideNavItemsTop">
        <div class="sideNavItem" @click="home">
          <div class="sideNavItemInner">
            <i class="fa-solid fa-house topIcon"></i>
            <div>{{nav_elements.home}}</div>
          </div>
        </div>
        <div v-for="(item,i) in nav" :key="'nav-item-2-'+i" @click="navigate(item)" class="hiddenSideNav" v-if="!adminMode">
          <div class="sideNavItem">
            <div class="sideNavItemInner" v-if="!item.sub_items"  @click="navigate(item)">
              <i v-if="item.icon" class="topIcon fa-solid" :class="item.icon"></i>
              <div>{{item.label}}</div>
            </div>
            <div class="sideNavItemInner" v-else @click="extendSideNav(i)">
              <i v-if="sideNavItemExtended === i" class=" topIcon fa-regular fa-chevron-up"></i>
              <i v-else class=" topIcon fa-regular fa-chevron-down"></i>
              <div>{{item.label}}</div>
            </div>
            <div class="sideNavDropDown" v-if="item.sub_items" :class="sideNavItemExtended === i ? 'extended':null">
              <div class="sideNavDropDownItem sideNavItem subDropDown" v-for="sub in item.sub_items" @click="navigate(sub)">
                <div class="sideNavItemInner pl-5">
                  <i v-if="sub.icon" class="fa-solid subIcon" :class="sub.icon"></i>
                  <div>{{sub.label}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-for="(item,i) in sideNav" :key="'nav-item-3'+i" @click="navigate(item)" v-if="!adminMode">
            <div class="sideNavItem">
              <div class="sideNavItemInner" v-if="!item.sub_items"  @click="navigate(item)">
                <i class="topIcon fa-solid" :class="item.icon ? item.icon : 'fa-circle'"></i>
                <div>{{item.label}}</div>
              </div>
              <div class="sideNavItemInner" v-else @click="extendSideNav(i)">
                <i v-if="sideNavItemExtended === i" class=" topIcon fa-regular fa-chevron-up"></i>
                <i v-else class=" topIcon fa-regular fa-chevron-down"></i>
                <div>{{item.label}}</div>
              </div>
              <div class="sideNavDropDown" v-if="item.sub_items" :class="sideNavItemExtended === i ? 'extended':null">
                  <div class="sideNavDropDownItem sideNavItem subDropDown" v-for="sub in item.sub_items" @click="navigate(sub)">
                    <div class="sideNavItemInner pl-5">
                      <i class="fa-solid subIcon" :class="sub.icon ? sub.icon : 'fa-circle'"></i>
                      <div>{{sub.label}}</div>
                    </div>
                  </div>
              </div>
            </div>
        </div>
<!--        <div class="sideNavItem">-->
<!--          <i class="fa-solid fa-chart-line"></i> My Progress-->
<!--        </div>-->
<!--        <div class="sideNavItem">-->
<!--          <i class="fa-solid fa-list-ul"></i> My List-->
<!--        </div>-->
<!--        <div class="sideNavItem">-->
<!--          <i class="fa-solid fa-clapperboard"></i>New Releases-->
<!--        </div>-->
      </div>

      <!--      Start of admin section-->
      <div class="sideNavItemsBottom" v-if="adminMode">

        <div class="sideNavItem" @click="navigateFromFixedButton('/customise')">
          <div class="sideNavItemInner">
            <i class="fa-solid fa-table-cells-large topIcon"></i>
            <div>{{admin_elements.branding_customise}}</div>
          </div>
        </div>

        <div class="sideNavItem" @click="navigateFromFixedButton('/custom-content')">
          <div class="sideNavItemInner">
            <i class="fa-solid fa-clapperboard topIcon"></i>
            <div>{{admin_elements.custom_content}}</div>
          </div>
        </div>

        <div class="sideNavItem" @click="navigateFromFixedButton('/data-analytics')">
          <div class="sideNavItemInner">
            <i class="fa-solid fa-chart-area topIcon"></i>
            <div>{{admin_elements.data_analytics}}</div>
          </div>
        </div>

        <div class="sideNavItem" @click="navigateFromFixedButton('/user-management')">
          <div class="sideNavItemInner">
            <i class="fa-solid fa-user topIcon"></i>
            <div>{{admin_elements.user_management}}</div>
          </div>
        </div>
      </div>
      <!--      End of admin section -->

      <div class="sideNavItemsBottom" v-if="!adminMode">
        <div class="sideNavItem" @click="navigateFromFixedButton('/my-progress')">
          <div class="sideNavItemInner">
            <i class="fa-solid fa-chart-line topIcon"></i>
            <div>{{nav_elements.my_progress}}</div>
          </div>
        </div>
        <div class="sideNavItem" @click="navigateFromFixedButton('/my-list')">
          <div class="sideNavItemInner">
            <i class="fa-solid fa-list-ul topIcon"></i>
            <div>{{nav_elements.my_list}}</div>
          </div>
        </div>
        <div class="sideNavItem" @click="navigateFromFixedButton('/favourites')">
          <div class="sideNavItemInner">
          <i class="fa-solid fa-star topIcon"></i>
            <div>{{nav_elements.favourites}}</div>
          </div>
        </div>
        <div class="sideNavItem">
          <div class="sideNavItemInner" @click="navigateFromFixedButton('/settings')">
          <i class="fa-solid fa-user topIcon"></i>
            <div>{{nav_elements.profile}}</div>
          </div>
        </div>
        <div class="sideNavItem" @click="logOut">
          <div class="sideNavItemInner">
          <i class="fa-solid fa-right-from-bracket topIcon" ></i>
            <div>{{nav_elements.logout}}</div>
          </div>
        </div>
        <div class="sideNavItem">
          <div class="sideNavItemInner">
            <i class="fa-solid fa-circle-info topIcon"></i> <div>{{nav_elements.info}}</div>
          </div>
        </div>
        <div class="sideNavItem" @click="adminMode = !adminMode" v-if="isAdmin">
          <div class="sideNavItemInner">
            <i class="fa-solid fa-gear topIcon"></i> <div>{{admin_btn_text}}</div>
          </div>
        </div>
        <LanguageSelector class="sideNavItem" :title="nav_elements.choose_language"/>
      </div>

      <div class="sideNavItemsBottom" v-if="adminMode">
        <div class="sideNavItem" @click="logOut">
          <div class="sideNavItemInner">
            <i class="fa-solid fa-right-from-bracket topIcon" ></i> <div>{{nav_elements.logout}}</div>
          </div>
        </div>
        <div class="sideNavItem" @click="adminMode = !adminMode" v-if="isAdmin">
          <div class="sideNavItemInner">
            <i class="fa-solid fa-gear topIcon"></i> {{admin_btn_text}}
          </div>
        </div>
        <LanguageSelector class="sideNavItem" :title="nav_elements.choose_language"/>
      </div>


<!--      <div class="adminBoxHolder">-->
<!--        <div class="adminBox">-->

<!--        </div>-->
<!--      </div>-->
    </div>
    <div class="newNavContent" :class="{sideNavExpanded: sideNavExpanded}">
      <div class="w-100">
          <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>

import SearchBar from "@/components/SearchBar.vue";
import {mapGetters} from "vuex";
import NavAppointmentHolder from "@/components/NavAppointmentHolder.vue";
import WalletCard from "@/components/DigitalClinics/Landing/WalletCard.vue";
import EventBus from "@/helpers/eventbus";
import moment from "moment";
import LanguageSelector from "@/components/Wellbeing/LanguageSelector.vue";
import DarkModeToggler from "@/components/Home/New/DarkModeToggler.vue";
import ReminderHolder from "@/components/ReminderHolder.vue";

export default {
    name: "NavigationV2",
    components: {
      ReminderHolder,
      SearchBar,
      WalletCard,
      NavAppointmentHolder,
      LanguageSelector,
      DarkModeToggler
    },
    data() {
        return {
            sideNavExpanded: false,
            walletUser: false,
            iconHoveredOver: -1,
            bookings: [],
            notifications: [],
            sideNavItemExtended: -1,
            bookingsExtended: false,
            remindersExtended: false,
            showChallenges: false,
            topNavHeight: 77,
            adminMode: false,
            unseenNotifications: false,
            testNotifications: [
                {id: 1,
                  read_at: null,
                  created_at: "2023-09-01 12:21:37",
                  subject: "Your initial appointment is starting in 5 minutes",
                  body: "Your initial appointment with Roman Coach is starting in 5 minutes",
                  data: {
                    "actionUrl": {
                      href: "/total-mental-health"
                    }
                  }
                },
                {id: 0,
                  read_at: "2023-09-01 12:53:37",
                  created_at: "2023-09-01 12:53:37",
                  subject: "Your initial appointment is starting in 30 minutes",
                  body: "Your initial appointment with Roman Coach is starting in 30 minutes",
                  data: {
                    "actionUrl": {
                      href: "/total-mental-health"
                    }
                  }
                },
            ],
        }
    },
    methods: {
      toggleRemindersExtended() {
        this.remindersExtended = !this.remindersExtended;
        if(this.remindersExtended) {
          EventBus.$emit('getReminders');
          this.unseenNotifications = false;
        }
      },
      async checkForNotifications() {
        return;
        const res = await this.api({
          path: '/api/u/notifications/unread'
        })
        if(res.success) {
          this.unseenNotifications = res.unread_count && res.unread_count > 0;
        } else {
          this.unseenNotifications = false;
        }
      },
      async getNotifications() {
        return;
        this.notifications = this.testNotifications;
        const res = await this.api({
          path: '/api/u/notifications/unread'
        })
        console.log(res)
      },
      closeChallenges() {
        this.showChallenges = false;
      },
      toggleChallenges() {
        this.updateNavHeight();
        this.showChallenges = !this.showChallenges;
      },
      navigateFromFixedButton(url) {
        this.sideNavExpanded = false;
        this.$router.push(url)
      },
      toggleSideNav() {
        EventBus.$emit("sideNavExpanded",!this.sideNavExpanded);
        this.sideNavExpanded = !this.sideNavExpanded;
      },
      toggleBookingsExtended() {
        if(this.showReminderAlert) this.$store.dispatch("setBookingReminderLastSeen");
        this.bookingsExtended = !this.bookingsExtended;
      },
      extendSideNav(i) {
        if(i === this.sideNavItemExtended) {
          this.sideNavItemExtended = -1;
        } else {
          this.sideNavItemExtended = i;
        }
      },
      async getBookings(){
        try {
          let res = await this.api({
            path: "api/u/home-page/get-booking-widget",
          });
          if (res.success) {
            this.bookings = res.bookings;
            EventBus.$emit("setBookingReminders", this.bookings);

          }
          this.bookingsLoading = false;
        } catch (e) {
          this.bookingsLoading = false;
        }

      },
      logOut() {
        this.$store.dispatch("logout");
        this.$router.push("/login").catch(err => {});
      },
      home() {
        this.$router.push({ name: "home" });
        this.sideNavExpanded = false;
      },
      navigate(item) {
        if(item.hasOwnProperty("sub_items")) return;
        this.sideNavExpanded = false;
        if (!item.external_link) {
          if(item.asset_id){
            this.$router.push({ name: item.item_slug, params: { id: item.asset_id }  });
          }else{
            this.$router.push({ name: item.item_slug });
          }
        } else {
          window.open("https://" + item.external_link, "_blank");
        }
      },
      toggleDarkMode() {
        this.$store.dispatch("setDarkMode",!this.darkMode)
      },
      async checkIfWalletUser() {
        let res = await this.api({
          path: "api/u/home/get-dc-banner",
        });
        if (res.success) {
          this.walletUser = res.dc_setting === "wallet";
        }
      },
      updateNavHeight() {
        this.topNavHeight = this.$refs.newTopNav.clientHeight;
      }
    },
    mounted() {      
      this.checkIfWalletUser();
      this.getBookings();
      //this.checkForNotifications();
      const _t = this;
      EventBus.$on('updateDFDReminders',function() {
        setTimeout(()=> {_t.getBookings();},5000);
      });
      window.addEventListener('resize', this.updateNavHeight);
      this.updateNavHeight();
      this.$store.dispatch('setMyList');
      if(this.$route.name === 'challenges') {
        this.showChallenges = true
      }
      // setInterval(()=> {
      //   if(!this.unseenNotifications) this.checkForNotifications();
      // },1000 * 60)
    },
    destroyed() {
      window.removeEventListener('resize', this.updateNavHeight);
    },
    watch: {
      iconHoveredOver(val) {
        if(val !== 2 && val !== -1) this.bookingsExtended = false
      }
    },
    computed: {
        ...mapGetters(["hardcoded", "skin", "client", "nav", "darkMode","sideNav","bookingReminderLastSeen","navElements","skinsWithDarkMode","default_nav_elements","user","admin_nav_elements"]),
        isAdmin() {
          return this.user.user.employer_admin;
        },
        nav_elements() {
          let els = this.default_nav_elements;
          Object.entries(this.navElements).forEach(el => {
            const [key,value] = el;
            if(els.hasOwnProperty(key)) {
              els[key] = value.replace('&#39;',"'");
            }
          });
          return els;
        },
        admin_btn_text() {
          return this.adminMode ? this.admin_elements.exit_admin_view : this.admin_elements.enter_admin_view;
        },
        admin_elements() {
          let els = this.admin_nav_elements;
          Object.entries(this.navElements).forEach(el => {
            const [key,value] = el;
            if(els.hasOwnProperty(key)) {
              els[key] = value.replace('&#39;',"'");
            }
          });
          return els;
        },
        showReminderAlert() {
          const today = moment().format("YYYY-MM-DD");
          if(today === this.bookingReminderLastSeen) return false;
          let show = 0;
          this.bookings.map(item => {
            if(moment(item.start).format("YYYY-MM-DD") === today) show++;
          });
          return show;
        },
        darkModeAvailable() {
          return this.skinsWithDarkMode.includes(this.skin)
        },
        getLogo() {
          if (this.hardcoded && this.hardcoded.client_logo) {
            return this.hardcoded.client_logo;
          } else if (this.skin === 8) {
            return "/img/boi/boi-logo-alt.png";
          } else return this.client.client_logo;
        },
    }
}
</script>
<style lang="less">
#NavigationV2 {
  width: 100vw;
  min-height: 100vh;
  display: grid;
  font-family: 'FilsonProBook', sans-serif;
  grid-template-columns: fit-content(100%) 1fr;
  grid-template-rows: fit-content(100%) 1fr;
  #content {
    margin-top: 0 !important;
  }
  .newNavTop {
    grid-column: 1 / span 2;
    grid-row: 1;
    display: grid;
    grid-template-columns: 75px 1fr;
    height: 77px;
    .topNavLeftSide {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 70px;
      transition: width 0.25s linear;
      height: 100%;
      &.sideNavExpanded {
        display: grid;
        grid-template-columns: 1fr fit-content(100%);
        background-color: white;
        .topButton {
          margin-right: 5px;
          .bar1, .bar2, .bar3 {
            background-color: black;
          }
        }
      }
      .imgHolder {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        margin-left: 10px;
        img {
          max-height: 65px;
          max-width: 180px;
        }
      }
      .topButton {
        display: inline-block;
        float: right;
        cursor: pointer;
        .bar1, .bar2, .bar3 {
          width: 30px;
          height: 3px;
          background-color: white;
          margin: 6px 0;
          transition: 0.4s;
        }
      }
      &.sideNavExpanded {
        width: 250px;
        .bar1 {
          transform: translate(0, 9px) rotate(-45deg);
        }
        .bar2 {
          opacity: 0;
        }
        .bar3 {
          transform: translate(0, -9px) rotate(45deg);
        }
      }
    }
    .topNavMain {
      padding-right: 10px;
      grid-template-columns: 1fr 1fr fit-content(100%) 100px;
      display: grid;
      .apptNavIcon, .reminderNavIcon {
        .bookingAlert {
          position: absolute;
          bottom: -2px;
          right: 5px;
          width: 10px;
          height: 10px;
          background: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%);
          border-radius: 50%;
          z-index: 201;
        }
        .bookingAlertBox {
          display: none;
          overflow: visible;
          position: absolute;
          z-index: 201;
          left: -150px;
          bottom: -70px;
          width: 300px;
          .bookingAlertBoxOuter {
            position: relative;
            .bookingAlertBoxTriangle {
              position: absolute;
              background-image: linear-gradient(90deg, #a73870 0%, #9c386e 100%);
              clip-path: polygon(50% 0, 100% 100%, 0 100%);
              width: 25px;
              height: 20px;
              top: -19px;
              left: 160px;
            }
          }
          .bookingAlertBoxInner {
            color: white;
            background: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%);
            overflow: visible;
            display: flex;
            align-items: center;
            font-size: 13px;
            border-radius: 5px;
            padding: 5px;
            i {
              color: white;
              margin-right: 10px;
              font-size: 2.3rem;
            }
          }
        }
        &:hover {
          .bookingAlertBox {
            display: block;
          }
        }
      }
      .navIcon {
        cursor: pointer;
        position: relative;
        margin: 0 8px;
        color: #9F9E93;
        &:hover, &.challengesOpen {
          color: white;
        }

        .walletHolder {
          width: 400px;
          height: 200px;
          background-color: transparent;
          top: 20px;
          right: 0;
        }
        .apptHolder {
          width: 300px;
          background-color: white;
          top: 25px;
          right: -80px;
        }
        .reminderDropDown {
          background-color: white;
          top: 25px;
          right: -80px;
        }
        .iconDropDown {
          display: none;
          position: absolute;
          overflow: visible;
          z-index: 9999;

        }
        &.hoverable:hover, &.extended {
          .iconDropDown{
            display: block;
          }
        }
      }
      .navItems {
        display: flex;
        align-items: center;
        justify-content: space-around;
        font-size: 14px;
        cursor: pointer;
        margin-left: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        .topNavItem {
          width: 100%;
          max-width: 200px;
          position: relative;
          height: 100%;
          padding: 2px 3px;
          display: flex;
          text-align: center;
          align-items: center;
          justify-content: center;
          .navDropDown {
            display: none;
            width: 100%;
            position: absolute;
            overflow: visible;
            z-index: 9999;
            top: 50px;
            .navDropDownItem {
              padding: 2px 3px;
              width: 100%;
              color: white;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 10px 0;
              &:hover {
                color: white;
                background: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%);
              }
            }
          }
          &:hover {
            background: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%);
            color: white;
            .navDropDown {
              display: flex;
              flex-direction: column;
            }
          }
          .navDropDown:hover {
            display: flex;
            flex-direction: column;
          }
        }
      }
      .iconRow {
        display: flex;
        align-items: center;
        justify-content: space-around;
        i {
          font-size: 25px;
        }
      }
    }
    &.sideNavExpanded {
      grid-template-columns: 250px 1fr;
    }
  }
  .newNavSide::-webkit-scrollbar{
    display: none !important;
  }
  .newNavSide {
    grid-column: 1;
    grid-row: 2;
    width: 0;
    height: 0;
    background-color: white;
    transition: width 0.25s linear;
    &:not(.sideNavExpanded) {
      .sideNavItemsTop, .sideNavItemsBottom {
        width: 0 !important;
        height: 0 !important;
        overflow: hidden !important;
      }
    }
    .hiddenSideNav {
      display: none;
    }
    .sideNavItemsTop, .sideNavItemsBottom {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
    }
    .adminBoxHolder {
      width: 100%;
      height: 200px;
      display: flex;
      opacity: 0;
      transition: none;
      align-items: center;
      margin-top: 20px;
      justify-content: center;
      .adminBox {
        width: 90%;
        height: 100%;
        background: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%), #DEDEDE;;

      }
    }
    .sideNavItem {
      width: 100%;
      font-size: 16px;
      cursor: pointer;
      opacity: 0;
      position: relative;
      transition: none;
      .sideNavItemInner {
        display: flex;
        padding: 15px 10px 10px 15px;
        i {
          font-size: 20px;
        }
      }
      i {
        margin-left: 10px;
        margin-right: 20px;
        background-image: linear-gradient(90deg, #ED3A75 0%, #B150B1 100%);
        background-clip: text;
        color: transparent;
      }
      &:hover {
        .topIcon {
          color: white;
        }
      }
      .sideNavItemInner:hover {
        background: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%);
        color: white;
        &::before {
          content: "";
          width: 8px;
          height: 100%;
          background: #1C1C1CB8;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }

    .sideNavItem {

      .sideNavDropDownItem {
        color: black;
        height: 0;
        overflow: hidden;
        opacity: 0;
        transition: all 0.5s ease-in-out !important;
      }
      .subDropDown:hover {
        .subIcon {
          color: white;
        }
      }
      .sideNavDropDown {
         color: black;
          &.extended {
          .sideNavDropDownItem {
            height: fit-content;
            opacity: 1;
          }
        }
       }

    }

    &.sideNavExpanded {
      height: 100%;
      width: 250px;
      .sideNavItem, .adminBoxHolder {
        opacity: 1;
        transition: opacity 1s linear;
        transition-delay: 0.5s;
      }
    }
  }
  .newNavContent {
    grid-column: 2;
    grid-row: 2;
    width: 100%;
    height: 100%;
    display: inline-block;
    overflow-x: hidden;
  }
  .switch-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    .switch {
      width: 50px;
      height: 19px;
      border-radius: 20px;
      cursor: pointer;
      background-color: #56554D;
      position: relative;
      .switch-text {
        color: white;
        width: 100%;
        height: 100%;
        line-height: 18px;
        font-size: 0.85rem;

      }
      &::after {
        content: '';
        width: 15px;
        height: 15px;
        background: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%);
        border-radius: 50%;
        position: absolute;
        box-shadow: 1px 1px 1px #6A356A;
        top: 2px;
        left: 3px;
        transition: transform 0.25s ease-out;
      }
      &.untoggled {
        background-color: #D3D3D3;
        .switch-text {
          text-align: right !important;
          padding-right: 5px;
          transform: translate(0px, 0px);
        }
      }
      &.toggled {
        .switch-text {
          text-align: left !important;
          padding-left: 8px;
        }
        &::after {
          top: 2px;
          transform: translate(30px, 0px) rotate(180deg);
        }
      }
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 992px) {
    .newNavTop {
      height: auto;
    }
    .topNavMain {
      margin-top: 10px;
      grid-template-columns: 3fr 3fr 2fr 100px !important;
      grid-template-rows: 1fr 1fr;
      .navDropDown {
        .navDropDownItem {
          height: 30px !important;
        }
        top: 30px !important;

      }
      .searchBar {
        grid-column: 1 / span 3;
        grid-row: 1;
        padding: 5px 0 !important;
      }
      .iconRow {
        grid-column: 3 / span 2;
        grid-row: 2;
      }
      .dark-mode-container {
        grid-column: 4;
        grid-row: 1;
      }
      .iconDropDown {
        right: 5px !important;
      }
      .navItems {
        grid-column: 1 / span 2;
        grid-row: 2;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .newNavContent {
      &.sideNavExpanded {
        display: none !important;
      }
    }
    .apptHolder {
      right: -60px !important;
      top: 20px !important;
    }

    .walletHolder {
      left: -80px !important;
      top: 35px !important;
      width: 300px !important;
    }
    .newNavTop {
      height: auto;
    }
    .newNavSide , .topNavLeftSide {
      &.sideNavExpanded {
        width: 100vw !important;
      }
    }
    .topNavLeftSide {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .hiddenSideNav {
      display: initial !important;
    }
    .switch {
      margin-top: 5px !important;
    }
    .topNavMain {
      &.sideNavExpanded {
       display: none !important;
      }
      grid-template-columns: 5fr 2fr !important;
      grid-template-rows: 1fr !important;
      display: grid;
      padding-right: 15px !important;
      padding-top: 8px !important;
      padding-bottom: 4px !important;

      .searchBar {
        display: none;
        max-height: 50px !important;
        i {
          margin-right: 5px !important;
        }
        .innerSearchBar {
          padding: 5px 5px 5px 14px !important;
        }
        input {
          padding-left: 3px !important;
          padding-right: 3px !important;
        }
        grid-column: 1 / span 2;
        padding: 2px 15px 5px 0 !important;
      }
      .navItems {
        display: none !important;
      }

    }
  }
}
</style>