<template>
  <div id="faqs" class="bg-white">
      <div class="faq-header bg-brand-secondary">
        <h2 class="faq-heading">Frequently Asked Questions</h2>
        <div>
          <div id="input-container">
            <input id="input" v-model="search" placeholder="Search" type="text">
            <i v-if="!search" class="fa-light fa-magnifying-glass brand-highlight-1"></i>
            <i v-else class="fa-light fa-xmark brand-highlight-1" @click="resetSearch()"></i>
          </div>
        </div>
      </div>
      <div class="content-container">

      <div v-for="(category, i) in questions" :id="'acc'+i" :key="'acc'+i">
        <div  class="accordion" role="tablist" v-if="search.length === 0 || category.display">
          <b-card  class="mb-1" no-body>
            <b-card-header :header="'header-'+i" class="p-1" role="tab">
              <b-button v-b-toggle="'accordion-' + i" block variant="info">{{ category.title }}
                <i class="fa-light fa-circle-plus"></i>
                <i class="fa-light fa-circle-minus"></i>
              </b-button>
            </b-card-header>
            <b-collapse :id="'accordion-' + i" :accordion="'my-accordion'+i" :visible="category.title === topDrawer" role="tabpanel">
              <b-card-body class="category-container">
                  <div v-for="(q,question,i) in category.questions"
                       :key="question + i"
                       class="question-container">
                    <div>
                      <div :class="{greyQuestion: !q.display}" class="question">{{q.question}}</div>
                      <div :class="{greyQuestion: !q.display}" class="answer" v-html="q.answer"></div>
                    </div>
                  </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>

      </div>
      <div class="faq-footer">
        <h4 class="footer-question">Can't Find the Answer You're Looking For?</h4>
        <p class="footer-paragraph">Contact our support team
          <a class="support-email brand-highlight-1" href="mailto: support@spectrum.life">support@spectrum.life</a>
        </p>
      </div>
    </div>
</template>

<script>

import qs from '../assets/faqs.json'

export default {
  name: "faqs",
  data() {
    return {
      questions: {},
      category: null,
      search: '',
      topDrawer: ''
    }
  },
  watch: {
    search() {
      this.extractJson();
      this.firstDrawer();
    }
  },
  methods: {
    firstDrawer() {
      let top = '';
      this.questions.forEach(q => {
        if(!top && q.display) {
          top = q.title;
        }
      });
      this.topDrawer = top;
    },
    resetSearch() {
      this.search = '';
    },
    displayQuestion() {
      return true;
    },
    selectCategory(category) {
      this.category = category;
    },
    extractJson() {
      let all_questions = [];
      Object.keys(qs).forEach(key => {
        let display = !this.search;
        if(!this.search) display = false;
        let questions = [];
        Object.keys(qs[key]).forEach(q => {
          let question = q;
          let answer = qs[key][q];
          const combined = question.toLowerCase() + answer.toLowerCase();
          let displayQ = true;
          if(this.search) {
            displayQ = combined.toLowerCase().includes(this.search.toLowerCase());
            if(displayQ) {
              display = true;
              questions.unshift({question: question, answer:answer, display: displayQ});
            } else {
              questions.push({question: question, answer:answer, display: displayQ});
            };
          } else {
            questions.push({question: question, answer:answer, display: displayQ});
          }
        })
        if(this.search && display) {
          all_questions.unshift({title: key, questions: questions, display: display});
        } else {
          all_questions.push({title: key, questions: questions, display: display});
        }

      });
      this.questions = all_questions;
    }
  },
  mounted() {
    this.extractJson();
  },
}
</script>

<style lang="less">
#faqs {

  .not-collapsed .fa-circle-plus {
    display: none !important;
  }
  .collapsed .fa-circle-minus {
    display: none !important;
  }
  .accordion {
    max-width: 600px !important;
    margin: auto !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;

    .collapsed:hover {
      background-color: darken(#f7f7f7,10%) !important;
    }
    .collapsed {
       background-color: #f7f7f7 !important;
       color: #403d56 !important;
     }
    button {
      background-color: #403d56 !important;
      border-radius: 0 !important;
      color: white;
      text-align: left !important;
      padding: 18px;
      display: flex;
      justify-content: space-between;
      font-family: "FilsonProBold", sans-serif !important;
      font-size: 1.4rem;
    }


    .card, .card-body, .card-header {
      border: none !important;
      background-color: white !important;

    }
    .card-header {
      padding: 0 !important;
    }
    .card {
      border: 1px lightgrey solid !important;
      margin-bottom: 10px !important;
    }
  }
  .category-container {
    padding: 20px;
  }
  .question-container {
    margin-bottom: 30px;
    .question {
      font-family: "FilsonProBold", sans-serif;
      font-size: 1.4rem;
      margin-bottom: 10px;
      color: #403d56;
    }
    .greyQuestion {
      color: darken(#f7f7f7,20%)!important;
    }
    .greyQuestion * {
      color: darken(#f7f7f7,20%)!important;
    }
    .answer {
      color: #403d56;
      font-size: 1.3rem;
    }
    img {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
  .faq-header{
    padding: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    #input-container {
      position:relative;
      padding:0;
      margin:0;
      display: flex;
      justify-content: center;
      align-content: center;
      width: 300px;
      input {
        width: 100%;
        height:40px;
        margin:0;
        padding-left: 20px;
        font-size: 1.5rem;
        font-family: "FilsonProBold", sans-serif;
      }
      i {
        position:absolute;
        bottom:19px;
        left:260px;
        width:10px;
        height:10px;
        font-size: 2rem;
      }
    }
    .faq-heading {
      font-size: 2.3rem;
      font-family: "FilsonProBold", sans-serif;
      color: white;
    }
  }
  .faq-footer{

    background-color: #f7f7f7;
    padding: 50px;
    .footer-question {
      color: #394961;
      font-family: "FilsonProBold", sans-serif;
      text-align: center;
      margin-bottom: 10px;
    }
    .footer-paragraph {
      color: #394961;
      font-size: 1.5rem;
      text-align: center;
      .support-email {
        font-family: "FilsonProBold", sans-serif;
      }
    }
  }
}
</style>