<template>
    <div class="VimeoCard">
      <div class="video-card-video-container-top-events shadow position-relative" @click="goToVideo()"
           v-if="false">
        <div class="w-100 h-100 clickable d-flex justify-content-center inner-container">
          <img class="img-fluid image-class h-100" :src="getThumbnail()" />
        </div>
        <div class="card-info row pt-2">
          <div class="row">
              <div class="col-8 pt-2">
                  <div class="top-event-title pt-0">{{video.title}}</div>
              </div>
              <div class="col-4 pt-2 px-0">
                  <div class="day col-12">{{getFormatedDate(video.start, "DD")}}</div>
                  <div class="month col-12">{{getFormatedDate(video.start, "MMM")}}</div>
              </div>
          </div>
            <div class="w-100">
                <div class="time">
                    <i class="fa-solid fa-clock"></i>
                    {{ getFormatedDate(video.start, "HH:mm") }} -
                    {{ getFormatedDate(video.end, "HH:mm") }}
                </div>
            </div>
        </div>
        <div class="book-now cta-button hover-darken py-3 px-5">
          <div class="start-text">{{bookingText}}</div>
        </div>
      </div>
        <div class="video-card-video-container-static shadow position-relative" v-else-if="status === 'not_started' || vimeoId === 0" @click="goToVideo()">
          <div class="w-100 h-100 clickable d-flex justify-content-center bg-grey">
            <img class="img-fluid image-class-permanent h-100 bg-img" :src="video.thumbnail" v-if="video.thumbnail" />
            <img class="img-fluid image-class-2-permanent h-100 px-5 bg-img"
                 :src="type === 'Digital Gym' ? '/img/wellbeing-studio/digital-gym.svg' : '/img/wellbeing-studio/wellbeing-studio.svg'"
                 v-else />
            <div class="vimeo-wrapper img-fluid video-class bg-img" style="width: 100%; height: 100%" @click="clickIframe">
              <div ref="player" style="width: 100%; height: 100%"></div>
            </div>
            <div class="video-card-live animate__animated d-flex justify-content-between align-items-center" v-if="status === 'live'">
              <svg height="12px" width="12px" class="blinking mr-2 ml-2">
                <circle cx="5" cy="5" r="5" fill="white" />
              </svg>
              <div>LIVE</div>
            </div>
          </div>
        </div>
        <div class="video-card-video-container shadow position-relative" @click="goToVideo()" v-else>
            <div class="w-100 h-100 clickable d-flex justify-content-center bg-grey" @mouseover="playVideo()"
                @mouseleave="resetVideo()" @click="">
                <img class="img-fluid image-class h-100 bg-img" :src="video.thumbnail" v-if="video.thumbnail" />
                <img class="img-fluid image-class-2 h-100 px-5 bg-img"
                    :src="type === 'Digital Gym' ? '/img/wellbeing-studio/digital-gym.svg' : '/img/wellbeing-studio/wellbeing-studio.svg'"
                    v-else />
              <div class="vimeo-wrapper img-fluid video-class bg-img" style="width: 100%; height: 100%" @click="clickIframe">
                <div ref="player" style="width: 100%; height: 100%"></div>
              </div>

            </div>
            <div class="video-card-featured bg-brand-highlight-1 animate__animated" v-if="false">Featured</div>
            <div class="video-card-live animate__animated d-flex justify-content-between align-items-center" v-if="status === 'live'">
              <svg height="12px" width="12px" class="blinking mr-2 ml-2">
                <circle cx="5" cy="5" r="5" fill="white" />
              </svg>
              <div>LIVE</div>
            </div>
            <div class="position-absolute w-100" v-if="videoProgress && !videoIsPlaying" style="bottom: 8px">
              <b-progress :value="videoProgress" :max="1" class="progressBar w-75" style="margin: auto">
              </b-progress>
            </div>
            <div class="video-card-duration" v-if="runningTime && !videoIsPlaying && !videoProgress">
              {{runningTime}}
            </div>
            <div v-if="video.type !== 'fitness_plans'">
              <div  class="video-card-favourite" v-if="!video.favourited"><i @click="toggleDGFavourite" class="fa-regular fa-heart fa-lg"></i></div>
              <div  class="video-card-favourite" v-else><i @click="toggleDGFavourite" class="fa-solid fa-heart fa-lg"></i></div>
            </div>
<!--            <div class="video-card-play-button w-100 text-center animate__animated"><i-->
<!--                    class="fas fa-play-circle fa-6x text-white"></i>-->
<!--            </div>-->
        </div>
        <div class="video-card-meta-container d-flex" v-if="true">
          <div class="w-75">
            <div class="video-card-series"></div>
            <div class="video-card-sub-title" v-if="video.subtitle && type !== 'exercise'">{{video.subtitle}}</div>
            <div class="d-flex flex-row flex-nowrap justify-content-between align-items-center">
              <div class="video-card-title brand-text clickable">{{ video.title }}</div>
            </div>
          </div>
          <div v-if="class_number" class="w-25 d-flex justify-content-end align-items-center">
            <b-badge pill variant="secondary">{{class_number}} classes</b-badge>
          </div>
          <div class="w-25 d-flex align-items-center justify-content-end" v-else-if="product_id && !progress">
            <i :id="'myList-'+unique_id" class="fa-solid myListBtn mr-4" :class="isItemInMyList ? 'fa-circle-check plus':'fa-circle-plus plus'" @click="watchLater"></i>
            <b-tooltip :target="'myList-'+unique_id" triggers="hover" placement="top" custom-class="mylist-tooltip">
              {{isItemInMyList ? 'Remove from Watch Later' : 'Add to Watch Later'}}
            </b-tooltip>
          </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import Vimeo from '@vimeo/player'
import VideoCardTags from "@/components/Home/New/VideoCardTags.vue";
export default {
    name: "VimeoCard",
    props: ['video', 'type','swiping','permaTag','reminders','unique_id','progress'],
    components: {
      VideoCardTags
    },
    data() {
        return {
          loading: false,
          runningTime: null,
          videoIsPlaying: false,
          vimeoId: 0,
          tagsToDisplay: [],
        };
    },
    mounted() {
      if(this.video.start && this.video.end) this.setRunningTime();
      this.extractVimeoId();
    },
    computed: {
      class_number() {
        if(this.video.hasOwnProperty('details')) {
          if(this.video.details.hasOwnProperty('class_number')) {
            return this.video.details.class_number;
          }
        }
        return null;
      },
      videoProgress() {
        const data = this.$store.getters.user.user.course_progress.filter(item => (item.channel === 'dg' || item.channel === 'ds')
            && Number.parseInt(this.video.id) === Number.parseInt(item.id));
        if(!data.length) return null;
        return data[0].percent;
      },
      startTime() {
        if(!this.videoProgress) return null;
        const data = this.$store.getters.user.user.course_progress.filter(item => (item.channel === 'dg' || item.channel === 'ds')
            && Number.parseInt(this.video.id) === Number.parseInt(item.id));
        return data[0].seconds;
      },
      product_id () {
        if (this.video.source === 'TrainingPlan') return null;
        return this.video.schedule_type === 'studio' ? 21 : 22;
      },
      isItemInMyList() {
        if (this.video.source === 'TrainingPlan') return null;
        const isInList = this.$store.getters.myList.filter(item =>
            Number.parseInt(item.product_id) === Number.parseInt(this.product_id)
            && Number.parseInt(item.content_id) === Number.parseInt(this.video.id));
        return isInList.length > 0;
      },
      componentText() {
        return this.getText.dfd;
      },
      bookingText() {
        if(this.status !== 'not_started') return this.componentText.cards.watchNow;
        if(this.reminderIsSet) return this.componentText.cards.booked;
        return this.componentText.cards.bookNow;
      },
      reminderIsSet() {
        let isSet = false;
        this.reminders.forEach(rem => {
          if(rem.type ==='ws' && this.video.schedule_type === 'studio' && this.video.id === rem.id) isSet = true;
          if(rem.type ==='dg' && this.video.schedule_type === 'gym' && this.video.id === rem.id) isSet = true;
        });
        return isSet;
      },
      status() {
        if(this.video.source === 'TrainingPlan') return 'ended';
        const start = moment(this.video.start);
        const end = moment(this.video.end);
        const now = moment();
        if(now.isBefore(start) || !this.video.start || !this.video.end) return 'not_started';
        if(now.isAfter(end)) return 'ended';
        return 'live';
      }
    },
    methods: {
      async watchLater() {
        if (this.video.source === 'TrainingPlan') return;
        if(!this.isItemInMyList) {
          await this.$store.dispatch('addMyListItem',({media_type: 'video', product_id: this.product_id, content_id: this.video.id}));
        } else {
          const myListRecord = this.$store.getters.myList.filter(item => item.product_id === this.product_id && item.content_id === this.video.id);
          await this.$store.dispatch('deleteMyListItem',({id: myListRecord[0].id}));
        }
      },
      getThumbnail() {
        if(this.video.thumbnail) return this.video.thumbnail;
        if(this.video.schedule_type === 'studio') return '/img/wellbeing-studio/wellbeing-studio.svg';
        return '/img/wellbeing-studio/digital-gym.svg';
      },
      extractVimeoId() { // Check if URL is a Vimeo URL
        if(this.video.embed_video && !this.video.embed_video.includes('vimeo')) {
          this.vimeoId = 0;
          return;
        }
        if (!/^https?:\/\/(?:www\.)?vimeo\.com\/\d+(?:\?|$)/i.test(this.video.embed_video)) this.vimeoId = 0;
        const match = /[0-9]+/.exec(this.type === 'exercise'? this.video.url:this.video.embed_video);
        if (!match) {
          this.vimeoId = 0;
        }
        this.vimeoId = match[0];
        return match[0];
      },
      async clickIframe() {
        if(this.loading || this.swiping) return;
        this.goToVideo();
      },
      setRunningTime() {
        const duration = moment.duration(moment(this.video.end).diff(moment(this.video.start)));
        const hours = parseInt(duration.asHours());
        const minutes = parseInt(duration.asMinutes()) % 60;
        const seconds = parseInt(duration.asSeconds()) % 60;
        let string = '';
        if(hours) {
          string += this.parseUnitOfTime(hours) + ':';
        }
        string += this.parseUnitOfTime(minutes) + ':';
        string += this.parseUnitOfTime(seconds);
        this.runningTime = string;
      },
      parseUnitOfTime(unit) {
        if(unit < 10) {
          return '0' + unit;
        } else {
          return unit;
        }
      },
      async toggleDGFavourite() {
        this.loading = true;
        const payload = {
          user_id: this.$store.getters.user_id,
          event_id: this.video.id,
        };
        let res = await this.axios.post(
            process.env.VUE_APP_API_PATH +
            "/application/api/u/studio/toggle-favourite-gym-event",
            payload
        );
        if (res.data.success) {
          this.video.favourited = res.data.is_favourite;
        }
        this.loading = false;
      },
      goToVideo() {
            if(this.loading || this.swiping) return;
            if(this.player) this.player.destroy();
            if(this.$route.name === "home") {
              if (this.video.source === 'TrainingPlan') this.$router.push('/digital-gym/training-plans/'+this.video.id + '?back=home')
              if (this.video.schedule_type === 'gym') this.$router.push('/digital-gym/' + this.video.id + '?back=home')
              if (this.video.schedule_type === 'studio') this.$router.push('/wellbeing-studio/' + this.video.id + '?back=home')
            } else {
              if (this.video.source === 'TrainingPlan') this.$router.push('/digital-gym/training-plans/'+this.video.id)
              if (this.video.schedule_type === 'gym') this.$router.push('/digital-gym/' + this.video.id)
              if (this.video.schedule_type === 'studio') this.$router.push('/wellbeing-studio/' + this.video.id)
            }

        },
      playVideo() {
        if(this.vimeoId === 0 || this.status === 'not_started' || this.status === 'live') return;
        if(!this.player) {
          this.initVideo()
        }
        this.videoIsPlaying = true;
        this.player.setCurrentTime(this.startTime ?? 0);
        this.player.play();
      },
      initVideo() {
        this.player = new Vimeo(this.$refs.player, {
          id: this.vimeoId,
          autoplay: false,
          controls: false,
          byline: false,
          title: false,
          portrait: false,
        });
      },
      resetVideo() {
          this.player.pause();
          this.videoIsPlaying = false;
      }
    }
};
</script>

<style lang="less">


.VimeoCard {
    border-radius: 10px;
  .video-card-video-container-static,
  .video-card-video-container,
  .video-card-meta-container,
  .inner-container,
  .bg-img,
  .video-card-video-container-top-events {
    border-radius: 10px;
    img {
      border-radius: 10px;
    }
  }
  @keyframes fadeIn {
    from {
      display: none;
    }

    to {
      display: block;
    }
  }

  @keyframes fadeOut {
    from {
      display: block;
    }

    to {
      display: none;
    }
  }
  .row {
    margin: 0!important;
  }

  .video-card-video-container-top-events {
    aspect-ratio: 16/9 !important;
    font-family: "FilsonProBold", sans-serif;
    .top-event-title {
      font-family: FilsonProBold, Arial, Helvetica, sans-serif;
      font-size: 1.4rem;
      line-height: 1.6rem;
      overflow: hidden;
    }
    .video-class {
      display: none;
    }
    .inner-container {
      filter: brightness(0.4);
      position: absolute;
    }
    .card-info {
      position: absolute;
      height: 90%;
      color: white !important;
      .row {
        width: 100%;
      }
      h3 {
        font-family: "FilsonProBold", sans-serif !important;
        color: white !important;
        font-size: 1.8em;
      }
      .day {
        font-family: FilsonProBook, Arial, Helvetica, sans-serif;
        text-align: right !important;
        font-size: 3rem;
        line-height: 3rem;
        width: 100%;
        padding: 0 10px 0 0 !important;
      }
      .month {
        font-family: FilsonProBook, Arial, Helvetica, sans-serif;
        text-align: right !important;
        font-size: 1.8rem;
        line-height: 2rem;
        width: 100%;
        padding: 0 10px 0 0 !important;
      }
      .time {
        font-family: FilsonProBook, Arial, Helvetica, sans-serif;
        text-align: right !important;
        font-size: 1.3rem;
        width: 100%;
        padding: 0 10px 0 0 !important;
      }
    }
    .cta-button {
      &:hover {
        cursor: pointer !important;
      }
    }
    .book-now {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 50%;
      padding: 1px 8px !important;
      border-radius: 15px 0px 9px 0px;
      .start-text {
        width: 100%;
        text-align: center;
        font-family: "FilsonProBold", sans-serif !important;
        font-size: 1.0rem;
      }
    }
  }
    .vimeo-wrapper {
      position: relative;
      * {
        border: none !important;
      }
      iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        pointer-events: none;
        border: none !important;
      }
    }
    .video-card-video-container {
        aspect-ratio: 16/9 !important;
        font-family: "FilsonProBold", sans-serif;
        overflow: hidden;
        margin-bottom: 10px;
        .iframe-class {
          iframe {
            border: none !important;
          }
          border: none !important;
        }
        .video-class, .iframe-class {
            display: none;
        }

        .image-class-2, .image-class-2-permanent {
            background-color: #eee !important;
        }

        &:hover {
            .image-class {
                display: none;
            }

            .image-class-2 {
                display: none;
            }

            .video-card-play-button {
                pointer-events: none;
                animation-name: fadeOut;
                animation-duration: 0.3s;
            }

            .video-card-featured {
                pointer-events: none;
                animation-name: fadeOut;
                animation-duration: 0.3s;
            }

            .video-card-duration {
                pointer-events: none;
                animation-name: fadeOut;
                animation-duration: 0.3s;
                z-index: 1000;
            }

            .video-class, .iframe-class {
                display: block;
            }
        }
    }

    .video-card-video-container-static {
    aspect-ratio: 16/9 !important;
    font-family: "FilsonProBold", sans-serif;
    overflow: hidden;
    margin-bottom: 10px;

    .iframe-class {
      iframe {
        border: none !important;
      }
      border: none !important;
    }
    .video-class, .iframe-class {
      display: none;
    }

    .image-class-2, .image-class-2-permanent {
      background-color: #eee !important;
    }
  }
    .video-card-play-button {
        position: absolute;
        top: 35%;

    }

    .video-card-featured {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: "FilsonProBold", sans-serif;
        position: absolute;
        left: 0;
        top: 0;
        margin-left: 10px;
        margin-top: 10px;
        padding: 5px;
        color: white;
        border-radius: 3px;
    }
    .video-card-live {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-family: "FilsonProBold", sans-serif;
      position: absolute;
      right: 0;
      bottom: 0;
      margin-right: 10px;
      margin-bottom: 10px;
      padding: 5px;
      color: white;
      border-radius: 3px;
      background-color: red;
    }

    .video-card-duration {
        font-family: 'FilsonProBold', sans-serif;
        position: absolute;
        bottom: 0;
        right: 0;
        margin-right: 10px;
        margin-bottom: 10px;
        padding: 2px;
        background-color: white;
        color: #555;
        font-size: 1.1rem;
        min-width: 45px;
        text-align: center;
    }

    .video-card-meta-container {
        .video-card-series {
            font-size: 1.4rem;
        }

        .video-card-title {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-family: "FilsonProBold", sans-serif;
            font-size: 1.5rem;
            max-height: 70px;
        }
        .video-card-sub-title {
          font-family: "FilsonPro", sans-serif;
          font-size: 1.2rem;
        }
        .video-card-tags {
            .video-card-tag {
                text-transform: capitalize;
                font-size: 1.2rem;
                padding: 3px 8px;
                margin-bottom: 2px;
                border: 1px solid #555;
                border-radius: 100px;
                max-width: 100px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }
  .video-card-favourite {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    &:hover {
      cursor: pointer;
    }
  }
  .blinking {
    -webkit-animation: 1s blink ease infinite;
    -moz-animation: 1s blink ease infinite;
    -ms-animation: 1s blink ease infinite;
    -o-animation: 1s blink ease infinite;
    animation: 1s blink ease infinite;

  }
  .myListBtn {
    font-size: 2.4rem;
    color: grey !important;
    cursor: pointer;
  }

  @keyframes "blink" {
    from, to {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

  @-moz-keyframes blink {
    from, to {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

  @-webkit-keyframes "blink" {
    from, to {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

  @-ms-keyframes "blink" {
    from, to {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

  @-o-keyframes "blink" {
    from, to {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

  @media only screen and (max-width: 450px) {
    .top-event-title {
      font-size: 3rem !important;
      line-height: 3rem !important;
    }
    .time {
      font-size: 2.5rem !important;
    }
    .day {
      font-size: 5rem !important;
    }
    .month {
      margin-top: 15px;
      font-size: 3.5rem !important;
    }
    .book-now {
      .start-text {
        font-size: 2rem !important;
      }
    }
  }
  @media only screen and (min-width: 451px) and (max-width: 576px) {
    .top-event-title {
      font-size: 4rem !important;
      line-height: 4rem !important;
    }
    .time {
      font-size: 3rem !important;
    }
    .day {
      font-size: 6rem !important;
    }
    .month {
      margin-top: 15px;
      font-size: 4rem !important;
    }
    .book-now {
      .start-text {
        font-size: 3rem !important;
      }
    }
  }
  @media only screen and (min-width: 577px) and (max-width: 767px) {
    .top-event-title {
      font-size: 5rem !important;
      line-height: 5rem !important;
    }
    .time {
      font-size: 4rem !important;
    }
    .day {
      font-size: 7rem !important;
    }
    .month {
      margin-top: 30px;
      font-size: 5rem !important;
    }
    .book-now {
      .start-text {
        font-size: 3rem !important;
      }
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 950px) {
    .top-event-title {
      font-size: 3rem !important;
      line-height: 3rem !important;
    }
    .time {
      font-size: 2.5rem !important;
    }
    .day {
      font-size: 5rem !important;
    }
    .month {
      margin-top: 20px;
      font-size: 3rem !important;
    }
    .book-now {
      width: 75% !important;
      .start-text {
        font-size: 2rem !important;
      }
    }
  }
  .progressBar {
    height: 10px;
    .progress-bar {
      background-color: #4097dd;
    }
  }
  .badge {
    padding: 5px 0 !important;
    text-align: center;
    width: 100%;
    font-size: 1.1rem;
    max-width: 120px;
  }
}
</style>
