<template>
    <div id="pop-up">
        <div class="pop-up-background" @click="hidePopUp"></div>
        <div class="pop-up-content bg-brand-secondary row">
            <div class="col-12">
                <h4>{{content.header}}</h4>
            </div>
            <div class="col-12">
                <p v-html="content.message"></p>
            </div>
            <div class="col-12 pt-5" v-if="content.subtext">
                <p>{{content.subtext}}</p>
            </div>
            <div class="col-12">
                <b-button v-if="content.buttonText" @click="$emit('close')" class="cta-button mt-5 py-3 px-5">{{content.buttonText}}</b-button>
            </div>
            <div v-if="content.showRating" class="col-12 mt-5 rating">
                <!--<star-rating v-model="rating" v-bind:show-rating="false"></star-rating> -->
                <!-- <p class="mt-5 review-message">{{getText.loggedIn.wellbeing.course.review}}</p> -->
            </div>
            <b-button class="close-popup" @click="hidePopUp"><font-awesome-icon icon="times-circle"></font-awesome-icon></b-button>
        </div>
    </div>
</template>
<script>
   // import StarRating from 'vue-star-rating'
    export default {
        name: "Popup",
        props: {
            content:{}

        },
        components:{
            //StarRating
        },
        data(){
            return {
                rating: 0
            }
        },
        methods:{
            hidePopUp(){
                this.$emit('close')
            }
        },
        watch:{
            rating(newRating){
                this.$emit('rating', newRating)
            }
        }
    }
</script>
<style lang="less">
    #pop-up{
        display: flex;
        align-items: center;
        z-index: 500;
        position:fixed;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        text-align: center;
        .pop-up-content{
            max-width: 636px;
            width: 100%;
            margin: auto;
            border-radius: 25px;
            padding: 50px 20px;
            font-size: 1.6rem;
            text-align: center;
            z-index: 10;
            color: #fff;
            position: relative;
            p{
                color: #fff;
            }
            h4{
                font-family: 'FilsonProBold', sans-serif;
                font-size: 3.8rem;
                color: #fff;
            }
            .form-control{
                height: 30px;
                border-radius: 5px;
                font-size: 1.6rem;
            }
            .service-description{
                text-align: left;
            }
            .close-popup{
                position: absolute;
                top: 0;
                right: 0;
                background-color: transparent;
                width: 50px;
                height:50px;
                svg{
                    width: 30px;
                    height: 30px;
                }
            }
            .rating{
                text-align:  center;
                margin: auto;
                .review-message {
                    color: #fff;
                    font-family: Open Sans, sans-serif;
                }
                .vue-star-rating{
                    text-align: center;
                    margin: auto;
                }
            }
        }
        .pop-up-background{
            width: 100vw;
            height: 100vh;
            position: absolute;
            left: 0;
            right: 0;
            z-index: 5;
            background: transparent;
        }
    }
</style>