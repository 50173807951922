<template>
    <div id="LayaBeCalm">
        <div class="page-header bg-grey">
            <div class="content-container row">
                <div class="page-title col-12 px-md-5">
                    <h2>Explore guided meditation</h2>
                    <p>Self-guided meditation is just one technique you could use to clear out negative energy from your mind, and promote positive thinking and inner peace. Granted, calming your mind can be a challenge, but with practice, it can become second nature!</p>
                </div>
            </div>
        </div>
        <div class="bg-white px-md-5 content-container" v-if="!loading">
          <div class="tpContainer" v-if="beCalmsCourses.length !== 0">
            <div style="max-width: 300px" v-for="(plan,i) in beCalmsCourses" class="tpOuter">
              <LayaCarouselStudioCard :item="plan" :swiping="false" :type="'beCalm'" :matomo_class="'guided_meditation'" :t_i="0"/>
            </div>

          </div>
        </div>
        <div class="bg-white px-md-5 py-5" v-else>
            <b-spinner></b-spinner>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import config from "@/config/constants";
    import LayaCarouselStudioCard from "@/components/NewWellbeing/LayaCarouselStudioEventCard.vue";
    export default {
        name: "LayaBeCalm",
      components: {LayaCarouselStudioCard},
        data(){
            return{
                loading: true,
                courses:[]
            }
        },
        mounted(){
            this.getCourses()
        },
        methods:{
            getBeCalmImage(plan) {
              let url = 'https://spectrum-life-media.s3.eu-west-1.amazonaws.com/CONTENT/BeCalm/dfd_be_calm_images/'
              switch (plan.course_title) {
                case 'Sleep Aid':
                  url += 'Sleep aid_hero.png'
                  break;
                case 'Reducing Stress':
                  url += 'Reducing stress_hero.png'
                  break;
                case 'Self-Compassion':
                  url += 'Self compassion_hero.png'
                  break;
                case 'Improving Performance':
                  url += 'Improving performance_hero.png'
                  break;
                case 'Increase Happiness':
                  url += 'Increase Happiness_hero.png'
                  break;
                case 'Reducing Anxiety':
                  url += 'Reducing Anxiety_hero.png'
                  break;
                case 'Birdsong in the Woods':
                  url += 'Birdsong in the Woods_hero.png'
                  break;
                case 'Babbling Brook':
                  url += 'Babbling Brook_hero.png'
                  break;
                case 'Living in the Moment':
                  url += 'Living in the Moment_hero.png'
                  break;
                case 'Inspiring Hope and Fulfilment':
                  url += 'Inspiring Hope and Fulfilment_hero.png'
                  break;
                default:
                  return plan.course_image_url;
              }
              return url;
            },
            async getCourses(){
                const payload = {
                    user_id: this.$store.getters.user_id,
                    channel: "be_calm",
                    limit: 0
                }
                let res = await axios.post(config.api_env + '/application/api/u/resources/get-be-calm-courses', payload, config.options)
                if(res.data.success){
                    this.courses = res.data.courses
                    this.loading = false
                }
            }
        },
        computed:{
            beCalmsCourses() {
              return this.courses.map(plan => {
                return {
                  created_on: null,
                  details: {
                    class_number: plan.course_sessions,
                    description: plan.course_description,
                    duration: null
                  },
                  favourited: false,
                  id: plan.course_id,
                  media: 'video',
                  source: 'beCalm',
                  subtitle: null,
                  tags: [],
                  thumbnail: this.getBeCalmImage(plan),
                  title: plan.course_title,
                  type: 'beCalm',
                  url: null,
                  course_color: plan.course_color
                }
              })
            },
            componentText() {
                return this.getText.loggedIn.beCalm;
            },
        }

    }
</script>

<style lang="less">
    #LayaBeCalm{
      padding-top: 48px;
      padding-bottom: 48px;
      h2 {
        padding-bottom: 8px;
      }
      .cardTextBottom {
        display: none !important;
      }
      .carouselCard {
        margin-left: 0 !important;
        .cardText {
          justify-content: flex-start;
        }

        .cardDescription {
          margin-bottom: 0 !important;
          p {
            margin-bottom: 0 !important;
          }
        }
      }
      .content-container {
        //max-width: 1500px !important;
      }
        background: #fff;
        .page-header {
            padding-bottom: 32px;
            .page-title {
                color: #3a4961;
                h2 {
                    font-family: 'FilsonProBold', sans-serif;
                    letter-spacing: 0;
                    text-align: left;
                    margin-bottom: 0 !important;
                }
                .light {
                    font-family: 'FilsonProLight', sans-serif;
                }
                p{
                    font-size: 16px;
                    margin-bottom: 0;
                    margin-left: 2px;
                    color: #36383A;
                }
            }
        }
        .course-card{
            display: flex;
            flex-direction: row;
            border-radius: 27px;
            box-shadow: 3px 3px 10px #cccccc;
            cursor: pointer;
            min-height: 292px;
            &:hover{
                transform: translate(-3px, -3px);
            }
            h3{
                padding: 0;
                color: #394961;
                letter-spacing: 0;
            }
            .course-description {
                font-size: 16px;
                line-height: 24px;
                max-height: 120px;
                text-overflow: ellipsis;
            }
            .course-image{
                width: 140px;
                border-radius: 27px 0 0 27px;
                display: flex;
                align-items: center;
                img{
                    width: 140px;
                }
            }
            .course-content {
                display: flex;
                flex-direction: column;
                .session-info {
                    display: inline-flex;
                    margin-top: auto;
                    font-size: 1.5rem;
                    color: #808080;
                    .duration{

                    }
                    .sessions{
                        margin-left: 20px;
                    }
                    a {
                        color: #e6007e;
                        margin-left: auto;
                        font-family: FilsonProBold, sans-serif;
                        svg {
                            width: 20px;
                            .cls-1{
                                fill: #e6007e;
                            }
                        }
                        &:hover{
                            svg{
                                width: 25px;
                            }
                        }
                    }
                }
            }
            @media (max-width: 992px){
                .course-image{
                    width: 120px;
                    img{
                        width: 120px;
                    }
                }
            }
            @media (max-width: 576px){
                .course-image{
                    width: 50px;
                    img{
                        width: 50px;
                    }
                }
                .session-info a{
                    display: none
                }
            }
        }
        //IE style
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            .course-card {
                .course-content {
                    display: flex;
                    flex: 1;
                    height: 292px;
                    .course-description{
                        padding: 20px 0;
                    }
                    .session-info{
                        height: 90px;
                        padding: 10px 0;
                    }
                }
            }
        }
      .tpContainer {
        display: grid;
        grid-template-columns: repeat(auto-fit,minmax(268px, 1fr));
        row-gap: 32px;
        column-gap: 32px;
        .tpOuter {
          width: 268px;
          margin: auto;
          #LayaCarouselCard {
            width: 268px;
            margin: auto !important;
            padding: 0 !important;
          }
        }
      }
      #LayaCarouselCard {
        .cardDescription {
          max-height: 72px !important;
          text-overflow: ellipsis;
          p {
            font-size: 16px !important;
          }
        }
      }
      @media (max-width: 768px) {
        #LayaCarouselCard {
          .carouselCard{
            margin: auto !important;
          }
        }
      }
      @media screen and (max-width: 576px) {
        padding-top: 32px !important;
        .content-container {
          padding-top: 0 !important;
        }
        .tpContainer {
          justify-content: center;
        }
      }
      @media screen and (min-width: 1036px) {
        .tpContainer {
          justify-content: start;
        }
      }
    }
</style>