<template>
  <div id="content" :class="{'bg-white': isWhitePage, wellbeing: isLayaWellbeing()}">
    <transition name="fade" mode="out-in">
      <component @scroll-to="scrollTo" :is="currentContent" />
    </transition>
  </div>
</template>

<script>
import Home from "../pages/Home.vue";
import EAP from "../pages/Eap.vue";
import FAQ from "../pages/FAQ.vue";
import Settings from "../pages/Settings.vue";
import Course from "../pages/Course.vue";
import AllCourses from "../pages/AllCourses.vue";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TCs from "../pages/TCs";
import Redirect from "../pages/Redirect";
import BeCalm from "../pages/BeCalm";
import BeCalmCourse from "../pages/BeCalmCourse";
import WellbeingStudio from "../pages/WellbeingStudio";
import DigitalGym from "../pages/DigitalGym";
import TrainingPlan from "../pages/TrainingPlan";
import LayaWellbeingCourse from "../pages/LayaWellbeingCourse";
import LiveEvent from "../pages/LiveEvent";
import LiveEventTest from "../pages/LiveEventTest";
import WellbeingCourse from "../pages/WellbeingCourse";
import CourseV2 from "@/pages/CourseV2.vue";
import TrainingPlanV2 from "@/pages/TrainingPlanV2.vue";
import StudioEvent from "@/components/Wellbeing/StudioEvent.vue";
import MyProgress from "@/pages/MyProgress.vue";
import wellbeingStudioV2 from "@/pages/WellbeingStudioV2.vue";
import MyList from "@/pages/MyList.vue";
import Favourites from "@/pages/Favourites.vue";
import LayaEap from "@/pages/LayaEap.vue";
import LayaStudioEvent from "@/components/NewWellbeing/LayaStudioEvent.vue";
import LayaDigitalGym from "@/pages/LayaDigitalGym.vue";
import LayaBeCalmCourse from "@/components/NewWellbeing/LayaBeCalmCourse.vue";
import LayaSettings from "@/pages/NewWellbeing/LayaSettings.vue";
import LayaBeCalm from "@/pages/NewWellbeing/LayaBeCalm.vue";
import LayaListPage from "@/pages/LayaListPage.vue";
import DigitalClinics from "@/pages/DigitalClinics.vue";

const mappings = {
  home: Home,
  iosAppDownload: Home,
  course: Course,
  wellbeingCourse: WellbeingCourse,
  layaCourse: LayaWellbeingCourse,
  allCourses: AllCourses,
  eap: EAP,
  viewEvent: Event,
  viewService: Event,
  viewEventSeries: Event,
  faq: FAQ,
  settings: Settings,
  privacy: PrivacyPolicy,
  TCs: TCs,
  Redirect: Redirect,
  beCalm: BeCalm,
  beCalmCourse: BeCalmCourse,
  digitalGym: DigitalGym,
  trainingPlan: TrainingPlan,
  virtualEvent: LiveEvent,
  virtualEventTest: LiveEventTest,
  digitalGymArticle: LiveEvent,
  myProgress: MyProgress,
  myList: MyList,
  favourites: Favourites,
  expert: LayaEap,
  studioOverview: Home
};
// the mappings below will overwrite the above mapping where NavigationV2 is being used as the nav
const navigationV2Mappings = {
  virtualEvent: StudioEvent,
  digitalGymArticle: StudioEvent,
  trainingPlan: TrainingPlanV2,
  beCalmCourse: CourseV2,
};

//the mappings below override both mappings and navigationV2Mappings where the domain name is "laya-wellbeing"
const layaWellbeingMappings = {
  wellbeingSeries: WellbeingStudio,
  virtualEvent: LayaStudioEvent,
  digitalGymArticle: LayaStudioEvent,
  eap: LayaEap,
  digitalGym: LayaDigitalGym,
  trainingPlan: LayaStudioEvent,
  beCalmCourse: LayaBeCalmCourse,
  settings: LayaSettings,
  beCalm: LayaBeCalm,
  favourites: LayaListPage,
  myList: LayaListPage,
  myProgress: LayaListPage,
  digitalClinics: DigitalClinics
}

export default {
  name: "ContentRouter",
  updated(){
    //setTimeout(function(){ window.__vimeoRefresh(); }, 1000);
  },
  methods: {
    closeNav() {

    },
    scrollTo(coordinates) {
      this.$emit("scroll-to", coordinates);
    },
  },
  computed: {
    navType() {
      return this.$store.getters.navType;
    },
    currentContent() {
      if(this.isLayaWellbeing()) {
        if(layaWellbeingMappings.hasOwnProperty(this.$route.name)) {
          return layaWellbeingMappings[this.$route.name]
        }
      }
      if(this.navType === 'versionTwo' || this.isLayaWellbeing()) {
        if(navigationV2Mappings.hasOwnProperty(this.$route.name)) {
          return navigationV2Mappings[this.$route.name]
        }
      }
      return mappings[this.$route.name];
    },
    isWhitePage(){
      let whitePages = ['digitalClinicsGeneric','digitalClinicsSelectClinician','wallet','soundSpace','podcast','bookingVideoRoom','totalMentalHealthSelectCoach', 'totalMentalHealth', 'companyBoard', 'wellbeingSeries', 'digitalGym', 'beCalm','myProgress','myList','favourites','gp', 'gpSelect'];
      if(this.$store.getters.skin === 25) {
        whitePages = whitePages.concat(['home', 'wellbeing', 'nutrition', 'fitness', 'eap', 'rewards','customise','dataAnalytics','favourites'])
      }
      return whitePages.includes(this.$route.name)
    }
  },
  watch: {
    currentContent() {
      window.scrollTo(0,0);
    }
  }
};
</script>
<style lang="less">
#content {
  min-height: calc(100vh - 200px);
  display: flex;
  justify-content: center;
  & > div {
    width: 100%;
  }
}
.fade-enter-active {
  transition-duration: 0.5s;
  transition-property: opacity;
  transition-timing-function: ease-in;
}
.fade-leave-active {
  transition-duration: 0s;
  transition-property: opacity;
  transition-timing-function: ease-in;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.slide-fade-enter-active {
  transition: all 1s ease;
}
.slide-fade-leave-active {
  transition: all 0s ease;
}
.slide-fade-enter
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.slide-fade-leave-to {
  transform: translateX(-10px);
  opacity: 0;
}
</style>
