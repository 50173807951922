<template>
  <div id="Notification" @click="$router.push(notification.event.actionUrl.href)" :class="{new: notification.readAt === null}">
    <div class="title ml-3" style="border-bottom-color: white;">
      {{title}}
    </div>

    <div class="divider"></div>

    <div class="w-100 d-flex p-2">
      <div class="w-50">        
        <div class="p-2" v-if="(this.type == 'digital_gym' || this.type == 'wellbeing_studio') 
          && notification.event[this.type].hasOwnProperty('thumbnail')
          && notification.event[this.type].thumbnail !== null
          && notification.event[this.type].thumbnail !== ''">
          <img style="max-height: 50px;" :src="eventImage" class="eventImage" :alt="title">
        </div>

        <div v-else class="iconHolder w-25 d-flex p-2" style="text-align:center; margin:22px auto">
          <i :class="iconClass"></i>
        </div>
      </div>

      <div class="w-50">
        <div class="d-flex align-items-center justify-content-between p-2">          
          <div class="date">{{dateAndTime}}</div>
        </div>
        <div class="body">{{body}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Notification",
  props: ['notification'],
  data() {
    return {

    }
  },
  computed: {
    iconClass() {
      switch(this.type) {
        case "digital_gym": return "fa-solid fa-calendar-check";        
        case "wellbeing_studio": return "fa-solid fa-calendar-check";
        case "coaching_booking": return "fa-solid fa-calendar-check";
        case "coaching_message": return "fa-solid fa-comment";
        case "digital_clinics": return "fa-solid fa-calendar-check";
      }
    },
    type() {
      if(this.notification.event.hasOwnProperty("digital_gym")) return "digital_gym";
      if(this.notification.event.hasOwnProperty('wellbeing_studio')) return "wellbeing_studio";
      if(this.notification.event.hasOwnProperty('coaching_booking')) return "coaching_booking";
      if(this.notification.event.hasOwnProperty('unread_message_from_coaching')) return "coaching_message";
      if(this.notification.event.hasOwnProperty('digital_clinics')) return "digital_clinics";
    },
    eventImage() {
      if (this.type === 'digital_gym') return this.notification.event.digital_gym.thumbnail;
      if (this.type === 'wellbeing_studio') return this.notification.event.wellbeing_studio.thumbnail;
      return null;
    },
    body() {
      if(this.type === 'coaching_message') return "Message from " + this.notification.event.unread_message_from_coaching.coach_identity;
      return this.notification.body;
    },
    title() {
      if(this.type === 'coaching_booking') return "Coaching Appointment";
      if(this.type === 'coaching_message') return "Coaching Message";
      return this.notification.event[this.type].title;
    },
    dateAndTime() {      
      let startDate = this.notification.event.startDateTime;      
      return this.getDateWithoutYear(startDate) +' '+ this.getHoursMinutes(startDate);
    }
  }

}
</script>

<style lang="less">
#Notification {
  min-width: 250px;
  border: 1px solid #9d9d9d;
  padding: 4px;
  .divider {
    width: 80%;
    margin: auto;
    height: 1px;
    background-color: grey;
  }
  .new {
    font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    //background-image: linear-gradient(90deg, #ED3A75 0%, #B150B1 100%);
    //background-clip: text;
    //color: transparent;
    color: white;
  }
  .date {
    text-align: right;
    color: #adabab;
  }
  .title {
    font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    color: white;
    margin-bottom: 5px;
    font-size: 1.3rem;
  }
  .body {
    color: white;
    font-size: 1.1rem;
  }
  &.new {
    //background: linear-gradient(90deg, #ED3A75 0%, #B150B1 100%);
  }
  .iconHolder {
    i {
      color: #9F9E93 !important;
    }
  }
}
</style>