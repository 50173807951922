import { render, staticRenderFns } from "./LayaEap.vue?vue&type=template&id=39aa37a8&"
import script from "./LayaEap.vue?vue&type=script&lang=js&"
export * from "./LayaEap.vue?vue&type=script&lang=js&"
import style0 from "./LayaEap.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports