<template>
  <div id="CourseV2">
      <div class="course-content">
            <div class="course-nav" v-if="!isNarrowDevice">
                <NewStudioSideBar
                        header="Course Contents"
                        :title="course.course_title"
                        :nav-items="navItems"
                        :default-image="course.featured_img"
                        module-name="Module"
                        :stay-on-track="true"
                        @change-selection="changeSelectedModule"

                ></NewStudioSideBar>
            </div>

          <div class="course-main-content" ref="content">
            <div class="back-container">
                <img src="/img/back-arrow.png" @click="back" alt="back arrow"/>
            </div>
            <VisualAudioDisplay
                 v-if="currentModule.module_type === 'video' ||
                  currentModule.module_type === 'audio'"
                :type="currentModule.module_type"
                :id="currentModule.module_id"
                :url="currentModule.asset_url"
                :is-first-item="isFirstItem"
                :is-last-item="isLastItem"
                :content="currentModule.content"
                :title="currentModule.title"
                @previous="previous"
                @next="completedModule"
                @ended="completedModule"
            ></VisualAudioDisplay>
            <TextDisplay
                    v-if="currentModule.module_type === 'text'"
                    :author-img="author.profile_img"
                    :author-name="author.name"
                    :author-title="author.title"
                    :back-route="'/wellbeing'"
                    :content="currentModule.content"
                    :title="currentModule.title"
                    @ended="completedModule"
            ></TextDisplay>
            <div class="course-details">
               <div class="course-details-nav pt-3">
                        <div class="course-details-nav-item pb-2"
                             :class="detailsNavSelection === 0 ? 'brand-highlight-1':'brand-text'"
                             @click="detailsNavSelection = 0"
                        >
                            <div class="nav-inner">
                                <i class="fa-regular fa-address-card"></i>
                                Overview
                            </div>
                            <div class="line" :class="detailsNavSelection === 0 ? 'bg-brand-highlight-1':'dark-line'"/>
                        </div>
<!--                        <div class="course-details-nav-item pb-2"-->
<!--                             :class="detailsNavSelection === 1 ? 'brand-highlight-1':'brand-text'"-->
<!--                             @click="detailsNavSelection = 1">-->
<!--                            <div class="nav-inner">-->
<!--                                <i class="fa-solid fa-pen"></i>-->
<!--                                My Notes-->
<!--                            </div>-->
<!--                            <div class="line" :class="detailsNavSelection === 1 ? 'bg-brand-highlight-1':'dark-line'"/>-->
<!--                        </div>-->
                        <div class="course-details-nav-item pb-2"
                             :class="detailsNavSelection === 2 ? 'brand-highlight-1':'brand-text'"
                             @click="detailsNavSelection = 2">
                            <div class="nav-inner">
                                <i class="fa-regular fa-video"></i>
                                Similar Courses
                            </div>
                            <div class="line" :class="detailsNavSelection === 2 ? 'bg-brand-highlight-1':'dark-line'"/>
                        </div>
                        <div class="course-details-nav-item pb-2"
                            :class="detailsNavSelection === 3 ? 'brand-highlight-1':'brand-text'"
                            @click="detailsNavSelection = 3" v-if="isNarrowDevice">
                             <div class="nav-inner">
                               <i class="fa-regular fa-video"></i>
                               Course Contents
                             </div>
                             <div class="line" :class="detailsNavSelection === 3 ? 'bg-brand-highlight-1':'dark-line'"/>
                           </div>
                        </div>
            </div>
            <div class="course-details h-100">
                <div class="course-details-inner">
                    <div>
                        <div class="selection-container">
                            <div class="overview-section" v-if="detailsNavSelection === 0">
                                <div class="author-details">
                                    <div class="top-row">
                                        <div class="featuring-box">
                                            <div class="top">FEATURING</div>
                                            <div class="sp-box mt-1">
                                                <div class="img-outer-holder">
                                                    <div class="img-holder" v-bind:style="{ backgroundImage: 'url(' + author.profile_img + ')' }"></div>
                                                </div>
                                                <div class="d-flex flex-column ml-4">
                                                    <div class="sp-name brand-text">{{ author.name }}</div>
                                                    <div class="sp-title brand-text">{{ author.title }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="main-details col-12 col-md-8">

                                        <div class="course-name-container">
                                            <div class="label">COURSE NAME</div>
                                            <div class="title brand-text">{{course.course_title}}</div>
                                        </div>
                                        <div class="course-desc-container mt-4">
                                            <div class="label">COURSE SUMMARY</div>
                                            <div class="brand-text">{{course.course_summary}}</div>
                                        </div>
                                    </div>
                                    <div class="actions col-12 col-md-4">
                                        <div class="divider"></div>
                                        <div class="actions-inner">
                                            <div class="label">
                                                ACTIONS
                                            </div>
                                            <div class="actions-row">
                                                <div class="share-btn border-brand-highlight-1 brand-highlight-1" @click="showShareModal">
                                                    <i class="fa-solid fa-share brand-highlight-1 mr-4 fa-lg"></i>
                                                    <span>Share</span>
                                                </div>
<!--                                                <div class="like-container">-->
<!--                                                    <div class="like">-->
<!--                                                        <i v-if="liked" class="fa-solid fa-heart fa-xl brand-highlight-1" @click="like"></i>-->
<!--                                                        <i v-else class="fa-regular fa-heart fa-xl brand-highlight-1" @click="like"></i>-->
<!--                                                    </div>-->
<!--                                                </div>-->
                                            </div>
                                            <div class="myListRow">
                                              <div class="myListBtn" @click="watchLater">
                                                <i class="fa-solid iconBtn" :class="isItemInMyList ? 'fa-check plus':'fa-circle-plus plus'"></i>
                                                <div class="myListText">{{isItemInMyList ? 'Watch Later' : 'Watch Later'}}</div>
                                              </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="" v-if="detailsNavSelection === 2">
                                <div class="slider-container mt-4">
                                    <SimilarCourses type="courses" class="car"/>
                                </div>

                            </div>
                            <div class="similar-courses section" v-if="detailsNavSelection === 3 && isNarrowDevice">

                              <div class="slider-container">
                                <NewStudioSideBar
                                    header="Course Contents"
                                    :title="course.course_title"
                                    :nav-items="navItems"
                                    :default-image="course.featured_img"
                                    module-name="Module"
                                    :stay-on-track="true"
                                    :narrow-device-mode="true"
                                    :extend-side-bar-at-start="true"
                                    @change-selection="changeSelectedModule"

                                ></NewStudioSideBar>
                              </div>

                          </div>
                        </div>
                    </div>
                </div>

            </div>
          </div>
      </div>
  </div>
</template>


<script>
  import axios from "axios";
  import config from "@/config/constants";
  import VisualAudioDisplay from "@/components/NewStudio&CourseLayout/Content/VisualAudioDisplay.vue";
  import NewStudioSideBar from "@/components/NewStudio&CourseLayout/Sidebar/NewStudioSideBar.vue";
  import ShareModal from "@/components/NewStudio&CourseLayout/Modals/ShareModal.vue";
  import TextDisplay from "@/components/NewStudio&CourseLayout/Content/TextDisplay.vue";
  import SimilarCourses from "@/components/NewStudio&CourseLayout/Content/SimilarCourses.vue";
  import HomeCardCarousel from "@/components/Home/New/HomeCardCarousel.vue";

  export default {
      name: 'CourseV2',
      components: {
          HomeCardCarousel,
          VisualAudioDisplay,
          NewStudioSideBar,
          ShareModal,
          TextDisplay,
          SimilarCourses
      },
      data() {
          return {
              author: {},
              course: {},
              modules: [],
              progress: "",
              sidebarExtended: false,
              selectedModule: '',
              detailsNavSelection: 0,
              liked: false,
              windowWidth: 1000,
          }
      },
      mounted() {
          this.getCourse();
          this.onResize();
          this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
          });
      },
      watch: {
        '$route'(to,from) {
          if(to.params.id !== from.params.id) {
            this.getCourse();
          }
        },
        selectedModule() {
            this.$refs["content"].scrollIntoView({ behavior: "smooth" })
        }
      },
      computed: {
        product_id () {
          return this.course.course_channel === 'surge' ? 4 : 17;
        },
        isItemInMyList() {
          const isInList = this.$store.getters.myList.filter(item => item.product_id === this.product_id && Number.parseInt(item.content_id) === Number.parseInt(this.course.course_id));
          return isInList.length > 0;
        },
        darkMode() {
          return this.$store.getters.darkMode;
        },
        isNarrowDevice() {
          return this.windowWidth <= 992;
        },
        backRoute() {
            if(this.$route.query.back === 'home') return '/';
            if(this.$route.query.back) return '/' + this.$route.query.back;
            return this.$route.name === 'beCalmCourse' ? '/be-calm' : '/wellbeing'
        },
        isFirstItem() {
          const i = this.modules.findIndex(mod => mod.module_id === this.selectedModule);
          return i === 0
        },
        isLastItem() {
            const i = this.modules.findIndex(mod => mod.module_id === this.selectedModule);
            return i === this.modules.length - 1
        },
        link() {
            return window.location.href;
        },
        currentModule() {
            const m = this.modules.filter(mod => mod.module_id === this.selectedModule);
            return m[0];
        },
        navItems() {
            return this.modules.map(module => {
                return {
                    id: module.module_id,
                    title: module.title,
                    description: null,
                    complete: module.complete,
                    img: module.featured_img,
                    selected: Boolean(module.module_id === this.selectedModule)
                }
            })
        }
      },
      methods: {
          async watchLater() {
            if(!this.isItemInMyList) {
              await this.$store.dispatch('addMyListItem',({media_type: 'course', product_id: this.product_id, content_id: this.course.course_id}));
            } else {
              const myListRecord = this.$store.getters.myList.filter(item => item.product_id === this.product_id && item.content_id === Number.parseInt(this.course.course_id));
              await this.$store.dispatch('deleteMyListItem',({id: myListRecord[0].id}));
            }
          },
          onResize() {
            this.windowWidth = window.innerWidth;
            this.detailsNavSelection = 0;
          },
          back() {
            this.$router.push(this.backRoute);
          },
          previous() {
              if(this.isFirstItem) return;
              const i = this.modules.findIndex(mod => mod.module_id === this.selectedModule);
              this.selectedModule = this.modules[i-1].module_id;
          },
          async completedModule() {
              if(!this.currentModule.complete) {
                  const payload = {
                      user_id: this.$store.getters.user_id,
                      module_id: this.currentModule.module_id
                  };
                  await this.axios.post(
                      process.env.VUE_APP_API_PATH +
                      "/application/api-hc/complete-course-module",
                      payload
                  );
                  this.setModuleAsCompleted(this.currentModule.module_id);
              }
              if(!this.isLastItem) {
                  this.moveToNextModule();
              }
          },
          setModuleAsCompleted(id) {
              this.modules.map(mod => {
                  if(mod.module_id === id) {
                      mod.complete = 1;
                  }
              })
          },
          moveToNextModule() {
              let i = false;
              let currentModuleFound = false
              this.modules.map(mod => {
                  if(mod.module_id === this.currentModule.module_id) currentModuleFound = true;
                  if(currentModuleFound && !i && Number.parseInt(mod.complete) !== 1) {
                      i = true;
                      this.selectedModule = mod.module_id;
                  }
              })
              if(!i) this.selectedModule = this.modules[0].module_id;
          },
          showShareModal() {
              const skin = this.$store.getters.skin;
              const content = this.$createElement(ShareModal, {
                  props: {
                      title: 'Share this course',
                      instruction: 'Share this course with other by copying the link below',
                      link: this.link
                  }
              });
            let modalClass = "share-modal theme-"+skin;
            if(this.darkMode) modalClass += ' dark-mode-modal';
              let opts = {
                  hideHeader: false,
                  hideFooter: true,
                  modalClass: modalClass,
                  headerClass: "share-modal d-none",
                  footerClass: "d-none",
                  size: "lg",
                  buttonSize: "sm",
                  centered: true,
                  contentClass: "share-modal theme-" + skin,
                  id : 'share-modal',
              }
              return this.$bvModal.msgBoxOk(content, opts);
          },
          like() {
            this.liked = ! this.liked;
          },
          changeSelectedModule(id) {
              this.selectedModule = id
          },
          async getCourse() {
              const payload = {
                  user_id: this.$store.getters.user_id,
                  course_id: this.$route.params.id,
              };
              let res = await axios.post(
                  config.api_env + "/application/api-hc/get-course",
                  payload,
                  config.options
              );
              if (res.data.success) {
                  this.author = res.data.author;
                  this.course = res.data.course;
                  this.modules = res.data.modules;
                  this.progress = res.data.progress;
                  this.selectedModule = null;
                  this.modules.forEach(mod => {
                      if(!mod.complete && !this.selectedModule) {
                          this.selectedModule = mod.module_id
                      }
                  });
                  if(!this.selectedModule) this.selectedModule = this.modules[0].module_id
              } else if (res.data.err && res.data.err.includes("3994")) {
                  this.$store.dispatch("logout");
                  this.$router.push("/login").catch((err) => {});
              }
          },
      }
  }
</script>

<style lang="less">
  #CourseV2 {
    .author-details {
      .top-row {
        display: flex;
        padding: 2px;
        color: #C9C6C6;
      }
      .featuring-box {
         padding-top: 5px;
         flex-direction: column;
         padding-bottom: 5px;
         display: flex;
         .top {
             font-size: 1.2rem;
         }
         .sp-box {
           display: flex;
           .sp-name{
             font-size: 2rem;
             font-family: FilsonProBold, Arial, Helvetica, sans-serif;
           }
           .sp-title {
             font-size: 1.6rem;
           }
           .img-outer-holder {
             width: 68px;
             height: 68px;
             border-radius: 50%;
             border: cyan 2px solid;
             display: flex;
             justify-content: center;
             align-items: center;
           }
           .img-holder {
             width: 60px;
             height: 60px;
             border-radius: 50%;
             background-repeat: no-repeat;
             background-size: cover;
             background-position: center;

           }
         }
       }
    }
    .myListRow {
      cursor: pointer;
      padding: 15px 0px;
      display: flex;
      align-items: center;
      justify-content: start;
      .myListBtn {
        width: 150px;
        background-color: #333232;
        color: white;
        padding: 7px 5px;
        align-items: center;
        border-radius: 10px;
        border: #a6a5a5 1px solid;
        display: flex;
        i {
          color: white;
          margin-right: 15px;
          font-size: 25px;
        }
        .myListText {
          font-family: FilsonProBold, Arial, Helvetica, sans-serif;
        }
      }
    }
    .course-content {
      width: 100%;
      height: 100%;
      font-family: "FilsonPro", sans-serif;
      display: flex;
      background-color: black;

      .course-nav {
        max-width: 350px;
      }
      .course-main-content {
        max-width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        flex-grow: 1;
        .back-container {
            position: absolute;
            top: 10px;
            left: 10px;
            cursor: pointer;
            z-index: 9999;
        }
      }
      .course-details {
        width: 100%;

        font-family: Open Sans, sans-serif;
        font-size: 1.5rem;
        background-color: white;
        .course-details-inner {
          width: 100%;
          //max-width: 1300px;
        }
        .course-details-nav {
          display: flex;
          cursor: pointer;
          background-color: #FAFAFA;
          .course-details-nav-item {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 200px;
            position: relative;
            .nav-inner {
              padding: 10px 15px;
              i {
                margin-right: 8px;
              }
            }
            .line {
              position: absolute;
              bottom: 0;
              width: 100%;
              height: 1px;
              margin: 0 !important;
            }
            .dark-line {
              background-color: #c4c4c4;
            }
          }
        }
        .selection-container {
          width: 100%;
          padding: 20px 30px 60px 30px;
          .divider {
            position: absolute;
            left: 0;
            top: 20px;
            width: 1px;
            height: 100%;
            background-color: #f6f5f5;
          }
          .label {
            color: #C9C6C6;
            font-size: 1.2rem;
            margin-bottom: 10px;
          }
          .overview-section {
            flex-direction: column;
            display: flex;
            .main-details, .actions-inner {
              padding: 10px;
            }
            .actions {
              position: relative;
              .actions-row {
                display: flex;
                .share-btn {
                  border-width: 1px;
                  border-style: solid;
                  padding: 5px 8px;
                  border-radius: 20px;
                  cursor: pointer;
                  min-width: 118px;
                }
                .like-container {
                  width: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
                .like {
                  cursor: pointer;
                }
              }
            }
            .title {
              font-family: FilsonProBold, Arial, Helvetica, sans-serif;
              font-size: 2rem;
            }
          }
        }
      }
    }
    .similar-courses {

      overflow: hidden;
      .headerContainer {
        align-items: center;
        justify-content: start;
        display: flex;
        .title {
          font-family: FilsonProBold, Arial, Helvetica, sans-serif;
          font-size: 2rem;
          margin-right: 15px;
        }
        .divline {
          width: 200px;
          height: 1px;
          background-color: #e1e1e1;
        }
      }
    }
    @media only screen and (max-width: 767px) {
      .row {
        margin: 0 !important;
      }
      .actions-inner {
        padding: 0 !important;
      }
      .selection-container {
        padding: 5px !important;
      }
      .nav-inner {
        padding: 5px !important;
        text-align: center;
      }
    }
  }
</style>