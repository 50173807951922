<template>
  <footer id="footerCMSPage">
    <div id="footer-links">
      <div class="container">
        <div class="row">
          <div class="col-sm-2 d-none d-md-block">
            <h6>Need help?</h6>
            <div>
              <a class="footer-link" href="https://www.layahealthcare.ie/questions/" target="_blank">FAQ</a> |
              <a class="footer-link" href="https://www.layahealthcare.ie/contactus/" target="_blank">Contact us</a>
            </div>
          </div>
          <div class="col-xs-12 col-md-3 footer-links-vertical-rule">
            <h6 class="d-none d-md-block">Connect with us</h6>
            <span class="nobreak">
                                    <a href="https://www.facebook.com/LayaHealthcare" aria-label="Facebook" class="social-media-logo social-media-logo-facebook footer-link"></a>
                                    <a href="https://www.twitter.com/LayaHealthcare" aria-label="Twitter" class="social-media-logo social-media-logo-twitter footer-link"></a>
                                    <a href="https://www.youtube.com/user/LayaHealthcare" aria-label="YouTube" class="social-media-logo social-media-logo-youtube footer-link"></a><wbr>
                                    <a href="https://www.linkedin.com/company/laya-healthcare" aria-label="LinkedIn" class="social-media-logo social-media-logo-linked-in footer-link"></a>
                                    <a href="https://www.instagram.com/layahealthcare/?hl=en" aria-label="Instagram" class="social-media-logo social-media-logo-instagram footer-link"></a>
                                    <a href="https://www.pinterest.ie/layahealthcare/pins/" aria-label="Pinterest" class="social-media-logo social-media-logo-pinterest footer-link"></a>
                                </span>
          </div>
          <div class="col-sm-7 d-none d-md-block">
            <h6>About Laya -
              <a class="footer-link" href="https://www.layahealthcare.ie/">Health Insurance</a> -
              <a class="footer-link" href="https://www.layalifeinsurance.ie/">Life Insurance</a> -
              <a class="footer-link" href="https://www.layatravelinsurance.ie">Travel Insurance</a></h6>

            <div><a class="footer-link" href="https://www.layahealthcare.ie/ourboard/" target="_blank">Our board</a> |
              <a class="footer-link" href="https://www.layahealthcare.ie/workingforlayahealthcare/" target="_blank">Careers</a> |
              <a class="footer-link" href="https://www.layahealthcare.ie/pressandmedia/" target="_blank">Media</a> |
              <a class="footer-link" href="https://www.layahealthcare.ie/privacypolicy/" target="_blank">Privacy Policy</a> |
              <a class="footer-link" href="https://www.layahealthcare.ie/info/cookiepolicy/" target="_blank">Cookie Policy</a> |
              <a class="footer-link" href="https://www.layahealthcare.ie/termsconditions/" target="_blank">Terms of Business</a> |
              <a class="footer-link" href="https://www.layahealthcare.ie/termsofuse/" target="_blank">Terms of use</a></div>
          </div>
        </div>
        <div class="row d-md-none">
          <div class="col-6 text-center">
            <img class="footer-link" id="white-mobile-logo" alt="Laya Logo" src="https://www.layahealthcare.ie/media/layahealthcare/desktopsite/newhomepage/images/Laya-Healthcare-Logo-White-2022-162x73.png" width="162" height="73" loading="lazy">
          </div>
          <div class="col-6 text-center">
            <div class="footerLinkBtns">
              <a class="footer-link" href="https://www.layahealthcare.ie/privacypolicy/">Privacy Policy</a><br>
              <a class="footer-link" href="https://www.layahealthcare.ie/info/cookiepolicy/">Cookie Policy</a>
              <a class="footer-link" href="https://www.layahealthcare.ie/termsconditions/">Terms of Business</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <p></p>
      <p>
        For health insurance policies incepted or renewed on or after 1 January 2025, insurance is provided by AXA Insurance dac trading as laya healthcare. For all other existing health insurance policies, insurance is provided by Elips Insurance Limited (Incorporated Liechtenstein) trading as laya healthcare. Laya Healthcare Limited, trading as laya healthcare and laya life, is regulated by the Central Bank of Ireland. Laya Healthcare Limited acts as an agent for healthcare products for Axa Insurance dac and Elips Insurance Limited, and is a private company limited by shares registered in Ireland, Company No 242048. The registered office address of Laya Healthcare Limited is Eastgate Road, Eastgate Business Park, Little Island, Co. Cork, T45 E181. Your Life Insurance is provided by IptiQ Life SA. Laya Healthcare Limited trading as laya life, is regulated by the Central Bank of Ireland. Travel insurance is provided by AIG Europe S.A.
      </p>
      <p></p>
      <p>
        <span class="float-right">© Laya Healthcare</span>
        <span id="footer-awards">
            <img src="/img/laya/GPTW_2023_Ireland_55x55.jpg" alt="Great Places to Work 2021 Europe Logo" width="64" height="55" loading="lazy">
            <img src="/img/laya/GPTW_2023_Ireland_for_women_55x55.jpg" alt="Great Places to Work For Women Ireland 2021 Logo" width="60" height="55" loading="lazy">
            <img src="https://www.layahealthcare.ie/media/layahealthcare/images/Investors_in_Diversity_Gold_fv.jpg" alt="Investors in Diversity Gold Award" width="140" height="27" loading="lazy">
        </span>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "NewLayaFooter"
}
</script>

<style lang="less">
#footer-awards {
  img {
    margin-right: 10px;
  }
}
</style>