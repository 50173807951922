<template>
    <div id="LoginAs" class="bg-brand-secondary" >
        <Loader />
    </div>
</template>

<script>
    // @ is an alias to /src
    import Loader from '@/components/Loader.vue';
    import config from "../config/constants";
    import {mapState} from 'vuex'
    export default {
        name: "LoginAs",
        components: {
            Loader,
        },
        data() {
            return {
                errorMessage: false
            };
        },
        mounted() {
            this.$route.query.auth ? this.resolveAuth() : this.resolveToken()
        },
        methods:{
            async loginAs(userObject){
                const user =  {
                    user_token: userObject.user_token,
                    user_info: userObject
                }
                const home = await this.$store.dispatch('loginAs', user)
                this.$route.query.redirect_to ?  this.$router.push('/' + this.$route.query.redirect_to) : this.$router.push(home)
            },
            async resolveToken(){
                const payload = {
                    token: this.$route.query.token,
                    source: "web"
                }
                let res = await this.axios.post(config.api_env + "/application/api/u/user/resolve-token", payload)
                if(res.data.success){
                    this.$store.dispatch('setSamlTrue');
                    this.loginAs(res.data)
                }else{
                    this.errorMessage = "Your SSO authentication token is not valid. Redirecting..."
                    setTimeout(() => this.$router.push('/login'), 2500);
                }
            },
            async resolveAuth(){
                const payload = {
                    user_id: this.$route.query.auth,
                }
                let res = await this.axios.post(config.api_env + "/application/api/u/user/authenticate-with-token", payload)
                if(res.data.success){
                    this.loginAs(res.data)
                }else{
                    this.errorMessage = "Your SSO authentication token is not valid. Redirecting..."
                    setTimeout(() => this.$router.push('/login'), 2500);
                }
            }
        },
        computed:{
            ...mapState(['user'])
        },
        watch:{

        }

    };
</script>
<style lang="less">
    #LoginAs{
        height: 100vh;
        display: flex;
        .error-message{
            width: 100%;
            padding: 45vh;
            text-align: center;
            font-size: 2.5rem;
            color: white;
        }
    }
</style>
