<template>
  <div id="ServicesCard" :class="getTileClass">
    <div class="backgroundContainer" :style="{ backgroundImage: 'url(' + serviceData.img + ')' }"></div>
<!--    <div class="expandedBackground" :style="{ backgroundImage: 'url(' + serviceData.img + ')',width: standardTileWidth * increaseFactor +'px' }"></div>-->
    <div class="unexpandedOverlay" @click="handleOverlayClick">
      <div class="textContainer" @click="handleOverlayClick">
          <div class="subtitle" :style="{fontSize: sizing.subtitle}">{{serviceData.subTitle}}</div>
          <div class="tileLabel" :style="{fontSize: sizing.tileLabel}">{{serviceData.label}}</div>
      </div>
    </div>
    <div class="expandedOverlay" :style="{width: standardTileWidth * increaseFactor +'px', backgroundImage: 'url(' + serviceData.img + ')'}" >
      <div class="expandedOverlayInner" :style="{paddingTop: sizing.overlayTop}">
        <div class="expandedTitle" :style="{fontSize: sizing.expandedTitle}">{{serviceData.label}}</div>
        <div class="btnRow my-3">
          <div class="moreButton"
               @click="goToContent"
               :style="{fontSize: sizing.moreButton, height: sizing.moreButtonHeight, width: sizing.moreButtonWidth}">
            {{serviceData.clickHere ? serviceData.clickHere : "Click Here"}}
          </div>
<!--          <i class="fa-solid fa-circle-plus plus" :style="{fontSize: sizing.plusIcon}"></i>-->
        </div>
        <div class="description" :style="{fontSize: sizing.description}">{{item.description}}</div>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: "ServicesCard",
  props: ["item","expanded","tileHeight","stopExpand","increaseFactor","standardTileWidth"],
  data() {
    return {
      servicesData: [
        {
          name: "wellbeingSeries",
          item_slug: "wellbeingSeries",
          img: "https://spectrum-life-media.s3.eu-west-1.amazonaws.com/dfd_services/ds.webp",
          subTitle: "Start a course",
          label: "Wellbeing Series",
          external_link: null,
          clickHere: null
        },
        {
          name: "eap",
          item_slug: "eap",
          img: "https://spectrum-life-media.s3.eu-west-1.amazonaws.com/dfd_services/eap.webp",
          subTitle: "Call us for",
          label: "24/7 Support",
          external_link: null,
          clickHere: null
        },
        {
          name: "digitalGym",
          item_slug: "digitalGym",
          img: "https://spectrum-life-media.s3.eu-west-1.amazonaws.com/dfd_services/dg.webp",
          subTitle: "Work out in the",
          label: "Digital Gym",
          external_link: null,
          clickHere: null
        },
        {
          name: "digitalClinicsGeneric",
          item_slug: "digitalClinicsGeneric",
          img: "https://spectrum-life-media.s3.eu-west-1.amazonaws.com/dfd_services/dc.webp",
          subTitle: "Book into the",
          label: "Digital Clinics",
          external_link: null,
          clickHere: null
        },
        {
          name: "totalMentalHealth",
          item_slug: "totalMentalHealth",
          img: "https://spectrum-life-media.s3.eu-west-1.amazonaws.com/dfd_services/tmh.webp",
          subTitle: "Start your Mental Health",
          label: "Coaching Journey",
          external_link: null,
          clickHere: null
        },
        {
          name: "cahms",
          item_slug: "cahms",
          img: "https://spectrum-life-media.s3.eu-west-1.amazonaws.com/dfd_services/cahms.webp",
          subTitle: "Find out more about",
          label: "Child & Adult Mental Health Service",
          external_link: null,
          clickHere: null
        },
        {
          name: "bookAService",
          item_slug: "bookAService",
          img: "https://spectrum-life-media.s3.eu-west-1.amazonaws.com/dfd_services/dc.webp",
          subTitle: "Find out more about",
          label: "Book a Service (GP) etc.",
          external_link: 'www.layahealthcare.ie/memberarea/#/services',
          clickHere: null
        },
        {
          name: "layaDigitalGym",
          item_slug: "digitalGym",
          img: "https://spectrum-life-media.s3.eu-west-1.amazonaws.com/dfd_services/dg.webp",
          subTitle: "Access live and on-demand classes like yoga, Pilates and HIIT.",
          label: "Digital Gym",
          external_link: null,
          clickHere: "Access the gym"
        },
        {
          name: "layaWellbeingSeries",
          item_slug: "wellbeingSeries",
          img: "https://spectrum-life-media.s3.eu-west-1.amazonaws.com/dfd_services/ds.webp",
          subTitle: "Watch expert speakers in a series of events and seminars.",
          label: "Wellbeing Series",
          external_link: null,
          clickHere: "See the events"
        },
        {
          name: "layaEap",
          item_slug: "eap",
          img: "https://spectrum-life-media.s3.eu-west-1.amazonaws.com/dfd_services/eap.webp",
          subTitle: "Access immediate mental wellbeing support, 24 hours a day.",
          label: "Speak with a Counsellor",
          external_link: null,
          clickHere: "Find out more"
        },
        {
          name: "layaBeCalm",
          item_slug: "beCalm",
          img: "https://spectrum-life-media.s3.eu-west-1.amazonaws.com/dfd_services/beCalm.jpg",
          subTitle: "Access meditations to help with sleep, anxiety, stress and more.",
          label: "Guided Meditation",
          external_link: null,
          clickHere: "Get Started"
        },
        {
          name: "layaSupport",
          item_slug: "eap",
          img: "https://spectrum-life-media.s3.eu-west-1.amazonaws.com/dfd_services/cahms.png",
          subTitle: "Our team of experts are here to help with legal issues, mediation, career counselling and more.",
          label: "Expert Support",
          external_link: null,
          clickHere: "Find out more"
        },
      ]
    }
  },
  methods: {
    goToContent() {
      if(this.serviceData.external_link) {
        window.open("https://" + this.serviceData.external_link, "_blank");
      } else {
        this.$router.push({name: this.serviceData.item_slug})
      }
    },
    handleOverlayClick() {
      if(!this.stopExpand) {
        this.$router.push({name: this.item.item_slug})
      }
    }
  },
  computed: {
    sizing() {
        return {
          subtitle: Math.floor(this.tileHeight * 0.035) + 'px',
          tileLabel:  Math.floor(this.tileHeight * 0.0575) + 'px',
          moreButton: Math.floor(this.tileHeight * 0.0275) + 'px',
          moreButtonHeight: Math.floor(this.tileHeight * 0.065) + 'px',
          moreButtonWidth: Math.floor(this.tileHeight * 0.30) + 'px',
          expandedTitle: Math.floor(this.tileHeight * 0.08) + 'px',
          plusIcon: Math.floor(this.tileHeight * 0.075) + 'px',
          description: Math.floor(this.tileHeight * 0.0325) + 'px',
          overlayTop: Math.floor(this.tileHeight * 0.3) + 'px'
        }
    },
    getTileClass() {
      let classStr = this.item.item_slug;
      if(this.expanded && !this.stopExpand) classStr += " expanded";
      if(this.stopExpand) classStr += " stopExpand"
      return classStr;
    },
    serviceData() {
      let service;
      if(this.isLayaWellbeing()) {
        service = this.servicesData.filter(obj => obj.name === this.item.item_slug)
      } else {
        service = this.servicesData.filter(obj => obj.item_slug === this.item.item_slug)
      }
      return service[0];
    }
  }
}

</script>
<style lang="less">
#ServicesCard {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  .expandedBackground {
    position: absolute;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    //border-radius: 15px;
    z-index: 0;
  }
  .backgroundContainer {
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    //border-radius: 15px;
    overflow: hidden;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 1;
    //transition: opacity 0.25s ease-in;
    //transition-delay: 0.5s;
  }
  .unexpandedOverlay {
    height: 80%;
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 3;
    transition: opacity 0.5s ease-in;
    transition-delay: 0.5s;
    opacity: 1;
    .subtitle {
      padding-left: 20%;
    }
    //border-radius: 15px;
    .textContainer {
      opacity: 1;
      color: white;
      width: 100%;
      height: 100%;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 4;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      text-align: right;
      padding-bottom: 8%;
      padding-right: 15px;
      .tileLabel {
        font-family: FilsonProBold, Arial, Helvetica, sans-serif;
        width: 90%;
        line-height: 2.5rem;
        margin-top: 1.25rem;
      }
    }
  }
  .expandedOverlay {
    opacity: 0;
    //transition: none;
    //transition-delay: 0.25s;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: white;
    z-index: 12;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    //border-radius: 15px;
    position: relative;
    .expandedOverlayInner {
      position: absolute;
      padding-left: 10%;
      background: linear-gradient(83.35deg, #000000 16.67%, rgba(0, 0, 0, 0) 98.17%);
      width: 100%;
      height: 100%;
    }
    //
    .expandedTitle {
      font-family: FilsonProBold, Arial, Helvetica, sans-serif;
      width: 75%;
    }
    .btnRow {
      align-items: center;
      display: flex;
      .moreButton {
        width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        cursor: pointer;
        border-radius: 30px;
        background: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%);

      }
      i {
        margin-left: 20px;
        color: #FFFFFFAB;
      }
    }
    .description {
      width: 60%;
    }
  }
  //stopExpand
  &.stopExpand {
    .unexpandedOverlay {
      cursor: pointer;
    }
  }
  //expanded
  &.expanded {
    .backgroundContainer {
      opacity: 0;
      //transition: none;
      //transition-delay: 0s;
    }
    .expandedOverlay {
      opacity: 1;
      //transition: opacity 0.5s ease-in;
      //transition-delay: 0.75s;
    }
    .unexpandedOverlay {
      opacity: 0;
      transition: none;
      transition-delay: 0s;
    }
  }
  //  service specific classes
  &.wellbeingSeries, &.layaWellbeingSeries {
    .unexpandedOverlay {
      background: linear-gradient(161.38deg, rgba(237, 58, 117, 0) 38.38%, #ED3A75 68.64%);
    }
  }
  &.eap, &.layaEap {
    .unexpandedOverlay {
      background: linear-gradient(161.38deg, rgba(135, 210, 221, 0) 38.38%, #87D2DD 68.64%);
    }
  }
  &.digitalClinicsGeneric, &.layaBeCalm {
    .unexpandedOverlay {
      background: linear-gradient(161.38deg, rgba(58, 74, 97, 0) 38.38%, #3A4A61 68.64%);
    }
  }
  &.cahms, &.layaSupport {
    .unexpandedOverlay {
      background: linear-gradient(161.38deg, rgba(58, 74, 97, 0) 38.38%, #02594c 68.64%);
    }
  }
  &.totalMentalHealth, &.bookAService {
    .unexpandedOverlay {
      background: linear-gradient(161.38deg, rgba(255, 178, 89, 0) 38.38%, #FFB259 68.64%);
    }
  }
  &.layaDigitalGym {
    .unexpandedOverlay {
      background: linear-gradient(161.38deg, rgba(52, 29, 79, 0) 38.38%, #341D4F 68.64%);
    }
    .backgroundContainer {
      background-position: 92%;
    }
  }
  &.digitalGym {
    .unexpandedOverlay {
      background: linear-gradient(161.38deg, rgba(52, 29, 79, 0) 38.38%, #341D4F 68.64%);
    }
    .backgroundContainer {
      background-position: 92%;
    }
  }
}
</style>