<template>
    <div class="HomeBannerEventCard">
        <div class="row mx-0 event-card-content bg-brand-highlight-1 row">
          <div class="col-12 col-md-6 d-flex flex-column justify-content-center align-items-center">
            <div class="event-title text-center">{{event.title}}</div>
            <b-button variant="cta" class="mt-3 reminder-btn button--loading button" v-if="loading">
            </b-button>
            <b-button variant="cta" class="mt-3 reminder-btn" @click="remindMe" v-else-if="status === 'not_started'">
              {{isReminderSet? componentText.cancelReminder : componentText.setReminder}}
            </b-button>
            <b-button variant="cta" class="mt-3 reminder-btn" @click="watch" v-else-if="status === 'live'">
              Live Now
              <svg height="12px" width="12px" class="blinking ml-3">
                <circle cx="5" cy="5" r="5" fill="white" />
              </svg>
            </b-button>
            <b-button variant="cta" class="mt-3 reminder-btn" @click="watch" v-else>
              Watch Now
            </b-button>
          </div>
        </div>
        <img :src="event.video_gif? event.video_gif:event.promo_banner_image" class="card-image" v-if="event.promo_banner_image||event.video_gif" :class="event.video_gif ?'gif-img':'banner-img'"/>
        <img src="/img/wellbeing-studio/wellbeing-studio.svg" class="default-card-image" v-else>
        <img src="/img/home/new/Rectangle.png" class="rectangle"/>
        <img src="/img/home/new/RectangleBlob.png" class="blob"/>
    </div>
</template>
<script>
import EventBus from "@/helpers/eventbus";
import moment from "moment";
export default {
    components: {
    },
    name: "HomeBannerEventCard",
    props: {
        event: Object,
        reminders: Array
    },
    data() {
        return {
          loading: false
        };
    },
    watch: {
      reminders: {
        handler() {
          this.loading = false
        },
        deep: true
      }
    },
    computed: {
      componentText() {
        return this.getText.dfd;
      },
      status() {
        const start = moment(this.event.start);
        const end = moment(this.event.end);
        const now = moment();
        if(now.isBefore(start)) return 'not_started';
        if(now.isAfter(end)) return 'ended';
        return 'live';
      },
      isReminderSet() {
        let reminderSet = false;
        this.reminders.forEach(reminder => {
          if((reminder.type === "ws" || reminder.type === "ws") && reminder.id === this.event.id) reminderSet = true;
        })
        return reminderSet
      }
    },
    mounted() {

    },
    beforeDestroy() {

    },
    methods: {
      watch() {
        this.$router.push('/wellbeing-studio/' + this.event.id + '?back=home')
      },
      async remindMe() {
        this.loading = true;
        const payload = {
          user_id: this.$store.getters.user_id,
          event_id: this.event.id,
        };
        if (!this.isReminderSet) {
          let res = await this.axios.post(
              process.env.VUE_APP_API_PATH +
              "/application/api/u/studio/register-reminder",
              payload
          );
          if (res.data.success) {
            EventBus.$emit('updateDFDReminders');
            this.loading = false;
          } else {
            this.loading = false;
          }
        } else {
          let res = await this.axios.post(
              process.env.VUE_APP_API_PATH +
              "/application/api/u/studio/cancel-reminder",
              payload
          );
          if (res.data.success) {
            EventBus.$emit('updateDFDReminders');
            this.loading = false;
          } else {
            this.loading = false;
          }
        }
      },
    }
}
</script>
<style lang="less">
.HomeBannerEventCard {
    position: relative;
    box-shadow: 1px 3px 2px #aaaaaa;
    border-radius: 30px;
    .event-card-content {
      border-radius: 30px;
    }
    &:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 500px 500px 0;
      border-radius: 0 30px 30px 0;
      right: 0;
      top: 0;
      position: absolute;
    }
  .button {
    position: relative;
    padding: 16px 18px !important;
    border: none;
    outline: none;
    border-radius: 2px;
    cursor: pointer;
  }

  .button__text {
    font: bold 40px "Quicksand", san-serif;
    color: #ffffff;
    transition: all 0.2s;
  }

  .button--loading::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid transparent;
    border-top-color: #ffffff;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
  }

  @keyframes button-loading-spinner {
    from {
      transform: rotate(0turn);
    }

    to {
      transform: rotate(1turn);
    }
  }
  .reminder-btn {
    margin-top: 30px !important;
    width: 180px;
    padding: 15px 20px !important;
    z-index: 99;
    font-size: 1.4rem !important;
  }
  .card-image {
    z-index: 10;
    position: absolute;
    bottom: 0;
    right: 30px;

  }
  .banner-img {
    height: 100%;
  }
  .gif-img {
    max-width: 62%;
    top: 100px;
    right: 80px;
  }
  .default-card-image {
    z-index: 10;
    position: absolute;
    top: 20%;
    right: 30px;
    max-height: 80%;
    max-width: 50% !important;
    background-color: rgba(255,255,255,0.65);
    border-radius: 5px;
  }
  .rectangle {
    position: absolute;
    top: 10px;
    left: 40%;
    z-index: 9;
    max-height: 90%;
  }
  .blob {
    position: absolute;
    top: 40px;
    left: 43%;
    z-index: 9;
    max-height: 90%;
  }
  .event-card-content {
        padding: 0 10px 35px 15px;
        height: 100%;
        min-width: 290px;
        min-height: 500px !important;
        position: relative;
        cursor: pointer;
        .event-title {
            color: #fff;
            font-size: 4.2rem;
            font-family: "FilsonProBold", sans-serif;

            line-height: 1.4;
            padding-top: 30px;
            z-index: 99;
        }
        .event-date {
            color: #fff;
            font-family: "FilsonProLight", sans-serif;
            line-height: 1;
            text-align: right;
        }
        .white-text{
            color: white;
        }
    }
  .blinking {
    -webkit-animation: 1s blink ease infinite;
    -moz-animation: 1s blink ease infinite;
    -ms-animation: 1s blink ease infinite;
    -o-animation: 1s blink ease infinite;
    animation: 1s blink ease infinite;

  }

  @keyframes "blink" {
    from, to {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

  @-moz-keyframes blink {
    from, to {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

  @-webkit-keyframes "blink" {
    from, to {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

  @-ms-keyframes "blink" {
    from, to {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

  @-o-keyframes "blink" {
    from, to {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  @media only screen and (max-width: 767px) {
    .event-card-content {
      padding-bottom: 10px !important;
    }
    .default-card-image {
      display: none !important;
    }
    .card-image {
      filter: brightness(60%) !important;
    }
  }
}
</style>