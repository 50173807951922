<template>
    <div id="home-eap" class="page-bg bg-brand-secondary px-md-5 d-none">
        <div class="content-container row">
            <div class="section-header">
                <div class="section-header-divider"></div>
                <h3 class="section-header-title text-white">{{componentText.title}}</h3>
            </div>

            <div class="col-12 pt-4 pb-5">
                <a class="home-link link-highlight" :class="{'border-brand-secondary': $store.getters.skin === 8 }" @click="seeAll">{{componentText.seeAll}}</a>
            </div>
        </div>
    </div>
</template>
<script>
    // @ is an alias to /src
    import axios from "axios";
    import config from "../../config/constants";
    export default {
        name: "HomeEap",
        components: {},
        data() {
            return {
                screenWidth: 0,
                displayLength: 4,
            };
        },
        created() {
            window.addEventListener('resize', this.handleResize)
            this.handleResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize)
        },
        mounted() {
            this.getResources()
        },
        methods: {
            handleResize() {
                this.screenWidth = window.innerWidth;
            },
            seeAll(){
                this.$router.push('eap')
            },
            async getResources(){

            }
        },
        computed:{
            componentText() {
                return this.getText.loggedIn.home.homeEap;
            },
        },
        watch:{
            screenWidth(newVal){
                if(newVal > 991){
                    this.displayLength = 4
                }else{
                    this.displayLength = 4
                }
            },
        }
    };
</script>
<style lang="less">

</style>
