<template>
    <div id="wellbeing-carousel">
        <link rel="stylesheet"
              href="https://use.fontawesome.com/releases/v5.2.0/css/all.css"
              integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ"
              crossorigin="anonymous">
        <slick v-if="displayedCourses.length > 0" ref="slick" :options="slickOptions">
            <a v-for="(course,i) in displayedCourses" :key="'carousel_course'+i" @click="selectCourse(course.course_id)">
                <div v-if="displayedCourses.length >= 3" class="slide-inner bg-brand-secondary">
                    <div
                            v-if="course.course_recommend === 1 && !$store.state.accessCode"
                            class="recommended-course"
                    ></div>
                    <div class="course-image">
                        <img :src="course.course_image_url" />
                    </div>
                    <div class="slide-content">
                        <div v-if="course.course_progress > 0 && !$store.state.accessCode" class="course-progress pb-4">
                            <div class="progress-bar">
                                <div
                                        class="progress"
                                        :style="'width:' + course.course_progress + '%;'"
                                ></div>
                                <div
                                        class="progress-info"
                                        :style="'left:' + course.course_progress + '%;'"
                                >
                                    {{ course.course_progress }}%
                                </div>
                            </div>
                        </div>
                        <div class="explore ">
                            {{componentText.exploreTitle}}
                            <h3 class="course-title ">{{ course.course_title }}</h3>
                        </div>
                    </div>
                    <div class="recommended-badge" v-if="course.course_recommend === '1' && !$store.state.accessCode"></div>
                </div>
                <div class="single-slide " v-else>
                    <div
                            v-if="course.course_recommend === 1"
                            class="recommended-course"
                    ></div>
                    <div class="col-8 slide-image bg-brand-secondary">
                        <img :src="course.course_image_url" />
                    </div>
                    <div class="col-4">
                        <div class="explore brand-secondary">
                            Explore
                            <h3 class="course-title my-5 pt-0">{{ course.course_title }}</h3>
                        </div>
                        <div v-if="course.course_progress > 0" class="course-progress my-5 py-4">
                            <div class="progress-bar">
                                <div
                                        class="progress"
                                        :style="'width:' + course.course_progress + '%;'"
                                ></div>
                                <div
                                        class="progress-info"
                                        :style="'left:' + course.course_progress + '%;'"
                                >
                                    {{ course.course_progress }}%
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </slick>
        <div class="col-12 no-courses text-center" v-else-if="filter === 'recommended' && !this.loading">
            <h4 class="brand-secondary">{{componentText.noRecommended}}</h4>
            <b-button class="filter-button py-3 px-5" @click="$router.push({name: 'insights'})">{{componentText.insights}}</b-button>
        </div>
        <div class="col-12 no-courses text-center" v-else-if="filter === 'active' && !this.loading">
            <h4 class="brand-secondary">{{componentText.noActive}}</h4>
        </div>
        <div class="col-12 no-courses text-center" v-else-if="!this.loading">
            <h4 class="text-white">{{componentText.noCourses}}</h4>
        </div>
        <div class="col-12 loading" v-else>
            <b-spinner></b-spinner>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import axios from "axios";
    import config from "../../config/constants";
    import Slick from "vue-slick";
    export default {
        name: "WellbeingCarousel",
        components: {
            Slick
        },
        props:{
            filter:'',
            searchQuery: ''
        },
        data() {
            return {
                courses:[],
                slickOptions: {
                    infinite: true,
                    autoplay: false,
                    dots: false,
                    nextArrow:
                        '<button class="slick-next  py-1" type="button" style=""><i class="fas fa-chevron-right "></i></button>',
                    prevArrow:
                        '<button class="slick-prev  py-1" type="button" style=""><i class="fas fa-chevron-left "></button>',
                    speed: 300,
                    initialSlide: 1,
                    slidesToScroll: 0,
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: "0",
                    variableWidth: false,
                    adaptiveHeight: true,
                    responsive:[
                        {
                            breakpoint: 768,
                            settings:{
                                slidesToShow: 1,
                                slidesToScroll: 0,
                            }
                        }
                    ]
                },
                filteredCourses: '',
                loading: true
            };
        },
        mounted() {
            this.getWellbeingCourses();
        },
        methods: {
            async getWellbeingCourses() {
                const payload = {
                    user_id: this.$store.getters.user_id,
                    limit: null,
                    channel: "surge"
                };
                let res = await axios.post(
                    config.api_env + "/application/api-hc/list-surge-courses",
                    payload,
                    config.options
                );
                if(res.data.courses) {
                    this.courses = res.data.courses;
                    this.filteredCourses = this.courses.filter((course) => {
                        return course.course_title.toLowerCase().includes(this.searchQuery.toLowerCase())
                    })
                    this.loading = false
                    //destroy and recreate slick carousel

                }
            },
            selectCourse(course_id) {
                this.$router.push("/wellbeing/" + course_id).catch(err => {});
            },
            async filterCourses(){
                this.loading = true
                let payload = {
                    user_id: this.$store.getters.user_id,
                    search_key: "",
                    channel:"surge",
                    limit: -1,
                    page_size:9999,
                }
                if(this.filter === 'body' ||this.filter === 'life' || this.filter === 'mind' || this.filter === 'remote_working'){
                    this.enrolled = 0
                    this.reccommended = 0
                    payload.category = this.filter
                }else if(this.filter === 'active' || this.filter === 'recommended'){
                    this.listCourses(this.filter)
                    return
                }
                let res = await axios.post(config.api_env + '/application/api-hc/list-surge-courses', payload, config.options)
                this.courses = res.data.courses;
                if(res.data.courses) {
                    this.filteredCourses = this.courses.filter((course) => {
                        return course.course_title.toLowerCase().includes(this.searchQuery.toLowerCase())
                    })
                    this.loading = false
                    //destroy and recreate slick carousel
                }
            },
            async listCourses(filter){
                let payload = {
                    user_id: this.$store.getters.user_id,
                    search_key: "",
                    tag: "all",
                    filter: "",
                    limit: -1,
                    rec_only: 0,
                    channel: "surge",
                    page: 0,
                    page_size: 9999,
                    enrolled_only: 0
                }
                if(filter === 'active'){
                    payload.enrolled_only = 1
                }else if(filter === 'recommended'){
                    payload.rec_only = 1
                }
                let res = await axios.post(config.api_env + '/application/api-hc/list-courses', payload, config.options)
                this.courses = res.data.courses;
                if(res.data.courses) {
                    this.filteredCourses = this.courses.filter((course) => {
                        return course.course_title.toLowerCase().includes(this.searchQuery.toLowerCase())
                    })
                    this.loading = false
                    //destroy and recreate slick carousel
                }
            }
        },
        computed:{
            displayedCourses() {
              return this.filteredCourses.slice(0,10);
            },
            componentText() {
                return this.getText.loggedIn.wellbeing.wellbeingCarousel;
            },
        },
        watch: {
            filter(){
                this.filteredCourses = ''
                this.filterCourses()
            },
            filteredCourses(newVal){
                if(newVal.length < 3){
                    this.slickOptions.slidesToShow = 1
                    this.slickOptions.initialSlide = 0
                } else {
                    this.slickOptions.slidesToShow = 3
                    this.slickOptions.initialSlide = 1
                }
                this.$nextTick(function () {
                    if (this.$refs.slick) {
                        this.$refs.slick.destroy();
                        this.$refs.slick.create(this.slickOptions);
                    }
                });
            },
            searchQuery(){
                //destroy the carousel, update the list, then render the carousel
                if (this.$refs.slick) {
                    this.$refs.slick.destroy();
                }
                if(this.courses) {
                    this.filteredCourses = this.courses.filter((course) => {
                        return course.course_title.toLowerCase().includes(this.searchQuery.toLowerCase())
                    })
                    this.$nextTick(function () {
                        if (this.$refs.slick) {
                            this.$refs.slick.create(this.slickOptions);
                        }
                    });
                }
            }
        }
    };
</script>
<style lang="less">
    .slick-slide {
        transform: scale(0.8);
        a:hover {
            text-decoration: none;
        }
        .slide-inner {
            height: 420px;
            margin: 0 10px;
            padding: 30px 20px;
            display: block;
            border-radius: 10px;
            &:hover {
                text-decoration: none;
            }
            position: relative;
            .course-image {
                .slide-overlay {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(28, 14, 42, 0.71);
                }
                margin: auto;
                padding-bottom: 20px;
                max-height: 240px;
                img {
                    max-height: 220px;
                    height: 100%;
                    margin: auto;
                    object-fit: contain;
                }
            }
            .slide-content {
                position: absolute;
                width: 100%;
                padding: 0 36px 18px 36px;
                bottom: 20%;
                left: 0;
                transform: translateY(50%);
                transition: 300ms;
                .course-progress {
                    display: none;
                }
                .explore {
                    font-size: 1.6rem;
                    font-family: "FilsonProLight", sans-serif;
                    text-transform: uppercase;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    h3 {
                        padding: 0;
                        font-size: 2.2rem;
                        letter-spacing: 0;
                        text-transform: none;
                        font-family: "FilsonProBold", sans-serif;
                    }
                }
            }
            .recommended-badge{
                position: absolute;
                top: 3px;
                left: 20px;
                width: 53px;
                height: 91px;
                background-image: url(/img/recommended.svg);
                background-position: center;
                background-size: cover;
                z-index: 1;
            }
        }
        &.slick-center {
            transform: scale(1);
            .slide-inner {
                .course-image {
                    .slide-overlay {
                        background-color: rgba(28, 14, 42, 0);
                    }
                }
                .slide-content {
                    bottom: 20px;
                    left: 50%;
                    transform: translateX(-50%);
                    align-items: center;
                    text-align: center;
                    .explore {
                        font-size: 1.8rem;
                        text-align: center;
                        h3 {
                            font-family: "FilsonProBook", sans-serif;
                        }
                    }
                    .course-progress {
                        display: block;
                        z-index: 1000;
                        .progress-bar {
                            height: 3px;
                            background-color: #fff;
                            width: 100%;
                            border-radius: 10px;
                            position: relative;
                            overflow: visible;
                            .progress {
                                position: absolute;
                                height: 10px;
                                top: 50%;
                                transform: translateY(-50%);
                                border-radius: 3px;
                                white-space: nowrap;
                            }
                            .progress-info {
                                position: absolute;
                                min-width: 50px;
                                height: 30px;
                                padding-left: 6px;
                                padding-right: 6px;
                                top: -45px;
                                transform: translateX(-50%);
                                font-size: 16px;
                                line-height: 34px;
                                text-align: center;
                                border-radius: 5px;
                                font-family: "FilsonProBold", sans-serif;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                &:before {
                                    position: absolute;
                                    content: "";
                                    left: 50%;
                                    bottom: -5px;
                                    transform: translateX(-50%);
                                    border-left: 5px solid transparent;
                                    border-right: 5px solid transparent;
                                }
                            }
                        }
                    }
                }
            }
        }
        .single-slide{
            display: flex;
            height: 380px;
            padding: 0 40px;
            .slide-image {
                height: 100%;
                display: flex;
                opacity: 0.7;
                img {
                    max-width: 300px;
                    width: 100%;
                    margin: auto;
                }
            }
            .explore {
                font-size: 1.6rem;
                font-family: "FilsonProLight", sans-serif;
                text-transform: uppercase;
                text-align: center;
                h3 {
                    font-size: 2.2rem;
                    letter-spacing: 0;
                    text-transform: unset;
                    font-family: "FilsonProBold", sans-serif;
                }
            }
            .course-progress {
                display: block;
                z-index: 1000;
                .progress-bar {
                    height: 3px;
                    background-color: #fff;
                    width: 100%;
                    border-radius: 10px;
                    position: relative;
                    overflow: visible;
                    .progress {
                        position: absolute;
                        height: 10px;
                        top: 50%;
                        transform: translateY(-50%);
                        border-radius: 3px;
                        white-space: nowrap;
                    }
                    .progress-info {
                        position: absolute;
                        min-width: 50px;
                        height: 30px;
                        padding-left: 6px;
                        padding-right: 6px;
                        top: -45px;
                        transform: translateX(-50%);
                        font-size: 16px;
                        line-height: 34px;
                        text-align: center;
                        border-radius: 5px;
                        font-family: "FilsonProBold", sans-serif;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        &:before {
                            position: absolute;
                            content: "";
                            left: 50%;
                            bottom: -5px;
                            transform: translateX(-50%);
                            border-left: 5px solid transparent;
                            border-right: 5px solid transparent;
                        }
                    }
                }
            }
        }
    }
    .slick-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
        background-color: transparent;
        color: #fff;
        font-size: 3rem;
        height: 40px;
        width: 40px;
        padding: 0 0 10px 10px;
        line-height: 0;
        font-family: "FilsonProBold", sans-serif;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        &:focus {
            outline: none;
        }
        &.slick-next {
            right: 0;
        }
    }
    .no-courses{
        h4{
            font-family: FilsonProBold;
        }
    }
    .loading{
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
