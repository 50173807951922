<template>
  <div id="ShareModal">
      <div class="header">
          <i class="fa-solid fa-xmark fa-2xl close-btn" @click="close"></i>
      </div>
      <div class="title brand-text">{{title}}</div>
      <div class="instruction brand-text">{{instruction}}</div>
      <div class="link-container">
          <div class="link-holder">
              <div class="link-half brand-text">{{link}}</div>
              <div class="btn-half bg-brand-highlight-1" :class="success?'success':null" @click="copyURL">
                  <i class="fa-solid fa-link"></i>
                  <div class="copy">{{displayMsg ? msg : 'Copy'}}</div>
              </div>
          </div>
      </div>

  </div>
</template>

<script >
  export default {
      name: 'ShareModal',
      props: ['title','instruction','link'],
      data() {
        return {
            displayMsg: false,
            msg: null,
            success: false
        }
      },
      methods: {
          close() {
            this.$bvModal.hide('share-modal')
          },
          async copyURL() {
              try {
                  await navigator.clipboard.writeText(this.link);
                  this.msg = "Copied";
                  this.displayMsg = true;
                  this.success = true;
                  setTimeout(()=> {
                      this.displayMsg = false;
                      this.success = false
                  },3000)
              } catch($e) {
                  this.msg = "Failed";
                  this.displayMsg = true;
                  setTimeout(()=> {
                      this.displayMsg = false;
                  },3000)
              }
          }
      }
  }
</script>

<style lang="less">
#share-modal {
  .modal-content {
    background-color: white;
    border-radius: 0;
    width: 500px;
    margin-top: 200px;
  }
  .modal-dialogue, .modal-dialog-centered {
    display: flex !important;
    align-items: flex-start !important;
  }
}
#ShareModal {
  width: 100%;
  text-align: center;
  font-family: Open Sans, sans-serif;
  .close-btn {
      cursor: pointer;
  }
  .title {
    margin-top: 40px;
    font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    font-size: 2rem;
  }
  .instruction {
    margin-top: 30px;
    font-size: 1.3rem;
  }
  .header {
    padding: 15px;
    display: flex;
    justify-content: end;
  }
  .link-container {
    align-items: center;
    justify-content: center;
    display: flex;
    .link-holder {
      margin-top: 30px;
      margin-bottom: 40px;
      height: 45px;
      display: flex;
      .link-half {
        line-height: 35px;
        font-size: 1.2rem;
        padding: 5px;
        border: black 1px solid;
        width: 200px;
        overflow: hidden;
        background-color: #FAFAFA;
        position: relative;
      }
      .link-half::after {
          content: "";
          position: absolute;
          z-index: 1;
          bottom: 0;
          left: 0;
          pointer-events: none;
          background-image: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
          width: 100%;
          height: 4em;
      }
      .btn-half {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        line-height: 2.5rem;
        font-size: 1.4rem;
        color: white;
        cursor: pointer;
        border-radius: 0 20px 20px 0;
          &.success {
              background-color: #64B811 !important;
          }
        i {
          margin-left: 10px;
          margin-right: 10px;
        }
        .copy {
          margin-right: 20px;
        }
      }
    }
  }

}
</style>