<template>
<!--  <b-modal-->
<!--      id="profile-questionnaire-modal"-->
<!--      v-bind:modal-class="'questionnaire-modal ' + getTheme()"-->
<!--      size="lg"-->
<!--      hide-footer-->
<!--      hide-header-->
<!--  >-->
    <section class="pulse-container" id="profile-questionnaire-modal">
      <div class="pulse-form-container content-container">
        <div class="pulse-form-header bg-brand-secondary">
          <h2>Complete your profile to start your journey!</h2>
        </div>
        <div class="content-container p-5">
          <h2 class="my-information">My Information</h2>
          <hr class="mb-5">
          <b-row>

          </b-row>
          <b-row>
            <div class="mb-3 col-12 mb-4 details-heading">
              Personal Details
            </div>
            <div class="mb-3 col-12 col-md-6">
              <b-form-group
                  id="fieldset-2"
                  label="Email"
                  label-for="input-5"
              >
                <b-form-input id="input-5" v-model="email" trim disabled></b-form-input>
              </b-form-group>
            </div>
            <div class="mb-3 col-12 col-md-6">
              <b-form-group
                  id="fieldset-2"
                  label="Organisation"
                  label-for="input-5"
              >
                <b-form-input id="input-5" v-model="client_name" trim disabled></b-form-input>
              </b-form-group>
            </div>
            <div class="mb-3 col-12 col-md-6">
              <b-form-group
                  id="fieldset-1"
                  label="Gender"
                  label-for="input-3"
              >
                <b-form-select id="input-3" v-model="user.user_profile.gender" :options="gender_options"></b-form-select>
              </b-form-group>
            </div>
            <div class="mb-3 col-12 col-md-6">
              <b-form-group
                  id="fieldset-2"
                  label="Date of Birth"
                  label-for="input-2"
              >
                <b-form-input id="input-2" type="date" v-model="user.user_profile.dob"></b-form-input>
              </b-form-group>
            </div>
            <div class="mb-3 col-12 col-md-6">
              <b-form-group
                  label="Place of Birth"
                  label-for="input-9"
              >
                <b-form-select v-model="user.user_profile.place_of_birth"
                               :options="ethnicity_options"
                               id="input-9"
                               :value="user.user_profile.place_of_birth"
                ></b-form-select>
              </b-form-group>
            </div>
            <div class="mb-3 col-12 col-md-6">
              <b-form-group
                  id="fieldset-1"
                  label="Education Level"
                  label-for="input-5"
              >
                <b-form-select id="input-5" v-model="user.user_profile.education_level"
                               :options="education_options"></b-form-select>
              </b-form-group>
            </div>
            <div class="mb-3 col-12 col-md-6">
              <b-form-group
                  id="fieldset-2"
                  label="Job Type"
                  label-for="input-5"
              >
                <b-form-input id="input-5" v-model="user.user_profile.job_type" trim></b-form-input>
              </b-form-group>
            </div>
            <div class="mb-3 col-12 col-md-6">
              <b-form-group
                  id="fieldset-3"
                  label="Occupation"
                  label-for="input-6"
              >
                <b-form-input id="input-6" v-model="user.user_profile.occupation" trim></b-form-input>
              </b-form-group>
            </div>
            <div class="mb-3 col-12 col-md-6">
              <b-form-group
                  label="Do you have a disability?"
                  label-for="input-3"
              >
                <b-form-select id="input-3" v-model="user.user_profile.disability" :options="disability_options"></b-form-select>
              </b-form-group>
            </div>
            <div class="mb-3 col-12 col-md-6">
              <b-form-group
                  label="If yes, please specify"
                  label-for="input-11"
              >
                <b-form-input id="input-11" v-model="user.user_profile.disability_specify"
                              :disabled="!user.user_profile.disability"
                              trim></b-form-input>
              </b-form-group>
            </div>
            <div class="mb-3 col-12 col-md-6">
              <b-form-group
                  id="fieldset-3"
                  label="Nationality"
                  label-for="input-13"
              >
                <b-form-input id="input-13" v-model="user.user_profile.nationality" trim></b-form-input>
              </b-form-group>
            </div>
            <div class="mb-3 col-12 col-md-6">
              <b-form-group
                  id="fieldset-3"
                  label="Languages"
                  label-for="input-14"
              >
                <b-form-input id="input-14" v-model="user.user_profile.language" trim></b-form-input>
              </b-form-group>
            </div>
            <div class="mb-3 col-12 col-md-6">
              <b-form-group
                  label="Employment Status"
                  label-for="input-7"
              >
                <b-form-select
                    id="input-7"
                    v-model="user.user_profile.employment_status"
                               :options="employment_options"></b-form-select>
              </b-form-group>
            </div>
            <div class="mb-3 col-12 col-md-6">
              <b-form-group
                  label="How may dependants do you have?"
                  label-for="input-10"
              >
                <b-form-input id="input-10" type="number" v-model="user.user_profile.dependants" min="0"></b-form-input>
              </b-form-group>
            </div>
            <div class="mb-3 col-12 col-md-6">
              <b-form-group
                  id="fieldset-5"
                  label="Relationship Status"
                  label-for="input-8"
              >
                <b-form-select id="input-8" v-model="user.user_profile.relationship_status"
                               :options="relationship_options"></b-form-select>
              </b-form-group>
            </div>
            <div class="mb-3 col-12 d-flex justify-content-end align-items-center">
                <b-button :disabled="!valid_form" @click="updateUserDetails" class="cta-button my-4">Save</b-button>
            </div>
          </b-row>
        </div>
      </div>
    </section>
<!--  </b-modal>-->

</template>

<script>
// @ is an alias to /src
import axios from "axios";
import config from "@/config/constants";

export default {
  name: "ProfileQuestionnaireModal",

  data() {
    return {
      loading: false,
      gender_options: [
        'Male',
        'Female',
        'Non-binary',
        'Other'
      ],
      education_options: [
        'Primary School',
        'Secondary / High School',
        'Bachelors Degree',
        'Masters Degree',
        'PhD',
        'Trade School',
        'Prefer not to say'
      ],
      ethnicity_options: [
        'Europe',
        'Africa',
        'Asia',
        'South America',
        'North/Central America',
        'Australia/NZ',
        'Caribbean Islands',
        'Pacific Islands',
        'Other',
        'Prefer not to say'
      ],
      relationship_options: [
        'Single',
        'in a relationship',
        'in a relationship and cohabiting'
      ],
      employment_options: [
        'Full Time Employed',
        'Part Time Employed',
        'Unemployed',
        'Free lancer',
        'Retired',
        'Prefer not to say'
      ],
      disability_options: [
        { value: true, text: 'Yes' },
        { value: false, text: 'No' }
      ],
      userDetailsPopupContent: {
        header: '',
        message: ''
      },
      showUserDetailsPopup: false,
    };
  },
  props: ['user','email','client_name'],
  mounted() {

  },
  computed: {
    valid_form() {
      return(
          this.user.user_profile.gender &&
          this.user.user_profile.dob &&
          this.user.user_profile.place_of_birth &&
          this.user.user_profile.job_type &&
          this.user.user_profile.education_level &&
          this.user.user_profile.occupation &&
          (this.user.user_profile.disability !== "" && this.user.user_profile.disability !== null) &&
          (this.user.user_profile.disability === false || this.user.user_profile.disability_specify) &&
          this.user.user_profile.nationality &&
          this.user.user_profile.language &&
          this.user.user_profile.employment_status &&
          (this.user.user_profile.dependants !== '' && this.user.user_profile.dependants !== null && this.user.user_profile.dependants >= 0) &&
          this.user.user_profile.relationship_status
      )
    }
  },
  methods: {
    closeModal() {
      this.$root.$emit('profileQuestionnaireCompleted');
      this.$bvModal.hide("profile-questionnaire-modal");
    },
    async updateUserDetails() {
      const payload = {
        user_id: this.$store.getters.user_id,
        user_profile: this.user.user_profile
      }
      await axios.post(config.api_env + '/application/api/u/user/update-user-profile', payload, config.options)
          .then((response) => {
            if(response.data.success) {
              this.closeModal();
            }
          });
    },

  },
};
</script>
<style lang="less">
#profile-questionnaire-modal{
  .my-information {
    font-family: FilsonProBold;
  }
  .cta-button {
    padding: 10px 40px;
  }
  .sub-header {
    padding: 15px;
    font-size: 1.8rem;
    font-weight: bold;
  }
  .pulse-form-header {
    font-size: 1.6rem;
    font-weight: bold;
    padding: 30px;
    text-align: center;
    color: white;
    }
  .pulse-container {
    background-color: #f8f8f8;
    min-height: 300px;
    border-radius: 300px;
  }
  .form-control {
    font-family: "FilsonProBook", sans-serif;
    border: 0;
    background: transparent;
    border-bottom: 1px solid #E6E6E6;
    font-size: 14px;
    height: auto !important;
  }

  .form-control:focus {
    outline: none !important;
    border: unset;
    border-bottom: 1px solid #E83B75 !important;
    box-shadow: unset;
  }

  .form-control:disabled {
    color: #d6d6d6;
    height: auto !important;
  }
  .custom-select {
    font-family: "FilsonProBook", sans-serif;
    border: 0;
    background: transparent;
    border-bottom: 1px solid #E6E6E6;
    font-size: 14px;
  }
  label, .details-heading {
    color: #322B5F;
    font-size: 1.6rem;
    font-family: "FilsonProBook", sans-serif;
  }
  .custom-select:focus {
    outline: none !important;
    border: unset;
    border-bottom: 1px solid #E83B75 !important;
    box-shadow: unset;
  }
}
</style>
