<template>
  <div id="home-carousel" class="carousel-wrap bg-brand-secondary px-md-5">
    <div class="content-container" v-if="promotionsData.promotions[0]">
      <b-carousel :interval="5000">
        <b-carousel-slide v-for="(promo, index) in promotionsData.promotions" :key="index">
          <div class="row ">
            <div class="col-md-8 col-sm-12">
              <div class="carousel-title text-center text-md-left mb-3">
                {{ promotionsData.promotions[index].title }}
              </div>

              <div class="carousel-text text-center text-md-left mb-5">
                {{ promotionsData.promotions[index].sub_title }}
              </div>
              <div class="text-center text-md-left promo-links">
                <b-button
                  class="cta-button text-uppercase px-5 py-3"
                  v-if="promotionsData.promotions[index].link_url != null"
                  :href="promotionsData.promotions[index].link_url"
                >
                  {{ promotionsData.promotions[index].link_text }}
                </b-button>
                <!--        CHECK FOR IOS LINK --->
                <div
                  class="row"
                  v-if="promotionsData.promotions[index].link_android != null || promotionsData.promotions[index].link_ios != null"
                >
                  <div class="col-12 mobile-logos">
                    <a @click="iosDownload(promotionsData.promotions[index].link_ios)" >
                      <img
                              class="apple-store_img pb-2"
                              alt="Get it on App Store"
                              src="/img/iphone-app.png"
                      />
                    </a>
                    <a target="_blank" :href="promotionsData.promotions[index].link_android" >
                      <img
                        class="google-play_img pb-2"
                        alt="Get it on Google Play"
                        src="/img/google-play.png"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-none d-md-flex col-md-4 carousel-image-wrap">
              <div class="carousel-image">
                <img :src="promotionsData.promotions[index].image_url" />
              </div>
            </div>
          </div>
        </b-carousel-slide>
      </b-carousel>
    </div>
    <popup v-if="showPopup === true" :content="popupContent" v-on:close="hidePopup" @back="hidePopup"></popup>
  </div>
</template>
<script>
// @ is an alias to /src
import axios from "axios";
import config from "../../config/constants";
import Popup from "../Popup.vue";
export default {
  name: "HomeCarousel",
  components: {Popup},
  data() {
    return {
      promotionsData: {
        promotions: [],
      },
      showPopup : false,
      popupContent: {
        header : '',
        message : '',
        buttonText : 'button text'
      },
    };
  },
  mounted() {
      if(this.$store.getters.hardcoded && this.$store.getters.hardcoded.promotions){
          this.promotionsData.promotions = this.$store.getters.hardcoded.promotions
          this.$emit('loaded')
      }else{
          this.getPromotions()
      }
  },
  methods: {
    hidePopup(){
      this.showPopup = false;
    },
    async getPromotions() {
      const payload = {
        user_id: this.$store.getters.user_id
      };

      let promotions = await axios.post(
        config.api_env + "/application/api-hc/get-promotions",
        payload,
        config.options
      );
      this.promotionsData = promotions.data;
        this.$emit('loaded')
    },
    async iosDownload(link) {
        //Vodafone have to keep the same app download flow.
        if(this.$store.getters.subdomain.toLowerCase() === 'vodafone'){
            this.requestAppVoucher()
        }else if(typeof link === 'string'){
            //if the link is a string, open it in new tab.
            window.open(link)
        }else {
            //push to boi app download page or any other internal page
            this.$router.push(link)
        }
    },
      async requestAppVoucher() {
          const payload = {
              user_id: this.$store.getters.user_id,
          };
          let res = await this.axios.post(config.api_env + "/application/api/redeem/enterprise-app-voucher", payload, config.options);

          if(res.data.success === true){
              //TODO update popup content
              this.popupContent = {
                  header : this.componentText.iosTitle,
                  message : this.componentText.iosMessage,
                  subtext: this.componentText.iosSubtext,
                  buttonText: this.componentText.iosButton
              };
              this.showPopup = true
              this.emailSent = true
          } else {
              this.popupContent = {
                  header : this.componentText.error,
                  message : this.componentText.errorMessage,
                  buttonText: this.componentText.iosButton
              };
              this.showPopup = true
          }
      },
  },
  computed:{
    componentText() {
      return this.getText.loggedIn.home.homeCarousel;
    },
  }
};
</script>
<style lang="less">
.carousel-wrap {
  .carousel-item {
    min-height: 330px;
    .carousel-caption {
      width: 100%;
      position: relative;
      padding-top: 60px;
      left: 0;
      right: 0;
    }
    .carousel-title {
      font-family: Open Sans, sans-serif;
      font-size: 4.3rem;
      color: #fff;
      font-weight: 800;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-box-pack: center;
    }
    .carousel-text {
      font-family: FilsonProLight, sans-serif;
      font-size: 1.8rem;
      color: #fff;
      letter-spacing: 2px;
    }
    .carousel-image {
      width: 100%;
      img{
        max-height: 200px;
      }
    }
    .promo-links {
      display: block;

      .mobile-logos {
        max-height: 100px;
        a {
          width: 30%;
          img {
            margin: auto;
            display: inline;
            padding-right: 16px;
            max-width: 200px;
            height: 51px;
          }
        }
      }
    }
  }
}
</style>
