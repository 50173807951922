<template>
  <div id="TrainingPlanVideo">
    <div class="event-content">
      <div class="event-video text-center">
        <div class="video-wrap">
          <div
            :class="{
              'aspect-ratio': !decodedEmbed.includes('sli.do'),
            }"
            v-html="decodedEmbed"
          ></div>
        </div>
        <div class="training-plan-content-disclaimer">
          <div class="col-12 text-center event-content brand-text">
            <h4 class="disclaimer-heading pt-4">
              {{ componentText.disclaimerHeading }}
            </h4>
            <a
              v-b-toggle.disclaimer
              class="text-link"
              v-if="!disclaimerVisible"
              >{{ componentText.showMore }}</a
            >
            <b-collapse
              id="disclaimer"
              v-model="disclaimerVisible"
              class="my-5"
              v-html="componentText.disclaimerContent"
            >
            </b-collapse>
            <a
              v-b-toggle.disclaimer
              class="text-link"
              v-if="disclaimerVisible"
              >{{ componentText.showLess }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TrainingPlanVideo",
  props: ["classItem"],
  data() {
    return {
      videoReload: false,
      disclaimerVisible: false,
    };
  },
  computed: {
    decodedEmbed() {
      return this.classItem.video_url ? atob(this.classItem.video_url) : "";
    },
    componentText() {
      return this.getText.liveEvent;
    },
  },
};
</script>

<style lang="less">
#TrainingPlanVideo {
  .event-content {
    position: relative;
    .return {
      background: transparent;
      position: absolute;
      top: 30px;
      left: 30px;
      .circle {
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
          border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
          -webkit-box-shadow 0.15s ease-in-out;
      }
      &:hover {
        .circle {
          fill: #AA0061;
        }
      }
    }
    .video-wrap {
      text-align: center;
      max-width: 100%;
      margin: auto;
      .aspect-ratio {
        max-width: 1000px;
        width: 100%;
        position: relative;
        margin: auto;
        &:after {
          padding-top: 56.4%;
          display: block;
          content: "";
        }
        iframe {
          width: 100%;
          height: 100%;
          border-radius: 30px;
          border: 1px solid #afb0b2;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }
    }
    .text-link {
      font-family: FilsonProBold;
    }
    #disclaimer {
      max-width: 600px;
      margin: auto;
      h4 {
        font-size: 1.6rem;
        margin-bottom: 10px;
      }
      p,
      li {
        font-size: 1.4rem;
        text-align: left;
      }
    }
    .not-available {
      max-width: 600px;
      margin: auto;
      text-align: center;
      h3 {
        padding: 0;
      }
    }
    .event-info {
      max-width: 600px;
      margin: auto;
      text-align: center;
      svg {
        transform: translateY(-3px);
        margin-right: 5px;
      }
      i {
        font-size: 2.1rem;
        color: #999b9e;
      }
      .date-title {
        font-size: 1.7rem;
        letter-spacing: -0.4px;
      }
      .cta-button {
        font-size: 2rem;
      }
    }
  }
}
</style>
