<template>
  <div
    id="NotLoggedIn"
    :class="{
      'bg-brand-primary': skin > 0 && !isLayaWellbeing(),
      'bg-grad-image-blue': skin === 0,
      'bg-white': isLayaWellbeing(),
    }"
  >
    <!--TODO::Refactor this section -->
    <DigitalClinicsLogin
      v-if="isLayaWellbeing() && layaLogin"
      @spectrum-login="layaLogin = false"
    ></DigitalClinicsLogin>
    <SSOLanding
      v-else-if="$store.getters.subdomain === 'ornua' && layaLogin"
      @spectrum-login="layaLogin = false"
    />
    <SSOLanding
      v-else-if="$store.getters.subdomain === 'pwcuk' && layaLogin"
      @spectrum-login="layaLogin = false"
    />
    <SSOLanding
      v-else-if="$store.getters.subdomain === 'pwcire' && layaLogin"
      @spectrum-login="layaLogin = false"
    />
    <SSOLanding
      v-else-if="$store.getters.subdomain === 'greenergy' && layaLogin"
      @spectrum-login="layaLogin = false"
    />
    <SSOLanding
        v-else-if="$store.getters.subdomain === 'convergint' && layaLogin"
        @spectrum-login="layaLogin = false"
    />
    <SSOLanding
        v-else-if="$store.getters.subdomain === 'nchwellbeing' && layaLogin"
        @spectrum-login="layaLogin = false"
    />
    <SSOLanding
        v-else-if="$store.getters.subdomain === 'nulondon' && layaLogin"
        @spectrum-login="layaLogin = false"
    />
    <SSOLanding
        v-else-if="$store.getters.subdomain === 'uhi' && layaLogin"
        @spectrum-login="layaLogin = false"
    />
    <SSOLanding
        v-else-if="($store.getters.subdomain === 'rcsi-eap' || $store.getters.subdomain === 'rcsieap') && layaLogin"
        @spectrum-login="layaLogin = false"
    />
    <SSOLanding
        v-else-if="($store.getters.subdomain === 'rcsi-sap' || $store.getters.subdomain === 'rcsisap') && layaLogin"
        @spectrum-login="layaLogin = false"
    />
    <SSOLanding
        v-else-if="($store.getters.subdomain === 'irish-distillers' || $store.getters.subdomain === 'irishdistillers') && layaLogin"
        @spectrum-login="layaLogin = false"
    />
    <SSOLanding
        v-else-if="($store.getters.subdomain === 'hrduo') && layaLogin"
        @spectrum-login="layaLogin = false"
    />
    <SSOLanding
        v-else-if="($store.getters.subdomain === 'test') && layaLogin"
        @spectrum-login="layaLogin = false"
    />
    <AccessCode
        v-else-if="$store.getters.subdomain === 'landg' && accessLogin"
    />
    <AccessCode v-else-if="$store.state.accessCode"></AccessCode>
    <LoginV2
        v-else-if="(screen === 0 || screen === 4) && navType === 'versionTwo' || isLayaWellbeing()"
        :reset="screen === 4"
        v-on:sign-up="changeScreen(1)"
        v-on:forgot-pass="changeScreen(2)"
        v-on:not-v3="changeScreen(5)"
        v-on:faqs="changeScreen(6)"
    />
    <Login
        v-else-if="screen === 0"
        v-on:sign-up="changeScreen(1)"
        v-on:forgot-pass="changeScreen(2)"
        v-on:not-v3="changeScreen(5)"
        v-on:faqs="changeScreen(6)"
    />
    <div class="container" v-else>

      <SignUp
        v-if="screen === 1"
        v-on:sign-in="changeScreen(0)"
        v-on:signed-up="changeScreen(3)"
      />
      <ForgotPassword v-if="screen === 2" v-on:sign-in="changeScreen(0)" />
      <VerifyEmail
        v-if="screen === 3"
        v-on:email-sent="changeScreen(0)"
      ></VerifyEmail>
      <reset-password
        v-if="screen === 4"
        v-on:reset-pass="changeScreen(0)"
      ></reset-password>
      <GetOrg v-if="screen === 5"></GetOrg>
      <Faqs v-if="screen === 6"></Faqs>
      <CoachingSurvey v-if="screen === 8"></CoachingSurvey>
      <PulseSurvey v-if="screen === 9"></PulseSurvey>
    </div>
    <Warning v-if="showWarning && navType !== 'versionTwo'" :warning="warning" />
    <WarningV2 v-else-if="showWarning" :warning="warning" />
  </div>
</template>

<script>
// @ is an alias to /src
import Login from "@/components/NotLoggedIn/Login.vue";
import SignUp from "@/components/NotLoggedIn/SignUp.vue";
import ForgotPassword from "@/components/NotLoggedIn/ForgotPassword.vue";
import Warning from "@/components/Warning.vue";
import VerifyEmail from "@/components/NotLoggedIn/VerifyEmail.vue";
import ResetPassword from "../components/NotLoggedIn/ResetPassword.vue";
import GetOrg from "../components/NotLoggedIn/GetOrg.vue";
import AccessCode from "../components/NotLoggedIn/AccessCode";
import DigitalClinicsLogin from "../components/NotLoggedIn/DigitalClinicsLogin";
import LayaFooter from "../components/LayaFooter";
import { mapGetters } from "vuex";
import SSOLanding from "../components/NotLoggedIn/SSOLanding";
import Faqs from "@/pages/Faqs";
import CoachingSurvey from "@/pages/CoachingSurvey";
import PulseSurvey from "@/pages/PulseSurvey";
import LoginV2 from "@/components/NotLoggedIn/LoginV2.vue";
import WarningV2 from "@/components/WarningV2.vue";
export default {
  name: "NotLoggedIn",
  components: {
    CoachingSurvey,
    Faqs,
    SSOLanding,
    DigitalClinicsLogin,
    AccessCode,
    ResetPassword,
    Login,
    SignUp,
    ForgotPassword,
    Warning,
    VerifyEmail,
    GetOrg,
    LayaFooter,
    PulseSurvey,
    LoginV2,
    WarningV2
  },
  data() {
    return {
      screen: 0,
      showWarning: null,
      layaLogin: true,
      accessLogin: true,
    };
  },
  beforeCreate() {
    if(this.$store.getters.subdomain === 'bupa'){
      window.open(this.getBupaTouchLink, '_self')
    }
  },
  mounted() {
    //TODO refactor this into separate pages ands routes
    if (this.$route.name === "verifyEmail") {
      this.screen = 3;
    } else if (this.$route.name === "resetPassword") {
      this.screen = 4;
    } else if (this.$route.name === "forgotPassword") {
      this.screen = 2;
    } else if (this.$route.query.org) {
      this.orgCode = this.$route.query.org;
      this.screen = 1;
    } else if (this.$route.name.includes("coaching-survey")) {
      this.screen = 8;
    } else if (this.$route.name.includes("survey")) {
      this.screen = 9;
    } else {
      this.screen = 0;
    }
    if(this.$store.getters.subdomain !== 'landg'){
      this.accessLogin = false;
    }
  },
  methods: {
    changeScreen(screen) {
      this.screen = screen;
    },
    toggleAccessLogin(){
      this.accessLogin = !this.accessLogin;
    },
  },
  computed: {
    ...mapGetters(["warning", "skin", "subdomain","navType"]),
    getBupaTouchLink() {
            if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'staging') {
                return 'https://scsmoke3.np.bupa.co.uk/touchdashboard/dashboard/gp-options'
            } else {
                return 'https://www.bupa.co.uk/touchdashboard/dashboard/gp-options'
            }
        },
  },
  watch: {
    warning(newWarning) {
      if (newWarning) {
        this.showWarning = true;
      } else this.showWarning = false;
    },
    $route(newRoute) {
      if (newRoute.name === "verifyEmail") {
        this.screen = 3;
      } else if (newRoute.name === "verifyEmail") {
        this.screen = 4;
      } else {
        this.screen = 0;
      }
    },
  },
};
</script>
<style lang="less">
#NotLoggedIn {
  position: relative;
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: auto;
  &:after {
    //IE vertical centre
    content: "";
    min-height: inherit;
    font-size: 0;
  }
  .warning {
    position: absolute;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    top: 0;
    left: 0;
  }
  .dropdown-toggle {
    color: inherit;
    font-family: "FilsonProBook", sans-serif;
    letter-spacing: 0;
    background: transparent;
    &:active {
      background: transparent;
    }
  }
  .dropdown-menu {
    font-size: 1.4rem;
  }
  .container {
    .page-content {
      background-color: #fff;
      border-radius: 30px;
      overflow: hidden;
      min-height: 400px;
      padding: 0px !important;
      @media screen and (max-width: 768px) {
        border-radius: 0px;
      }
      .img-part {
        padding-left: 0px;
        .login-bg {
          -webkit-background-size: cover;
          -moz-background-size: cover;
          -o-background-size: cover;
          background-size: cover;
          position: relative;
          height: 100%;
          width: 100%;
          .heart-placeholder {
            position: absolute;
            top: 10%;
            left: 10%;
            height: 10%;
            width: 10%;
            .heart-img {
              height: 65px;
            }
          }
          .login-image {
            height: 60%;
            width: 80%;
            background-image: url("/img/auth/_sign-in.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
          }
          .verify-image {
            position: absolute;
            top: 20%;
            left: 20%;
            height: 60%;
            width: 60%;
            background-image: url("/img/auth/_verify-email.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
          }
          .sign-up-image {
            position: absolute;
            top: 10%;
            left: 10%;
            height: 80%;
            width: 80%;
            background-image: url("/img/auth/_sign-up.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
          }
          .forgot-pass-image {
            position: absolute;
            top: 20%;
            left: 20%;
            height: 60%;
            width: 60%;
            background-image: url("/img/auth/_forgot-password.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
          }
        }
      }
      .logos {
        flex-wrap: nowrap;
        justify-content: center;
        .logo {
          flex-grow: 1;
          justify-content: center;
          align-items: center;
          //height: 25px;
          img {
            max-height: 100%;
            max-width: 100%;
          }
        }
      }
      .form {
        background: #ffffff;
        .form-content {
          padding: 25px;
          min-heigh: 795px;
          .form-logo {
            margin: 30px auto 30px auto;
            img {
              width: 100%;
            }
          }
          p {
            font-size: 1.7rem;
            font-family: "FilsonProBook", sans-serif;
            text-align: center;
            &.small {
              font-size: 1.3rem;
            }
          }
          label {
            color: #8d8e92;
            font-size: 1.5rem;
            margin-left: 20px;
          }
          .form-control {
            background-color: #efeef2;
            height: 50px;
            border-radius: 25px;
            font-size: 1.8rem;
            font-family: "Open Sans", sans-serif;
          }
          .forgot-password {
            color: #8d8e92;
            font-size: 1.5rem;
            text-decoration: underline;
            margin-left: 20px;
          }
          .submit-form {
            margin: 50px 0 0 0;
            text-align: center;
            .btn {
              margin: 0 0 30px 0;
              padding: 0 50px;
              box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.3);
              &.disabled {
                cursor: default;
              }
            }
          }
          .new-here {
            font-size: 1.7rem;
            text-decoration: none;
            text-align: center;
            margin-bottom: 25px;
          }
          .invalid-feedback {
            font-size: 1.4rem;
            color: red;
          }
          .is-valid {
            border-color: #ced4da;
            box-shadow: none;
          }
          .is-invalid,
          .is-valid {
            background-image: none;
          }
          .password-wrap {
            position: relative;
            .eye {
              font-size: 1.3rem;
              position: absolute;
              right: 20px;
              top: 50%;
              transform: translateY(-50%);
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
