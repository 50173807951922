<template>
  <div id="download-banner" class="bg-brand-secondary px-md-5">
    <div class="row content-container">
      <div class="col-12 col-md-6 pl-md-0 download-title">{{bannerText}}
        <div class="subtext" v-if="subtext">{{subtext}}</div>
      </div>
      <div class="col-12 col-md-6 app-links">
        <div class="download-links">
          <a v-if="ios_app_link !== '' " :href="ios_app_link" target="_blank"
          >
            <img
              class="apple-store_img"
              alt="Get it on App Store"
              src="/img/iphone-app.png"
            />
          </a>
          <a v-else @click="$router.push({name: 'download'})">
            <img
                    class="apple-store_img"
                    alt="Get it on App Store"
                    src="/img/iphone-app.png"
            />
          </a>
          <a :href="android_app_link" target="_blank">
            <img
              class="google-play_img"
              alt="Get it on Google Play"
              src="/img/google-play.png"
            />
          </a>
        </div>
      </div>
    </div>
    <popup v-if="showPopup === true" :content="popupContent" v-on:close="hidePopup"></popup>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import config from "../config/constants";
import Popup from "./Popup.vue";
export default {
  name: "DownloadBanner",
  components: {Popup},
  data() {
    return {
      bannerText : 'Download the Spectrum.Life App',
        subtext: '',
      android_app_link : '',
      ios_app_link : '',
      popupContent : {
        header : '',
        message : ''
      },
      showPopup : false
    };
  },
  mounted() {
    this.getContent();
  },
  methods: {
    getContent(){
      if(this.$store.getters.hardcoded && this.$store.getters.hardcoded.downloadBanner){
          const banner = this.$store.getters.hardcoded.downloadBanner
        this.bannerText = banner.bannerText
          this.subtext = banner.bannerSubText
        this.android_app_link = banner.link_android
        this.ios_app_link = banner.link_ios
      }
    },
    async requestAppVoucher() {
      const payload = {
        user_id: this.$store.getters.user_id
      };
      let res = await this.axios.post(config.api_env + "/application/api/redeem/enterprise-app-voucher", payload, config.options);

      if(res.data.success === true){
        //TODO update popup content
          this.popupContent = {
              header : 'iOS Voucher Link Emailed',
              message : 'We have emailed you a unique voucher number to install the Bank of Ireland Wellbeing mobile app',
              subtext: '*Please check your junkmail!'
          };
        this.showPopup = true;
      } else {
        //TODO display something else
      }
    },
    hidePopup(){
      this.showPopup = false;
    }
  }
};
</script>
<style lang="less">
#download-banner {
  padding: 40px 0;
  .download-title {
    font-size: 3rem;
    font-family: "FilsonProLight";
    color: #fff;
    @media (max-width: 768px) {
      margin-bottom: 1em;
      text-align: center;
    }
    .subtext{
      font-size: 1rem;
    }
  }
  .app-links {
    margin: auto;
    .download-links {
      max-height: 40px;
      display: block;
      text-align: right;

      a {
        &:first-child {
          padding-right: 20px;
        }
        img {
          height: 40px;
        }
      }
    }
  }
}
</style>
