<template>
    <div class="CourseCard">
        <div class="video-card-video-container shadow position-relative" @click="goToVideo()"
             v-if="!item.details.completed_modules">
            <div class="w-100 h-100 clickable d-flex justify-content-center inner-container not-started">
                <img class="img-fluid image-class h-100" :src="item.thumbnail" />
            </div>
<!--            <div class="card-title">{{item.title}}</div>-->
<!--            <div class="book-now cta-button hover-darken py-3 px-5">-->
<!--                <div class="start-text">{{ componentText.cards.viewCourse }}</div>-->
<!--            </div>-->
        </div>
        <div class="video-card-video-container shadow position-relative" @click="goToVideo()"
             v-else>
            <div class="w-100 h-100 clickable d-flex justify-content-center inner-container">
                <img class="img-fluid image-class h-100" :src="item.thumbnail" />
            </div>
            <div class="innerCard pb-3">

                <div class="progress-text mt-3">
                    <div class="d-flex mb-1">
                        <img alt="road into the distance" src="/img/gold-star.png" class="mr-2 star"/>
                        <div class="progress-title" v-if="item.details.completed_modules >= 1">
                            Great Work {{user.user.first_name}}!</div>
                        <div class="progress-title" v-else>Begin now to progress</div>
                    </div>
                    <div class="continue mt-1">{{item.details.completed_modules < item.details.total_modules ? 'Continue':''}} {{title}}</div>
                </div>

                <div class="progress-bar-container mt-3 mb-2">
                    <b-progress :value="item.details.completed_modules / item.details.total_modules" :max="1" class="mb-1 studio-sidebar-pb">
                    </b-progress>
                    <div class="progress-desc">
                        <span class="completed">{{item.details.completed_modules}}</span> / {{item.details.total_modules}}
                        modules Completed
                    </div>
                </div>

            </div>
<!--            <div class="book-now bg-brand-highlight-1"><div class="start-text text-white">{{ item.details.completed_modules === item.details.total_modules ? "View" :"Continue" }}</div></div>-->
        </div>
      <div class="video-card-meta-container" v-if="true">
        <div class="video-card-series"></div>
<!--        <div class="video-card-sub-title" v-if="item.subtitle">{{item.subtitle}}</div>-->
        <div class="d-flex flex-row flex-nowrap justify-content-between align-items-center mt-3">

          <div class="video-card-title brand-text clickable">{{ item.title }}</div>
          <!--                <VideoCardTags :video="video" :type="type"/>-->
        </div>
      </div>
    </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
    name: "CourseCard",
    props: ['item', 'type','swiping'],
    data() {
        return {
          loading: false,
          completed: 2 // todo: remove once end-point gives number of completed
        };
    },
    mounted() {
    },
    computed: {
      ...mapGetters(["user"]),
      title() {
          if (this.item.title.length > 26) {
              return this.item.title.substring(0, 25) + "..."
          }
          return this.item.title;
      },
      componentText() {
        return this.getText.dfd;
      },
      courseStarted() {
          // todo: needs to check if course has completed modules
       return true;
      }
    },
    methods: {
      goToVideo() {
            if(this.loading || this.swiping) return;
            let back = '';
            if(this.$route.name === 'home') {
              back = "?back=home"
            }
            switch (this.item.details.channel) {
              case 'wellbeing_courses':
                this.$router.push('/wellbeing/' + this.item.id + back)
                break;
              case 'surge':
                this.$router.push('/wellbeing/' + this.item.id + back)
                break;
              case 'be_calm':
                this.$router.push('/be-calm/' + this.item.id + back)
                break;
              default:
                this.$router.push('/wellbeing/' + this.item.id + back)
            }

        }
    }
};
</script>

<style lang="less">
@keyframes fadeIn {
    from {
        display: none;
    }

    to {
        display: block;
    }
}

@keyframes fadeOut {
    from {
        display: block;
    }

    to {
        display: none;
    }
}

.CourseCard {
    .video-card-meta-container {
    .video-card-series {
      font-size: 1.4rem;
    }

    .video-card-title {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-family: "FilsonProBold", sans-serif;
      font-size: 1.5rem;
      max-height: 70px;
    }
    .video-card-sub-title {
      font-family: "FilsonPro", sans-serif;
      font-size: 1.2rem;
    }
    .video-card-tags {
      .video-card-tag {
        text-transform: capitalize;
        font-size: 1.2rem;
        padding: 3px 8px;
        margin-bottom: 2px;
        border: 1px solid #555;
        border-radius: 100px;
        max-width: 100px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
    border-radius: 10px;
    .image-class {
        border-radius: 10px;
    }
    .video-card-video-container {
        border-radius: 10px;
        aspect-ratio: 16/9 !important;
        font-family: "FilsonProBold", sans-serif;
        .video-class {
            display: none;
        }
        .inner-container {
            border-radius: 10px;
            background-color: grey;
            filter: brightness(0.4);
            position: absolute;
            width: 100%;
            height: 100%;
        }
        .not-started {
          filter: none !important;
        }
        .card-title {
            font-family: "FilsonProBold", sans-serif !important;
            color: white !important;
            position: absolute;
            color: white !important;
            font-size: 1.6rem;
            top: 0;
            left: 20px;
            width: 80%;
            height: 70%;
            overflow: hidden;
            padding-top: 10px;
        }
        .cta-button {
            &:hover {
                cursor: pointer !important;
            }
        }
        .innerCard {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            color: white;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            padding-right: 10px;
            padding-left: 10px;
            .progress-text {
                font-family: "FilsonPro", sans-serif;
                flex-direction: column;
                display: flex;
                .star {
                    width: 12px;
                }
            }
            .continue {
                font-family: FilsonProBold, Arial, Helvetica, sans-serif;
                font-size: 1.8rem;
                line-height: 1.7rem;
            }
            .progress-bar-container {
                width: 100%;
                .progress-bar {
                    background: #64B811;
                    position: relative !important;
                    border-radius: 5px;
                }
                .studio-sidebar-pb {
                    border-radius: 5px;
                }
            }
            .progress-desc {
                font-family: "FilsonPro", sans-serif;
                font-size: 1.0rem;
                line-height: 1.1rem;
                width: 65%;
                margin-bottom: 10px;
                .completed {
                    font-family: FilsonProBold, Arial, Helvetica, sans-serif;
                    font-size: 1.3rem;
                }
            }
        }
        .book-now {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 50%;
            padding: 3px 0px !important;
            border-radius: 15px 0px 10px 0px;
            .start-text {
                width: 100%;
                text-align: center;
                font-family: "FilsonProBold", sans-serif !important;
                font-size: 1.1rem;
            }
        }

    }
  @media only screen and (max-width: 450px) {
    .card-title {
      margin-top: 40px !important;
      font-size: 3rem !important;
    }
    .star {
      width: 25px !important;
      height: 25px !important;
    }
    .continue {
      font-size: 2.2rem !important;
      line-height: 2.2rem !important;
    }
    .progress-title {
      font-size: 2rem !important;
    }
    .progress {
      height: 1.5rem !important;
      margin-bottom: 1.75rem !important;
    }
    .progress-desc {
      .completed {
        font-size: 2rem !important;
      }
      font-size: 1.6rem !important;
    }
    .start-text {
      font-size: 2rem !important;
    }
  }
  @media only screen and (min-width: 451px) and (max-width: 576px) {
    .card-title {
      margin-top: 40px !important;
      font-size: 3rem !important;
    }
    .star {
      width: 25px !important;
      height: 25px !important;
    }
    .continue {
      font-size: 2.5rem !important;
      line-height: 2.5rem !important;
    }
    .progress-title {
      font-size: 2.3rem !important;
    }
    .progress {
      height: 1.5rem !important;
      margin-bottom: 1.75rem !important;
    }
    .progress-desc {
      .completed {
        font-size: 2rem !important;
      }
      font-size: 1.6rem !important;
    }
    .start-text {
      font-size: 2.4rem !important;
    }
  }
  @media only screen and (min-width: 577px) and (max-width: 767px) {
    .card-title {
      margin-top: 40px !important;
      font-size: 3rem !important;
    }
    .star {
      width: 40px !important;
      height: 40px !important;
    }
    .continue {
      font-size: 2.8rem !important;
      line-height: 2.8rem !important;
    }
    .progress-title {
      font-size: 2.3rem !important;
    }
    .progress {
      height: 1.5rem !important;
      margin-bottom: 1.75rem !important;
    }
    .progress-desc {
      .completed {
        font-size: 2rem !important;
      }
      font-size: 1.6rem !important;
    }
    .start-text {
      font-size: 2.6rem !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 950px) {

  }
}
</style>
