<template>
    <div id="TextDisplay">
        <div id="vl-inner-container">
            <div class="top-row">
                <div class="title">
                    {{title}}
                </div>
            </div>
            <div class="content-row">
                <div class="text-content" v-html="content">
                </div>
                <div class="button-container">
                    <b-button class="bg-brand-highlight-1" @click="ended">
                        Mark as complete
                    </b-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import CustomAudioPlayer from "@/components/NewStudio&CourseLayout/Content/CustomAudioPlayer.vue";
export default {
    name: 'TextDisplay',
    components: {
        CustomAudioPlayer
    },
    props: {
        authorName: String,
        authorImg: {
            type: String,
            default: '/img/progress.png'
        },
        authorTitle: String,
        backRoute: String,
        content: String,
        title: String
    },
    data() {
        return {

        }
    },
    methods: {
        next() {
            this.$emit('ended');
        },
        previous() {
            this.$emit('previous');
        },
        back() {
            if(this.backRoute) this.$router.push(this.backRoute);
        },
        ended() {
            this.$emit('ended')
        }
    }
}
</script>
<style lang="less">
#TextDisplay {
  font-family: Open Sans, sans-serif;
  background-color: black;
  width: 100%;
  #vl-inner-container {
    display: grid;
      grid-template-rows: fit-content(100%) 1fr;
    width: 100%;
    height: 100%;

    .top-row {
      display: flex;
      grid-row: 1;
      color: #ffffff;
      flex-direction: column;
      padding-top: 30px;
      padding-left: 30px;
      .back {
        cursor: pointer;
      }
      .title {
        font-family: FilsonProBold, Arial, Helvetica, sans-serif;
        margin-top: 25px;
        margin-left: 5%;
        margin-bottom: 15px;
        font-size: 2.5rem;
      }
    }
    .content-row {
      padding-left: 30px;
      padding-top: 15px;
      background-color: #242424;
      grid-row: 2;
      width: 100%;
      .author-row {
        margin-bottom: 20px;
        .featuring-box {
          padding-top: 10px;
          padding-left: 20px;
          flex-direction: column;
          display: flex;
          .top {
            color: #C9C6C6;
          }
          .sp-box {
            display: flex;
            color: white;
            .sp-name{
              font-size: 2rem;
              font-family: FilsonProBold, Arial, Helvetica, sans-serif;
            }
            .sp-title {
              font-size: 1.6rem;
            }
            .img-outer-holder {
              width: 68px;
              height: 68px;
              border-radius: 50%;
              border: cyan 2px solid;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .img-holder {
              width: 60px;
              height: 60px;
              border-radius: 50%;
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;

            }
          }
        }
      }
      .text-content {
        padding: 15px 25px;

        color: white;
        p, li {
            color: white !important;
            font-size: 1.5rem;
            line-height: 2.5rem;
        }
        strong {
            font-family: FilsonProBold, Arial, Helvetica, sans-serif;
        }
        h1, h2, h3, h4, h5, h6 {
            color: white;
        }
      }
    }
    .text-content, .button-container {
        max-width: 1100px;
    }
    .button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        button {
            font-family: Open Sans, sans-serif;
        }
    }
  }
}
</style>