<template>
  <div id="LiveStatsDemo">
    <div class="top">
      <i class="fa-solid fa-circle"></i>My Live Stats
    </div>
    <div class="item">
      <div class="itemSide"><i class="fa-solid fa-clock"></i> </div>
      <div class="statBox">
        <div class="statTitle">Duration</div>
        <div class="stat">17:22</div>
      </div>
    </div>
    <div class="item">
      <div class="itemSide"><i class="fa-solid fa-heart"></i> </div>
      <div class="statBox">
        <div class="statTitle">Heartrate</div>
        <div class="stat">144 <span>bpm</span></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LiveStatsDemo"
}
</script>


<style lang="less">
#LiveStatsDemo {
  position: absolute;
  width: 190px;
  padding-bottom: 8px;
  top: 0;
  color: white;
  left: 0;
  background-color: rgba(0,0,0,0.7);
  .top {
    font-size: 1.6rem;
    padding: 12px 15px;
    display: flex;
    align-items: center;
    font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    border-bottom: #0079C8 1px solid;
    color: white;
    i {
      color: #0BDA51;
      font-size: 1.2rem;
      margin-right: 10px;
    }
  }
  .item {
    display: grid;
    padding: 10px;
    grid-template-columns: 20px 6fr;
    font-size: 1.2rem;
    border-bottom: 1px solid #FFFFFF80;
    .stat {
      font-size: 1.8rem;
      font-family: FilsonProBold, Arial, Helvetica, sans-serif;
      span {
        font-size: 1.2rem;
        font-family: Open Sans, sans-serif;
      }
    }
  }
}
</style>