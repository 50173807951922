<template>
  <div id="ThirdPartyLogin" :class="{'justify-content-between': showMsSso && showGoogleSso, 'justify-content-center': showMsSso + showGoogleSso < 2}">
    <div class="tpBtn" v-if="showGoogleSso">
      <a style="text-decoration: none; color: inherit;" 
        href="https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&client_id=1004686293381-ksji01keetglvips5s7vvjvb3ls96e56.apps.googleusercontent.com&response_type=code&redirect_uri=https%3A%2F%2Fup-spa.surge-qa-1.com%2Foauth%2Fgoogle%2Fauthenticate&xoauth_displayname=Spectrum.Life&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Ffitness.activity.read%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Ffitness.body.read%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Ffitness.location.read%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Ffitness.nutrition.read%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email">
        <img src="/img/logos/ic_google%20logo.png" alt="google logo"/> Google
      </a>
    </div>
    
    <div class="tpBtn" v-if="showMsSso">
      <img src="/img/logos/ms.png" alt="microsoft logo"/>Microsoft
    </div>

  </div>
</template>

<script>
export default {
  name: 'ThirdPartyLogin',
  props: ['showGoogleSso','showMsSso']
}
</script>

<style lang="less">
#ThirdPartyLogin {
  width: 100%;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .tpBtn {
    width: 46%;
    height: 50px;
    border: 1px #DCDBDD solid;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 1.6rem;
    img {
      margin-right: 15px;
    }
    &:hover {
      background: #efefef;
    }
  }
}
</style>