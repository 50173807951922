<template>
  <div id="access-code" class="container login">
    <div class="row page-content">
      <div class="col-md-7 pr-0 img-part">
        <div class="login-bg d-flex justify-content-center align-items-center">
        </div>
      </div>
      <div class="col-md-5 form min-height">
        <div class="form-content">
          <div class="form-logo">
            <img :src="getLogo" onerror="javascript:this.src='/img/logo-spectrum-life.svg'" />
          </div>
          <h2 class="form-title">Sign In</h2>
          <b-form @submit="signIn" class="px-5">
            <b-form-group id="input-group-2" :label="componentText.code" label-for="inputPass">
              <div class="password-wrap">
                <b-form-input type="text" id="inputPass" v-model="accessCode"></b-form-input>
              </div>
            </b-form-group>
            <div col-sm-12 class="submit-form text-center d-flex align-items-center justify-content-center">
              <b-button type="button" @click="signIn" class="cta-button py-3">Sign In</b-button>
            </div>
            <div class="d-flex justify-content-center" v-if="false">
              <a class="no-access-link" @click="toggleAccessLogin()">I don't have an access code</a>
            </div>
            <div class="form-logo d-flex flex-column justify-content-center align-items-center">
              <p>Powered By</p>
              <img src="/img/logo-spectrum-life.svg"/>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  name: "AccessCode",
  data() {
    return {
      accessCode: "",
    };
  },
  methods: {
    async signIn(event) {
      event.preventDefault();
      let payload = {access_code: this.accessCode};
      this.$store.dispatch("newCodeLogin", {
        router: this.$router,
        payload
      });
    },
    toggleAccessLogin(){
      this.$parent.toggleAccessLogin();
    },
  },
  computed: {
    ...mapGetters(["hardcoded", "skin"]),
    getLogo() {
      if (this.hardcoded && this.hardcoded.logo) {
        return this.hardcoded.logo;
      } else return this.$store.getters.client.client_logo;
    },
    componentText() {
      return this.getText.notLoggedIn.accessCode;
    },
  },
};
</script>
<style lang="less">
.container {
  .page-content {
    background-color: #fff;
    border-radius: 30px;
    overflow: hidden;
    min-height: 400px;
    padding: 0px !important;
    .no-access-link{
      font-size: 1.4rem;
      text-decoration: underline;
      color: #bbb;
    }

    @media screen and (max-width: 768px) {
      border-radius: 0px;
    }

    .img-part {
      padding-left: 0px;
      background-image: url("/img/loggin/accesslogin.jpg");
      background-size: cover;
      background-position: top;

      .login-bg {
        position: relative;
        height: 100%;
        width: 100%;

        .heart-placeholder {
          position: absolute;
          top: 10%;
          left: 10%;
          height: 10%;
          width: 10%;

          .heart-img {
            height: 65px;
          }
        }

        .login-image {
          height: 60%;
          width: 80%;
          background-image: url("/img/_sign-in.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }
      }
    }

    .form {
      background: #ffffff;
      padding-bottom: 8;

      .form-content {
        padding: 25px;

        .form-logo {
          margin: 30px auto 30px auto;
          max-width: 300px;
          padding-top: 30px !important;
          padding-bottom: 10px !important;

          img {
            width: 100%;
          }
        }

        p {
          font-size: 1.7rem;
          font-family: "FilsonProBook", sans-serif;
          text-align: center;
        }

        label {
          color: #8d8e92;
          font-size: 1.5rem;
          margin-left: 20px;
        }

        .form-control {
          background-color: #efeef2;
          height: 50px;
          border-radius: 25px;
          font-size: 1.8rem;
          font-family: "Open Sans", sans-serif;
        }

        .submit-form {
          margin: 50px 0 0 0;
          text-align: center;

          .btn {
            margin: 0 0 30px 0;
            padding: 0 50px;
            box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.3);

            &.disabled {
              cursor: default;
            }
          }
        }

        .password-wrap {
          position: relative;

          .eye {
            font-size: 1.3rem;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
