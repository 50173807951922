<template>
  <div class="studio-sidebar" :class="{narrowDevice :narrowDeviceMode}">
      <div class="studio-sidebar-top-block" v-if="!narrowDeviceMode">
          <i class="fa-solid fa-list mr-4"></i> {{ header }}
      </div>
      <div class="studio-sidebar-title d-flex justify-content-between" v-if="!narrowDeviceMode">
          <div>{{title}}</div>
          <i
              v-if="navItems.length > 0"
              :class="sidebarExtended ? 'fa-solid fa-chevron-up':'fa-solid fa-chevron-down'"
              @click="toggleSidebar"
          ></i>

      </div>
      <b-collapse id="studio-sidebar-collapse" v-model="sidebarExtended" v-if="navItems.length > 0">
        <div v-for="(item, i) in navItems" class="nav-item row pb-2"  :class="getItemClass(item,i)" @click="itemSelected(item.id)">
            <div class="nav-item-img-container col-4">
                <div class="nav-item-img" v-if="getImage(item)" v-bind:style="{ backgroundImage: 'url(' + getImage(item) + ')' }"/>
            </div>
            <div class="nav-item-text-container col-8">
                <div class="nav-item-title">{{item.title}}</div>
                <div class="nav-item-description">{{drawUpDescription(item.description)}}</div>
                <div class="nav-item-complete" :class="completedClass(item)" v-if="showProgress"><i class="fa-solid fa-check mr-2" v-if="item.complete"></i>{{completedText(item)}}</div>
                <div class="nav-item-complete" :class="availabilityClass(item)" v-else-if="showAvailability">{{availabilityText(item)}}</div>
            </div>
        </div>
      </b-collapse>
      <div class="stay-on-track bg-brand-secondary" v-if="stayOnTrack">
        <div class="image-holder">
            <img src="/img/bell.png" alt="bell"/>
        </div>
        <div class="text-holder">
            <div class="title">Stay on Track</div>
            <div class="body-text">Activate daily reminders to continue your progress through this course</div>
        </div>
        <div class="switch-holder">
            <div class="switch" :class="onTrackToggled ? 'toggled bg-brand-highlight-1': 'untoggled'" @click="toggleStayOnTrack">
                <div class="switch-text">{{onTrackToggled ? 'ON' : 'OFF'}}</div>
            </div>
        </div>
      </div>
      <div class="progress-container" v-if="showProgress">
          <div class="progress-text">
              <img alt="road into the distance" src="/img/gold-star.png" class="mr-4"/>
              <div class="progress-title" v-if="numberComplete >= 1">Great Work {{user.user.first_name}}!</div>
              <div class="progress-title" v-else>Begin now to progress</div>
          </div>
          <div class="progress-bar-container mt-3">
              <b-progress :value="percentageComplete" :max="1" class="mb-3 studio-sidebar-pb">
              </b-progress>
          </div>
          <div class="progress-desc">
              <span class="completed">{{numberComplete}}</span> / {{navItems.length}}
              {{navItems.length === 1 ? moduleName : moduleName + 's'}} Completed
          </div>
      </div>
  </div>

</template>
<script>

    import { mapGetters } from "vuex";
    import moment from "moment";
    export default {
      name: 'NewStudioSideBar',
      props: {
          header: String,
          title: String,
          navItems: {
              type: Array,
              default: []
          },
          defaultImage: {
              type: String,
              default: null
          },
          moduleName: {
            type: String,
            default: 'Module'
          },
          stayOnTrack: {
              type: Boolean,
              default: false
          },
          showProgress: {
              type: Boolean,
              default: true
          },
          showAvailability: {
               type: Boolean,
               default: false
          },
          extendSideBarAtStart: {
              type:Boolean,
              default: false
          },
          narrowDeviceMode: {
              type: Boolean,
              default: false
          }
      },
      mounted() {
          if(this.extendSideBarAtStart) this.sidebarExtended = true;
      },
      data() {
          return {
              onTrackToggled: true,
              sidebarExtended: false,
              maxDescriptionCharLength: 80,
              defaultNavItem: {
                  id: 0,
                  title: '',
                  description: '',
                  complete: 0,
                  img: '',
                  selected: false
              }
          }
      },
      computed: {
          ...mapGetters(["user"]),
          numberComplete() {
              let completed = 0;
              this.navItems.map(item => {
                  if(item.complete) completed++
              });
              return completed;
          },
          percentageComplete() {
              if(!this.numberComplete) return 0;
              return this.numberComplete / this.navItems.length;
          },
          playingId() {
            return Number.parseInt(this.$route.params.id);
          },
      },
      methods: {
          toggleSidebar() {
            if(this.narrowDeviceMode) this.sidebarExtended = true;
            this.sidebarExtended = !this.sidebarExtended;
          },
          drawUpDescription(desc) {
              if(desc === '' || desc === null) return '';
              let output = '';
              const descArr = desc.split(" ");
              descArr.forEach(w => {
                  if(output.length + w.length < this.maxDescriptionCharLength) output += ' ' + w;
              });
              if(output.length < desc.length) output += ' ...';
              return output;
          },
          availabilityText(item) {
              const start = moment(item.start);
              const now = moment();
              if(now.isBefore(start)) {
                  if(now.format("Do MMMM yy") === start.format("Do MMMM yy")) return 'Live: Today at ' + start.format("h:mm")
                  return 'Live: ' + start.format("Do MMMM")
              }
            if(Number.parseInt(item.id) === this.playingId) return "In progress";
              return 'Available'
          },
          availabilityClass(item) {
              const start = moment(item.start);
              const now = moment();
              if(now.isBefore(start) || !item.start || !item.end) return 'not-available';
              return 'available'
          },
          toggleStayOnTrack() {
            this.onTrackToggled = !this.onTrackToggled;
          },
          completedClass(item) {
              const i = Number.parseInt(item.complete);
              if(i === 1) return "complete";
              if(item.selected) return 'in-progress'
              return "not-complete"
          },
          getItemClass(item,i) {
            let str = '';
            if(i + 1 === this.navItems.length) str += 'lastRow ';
            if(item.selected) str += 'selected'
            return str;
          },
          itemSelected(id) {
            this.$emit('change-selection',id)
          },
          getImage(item) {
              if(item.img) return item.img;
              if(this.defaultImage) return this.defaultImage;
              return null;
          },

          completedText(item) {
              const i = Number.parseInt(item.complete);
              if(i === 1) return "Completed";
              if(item.selected) return "In Progress";
              return "To Be Completed"
          }
      }
  }
</script>
<style lang="less">
  .studio-sidebar {
      font-family: "FilsonProBook", sans-serif;
      width: 100%;
      max-width: 400px;
      min-width: 350px;
      background: #3A3A3A;
      height: 100% !important;
      color: white !important;
    .studio-sidebar-top-block {
        padding: 20px;
        font-size: 2rem;
        font-weight: 700;
        font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    }
    .studio-sidebar-title {
        background: #242424;
        padding: 25px 20px;
        font-weight: 700;
        font-size: 1.7rem;
        i {
            font-size: 1.8rem;
            &:hover {
             cursor: pointer;
            }
        }
    }
    .progress-container {
        border-top: #FFFFFF 1px solid;
        border-bottom: #FFFFFF 1px solid;
        padding: 15px 25px;
        .progress-text {
            display: flex;
            font-size: 2rem;
            font-family: FilsonProBold, Arial, Helvetica, sans-serif;
        }
        .progress-desc {
            width: 80%;
            margin: auto;
            font-size: 1.2rem;
            .completed {
                font-family: FilsonProBold, Arial, Helvetica, sans-serif;
                font-size: 1.5rem;
            }
        }
        .progress-bar-container {
            width: 80%;
            margin: auto;
            .progress-bar {
                background: linear-gradient(90deg, rgba(135,210,221,1) 0%, rgba(20,139,156,1) 100%) !important;
                position: relative !important;
                border-radius: 5px;
            }
            .studio-sidebar-pb {
                border-radius: 5px;
            }
            //.progress-bar::after {
            //    content: '';
            //    width: 10px;
            //    height: 10px;
            //    background-color: white;
            //    border-radius: 50%;
            //    position: absolute;
            //    right: 1px;
            //}
        }
    }
    .nav-item {
        border-top: grey 1px solid;
        margin-right: 0 !important;
        margin-left: 0 !important;
        padding-top: 10px;
        cursor: pointer;
        min-height: 90px;
        &.lastRow {
            //border-bottom: grey 1px solid;
        }
        &.selected {
            background-color: #242424;
        }
        .nav-item-img-container {
            padding: 10px 15px;
            .nav-item-img {
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
            }
        }
        .nav-item-text-container {
            padding-top: 10px;
            justify-content: space-around;
            flex-direction: column;
            display: flex;

            .nav-item-title {
                font-size: 1.4rem;
                margin-right: 15px;
            }
            .nav-item-description {
                color: #A5A5A5;
                margin-right: 15px;
                font-size: 1.2rem;
            }
            .available {
                color: #64B811 !important;
            }
            .not-available {
                color: #D3B516 !important;
            }
            .nav-item-complete {
                margin-right: 15px;
                text-align: right;
                color: #A5A5A5;
                &.in-progress {
                    color: #D3B516;
                }
                &.complete {
                    color: #64B811;
                }
            }
        }
      }
    .stay-on-track {
        border-top: #FFFFFF 1px solid;
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr 4fr 2fr;
        .image-holder {
            img {
                display: block;
                margin-right: 10px;
            }
            display: flex;
            justify-content: end;
            align-items: start;
            margin-top: 16px;
        }
        .text-holder {
            & div {
                width: 100%;
            }
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 5px 5px;
            text-align: left;
            .title {
                font-size: 1.6rem;
                font-family: FilsonProBold, Arial, Helvetica, sans-serif;
                padding-bottom: 10px;
            }
            .body-text {
                font-size: 1.2rem;
            }
        }
        .switch-holder {
            display: flex;
            align-items: center;
            justify-content: center;
            .switch {
                width: 50px;
                height: 26px;
                border-radius: 20px;

                cursor: pointer;
                position: relative;
                .switch-text {
                    color: white;
                    width: 100%;
                    height: 100%;
                    line-height: 26px;
                    font-size: 0.85rem;

                }
                &::after {
                    content: '';
                    width: 20px;
                    height: 20px;
                    background-color: white;
                    border-radius: 50%;
                    position: absolute;
                    box-shadow: 1px 1px 1px #afaeae;
                    top: 3px;
                    left: 3px;
                    transition: transform 0.25s ease-out;
                }
                &.untoggled {
                    background-color: #D3D3D3;
                    .switch-text {
                        text-align: right !important;
                        padding-right: 5px;
                        transform: translate(0px, 0px);
                    }
                }
                &.toggled {
                    .switch-text {
                        text-align: left !important;
                        padding-left: 5px;
                    }
                    &::after {
                        top: 3px;
                        transform: translate(23px, 0px);
                    }
                }
            }
        }
    }
    &.narrowDevice {
      width: 100% !important;
      max-width: 100% !important;
      margin: auto;
      background: transparent;
      .stay-on-track {
        margin-top: 10px;
      }
      .progress-container {
        margin-top: 10px;
      }
      .nav-item {
        width: 100% !important;
        min-height: 80px;
        padding: 5px !important;
        border-top: 0 !important;
        border-radius: 10px !important;
        &:hover {
          background-color: #e1dfdf;
        }
        .nav-item-img-container {
          padding: 0;
          .nav-item-img {
            aspect-ratio: 16 / 9;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            border-radius: 10px;
          }
        }
        .nav-item-text-container {
          .nav-item-title {
            color: black;
            font-size: 1.6rem;
            font-family: FilsonProBold, Arial, Helvetica, sans-serif;
          }
          .nav-item-description {
            font-size: 1.4rem;
            margin-right: 5px;
          }
          padding-top: 0;
        }
      }
      .nav-item.selected {
        background-color: transparent;
      }
    }
    @media only screen and (max-width: 767px) {

    }
}
</style>
