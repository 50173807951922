<template>
    <div id="TrainingPlanV2">
        <div class="course-content">
            <div class="course-nav" v-if="!isNarrowDevice">
                <NewStudioSideBar
                        header="Course Contents"
                        :title="course.title"
                        :nav-items="navItems"
                        :default-image="course.featured_img"
                        module-name="Class"
                        :stay-on-track="true"
                        @change-selection="changeSelectedModule"

                ></NewStudioSideBar>
            </div>

            <div class="course-main-content" ref="content">
                <div class="back-container">
                    <img src="/img/back-arrow.png" @click="back" alt="back arrow"/>
                </div>
                <VisualAudioDisplay
                        :id="currentModule.event_id"
                        :type="'embedded_code'"
                        :url="currentModule.asset_url"
                        :is-first-item="isFirstItem"
                        :is-last-item="isLastItem"
                        :content="currentModule.content"
                        :title="currentModule.title"
                        :embedded-code="currentModule.video_url"
                        @previous="previous"
                        @next="completedModule"
                        @ended="completedModule"
                ></VisualAudioDisplay>
                <div class="course-details">
                    <div class="course-details-nav pt-3">
                        <div class="course-details-nav-item pb-2"
                             :class="detailsNavSelection === 0 ? 'brand-highlight-1':'brand-text'"
                             @click="detailsNavSelection = 0"
                        >
                            <div class="nav-inner">
                                <i class="fa-regular fa-address-card"></i>
                                Overview
                            </div>
                            <div class="line" :class="detailsNavSelection === 0 ? 'bg-brand-highlight-1':'dark-line'"/>
                        </div>
                        <!--                        <div class="course-details-nav-item pb-2"-->
                        <!--                             :class="detailsNavSelection === 1 ? 'brand-highlight-1':'brand-text'"-->
                        <!--                             @click="detailsNavSelection = 1">-->
                        <!--                            <div class="nav-inner">-->
                        <!--                                <i class="fa-solid fa-pen"></i>-->
                        <!--                                My Notes-->
                        <!--                            </div>-->
                        <!--                            <div class="line" :class="detailsNavSelection === 1 ? 'bg-brand-highlight-1':'dark-line'"/>-->
                        <!--                        </div>-->
                        <div class="course-details-nav-item pb-2"
                             :class="detailsNavSelection === 2 ? 'brand-highlight-1':'brand-text'"
                             @click="detailsNavSelection = 2">
                            <div class="nav-inner">
                                <i class="fa-regular fa-video"></i>
                                Similar Courses
                            </div>
                            <div class="line" :class="detailsNavSelection === 2 ? 'bg-brand-highlight-1':'dark-line'"/>
                        </div>
                      <div class="course-details-nav-item pb-2"
                           :class="detailsNavSelection === 3 ? 'brand-highlight-1':'brand-text'"
                           @click="detailsNavSelection = 3" v-if="isNarrowDevice">
                        <div class="nav-inner">
                          <i class="fa-regular fa-video"></i>
                          Course Contents
                        </div>
                        <div class="line" :class="detailsNavSelection === 3 ? 'bg-brand-highlight-1':'dark-line'"/>
                      </div>
                    </div>
                </div>
                <div class="course-details h-100">
                    <div class="course-details-inner">
                        <div>
                            <div class="selection-container">
                                <div class="overview-section" v-if="detailsNavSelection === 0">
                                    <div class="author-details">
                                        <div class="top-row">
                                            <div class="featuring-box">
                                                <div class="top">FEATURING</div>
                                                <div class="sp-box mt-1">
                                                    <div class="img-outer-holder">
                                                        <div class="img-holder" v-bind:style="{ backgroundImage: 'url(' + author.profile_img + ')' }"></div>
                                                    </div>
                                                    <div class="d-flex flex-column ml-4">
                                                        <div class="sp-name brand-text">{{ author.name }}</div>
                                                        <div class="sp-title brand-text">{{ author.title }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="main-details col-12 col-md-8">
                                        <div class="course-name-container">
                                            <div class="label">COURSE NAME</div>
                                            <div class="title brand-text">{{course.title}}</div>
                                        </div>
                                        <div class="course-desc-container mt-4">
                                            <div class="label">COURSE SUMMARY</div>
                                            <div class="brand-text">{{course.description}}</div>
                                        </div>
                                    </div>
                                        <div class="actions col-12 col-md-4">
                                        <div class="divider"></div>
                                        <div class="actions-inner">
                                            <div class="label">
                                                ACTIONS
                                            </div>
                                            <div class="actions-row">
                                                <div class="share-btn border-brand-highlight-1 brand-highlight-1" @click="showShareModal">
                                                    <i class="fa-solid fa-share brand-highlight-1 mr-4 fa-lg"></i>
                                                    <span>Share</span>
                                                </div>
<!--                                                <div class="like-container">-->
<!--                                                    <div class="like">-->
<!--                                                        <i v-if="liked" class="fa-solid fa-heart fa-xl brand-highlight-1" @click="like"></i>-->
<!--                                                        <i v-else class="fa-regular fa-heart fa-xl brand-highlight-1" @click="like"></i>-->
<!--                                                    </div>-->
<!--                                                </div>-->
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div class="" v-if="detailsNavSelection === 2">
                                  <div class="slider-container mt-4">
                                    <SimilarCourses type="exercise" class="car"/>
                                  </div>
                                </div>
                              <div class="similar-courses section" v-if="detailsNavSelection === 3 && isNarrowDevice">

                                <div class="slider-container">
                                  <NewStudioSideBar
                                      header="Course Contents"
                                      :title="course.course_title"
                                      :nav-items="navItems"
                                      :default-image="course.featured_img"
                                      module-name="Module"
                                      :stay-on-track="true"
                                      :narrow-device-mode="true"
                                      :extend-side-bar-at-start="true"
                                      @change-selection="changeSelectedModule"

                                  ></NewStudioSideBar>
                                </div>

                              </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>


<script>
import axios from "axios";
import config from "@/config/constants";
import VisualAudioDisplay from "@/components/NewStudio&CourseLayout/Content/VisualAudioDisplay.vue";
import NewStudioSideBar from "@/components/NewStudio&CourseLayout/Sidebar/NewStudioSideBar.vue";
import ShareModal from "@/components/NewStudio&CourseLayout/Modals/ShareModal.vue";
import TextDisplay from "@/components/NewStudio&CourseLayout/Content/TextDisplay.vue";
import SimilarCourses from "@/components/NewStudio&CourseLayout/Content/SimilarCourses.vue";
import HomeCardCarousel from "@/components/Home/New/HomeCardCarousel.vue";
import Vimeo from "@vimeo/player";

export default {
    name: 'TrainingPlanV2',
    components: {
        HomeCardCarousel,
        VisualAudioDisplay,
        NewStudioSideBar,
        ShareModal,
        TextDisplay,
        SimilarCourses
    },
    data() {
        return {
            author: {},
            course: {},
            modules: [],
            progress: "",
            sidebarExtended: false,
            selectedModule: '',
            detailsNavSelection: 0,
            liked: false,
            windowWidth: 1000
        }
    },
    mounted() {
        this.getCourse();
        this.onResize();
        this.$nextTick(() => {
          window.addEventListener('resize', this.onResize);
        });
    },
    watch: {
        '$route'(to,from) {
          if(to.params.id !== from.params.id) {
            this.getCourse();
          }
        },
        selectedModule() {
            this.$refs["content"].scrollIntoView({ behavior: "smooth" })
        }
    },
    computed: {
        isNarrowDevice() {
          return this.windowWidth <= 992;
        },
        darkMode() {
          return this.$store.getters.darkMode;
        },
        backRoute() {
            if(this.$route.query.back === 'home') return '/';
            return '/digital-gym?selectedView=%27training_plans%27';
        },
        isFirstItem() {
            const i = this.modules.findIndex(mod => mod.id === this.selectedModule);
            return i === 0
        },
        isLastItem() {
            const i = this.modules.findIndex(mod => mod.id === this.selectedModule);
            return i === this.modules.length - 1
        },
        link() {
            return window.location.href;
        },
        currentModule() {
            const m = this.modules.filter(mod => mod.id === this.selectedModule);
            return m[0];
        },
        navItems() {
            return this.modules.map(item => {
                return {
                    id: item.id,
                    title: item.title,
                    description: item.description,
                    complete: item.attended ? 1 : 0,
                    img: '',
                    selected: Boolean(item.id === this.selectedModule)
                }
            })
        }
    },
    methods: {
        onResize() {
          this.windowWidth = window.innerWidth;
          this.detailsNavSelection = 0;
        },
        back() {
            this.$router.push(this.backRoute);
        },
        previous() {
            if(this.isFirstItem) return;
            const i = this.modules.findIndex(mod => mod.module_id === this.selectedModule);
            this.selectedModule = this.modules[i-1].module_id;
        },
        async completedModule() {
            if(!this.currentModule.attended) {
                const payload = {
                    user_id: this.$store.getters.user_id,
                    training_class_id: this.currentModule.id
                };
                await this.axios.post(
                    process.env.VUE_APP_API_PATH +
                    "/application/api/u/studio/training-class-attended",
                    payload
                );
                this.setModuleAsCompleted(this.currentModule.id);
            }
            if(!this.isLastItem) {
                this.moveToNextModule();
            }
        },
        async setModuleAsCompleted(id) {
            this.modules.map(mod => {
                if(mod.id === id) {
                    mod.attended = 1;
                }
            })
            await this.api({
              path: "api/u/studio/log-event-as-activity",
              event_id: this.currentModule.event_id
            });
        },
        moveToNextModule() {
            let i = false;
            let currentModuleFound = false
            this.modules.map(mod => {
                if(mod.id === this.currentModule.id) currentModuleFound = true;
                if(currentModuleFound && !i && !mod.attended) {
                    i = true;
                    this.selectedModule = mod.id;
                }
            })
            if(!i) this.selectedModule = this.modules[0].id;
        },
        showShareModal() {
            const skin = this.$store.getters.skin;
            const content = this.$createElement(ShareModal, {
                props: {
                    title: 'Share this course',
                    instruction: 'Share this course with other by copying the link below',
                    link: this.link
                }
            });
            let modalClass = "share-modal theme-"+skin;
            if(this.darkMode) modalClass += ' dark-mode-modal';
            let opts = {
                hideHeader: false,
                hideFooter: true,
                modalClass: modalClass,
                headerClass: "share-modal d-none",
                footerClass: "d-none",
                size: "lg",
                buttonSize: "sm",
                centered: true,
                contentClass: "share-modal theme-" + skin,
                id : 'share-modal',
            }
            return this.$bvModal.msgBoxOk(content, opts);
        },
        changeSelectedModule(id) {
            this.selectedModule = id
        },
        async getCourse() {
            let self = this;
            const payload = {
                user_id: this.$store.getters.user_id,
            };
            let res = await this.axios.post(
                process.env.VUE_APP_API_PATH +
                "/application/api/u/studio/get-training-plans",
                payload
            );
            if (res.data.success) {
                let planId = this.$route.params.id;
                let course = res.data.training_plans.filter(item => Number.parseInt(item.id) === Number.parseInt(planId));
                this.course = course[0];
                this.modules = this.course.training_classes;
                let completed = 0;
                this.modules.map(item => {
                    if (item.attended)  completed++
                });
                this.progress = completed;
                this.selectedModule = null;
                this.modules.forEach(mod => {
                    if(!mod.attended && !this.selectedModule) {
                        this.selectedModule = mod.id
                    }
                });
                if(!this.selectedModule) this.selectedModule = this.modules[0].id
                this.loading = false;
            } else if (res.data.err && res.data.err.includes("3994")) {
                this.$store.dispatch("logout");
                this.$router.push("/login");
            }
        },
    }
}
</script>

<style lang="less">
#TrainingPlanV2 {
  .author-details {
    .top-row {
      display: flex;
      padding: 2px;
      color: #C9C6C6;
    }
    .featuring-box {
      padding-top: 5px;
      flex-direction: column;
      padding-bottom: 5px;
      display: flex;
      .top {
        font-size: 1.2rem;
      }
      .sp-box {
        display: flex;
        .sp-name{
          font-size: 2rem;
          font-family: FilsonProBold, Arial, Helvetica, sans-serif;
        }
        .sp-title {
          font-size: 1.6rem;
        }
        .img-outer-holder {
          width: 68px;
          height: 68px;
          border-radius: 50%;
          border: cyan 2px solid;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .img-holder {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;

        }
      }
    }
  }
  .course-content {
    width: 100%;
    height: 100%;
    font-family: "FilsonPro", sans-serif;
    display: flex;
    background-color: black;

    .course-nav {
      max-width: 350px;
    }
    .course-main-content {
      max-width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      flex-grow: 1;
      .back-container {
        position: absolute;
        top: 10px;
        left: 10px;
        cursor: pointer;
        z-index: 9999;
      }
    }
    .course-details {
      width: 100%;

      font-family: Open Sans, sans-serif;
      font-size: 1.5rem;
      background-color: white;
      .course-details-inner {
        width: 100%;
        //max-width: 1300px;
      }
      .course-details-nav {
        display: flex;
        cursor: pointer;
        background-color: #FAFAFA;
        .course-details-nav-item {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          max-width: 200px;
          position: relative;
          .nav-inner {
            padding: 10px 15px;
            i {
              margin-right: 8px;
            }
          }
          .line {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 1px;
            margin: 0 !important;
          }
          .dark-line {
            background-color: #c4c4c4;
          }
        }
      }
      .selection-container {
        width: 100%;
        padding: 20px 30px 60px 30px;
        .divider {
          position: absolute;
          left: 0;
          top: 20px;
          width: 1px;
          height: 100%;
          background-color: #f6f5f5;
        }
        .label {
          color: #C9C6C6;
          font-size: 1.2rem;
          margin-bottom: 10px;
        }
        .overview-section {
          flex-direction: column;
          display: flex;
          .main-details, .actions-inner {
            padding: 10px;
          }
          .actions {
            position: relative;
            .actions-row {
              display: flex;
              .share-btn {
                border-width: 1px;
                border-style: solid;
                padding: 5px 8px;
                border-radius: 20px;
                cursor: pointer;
                min-width: 118px;
              }
              .like-container {
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .like {
                cursor: pointer;
              }
            }
          }
          .title {
            font-family: FilsonProBold, Arial, Helvetica, sans-serif;
            font-size: 2rem;
          }
        }
      }
    }
  }
  .similar-courses {

    overflow: hidden;
    .headerContainer {
      align-items: center;
      justify-content: start;
      display: flex;
      .title {
        font-family: FilsonProBold, Arial, Helvetica, sans-serif;
        font-size: 2rem;
        margin-right: 15px;
      }
      .divline {
        width: 200px;
        height: 1px;
        background-color: #e1e1e1;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .row {
      margin: 0 !important;
    }
    .actions-inner {
      padding: 0 !important;
    }
    .selection-container {
      padding: 5px !important;
    }
    .nav-inner {
      padding: 5px !important;
      text-align: center;
    }
  }
}
</style>
