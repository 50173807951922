<template>
  <div id="eap">
    <div
      class="page-header"
      v-if="pageData && pageData.defaults"
      :class="{
        'bg-brand-secondary': skin !== 0,
        'bg-transparent': skin === 0
      }"
    >
      <div
        class="content-container row"
        v-if="$store.getters.isIE && skin === 8"
      >
        <div class="page-title col-md-7 col-sm-12">
          <h2>{{ pageData.defaults.eap_title }}</h2>
          <p>
            {{ componentText.boiIeIntro }}
          </p>
        </div>
        <div class="call-us col-md-5 col-sm-12 ">
          <img src="/img/boi/laya-logo.png" />
          <h3 class="text-white pt-2 pb-1">{{ componentText.callUsOn }}</h3>
          <h3 class="text-white  py-1">
            {{ componentText.ROI }}
            <a class="text-white" href="tel:1800911909">1800 911 909</a>
          </h3>
          <h3 class="text-white  pt-1 pb-2">
            {{ componentText.UK }}
            <a class="text-white" href="tel:08000988350">0800 0988 350</a>
          </h3>
          <div
            class="d-flex justify-content-md-end justify-content-center"
            v-if="pageData.defaults.request_cta"
          >
            <b-button class="cta-button px-4 py-3" @click="callBack">{{
              componentText.callback
            }}</b-button>
          </div>
        </div>
      </div>
      <div class="content-container row" v-else-if="contentLoading">
        <b-spinner></b-spinner>
      </div>
      <div class="content-container row" v-else>
        <div class="page-title col-12">
          <h2>{{ pageData.defaults.eap_title }}</h2>
        </div>
        <div class="page-title col-md-12 col-lg-6">
          <p v-html="pageData.defaults.eap_intro"></p>
          <h3
            class="text-white pt-2 pb-1"
            v-if="skin === 8 || pageData.defaults.eap_phone"
          >
            {{ componentText.callUsOn }}
          </h3>
          <div v-if="skin === 8">
            <!-- BOI default -->
            <h3 class="text-white  py-1">
              {{ componentText.ROI }}
              <a class="text-white" href="tel:1800911909">1800 911 909</a>
            </h3>
            <h3 class="text-white  pt-1 pb-2">
              {{ componentText.UK }}
              <a class="text-white" href="tel:08000988350">0800 0988 350</a>
            </h3>
          </div>
          <div v-else-if="pageData.defaults.eap_phone">
            <h3
              class="text-white  py-2"
              v-for="(phone, i) in pageData.defaults.eap_phone"
              :key="i"
            >
              <a
                class="text-white"
                :href="'tel:' + decodeURIComponent(phone[1])"
                >{{ phone[0] }}: {{ phone[1] }}</a
              >
            </h3>
          </div>
          <div
            class="text-center d-flex text-md-left justify-content-md-start justify-content-center"
            v-if="pageData.defaults.request_cta"
          >
            <b-button
              class="cta-button text-md-center px-4 py-3 my-5"
              @click="callBack"
              >{{ componentText.callback }}</b-button
            >
          </div>
        </div>
        <div
          class="call-us col-md-12 col-lg-6"
          v-if="pageData.defaults && pageData.defaults.video_on"
        >
          <div class="video-wrap">
            <video
              width="100%"
              class="video"
              controls
              controlsList="nodownload"
              :poster="'/img/eap/EAP-placeholder.png'"
            >
              <source :src="pageData.defaults.video_url" />
            </video>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column">
      <div class="bg-grey py-5 px-md-5" v-if="pageData.defaults && pageData.services_offered && pageData.services_offered.active && filteredServices.length !== 0">
        <div class="content-container row services-offered">
          <div class="section-header section-header-right">
            <div class="section-header-divider"></div>
            <h3 class="section-header-title" v-if="$store.getters.skin !== 25">{{ componentText.services }}</h3>
            <h3 class="section-header-title" v-else>How we do this</h3>
          </div>
          <div
            v-for="card in pageData.services_offered.items"
            class="eap-cards col-md-3 col-sm-6 mb-4"
            v-if="card.active"
          >
            <div class="eap-card">
              <div class="row align-items-center">
                <div class="col-12 col-lg-5 pr-lg-0 text-center">
                  <img
                    class="eap-card-img bg-brand-secondary"
                    :src="card.img_url + getEAPServicesImage() + '.svg'"
                    alt="eap-type"
                  />
                </div>
                <div class="col-12 col-lg-7 pl-1">
                  <div class="eap-card-content">
                    {{ card.title }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="bg-white py-4 px-md-5 d-none d-lg-flex"
        v-if="
          pageData.defaults &&
            pageData.services_support &&
            pageData.services_support.active &&
            hasServices
        "
      >
        <div class="content-container row support justify-content-center">
          <div class="section-header">
            <div class="section-header-divider"></div>
            <h3 class="section-header-title" v-if="$store.getters.skin !== 25">{{ componentText.support }}</h3>
            <h3 class="section-header-title" v-else>What we can help with</h3>

          </div>
          <div
            v-for="serviceCard in pageData.services_support.items"
            v-if="serviceCard.active"
            class="eap-service-card col-md-5ths px-5 flex-grow-1"
          >
            <div class="service-card-img-container">
              <img class="service-card-img" :src="serviceCard.img_url" />
            </div>
            <div class="service-card-description bran" v-html="serviceCard.title">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="articles bg-white pb-5"
      v-if="pageData.defaults && pageData.defaults.articles"
    >
      <div class="content-container row">
        <div class="section-header section-header-right">
          <div class="section-header-divider"></div>
          <h3 class="section-header-title">{{ componentText.articles }}</h3>
        </div>
        <div v-for="(article, index) in articles" class="col-md-3 article-wrap">
          <a class="card" @click="selectArticle(article.post_id)">
            <div class="card-header bg-brand-primary">
              <img
                class="card-logo"
                :src="'/img/' + img_type[article.post_type]"
              />
            </div>
            <div class="card-body" ref="cardBody">
              <p class="card-title" ref="cardTitle">{{ article.post_type }}</p>
              <div class="card-text text-eclipse">{{ article.title }}</div>
            </div>
          </a>
          <div
            v-if="!$store.state.accessCode"
            :class="article.fav ? 'is-active ' : ''"
            @click="likeArticle(article.post_id, index)"
            class="card-heart"
          ></div>
        </div>
        <a
          class="home-link border-brand-secondary"
          @click="$router.push('/wellbeing/articles')"
          >{{ componentText.allArticles }}</a
        >
      </div>
    </div>
    <div v-if="callbackSelected" class="pop-up">
      <div class="pop-up-background" @click="hidePopUp"></div>
      <div class="pop-up-content bg-brand-secondary row">
        <div class="col-12">
          <h4>{{ pageData.defaults.eap_title }} {{componentText.callbackTitle}}</h4>
          <div class="callback-form" v-if="!callBackSuccess">
            <div class="col-md-6 offset-md-3 ">
              <p>{{componentText.select}}</p>
              <b-form-select class="form-control" v-model="selectedService">
                <option v-for="(service, i) in filteredServices" :key="i" :value="service.id">{{
                  service.name
                }}</option>
              </b-form-select>
            </div>
            <div v-if="selectedService !== ''">
              <div class="service-description py-5 px-5">
                <p>{{ services[selectedService].content }}</p>
              </div>
              <div class="col-md-6 offset-md-3 pb-5">
                <p>{{ componentText.phone }}</p>
                <b-input
                  type="number"
                  class="form-control"
                  v-model="phoneNumber"
                  @keydown="testPhoneInput"
                ></b-input>
              </div>
              <div v-if="$store.getters.user.access_code_user" class="col-md-6 offset-md-3 pb-5">
                <p>{{ componentText.accessCodeEmail }}</p>
                <b-input
                    v-model="accessCodeEmail"
                    class="form-control"
                    type="text"
                ></b-input>
              </div>
              <div v-if="$store.getters.user.access_code_user" class="col-md-6 offset-md-3 pb-5">
                <p>{{ componentText.accessCodeName }}</p>
                <b-input
                    v-model="accessCodeName"
                    class="form-control"
                    type="text"
                ></b-input>
              </div>
              <div class="d-flex justify-content-center">
                <b-button @click="requestCall" class="cta-button px-5 py-3">
                  {{ componentText.callback }}</b-button
                >
              </div>
            </div>
          </div>
          <div class="callback-confirmation" v-if="callBackSuccess">
            {{ $store.getters.subdomain === 'landg'? componentText.landg_thanks : componentText.thanks }}
          </div>
        </div>
        <b-button class="close-popup" @click="hidePopUp"
          ><font-awesome-icon icon="times-circle"></font-awesome-icon
        ></b-button>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import config from "../config/constants";
export default {
  name: "eap",
  data() {
    return {
        callbackSelected: false,
        selectedService: '',
        phoneNumber:'',
        accessCodeEmail: '',
        accessCodeName: '',
        callBackSuccess: false,
        pageData: {
            defaults:{
                eap_title: 'EAP',
                live_chat: true
            }
        },
        articles: [],
        img_type: {
            video: "video-white.svg",
            text: "blog-white.svg",
            image: "far fa-image",
            audio: "podcast-white.svg"
        },
        contentLoading: true,
        landg_eap: false,
    };
  },
  mounted() {
    this.getEapContent();
    this.getResourceList();
    const vm = this
    window.addEventListener("load", function(){
      if(window.$zopim) {
        window.$zopim.livechat.setLanguage(vm.$store.state.lang)
        window.$zopim.livechat.setGreetings({
          'online': vm.componentText.zendeskTitle,
        })
        window.$zopim.livechat.window.setTitle(vm.componentText.zendeskTitle)
        window.$zopim.livechat.prechatForm.setGreetings(vm.componentText.zendeskIntro)
      }
    });
    if(this.$route.query.callback) {
      this.callbackSelected = true;
    }
  },
  destroyed(){
    //remove zendesk
    if(window.$zopim) {
      window.$zopim.livechat.hideAll()
    }
  },
  methods: {
    testPhoneInput(evt) {
      if(this.phoneNumber.toString().length > 14 && evt.key !== 'Backspace') {
        console.log(this.phoneNumber.toString().length)
        evt.preventDefault();
      }
    },
    log(){
      console.log(this.pageData.services_offered.items);
    },
      callBack(){
        this.callbackSelected = true
      },
      async getEapContent(){
        const payload = {
              user_id: this.$store.getters.user_id
          }
          let res= await axios.post(config.api_env + '/application/api/u/customisations/get-eap-content', payload, config.options)
          if(res.data.success){
            this.pageData = res.data.eap_customisation
            this.landg_eap = res.data.landg_eap
            this.contentLoading = false
          }
          if(this.pageData.defaults.live_chat){
            window.$zopim ? window.$zopim.livechat.button.show() : this.enableZendeskWidget()
          }
      },
      async getResourceList(){
          const payload = {
              user_id: this.$store.getters.user_id,
              "search_key":"",
              "filter":"",
              "tag":"mind",
              "fav_only":"0",
              "page":"0",
              "page_size":"4",
              "surge_only":1
          }
          let res= await axios.post(config.api_env + '/application/api-hc/get-all-resource-list', payload, config.options)
          if(res.data.success){
              this.articles = res.data.resources
          }

      },
      async likeArticle(articleId, index){
          const payload = {
              user_id: this.$store.getters.user_id,
              type: 'module',
              resource_id: articleId
          }
          if(!this.articles[index].fav){
              this.articles[index].fav = 1
              let res = await axios.post(config.api_env + "/application/api-hc/like-item", payload, config.options)
              if(!res.data.success) {
                  this.articles[index].fav = 0
              }
          }else{
              this.articles[index].fav = 0
              let res = await axios.post(config.api_env + "/application/api-hc/unlike-item", payload, config.options)
              if(!res.data.success) {
                  this.articles[index].fav = 1
              }
          }
      },
      selectArticle(articleId){
          this.$router.push('/wellbeing/article/'+ articleId).catch(err => {})
      },
      async requestCall(){
        let payload = {
            user_id: this.$store.getters.user_id,
            phone: this.phoneNumber,
            service_id: this.selectedService
        }
        if(this.$store.getters.user.access_code_user) {
          payload.name = this.accessCodeName;
          payload.email = this.accessCodeEmail;
        }
        let res = await axios.post(config.api_env + '/application/api-hc/send-eap-request', payload, config.options)
          if(res.data.sent){
            this.callBackSuccess = true
          }
      },
      hidePopUp(){
        this.callbackSelected = false
          this.selectedService = ''
          this.callBackSuccess = false
      },
      getEAPServicesImage(){
          if (this.$store.getters.skin === 0) {
              return '';
          }else if (this.$store.getters.skin === 10){
            return '-laya'
          }
          return '-generic';
      },

    enableZendeskWidget() {
      let key = "231b7a97-95a0-4837-86a2-5be70b3b9db1";
      // the if statement below changes the ZenDesk key for L&G users
      if(this.landg_eap)
      {
        key = "a04aaa15-6e9e-4947-a98d-e5560870d7a9";
      }
      window.$zopim ||
        (function(d, s) {
          var z = (window.$zopim = function(c) {
              z._.push(c);
            }),
            $ = (z.s = d.createElement(s)),
            e = d.getElementsByTagName(s)[0];
          z.set = function(o) {
            z.set._.push(o);
          };
          z._ = [];
          z.set._ = [];
          $.async = !0;
          $.setAttribute("charset", "utf-8");
          $.setAttribute("id", "ze-snippet");
          $.src = "https://static.zdassets.com/ekr/snippet.js?key=" + key;
          z.t = +new Date();
          $.type = "text/javascript";
          e.parentNode.insertBefore($, e);
        })(document, "script");
    }
  },
  computed: {
    componentText() {
      return this.getText.loggedIn.eap;
    },
    skin() {
      return this.$store.getters.skin;
    },
    services() {
      return [
        {
          id: 0,
          name: this.componentText.mentalWellbeing,
          content:this.componentText.mentalWellbeingContent
        },
        {
          id: 1,
          name: this.componentText.finance,
          content: this.componentText.financeContent
        },
        {
          id: 2,
          name: this.componentText.life,
          content: this.componentText.lifeContent
        },
        {
          id: 3,
          name: this.componentText.career,
          content:
            this.componentText.careerContent1 +
            this.pageData.defaults.eap_title +
            this.componentText.careerContent2
        },
        {
          id: 4,
          name: this.componentText.consumer,
          content:
            this.componentText.consumerContent1 +
            this.pageData.defaults.eap_title +
            this.componentText.consumerContent2
        },
        {
          id: 5,
          name: this.componentText.mediation,
          content: this.componentText.mediationContent
        },
        {
          id: 6,
          name: this.componentText.nonNationals,
          content: this.componentText.nonNationalsContent
        },
        {
          id: 7,
          name: this.componentText.legal,
          content:
                  this.componentText.legalContent1 +
                  this.pageData.defaults.eap_title +
                  this.componentText.legalContent2
        },
        {
          id: 8,
          name: this.componentText.medical,
          content:
              this.componentText.medicalContent
        }
      ];
    },
    filteredServices(){
      let services = []
      for(let i = 0; i<this.pageData.services_offered.items.length; i++){
        if(this.pageData.services_offered.items[i].active){
          services.push(this.services[i])
        }
      }
      return services
    },
    hasServices(){
      let hasServices = false;
      for(let i = 0; i < this.pageData.services_support.items.length; i++){
        if(this.pageData.services_support.items[i].active){
          hasServices = true
        }
      }
      return hasServices
    },
  },
};
</script>
<style lang="less">
  #eap {
    .page-header {
      padding: 60px 0;
      .page-title {
        text-align: left;
        h2 {
          text-align: left;
          font-size: 3.8rem;
          letter-spacing: 0.7rem;
          font-family: "FilsonProBold", sans-serif;
          color: #fff;
        }
        p{
          font-size: 2rem;
          font-family: 'FilsonProBook';
          color: #fff;
          a{
            color: #fff;
          }
        }
      }
      .call-us{
        text-align: right;
        img{
          max-width: 220px;
        }
        h3 {
          font-family: 'FilsonProLight';
          font-size: 3.2rem;
          letter-spacing: 0;
          color: #fff;
          font-weight: 100;
          padding-top: 20px;
        }
        .btn{
          font-size: 1.3rem;
          font-family: 'FilsonProBold';
        }
      }
      .video-wrap {
        border-radius: 20px;
        -o-object-fit: fill;
        object-fit: fill;
        .video {
          -o-object-fit: fill;
          object-fit: fill;
          border-radius: 20px;
          &:focus {
            outline: none;
          }
        }
      }
      .video-player{
        max-width: 550px;
        text-align: center;
        margin: auto;
      }
    }
    .services-offered{
      justify-content: center;
    }
    .eap-cards{
      .eap-card {
        .eap-card-img {
          border-radius:50%;
          width: 100%;
          height: 100%;
          max-width: 100px;
        }
        .eap-card-content{
          font-family: FilsonProLight, sans-serif;
          font-size: 1.8rem;
          line-height: 2.3rem;
          letter-spacing: 0;
          @media (max-width: 992px){
            text-align: center;
          }
        }
      }
    }
    .eap-service-card {
      &:not(:last-of-type)::after {
        content: "";
        position: absolute;
        top: 70%;
        right: 0;
        width: 1px;
        height: 30%;
        transform: translateY(-50%);
        background: #cccccc;
      }
      .service-card-img-container{
        height: 90px;
        .service-card-img{
          height: 90px;
          transform: translateX(25%);
          width: 65%;
          z-index: 2;
        }
      }
      .service-card-description{
        font-family: Open Sans, sans-serif;
        margin-top: 20px;
        font-size: 1.8rem;
        line-height: 1.2;
        text-align: center;
        letter-spacing: 0;
      }
    }
    .support{
      padding-bottom: 80px;
    }
    .pop-up{
      display: flex;
      align-items: center;
      z-index: 500;
      position:fixed;
      top:0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      text-align: center;
      .pop-up-content{
        max-width: 636px;
        width: 100%;
        margin: auto;
        border-radius: 25px;
        padding: 50px 20px;
        font-size: 1.6rem;
        text-align: center;
        z-index: 10;
        color: #fff;
        position: relative;
        p{
          color: #fff;
        }
        h4{
          font-family: 'FilsonProBold', sans-serif;
          font-size: 3.8rem;
          color: #fff;
        }
        .form-control{
          height: 30px;
          border-radius: 5px;
          font-size: 1.6rem;
        }
        .service-description{
          text-align: left;
        }
        .close-popup{
          position: absolute;
          top: 0;
          right: 0;
          background-color: transparent;
          width: 50px;
          height:50px;
          svg{
            width: 30px;
            height: 30px;
          }
        }
      }
      .pop-up-background{
        width: 100vw;
        height: 100vh;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 5;
        background: transparent;
      }
    }
    .articles {
      .article-wrap {
        position: relative;
        .card-heart {
          background-image: url('/img/heart-line_white.svg');
          position: absolute;
          width: 30px;
          height: 26px;
          top: 15px;
          right: 30px;
          background-size: cover;
          cursor: pointer;
          &.is-active {
            background-image: url('/img/heart_white.svg');
          }
        }
      }
      .card {
        position: relative;
        box-sizing: border-box;
        margin-bottom: 36px;
        transition: .3s ease-out;
        border: none;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
        font-family: 'FilsonProLight', sans-serif;
        border-radius: 10px;
        &:hover {
          cursor: pointer;
          text-decoration: none;
          box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.6);
        }
        .card-header {
          border-radius: 2px 2px 0 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 150px;
          border-radius: 10px 10px 0 0;
          .card-logo {
            max-height: 60%;
            max-width: 55%;
          }
        }
        .card-body {
          width: 100%;
          height: 110px;
          box-sizing: border-box;
          padding: 10px 10px 20px;
          background: #ffffff;
          text-align: center;
          border-radius: 0 0 10px 10px;
          .card-title {
            font-family: FilsonProLight, sans-serif;
            margin: 0;
            padding: 0 0 10px;
            font-size: 1.6rem;
            text-align: center;
            text-transform: uppercase;
            opacity: 0.5;
          }
          .card-text {
            margin: 0;
            padding: 0;
            font-family: FilsonProLight, sans-serif;
            font-size: 1.8rem;
            line-height: 1;
            text-align: center;
          }
        }
      }
    }
  }
</style>
