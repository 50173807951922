<template>
  <div id="LayaCarouselCard" :class="matomo_class" :tabindex="t_i" @keydown.enter="goToVideo">
    <div class="carouselCard" @click="goToVideo()" :class="matomo_class" :id="title">
      <div :id="title" :class="matomo_class" class="topCard d-flex justify-content-center align-items-center" v-if="type === 'be-calm' || type === 'beCalm'" @click="goToVideo()">
        <img :id="title" :src="thumbnail" style="display: block; width: 100%" :alt="item.title" :class="matomo_class"/>
      </div>
      <div :id="title" :class="matomo_class" class="topCard" :style="{ backgroundImage: 'url(' + thumbnail + ')' }" v-else>
        <b-badge :id="title" v-if="isNew" class="newTag" :class="matomo_class">New</b-badge>
      </div>
      <div :id="title" class="cardText w-100" :class="matomo_class" >
        <b-progress :id="title" v-if="progress" class="position-absolute w-100" max="100" :value="progress" style="top: -7px; left: 0" @click="goToVideo()"></b-progress>
        <div :id="title" class="cardTextTop" :class="matomo_class" @click="goToVideo()">
          <div :id="title" :class="matomo_class" class="float-start">{{authorName}}</div>
          <div :id="title" :class="matomo_class" class="float-end" v-if="type !== 'exercise' && type !== 'beCalm'">{{mins_left}}</div><div class="float-end" v-else>{{item.details.class_number}}
          {{ type === 'beCalm' ? 'meditations' : 'classes' }}</div>
        </div>
        <div :id="title" class="cardTitle" @click="goToVideo()" :class="type === 'event-progress' ? matomo_class + ' in-progress' : matomo_class" :style="{maxHeight: titleLineHeight + 'px'}">
          <TextTruncator :matomo-class="matomo_class" :text="title" :font-name="'Cocon Pro Regular'" :font-size="titleFontSize" :line-height="titleLineHeight" :id="title" :enable-tool-tip="true" :maxLines="1"/>
        </div>
        <div class="cardDescription w-100" @click="goToVideo" :class="matomo_class">
          <TextTruncator :matomo-class="matomo_class" :text="description" :font-name="'Open Sans'" :font-size="16" :line-height="19.2" :id="title"/>
        </div>
        <div :id="title" class="cardTextBottom d-flex" :class="matomo_class">
          <div class="d-flex flex-wrap" style="width: 75%" :class="matomo_class" :id="title">
            </div>

            <div :id="title" :class="matomo_class" class="float-end d-flex align-items-center justify-content-between w-25" v-if="item.type !== 'fitness_plans' && item.type !== 'beCalm'">
              <div :id="title" :class="matomo_class" style="cursor: pointer">
                <div :class="matomo_class" :id="'favs-'+unique_id" class="video-card-favourite d-flex align-items-center justify-content-center" v-if="!item.favourited">
                  <i :id="title" @click.stop="toggleDGFavourite" class="fa-regular fa-heart fa-lg" :class="matomo_class"></i>
                  <b-tooltip :target="'favs-'+unique_id" triggers="hover" placement="top" custom-class="mylist-tooltip">
                    {{item.favourited ? 'Remove from favourites' : 'Add to favourites'}}
                  </b-tooltip>
                </div>
                <div :class="matomo_class" :id="'favs-'+unique_id" class="video-card-favourite d-flex align-items-center justify-content-center" v-else>
                  <i :id="title" @click.stop="toggleDGFavourite" class="fa-solid fa-heart fa-lg"></i>
                  <b-tooltip :target="'favs-'+unique_id" triggers="hover" placement="top" custom-class="mylist-tooltip">
                    {{item.favourited ? 'Remove from favourites' : 'Add to favourites'}}
                  </b-tooltip>
                </div>
              </div>
              <div :class="matomo_class" class="d-flex align-items-center justify-content-center" style="cursor: pointer; z-index: 100">
                <i :id="'myList-'+unique_id" class="fa-regular fa-lg myListBtn" :class="isItemInMyList ? 'fa-circle-check plus':'fa-circle-plus plus'" @click.stop="watchLater">
              </i>
              <b-tooltip @click.stop="toggleDGFavourite" :target="'myList-'+unique_id" triggers="hover" placement="top" custom-class="mylist-tooltip">
                {{isItemInMyList ? 'Remove from Watch Later' : 'Add to Watch Later'}}
              </b-tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vimeo from "@vimeo/player";
import moment from "moment/moment";
import EventBus from "@/helpers/eventbus";
import TextTruncator from "@/components/TextTruncator.vue";

export default {
  name: 'LayaCarouselListCard',
  components: {TextTruncator},
  props: ['item','swiping','type','matomo_class','t_i'],
  data() {
    return {
      vimeoId: 0,
      videoIsPlaying: false,
      unique_id: null,
      titleFontSize: 20,
      titleLineHeight: 30,
      titleResponsiveness: [{titleFontSize: 20, titleLineHeight: 30, screenWidth: '*'},{titleFontSize: 16, titleLineHeight: 18, screenWidth: 567}],
    }
  },
  mounted() {
    this.unique_id = this.genRandonString(10);
    this.setTitleHeight();
    window.addEventListener('resize', this.setTitleHeight);
  },
  destroyed() {
    window.removeEventListener('resize', this.setTitleHeight)
  },
  methods: {
    setTitleHeight() {
      let fontSize = this.titleFontSize;
      let lineHeight = this.titleLineHeight;
      let width = window.innerWidth;
      this.titleResponsiveness.forEach(item=> {
        if(item.screenWidth === '*') {
          fontSize = item.titleFontSize;
          lineHeight = item.titleLineHeight;
        } else if (width <= item.screenWidth) {
          fontSize = item.titleFontSize;
          lineHeight = item.titleLineHeight;
        }
      });
      this.titleLineHeight = lineHeight;
      this.titleFontSize = fontSize;
    },
    getBeCalmImage(title) {
      let url = 'https://spectrum-life-media.s3.eu-west-1.amazonaws.com/CONTENT/BeCalm/dfd_be_calm_images/'
      switch (title) {
        case 'Sleep Aid':
          url += 'Sleep aid_hero.png'
          break;
        case 'Reducing Stress':
          url += 'Reducing stress_hero.png'
          break;
        case 'Self-Compassion': case 'Self-compassion':
          url += 'Self compassion_hero.png'
          break;
        case 'Improving Performance':
          url += 'Improving performance_hero.png'
          break;
        case 'Increase Happiness':
          url += 'Increase Happiness_hero.png'
          break;
        case 'Reducing Anxiety':
          url += 'Reducing Anxiety_hero.png'
          break;
        case 'Birdsong in the Woods':
          url += 'Birdsong in the Woods_hero.png'
          break;
        case 'Babbling Brook':
          url += 'Babbling Brook_hero.png'
          break;
        case 'Living in the Moment':
          url += 'Living in the Moment_hero.png'
          break;
        case 'Inspiring Hope and Fulfilment':
          url += 'Inspiring Hope and Fulfilment_hero.png'
          break;
        default:
          return null;
      }
      return url;
    },
    async watchLater(evt) {
      evt.stopPropagation();
      if (this.type === 'TrainingPlan') return;
      let action = 'add';
      if(!this.isItemInMyList) {
        await this.$store.dispatch('addMyListItem',({media_type: 'video', product_id: this.product_id, content_id: this.item.content_id}));
      } else {
        action = 'remove';
        const myListRecord = this.$store.getters.myList.filter(item => item.product_id === this.product_id && item.content_id === this.item.content_id);
        await this.$store.dispatch('deleteMyListItem',({id: myListRecord[0].id}));
        EventBus.$emit('removeFromMyList',{product_id: this.product_id, content_id: this.item.content_id})
      }
      this.m('watch_later_toggle', {title: this.title, area: this.matomo_class, action: action});
    },
    goToVideo() {
      if(this.swiping) return;
      if(this.$route.name === "home") {
        if (this.type === 'be-calm' || this.type === 'beCalm') this.$router.push('/be-calm/'+this.item.content_id)
        if (this.type === 'exercise') this.$router.push('/digital-gym/training-plans/'+this.item.content_id )
        if (this.type === 'gym' || this.item.schedule_type === 'gym') this.$router.push('/digital-gym/' + this.item.content_id)
        if (this.type === 'studio' || this.item.schedule_type === 'studio') this.$router.push('/wellbeing-studio/' + this.item.content_id)
      } else {
        if (this.type === 'be-calm' || this.type === 'beCalm') this.$router.push('/be-calm/'+this.item.content_id)
        if (this.type === 'exercise') this.$router.push('/digital-gym/training-plans/'+this.item.content_id)
        if (this.type === 'gym' || this.item.schedule_type === 'gym') this.$router.push('/digital-gym/' + this.item.content_id)
        if (this.type === 'studio' || this.item.schedule_type === 'studio') this.$router.push('/wellbeing-studio/' + this.item.content_id)
      }
    },
    async toggleDGFavourite(evt) {
      evt.stopPropagation();
      if(this.type === 'be-calm'|| this.type === 'beCalm') {
        await this.toggleBeCalmFavourite();
        return;
      }
      const payload = {
        user_id: this.$store.getters.user_id,
        event_id: this.item.content_id,
      };
      let res = await this.axios.post(
          process.env.VUE_APP_API_PATH +
          "/application/api/u/studio/toggle-favourite-gym-event",
          payload
      );
      if (res.data.success) {
        this.item.favourited = res.data.is_favourite;
        this.m('favourite_toggle', {title: this.title, area: this.matomo_class, action: res.data.is_favourite ? 'add' : 'remove'});
        if(!res.data.is_favourite) {
          EventBus.$emit('removeFromMyFavs',{product_id: this.product_id, content_id: this.item.content_id});
        }
      }
    },
    async toggleBeCalmFavourite() {
      const payload = {
        user_id: this.$store.getters.user_id,
        course_id: this.item.content_id
      }
      let res = await this.axios.post(
          process.env.VUE_APP_API_PATH +
          "/application/api/u/resources/toggle-be-calm-favourite",
          payload
      );
      if(res.data.success) {
        this.item.favourited = res.data.is_favourite;
        this.m('favourite_toggle', {title: this.title, area: this.matomo_class, action: res.data.is_favourite ? 'add' : 'remove'});
        EventBus.$emit('removeFromMyFavs',{product_id: this.product_id, content_id: this.item.content_id})
      }
    },
    resetVideo() {
      // this.player.pause();
      // this.videoIsPlaying = false;
    },
    extractVimeoId() { // Check if URL is a Vimeo URL
      if (this.item.embed_video && !this.item.embed_video.includes('vimeo')) {
        this.vimeoId = 0;
        return;
      }
      if (!/^https?:\/\/(?:www\.)?vimeo\.com\/\d+(?:\?|$)/i.test(this.item.embed_video)) this.vimeoId = 0;
      const match = /[0-9]+/.exec(this.type === 'exercise' ? this.item.url : this.item.embed_video);
      if (!match) {
        this.vimeoId = 0;
      }
      this.vimeoId = match[0];
      return match[0];
    },
    playVideo() {
      // if (this.vimeoId === 0 || this.status === 'not_started' || this.status === 'live') return;
      // if (!this.player) {
      //   this.initVideo()
      // }
      // this.videoIsPlaying = true;
      // this.player.setCurrentTime(65);
      // this.player.setMuted(true);
      // this.player.play();
    },
    initVideo() {
      // this.player = new Vimeo(this.$refs.player, {
      //   id: this.vimeoId,
      //   autoplay: false,
      //   controls: false,
      //   byline: false,
      //   title: false,
      //   portrait: false,
      //   texttrack: "none",
      // });
    },
    parseUnitOfTime(unit) {
      if(unit < 10) {
        //return '0' + unit;
        return unit;
      } else {
        return unit;
      }
    },
  },
  computed: {
    thumbnail() {
      if(this.type === 'be-calm') {
        const image = this.getBeCalmImage(this.item.title);
        return image ? image : this.item.thumbnail;
      }
      if(this.item.thumbnail) return this.item.thumbnail;
      if(this.item.schedule_type === 'gym' || this.type === 'gym') return '/img/wellbeing-studio/wellbeing-studio.svg';
      if(this.item.schedule_type === 'studio' || this.type === 'studio') return '/img/wellbeing-studio/wellbeing-studio.svg';
    },
    product_id() {
      if(this.type === 'event-progress') {
        if(this.item.schedule_type === 'gym') {
          return 22;
        } else {
          return 21;
        }
      }
      switch (this.type) {
        case 'studio':
          return 21;
        case 'gym':
          return 22;
        case 'beCalm': case 'be-calm':
          return 17;
        default:
          return 0;
      }
    },
    isItemInMyList() {
      if (this.type === 'exercise') return null;
      const productId = this.type === 'be-calm' ? 17 : this.product_id;
      const isInList = this.$store.getters.myList.filter(item =>
          Number.parseInt(item.product_id) === productId
          && Number.parseInt(item.content_id) === Number.parseInt(this.item.content_id));
      return isInList.length > 0;
    },
    isNew() {
      if(this.type === 'exercise') return false;
      const now = moment();
      const date = moment(this.item.start, "YYYY-MM-DD HH:mm:ss"); // Parse the timestamp
      const daysDifference = now.diff(date, 'days');
      return daysDifference < 5;
    },
    mins_left() {
      if(this.type === 'be-calm' || this.type === 'exercise') return null;
      if(this.progress) {
        const course_progress_data = this.course_progress;
        const channel = this.item.schedule_type === 'gym' ? 'dg' : 'ds';
        const i = course_progress_data.findIndex(item => item.channel === channel && item.id === this.item.content_id);
        return Math.round((course_progress_data[i].duration - course_progress_data[i].seconds)/60,0) + ' mins left';
      } else {
        return this.runningTime;
      }
    },
    runningTime() {
      const duration = moment.duration(moment(this.item.end).diff(moment(this.item.start)));
      const hours = parseInt(duration.asHours());
      const minutes = parseInt(duration.asMinutes()) % 60;
      let string = '';
      if(hours) {
        string += hours === 1 ? hours + ' hour ' : hours + ' hours';
      }
      if(minutes) {
        string += minutes + ' mins';
      }
      return  string;
    },
    course_progress() {
      return this.$store.getters.user.user.course_progress;
    },
    progress() {
      if(this.type === 'exercise' || this.type === 'beCalm') return false;
      const course_progress_data = this.course_progress;
      const channel = this.item.schedule_type === 'gym' ? 'dg' : 'ds';
      const i = course_progress_data.findIndex(item => item.channel === channel && item.id === this.item.content_id);
      if(i === -1) return false
      return course_progress_data[i].percent * 100;
    },
    authorName() {
      if(!this.item.author) return null;
      let author = '';
      if(this.item.author.firstname) author = this.item.author.firstname + ' ';
      if(this.item.author.lastname) author += this.item.author.lastname;
      return author;
    },
    title() {
      return this.item.title;
    },
    description() {
      if(this.item.source === 'TrainingPlan' || this.item.source === 'beCalm' || this.item.source === 'ResCourses') return this.item.details.description;
      return this.item.description;
    },
    tags() {
      return this.item.tags
    }
  }
}
</script>

<style lang="less">

</style>