<template>
  <div id="LiveEvent">
    <section class="event laya-event pt-5" v-if="$store.getters.skin === 10">
      <div class="content-container row event-content my-5" v-if="!loading">
        <div class="col-12 py-5">
          <b-button
            class="return ml-3"
            @click="goBack()"
            alt="return to all events"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              viewBox="0 0 50 50"
            >
              <g>
                <g>
                  <g>
                    <path
                      class="circle"
                      fill="#d60570"
                      d="M24.805 0c13.698 0 24.804 11.105 24.804 24.804 0 13.699-11.106 24.804-24.804 24.804C11.105 49.608 0 38.503 0 24.804 0 11.105 11.106 0 24.805 0z"
                    />
                  </g>
                  <g>
                    <path
                      fill="none"
                      stroke="#f1f1f1"
                      stroke-linecap="round"
                      stroke-miterlimit="20"
                      stroke-width="2.83"
                      d="M23.137 13.934v0l-8.908 10.028v0l7.793 10.033v0"
                    />
                  </g>
                  <g>
                    <path
                      fill="none"
                      stroke="#f1f1f1"
                      stroke-linecap="round"
                      stroke-miterlimit="20"
                      stroke-width="2.83"
                      d="M14.711 23.906h26.893"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </b-button>
          <p>
            {{
              event.schedule_type === "studio" ? "Virtual Event" : "Digital Gym"
            }}
          </p>
          <h2>{{ event.title }}</h2>
        </div>
        <div v-if="shouldRenderIFrame" :class="event.slido_hash_code ? 'videoContainer' : 'videoContainerWithoutSlido'" >
          <EventVideo :event="event" class="col-12" />
          <SlidoPoll :code="event.slido_hash_code" v-if="event.slido_hash_code"/>
        </div>
        <div class="not-available" v-else>
          <h3>{{ componentText.unavailable }}</h3>
        </div>
        <div class="col-12 pb-5">
          <div class="event-info">
            <p class="date-title mt-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="23"
                viewBox="0 0 24 23"
              >
                <g>
                  <g>
                    <path
                      fill="#999b9e"
                      d="M17.69 3.139H20.346c1.025.003 1.824.643 2.042 1.642.027.123.035.253.035.379.004.876.002 1.752.002 2.627l-.003.08H1.602c-.003-.025-.008-.045-.008-.065.002-.917-.01-1.834.009-2.75.02-.933.785-1.762 1.712-1.888.1-.014.204-.023.306-.023.868-.002 1.735-.002 2.603-.002h.098c.002.056.006.103.006.15.003.35-.006.699.01 1.048.02.41.377.728.79.729.41 0 .764-.305.79-.713.022-.328.012-.657.015-.986v-.218h8.153c0 .354-.011.707.004 1.058.007.152.04.314.107.449.15.308.526.467.855.391.36-.081.623-.377.634-.737.009-.34.003-.682.004-1.023v-.149zM7.922 1.524c0-.297.013-.586-.003-.872-.02-.354-.282-.63-.638-.708-.322-.07-.676.1-.847.396a.928.928 0 0 0-.106.476v.719h-.152c-.826 0-1.652-.002-2.48 0a3.78 3.78 0 0 0-1.082.146 3.6 3.6 0 0 0-2.11 1.637c-.37.61-.516 1.28-.516 1.99v13.319c0 .387.043.769.158 1.142.315 1.018.956 1.755 1.903 2.23.536.27 1.11.369 1.706.367l16.49-.001c.217 0 .437-.01.65-.04a3.555 3.555 0 0 0 2.33-1.327c.56-.687.806-1.484.806-2.365-.003-4.444-.001-8.888-.002-13.332 0-.099.002-.198-.005-.295-.062-.955-.418-1.778-1.107-2.445-.72-.696-1.591-1.026-2.59-1.026h-2.64c0-.3.009-.58-.002-.859a.801.801 0 0 0-.741-.742c-.47-.027-.848.333-.857.819-.005.255-.001.509-.001.77z"
                    />
                  </g>
                </g>
              </svg>
              {{
                getFormatedDate(event.start_date_time, "dddd DD MMMM &#39;YY")
              }}
              <span class="px-4">•</span
              ><span v-if="videoAvailable">
                <span v-if="event.instructor === ''">{{
                  componentText.expert
                }}</span>
                <span v-else>With {{ event.instructor }}</span> </span
              ><span v-else>
                <i class="far fa-clock"></i>
                {{ getFormatedDate(event.start_date_time, "HH:mm a") }} -
                {{ getFormatedDate(event.end_date_time, "HH:mm a") }}</span
              >
            </p>
            <p class="py-5" v-html="event.description"></p>
            <div class="btn-wrap pb-5" v-if="showReminder">
              <b-button @click="remindMe" class="btn shadow py-3 px-5 mx-4 my-3 my-md-0 btn-secondary cta-button">{{
                !event.reminder_registered ? "Remind me" : "Cancel reminder"
              }}</b-button>
            </div>
            <div v-else>
              <b-button
              v-if="!$store.getters.user.access_code_user"
                @click="favourite()"
                class="px-5 py-3"
                :class="[
                  event.is_favourited ? 'cta-button' : 'cta-button btn-outline',
                ]"
                >{{
                  event.is_favourited ? "Favourited" : "Favourite"
                }}</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="spectrum-event bg-grey" v-else>
      <section class="spectrum-intro">
        <div class="content-container row event-content py-5">
          <div class="col-2 return-wrap">
            <b-button
              class="return ml-3"
              @click="goBack()"
              alt="return to all events"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                viewBox="0 0 50 50"
              >
                <g>
                  <g>
                    <g>
                      <path
                        class="circle"
                        fill="#d60570"
                        d="M24.805 0c13.698 0 24.804 11.105 24.804 24.804 0 13.699-11.106 24.804-24.804 24.804C11.105 49.608 0 38.503 0 24.804 0 11.105 11.106 0 24.805 0z"
                      />
                    </g>
                    <g>
                      <path
                        fill="none"
                        stroke="#f1f1f1"
                        stroke-linecap="round"
                        stroke-miterlimit="20"
                        stroke-width="2.83"
                        d="M23.137 13.934v0l-8.908 10.028v0l7.793 10.033v0"
                      />
                    </g>
                    <g>
                      <path
                        fill="none"
                        stroke="#f1f1f1"
                        stroke-linecap="round"
                        stroke-miterlimit="20"
                        stroke-width="2.83"
                        d="M14.711 23.906h26.893"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </b-button>
          </div>
          <div class="col-8 event-title pt-5">
            <h2>{{ event.title }}</h2>
          </div>
          <div
            class="col-2"
            :class="{ 'py-3': event.schedule_type === 'studio' }"
          >
            <img
              :src="
                event.schedule_type === 'studio'
                  ? '/img/wellbeing-studio/wellbeing-studio.svg'
                  : '/img/wellbeing-studio/dg-image.svg'
              "
            />
          </div>
        </div>
      </section>
      <section class="bg-white py-5">
        <div class="content-container event-content row py-5" v-if="!loading">
          <div v-if="shouldRenderIFrame" :class="event.slido_hash_code ? 'videoContainer' : 'videoContainerWithoutSlido'" >
            <EventVideo :event="event" class="col-12" />
            <SlidoPoll :code="event.slido_hash_code" v-if="event.slido_hash_code"/>
          </div>

          <span class="not-available" v-else>
            <h3>{{ componentText.unavailable }}</h3>
          </span>
          <div class="col-12">
            <div class="event-info">
              <p class="date-title mt-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="23"
                  viewBox="0 0 24 23"
                >
                  <g>
                    <g>
                      <path
                        fill="#999b9e"
                        d="M17.69 3.139H20.346c1.025.003 1.824.643 2.042 1.642.027.123.035.253.035.379.004.876.002 1.752.002 2.627l-.003.08H1.602c-.003-.025-.008-.045-.008-.065.002-.917-.01-1.834.009-2.75.02-.933.785-1.762 1.712-1.888.1-.014.204-.023.306-.023.868-.002 1.735-.002 2.603-.002h.098c.002.056.006.103.006.15.003.35-.006.699.01 1.048.02.41.377.728.79.729.41 0 .764-.305.79-.713.022-.328.012-.657.015-.986v-.218h8.153c0 .354-.011.707.004 1.058.007.152.04.314.107.449.15.308.526.467.855.391.36-.081.623-.377.634-.737.009-.34.003-.682.004-1.023v-.149zM7.922 1.524c0-.297.013-.586-.003-.872-.02-.354-.282-.63-.638-.708-.322-.07-.676.1-.847.396a.928.928 0 0 0-.106.476v.719h-.152c-.826 0-1.652-.002-2.48 0a3.78 3.78 0 0 0-1.082.146 3.6 3.6 0 0 0-2.11 1.637c-.37.61-.516 1.28-.516 1.99v13.319c0 .387.043.769.158 1.142.315 1.018.956 1.755 1.903 2.23.536.27 1.11.369 1.706.367l16.49-.001c.217 0 .437-.01.65-.04a3.555 3.555 0 0 0 2.33-1.327c.56-.687.806-1.484.806-2.365-.003-4.444-.001-8.888-.002-13.332 0-.099.002-.198-.005-.295-.062-.955-.418-1.778-1.107-2.445-.72-.696-1.591-1.026-2.59-1.026h-2.64c0-.3.009-.58-.002-.859a.801.801 0 0 0-.741-.742c-.47-.027-.848.333-.857.819-.005.255-.001.509-.001.77z"
                      />
                    </g>
                  </g>
                </svg>
                {{
                  getFormatedDate(event.start_date_time, "dddd DD MMMM &#39;YY")
                }}
                <span class="px-4">•</span
                ><span v-if="videoAvailable">
                  <span v-if="event.instructor === ''">{{
                    componentText.expert
                  }}</span>
                  <span v-else>With {{ event.instructor }}</span> </span
                ><span v-else>
                  <i class="far fa-clock"></i>
                  {{ getFormatedDate(event.start_date_time, "HH:mm a") }} -
                  {{ getFormatedDate(event.end_date_time, "HH:mm a") }}</span
                >
              </p>
              <p class="py-5" v-html="event.description"></p>
              <div class="btn-wrap pb-5" v-if="showReminder">
                <div v-if="$store.getters.user.access_code_user" class="d-flex align-items-center justify-content-center">
                  <a :href="event.google_cal_link" target="_blank"><b-button class="calBtn btn shadow py-3 px-5 mx-4 my-3 my-md-0 btn-secondary cta-button">
                    Add to Google Calendar
                  </b-button></a>
                  <a :href="event.ical_link" target="_blank"><b-button class="calBtn btn shadow py-3 px-5 mx-4 my-3 my-md-0 btn-secondary cta-button">
                    Add to Outlook Calendar
                  </b-button></a>
                </div>

                <b-button v-else
                  @click="remindMe"
                  class="btn shadow py-3 px-5 mx-4 my-3 my-md-0 btn-secondary cta-button"
                  :class="{ 'btn-outline': event.reminder_registered }"
                  >{{
                    !event.reminder_registered
                      ? componentText.remind
                      : componentText.cancel
                  }}</b-button
                >
              </div>
              <div v-else>
                <b-button
                  @click="favourite()"
                  class="px-5 py-3"
                  :class="[
                    event.is_favourited
                      ? 'cta-button'
                      : 'cta-button btn-outline',
                  ]"
                  v-if="$store.getters.skin !== 20 && !$store.getters.user.access_code_user"
                  >{{
                    event.is_favourited ? "Favourited" : "Favourite"
                  }}</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
    <div v-if="reminderConfirmation" class="log-confirmation">
      {{ componentText.remindConfirm }} <i class="fas fa-check"></i>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import EventVideo from "../components/LiveEvent/EventVideo";
import SlidoPoll from "@/components/NewStudio&CourseLayout/Content/SlidoPoll.vue";
//TODO:: Consider breaking this into laya / spectrum components
export default {
  name: "LiveEvent",
  components: { EventVideo, SlidoPoll },
  data() {
    return {
      event: {},
      loading: true,
      disclaimerVisible: false,
      reminderConfirmation: false,
      shouldRenderIFrame: false,
    };
  },
  mounted() {
    this.getEvent();
  },
  methods: {
    goBack() {
      if(this.$route.query.back === 'home') {
          this.$router.push("/").catch((err) => {
          })
      } else if (this.event.schedule_type === "studio") {
        this.$router.push("/wellbeing-studio").catch((err) => {});
      } else {
        if(this.$route.query.ref === 'cs'){
          this.$router.push("/digital-gym?selectedView=%27classes%27").catch((err) => {});
        }else{
          this.$router.push("/digital-gym").catch((err) => {});
        }
      }
    },

    async getEvent() {
      const payload = {
        user_id: this.$store.getters.user_id,
        event_id: this.$route.params.id,
        access_code_user: this.$store.getters.user.access_code_user
      };
      let res = await this.axios.post(
        process.env.VUE_APP_API_PATH +
          "/application/api/u/studio/get-virtual-event",
        payload
      );
      if (res.data.success) {
        this.event = res.data.event;
        this.loading = false;
        this.setRefreshTimer();
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        this.showMsgModal(
          "Woops, we couldn't find that event. Please try another!"
        ).then(() => {
          this.$route.path.includes("gym")
            ? this.$router.push("/digital-gym")
            : this.$router.push("/wellbeing-studio");
        });
      }
    },
    async favourite() {
      const payload = {
        user_id: this.$store.getters.user_id,
        event_id: this.$route.params.id,
      };
      let res = await this.axios.post(
        process.env.VUE_APP_API_PATH +
          "/application/api/u/studio/toggle-favourite-gym-event",
        payload
      );
      if (res.data.success) {
        this.event.is_favourited = res.data.is_favourite;
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      }
    },

    setRefreshTimer() {
      let self = this;
      if (this.event.embed_code === false) {
        const eventStartTime = moment(this.event.start_date_time)
          .subtract(1, "minutes")
          .format("X");
        const now = moment().format("X");
        const diff = eventStartTime - now;
        if (now < eventStartTime && diff * 1000 < 2147483647) {
          setTimeout(function () {
            self.getEvent();
          }, diff * 1000);
        }
      } else {
        this.shouldRenderIFrame = true;
      }
    },

    async remindMe() {
      const payload = {
        user_id: this.$store.getters.user_id,
        event_id: this.$route.params.id,
      };
      if (!this.event.reminder_registered) {
        let res = await this.axios.post(
          process.env.VUE_APP_API_PATH +
            "/application/api/u/studio/register-reminder",
          payload
        );
        if (res.data.success) {
          this.event.reminder_registered = true;
          this.reminderConfirmation = true;
          setTimeout(() => (this.reminderConfirmation = false), 5000);
        }
      } else {
        let res = await this.axios.post(
          process.env.VUE_APP_API_PATH +
            "/application/api/u/studio/cancel-reminder",
          payload
        );
        if (res.data.success) {
          this.event.reminder_registered = false;
        }
      }
    },
  },
  computed: {
    videoAvailable() {
      const now = moment();
      const vidAvailable = moment(this.event.start_date_time);
      return now > vidAvailable || now.isSame(vidAvailable, "day");
    },
    showReminder() {
      const now = moment();
      const vidAvailable = moment(this.event.start_date_time);
      return now < vidAvailable;
    },
    decodedEmbed() {
      return this.event.embed_code ? atob(this.event.embed_code) : "";
    },
    componentText() {
      return this.getText.liveEvent;
    },
  },
};
</script>

<style lang="less">
#LiveEvent {
  font-size: 1.6rem;
  .videoContainerWithoutSlido {
    width: 100%;
    height: 400px;
  }
  .videoContainer {
    min-height: 450px;
    width: 100%;
    display: grid;
    grid-template-columns: 650px 1fr;
    #SlidoPoll {
      margin: 5px auto;

    }
  }

  @media only screen and (max-width: 999px) {
    .videoContainer {
      grid-template-columns: 1fr !important;
      #SlidoPoll {
        width: 350px !important;
        height: 450px;
        margin: 15px auto;
      }
    }
  }
  .spectrum-intro {

    .event-title {
      display: flex;
      justify-content: center;
      align-items: center;
      h2 {
        font-family: FilsonProBold, sans-serif;
        letter-spacing: 0;
        font-size: 2.8rem;
        margin-bottom: 0;
      }
    }
    .return-wrap {
      display: flex;
      align-items: center;
      .return {
        position: unset;
      }
    }
  }
  .event-content {
    position: relative;
    .return {
      background: transparent;
      position: absolute;
      top: 30px;
      left: 30px;
      .circle {
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
          border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
          -webkit-box-shadow 0.15s ease-in-out;
      }
      &:hover {
        .circle {
          fill: darken(#d60570, 10%);
        }
      }
    }
    .video-wrap {
      text-align: center;
      max-width: 100%;
      margin: auto;
      .aspect-ratio {
        max-width: 600px;
        width: 100%;
        position: relative;
        margin: auto;
        &:after {
          padding-top: 56.4%;
          display: block;
          content: "";
        }
        iframe {
          width: 100%;
          height: 100%;
          border-radius: 30px;
          border: 1px solid #afb0b2;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }
    }
    .text-link {
      font-family: FilsonProBold;
    }
    #disclaimer {
      max-width: 600px;
      margin: auto;
      h4 {
        font-size: 1.6rem;
        margin-bottom: 10px;
      }
      p,
      li {
        font-size: 1.4rem;
        text-align: left;
      }
    }
    .not-available {
      max-width: 600px;
      margin: auto;
      text-align: center;
      h3 {
        padding: 0;
      }
    }
    .event-info {
      max-width: 600px;
      margin: auto;
      text-align: center;
      svg {
        transform: translateY(-3px);
        margin-right: 5px;
      }
      i {
        font-size: 2.1rem;
        color: #999b9e;
      }
      .date-title {
        font-size: 1.7rem;
        letter-spacing: -0.4px;
      }
      .cta-button {
        font-size: 2rem;
      }
    }
  }
  .spectrum-event {
    .not-available h3 {
      letter-spacing: 0;
      font-size: 2.1rem;
    }
  }
  .log-confirmation {
    font-size: 3.3rem;
    font-family: "FilsonProBold", sans-serif;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    width: 100%;
    text-align: center;
    z-index: 9999;
    padding: 80px 0;
  }
  .calBtn {
    font-size: 1.5rem !important;
  }
  .cta-button{
    padding-top: 12.5px !important;
    padding-bottom: 12.5px !important;
  }
  @media (max-width: 675px) {
    h2 {
      font-size: 3.5rem;
    }
    .event-content {
      .return {
        top: 20px;
        left: 20px;
        svg {
          height: 30px;
          width: 30px;
        }
      }
    }
  }
}
</style>
